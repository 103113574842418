var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { useStatus } from 'common/statusContext';
import { Button } from 'components/button/Button.component';
import { List } from 'components/list/List.component';
import { Switch } from 'components/switch/Switch';
import { ConfirmDialog } from 'pages/priceListImport/confirmDialog/ConfirmDialog.component';
import { usePriceListApi } from 'pages/priceListImport/usePriceListApi';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { formatDate } from 'utils/formatDate';
import { immutableSort } from 'utils/immutableSort';
import { useInterval } from '../dataImport/useInterval';
import './priceListImportSummary.css';
import SessionStorage from 'common/sessionStorage';
export var PriceListImportSummary = withCancellation(function (_a) {
    var priceListName = _a.priceListName, cancelPopupMessage = _a.cancelPopupMessage;
    var _b = useState(undefined), priceListModels = _b[0], setPriceListModels = _b[1];
    var _c = useState(undefined), mode = _c[0], setMode = _c[1];
    var _d = useState(null), pollingDelay = _d[0], setPollingDelay = _d[1];
    var _e = useState(false), openConfirmDialog = _e[0], setOpenConfirmDialog = _e[1];
    var history = useHistory();
    var setStatus = useStatus().setStatus;
    var isEmployeeLeasing = SessionStorage.get('employeeLeasingContext');
    var priceListsApi = isEmployeeLeasing ? usePriceListApi({ context: 'employeeLeasing' }) : usePriceListApi();
    function getAvailableModelData() {
        return __awaiter(this, void 0, void 0, function () {
            var availableModelsResponse, availableModelsWithPublishedData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, priceListsApi.getAvailableModel(priceListName)];
                    case 1:
                        availableModelsResponse = _a.sent();
                        availableModelsWithPublishedData = availableModelsResponse.map(function (model) {
                            return __assign(__assign({}, model), { published: false });
                        });
                        ReactDOM.unstable_batchedUpdates(function () {
                            setPriceListModels(availableModelsWithPublishedData);
                            setStatus('success');
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        function getImportStatus() {
            return __awaiter(this, void 0, void 0, function () {
                var statusResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, priceListsApi.getImportStatus()];
                        case 1:
                            statusResponse = _a.sent();
                            if (!(statusResponse[0].status === 'DOWNLOADED')) return [3 /*break*/, 2];
                            setMode('MODELS');
                            getAvailableModelData();
                            return [3 /*break*/, 5];
                        case 2:
                            if (!(statusResponse[0].status === 'IMPORTING')) return [3 /*break*/, 3];
                            setMode('IMPORTING');
                            setPollingDelay(2000);
                            return [3 /*break*/, 5];
                        case 3:
                            if (!(statusResponse[0].status === 'ERROR')) return [3 /*break*/, 5];
                            setMode('ERROR');
                            return [4 /*yield*/, priceListsApi.cancelImport(priceListName)];
                        case 4:
                            _a.sent();
                            SessionStorage.remove('employeeLeasingContext');
                            history.push('price-lists');
                            _a.label = 5;
                        case 5: return [2 /*return*/];
                    }
                });
            });
        }
        getImportStatus();
    }, []);
    var handleInterval = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var statusResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, priceListsApi.getImportStatus()];
                case 1:
                    statusResponse = _a.sent();
                    if (!(statusResponse[0].status === 'ERROR')) return [3 /*break*/, 3];
                    setMode('ERROR');
                    return [4 /*yield*/, priceListsApi.cancelImport(priceListName)];
                case 2:
                    _a.sent();
                    SessionStorage.remove('employeeLeasingContext');
                    history.push('price-lists');
                    _a.label = 3;
                case 3:
                    if (statusResponse[0].status === '') {
                        setPollingDelay(null);
                        SessionStorage.remove('employeeLeasingContext');
                        history.push('price-lists');
                    }
                    return [2 /*return*/];
            }
        });
    }); }, []);
    function handleOnCancelClick() {
        setOpenConfirmDialog(true);
    }
    function handleOnCancelDialog() {
        setOpenConfirmDialog(false);
    }
    function handleOnConfirmDialog() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, priceListsApi.cancelImport(priceListName)];
                    case 1:
                        _a.sent();
                        setOpenConfirmDialog(false);
                        SessionStorage.remove('employeeLeasingContext');
                        history.push('/price-lists');
                        return [2 /*return*/];
                }
            });
        });
    }
    function handlePublishSwitchChange(checked, modelId) {
        setPriceListModels(function (currentPublicationData) {
            var editedModelIndex = currentPublicationData.findIndex(function (model) { return model.id === modelId; });
            return __spreadArray(__spreadArray(__spreadArray([], currentPublicationData.slice(0, editedModelIndex), true), [
                __assign(__assign({}, currentPublicationData[editedModelIndex]), { published: checked })
            ], false), currentPublicationData.slice(editedModelIndex + 1), true);
        });
    }
    function handleOnSavePriceListClick() {
        return __awaiter(this, void 0, void 0, function () {
            var priceListModelsToSend;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        priceListModelsToSend = priceListModels.filter(function (model) { return model.toBeUploaded; }).map(function (model) {
                            return {
                                id: model.id,
                                published: model.published
                            };
                        });
                        return [4 /*yield*/, priceListsApi.applyPriceList(priceListName, priceListModelsToSend)];
                    case 1:
                        _a.sent();
                        setMode('IMPORTING');
                        setPollingDelay(2000);
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleOnBackWithErrorClick() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, priceListsApi.cancelImport(priceListName)];
                    case 1:
                        _a.sent();
                        SessionStorage.remove('employeeLeasingContext');
                        history.push('price-lists');
                        return [2 /*return*/];
                }
            });
        });
    }
    var sortedPriceListData = priceListModels &&
        immutableSort(priceListModels, function (modelA, modelB) { return modelA.name.localeCompare(modelB.name); });
    useInterval(handleInterval, pollingDelay);
    function handleBackClick() {
        SessionStorage.remove('employeeLeasingContext');
        history.push('price-lists');
    }
    return (mode === 'MODELS' ?
        React.createElement("div", { className: "priceListImportSummaryWrapper", "data-testid": "pricelist-import-summary-wrapper" },
            React.createElement("div", { className: "priceListImportSummaryTableWrapper" },
                React.createElement("div", { className: "priceListImportSummaryTableHeaders" },
                    React.createElement("div", { className: "priceListImportSummaryTableLeftCell" }),
                    React.createElement("div", null, "Data obowi\u0105zywania cennika"),
                    React.createElement("div", { className: "priceListImportSummaryTableRightCell priceListImportSummaryTableRightCellHeader" }, "Publikuj")),
                priceListModels ? (React.createElement("div", { "data-testid": 'pricelist-import-summary-table' },
                    React.createElement(List.Root, null, sortedPriceListData.map(function (model) { return (React.createElement(List.Item, { key: model.id, height: "fixed", cursor: 'default' },
                        React.createElement("div", { className: "priceListImportSummaryTableRow" },
                            React.createElement("div", { className: "priceListImportSummaryTableLeftCell ".concat(!model.toBeUploaded ? 'modelDisabled' : '') }, model.name),
                            React.createElement("div", null, model.toBeUploaded ? (React.createElement("div", null, formatDate(new Date(model.selectedFromDate)))) : null),
                            React.createElement("div", { className: "priceListImportSummaryTableRightCell" }, model.toBeUploaded ? (React.createElement(Switch, { checked: model.published, onCheckedChange: function (checked) {
                                    return handlePublishSwitchChange(checked, model.id);
                                } })) : null)))); })))) : null),
            React.createElement("div", { className: "summaryActionsWrapper" },
                React.createElement("div", { className: "summaryButtonWrapper" },
                    React.createElement(Button, { type: "button", variant: "secondary", onClick: handleOnCancelClick }, "Anuluj")),
                React.createElement("div", { className: "summaryButtonWrapper" },
                    React.createElement(Button, { type: "button", onClick: handleOnSavePriceListClick }, "Zapisz cennik"))),
            React.createElement(ConfirmDialog, { open: openConfirmDialog, message: cancelPopupMessage, onCancel: handleOnCancelDialog, onConfirm: handleOnConfirmDialog })) : mode === 'IMPORTING' ?
        React.createElement("div", { className: 'priceListImportSummaryMessage' },
            React.createElement("p", null, "Trwa zapis danych do bazy...")) : mode === 'CANCELLING' ?
        React.createElement("div", null,
            React.createElement("p", null, "Anulacja poprzedniego importu nie zosta\u0142a jeszcze zako\u0144czona"),
            React.createElement("div", { className: "summaryButtonWrapper" },
                React.createElement(Button, { type: 'button', onClick: handleBackClick }, "Powr\u00F3t"))) :
        React.createElement("div", { className: 'priceListImportSummaryMessage' },
            React.createElement("p", { className: 'priceListImportSummaryError' }, "B\u0142\u0105d zapisu danych"),
            React.createElement("div", { className: "summaryButtonWrapper" },
                React.createElement(Button, { type: 'button', onClick: handleOnBackWithErrorClick }, "Powr\u00F3t"))));
});
