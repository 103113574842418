var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState } from 'react';
export function useEditSpecialOfferForm(requestSubjects) {
    var _a = useState(Object.fromEntries(requestSubjects.map(function (requestSubject) {
        return [requestSubject.specialOfferId, {
                newCarCounter: ''
            }];
    }))), updateQuerySubject = _a[0], setUpdateQuerySubject = _a[1];
    function handleSetUpdateQuerySubject(specialOfferId, updatedOption, updatedValue) {
        var _a, _b;
        setUpdateQuerySubject(__assign(__assign({}, updateQuerySubject), (_a = {}, _a[specialOfferId] = __assign(__assign({}, updateQuerySubject[specialOfferId]), (_b = {}, _b[updatedOption] = updatedValue, _b)), _a)));
    }
    function onNewCarAmountChange(event, specialOfferId) {
        var result = event.target.value.replace(/\D/g, '');
        handleSetUpdateQuerySubject(specialOfferId, 'newCarCounter', result);
    }
    function onNewDealerMarginChange(_, option, specialOfferId) {
        handleSetUpdateQuerySubject(specialOfferId, 'newDealerMargin', option);
    }
    function onNewFleetCoofinancingChange(_, option, specialOfferId) {
        handleSetUpdateQuerySubject(specialOfferId, 'newFleetCoofinancing', option);
    }
    function onFundingFormChange(_, option, specialOfferId) {
        var _a;
        setUpdateQuerySubject(__assign(__assign({}, updateQuerySubject), (_a = {}, _a[specialOfferId] = __assign(__assign({}, updateQuerySubject[specialOfferId]), { newFundingForm: option, newFundingProvider: '', newOtherProvider: '' }), _a)));
    }
    function onProviderChange(_, option, specialOfferId) {
        handleSetUpdateQuerySubject(specialOfferId, 'newFundingProvider', option);
    }
    function onOtherProviderChange(event, specialOfferId) {
        var value = event.currentTarget.value;
        handleSetUpdateQuerySubject(specialOfferId, 'newOtherProvider', value);
    }
    var formApi = {
        onNewCarAmountChange: onNewCarAmountChange,
        onNewDealerMarginChange: onNewDealerMarginChange,
        onNewFleetCoofinancingChange: onNewFleetCoofinancingChange,
        onFundingFormChange: onFundingFormChange,
        onProviderChange: onProviderChange,
        onOtherProviderChange: onOtherProviderChange
    };
    return {
        updateQuerySubject: updateQuerySubject,
        formApi: formApi
    };
}
