var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { CreatorHeader } from 'components/creatorHeader/CreatorHeader.component';
import { Tabs } from 'components/tabs/Tabs.component';
import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import './creator.css';
import { PricesList } from './priceList/PricesList.component';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { Drive } from './drive/Drive.component';
import { Version } from './version/Version.component';
import { Options } from './options/Options.component';
import { useOptionsTab } from './options/useOptionsTab';
import { usePriceListTab } from './priceList/usePriceListTab';
import { Exterior } from './exterior/Exterior.component';
import { useExteriorTab } from './exterior/useExteriorTab';
import { useDriveTab } from './drive/useDriveTab';
import { useVersionTab } from './version/useVersionTab';
import { Financing } from './financing/Financing.component';
import { useStatus } from '../../common/statusContext';
import { Spinner } from 'components/spinner/Spinner';
import uuid from 'react-uuid';
import { useCreatorApi } from './useCreatorApi';
import UserContext from 'common/userContext';
import ReactDOM from 'react-dom';
import { AccessoriesTab } from './accessories/AccessoriesTab.component';
import { useAccessoriesTab } from './accessories/useAccessoriesTab';
import { Interior } from './interior/Interior.component';
import { useInteriorTab } from './interior/useInteriorTab';
import { Diff } from './diff/Diff.component';
import { useCarPreview } from './carPreview/usePreview';
import { CarPreview } from './carPreview/CarPreview.component';
import { Attractiveness } from './attractiveness/Attractiveness.component';
import { useAttractiveness } from './attractiveness/useAttractiveness';
import { SummaryTab } from './summary/SummaryTab.component';
import { useSummaryTab } from './summary/useSummaryTab';
import { usePricesListApi } from './priceList/usePricesListApi';
import { useAppError } from '../../common/appErrorContext';
import { ErrorPopup } from './popup/ErrorPopup.component';
import { ConfigErrorPopup } from './popup/ConfigErrorPopup.component';
import SessionStorage from '../../common/sessionStorage';
import { useFinancingTab } from './financing/useFInancingTab';
import { immutableSort } from 'utils/immutableSort';
import { useFinancingApi } from './financing/useFinancingApi';
function isFromVCP() {
    return UserContext.isFromVCP();
}
export var Creator = withCancellation(function () {
    var _a = useStatus(), status = _a.status, setStatus = _a.setStatus;
    var _b = useAppError(), appError = _b.appError, setAppError = _b.setAppError;
    function handleSetAppError(newAppError) {
        setAppError(newAppError);
    }
    var location = useLocation();
    var _c = useState(), token = _c[0], setToken = _c[1];
    function handleOnTokenChange(token) {
        setToken(token);
    }
    var _d = useState(undefined), carModelId = _d[0], setCarModelId = _d[1];
    var _e = useState(undefined), offerId = _e[0], setOfferId = _e[1];
    var _f = useState(undefined), offer = _f[0], setOffer = _f[1];
    function handleChangeOffer(offer) {
        setOffer(offer);
    }
    var _g = useState(false), isTokenConfigurationVisible = _g[0], setIsTokenConfigurationVisible = _g[1];
    function handleChangeTokenConfigurationVisible(value) {
        setIsTokenConfigurationVisible(value);
    }
    var creatorApi = useCreatorApi();
    var _h = window.location.href.split('?'), searchParamsString = _h[1];
    var searchParams = new URLSearchParams(searchParamsString);
    var _j = useState(''), clientId = _j[0], setClientId = _j[1];
    function handleChangeClientId(id) {
        setClientId(id);
    }
    var _k = useState(undefined), clientParties = _k[0], setClientParties = _k[1];
    var _l = useState(undefined), selectedParty = _l[0], setSelectedParty = _l[1];
    function handleChangeSelectedParty(partyId) {
        return __awaiter(this, void 0, void 0, function () {
            var setPartyRequest, getOfferResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setSelectedParty(partyId);
                        setPartyRequest = isFromVCP() ? creatorApi.setPartyVcp : creatorApi.setParty;
                        return [4 /*yield*/, setPartyRequest(offerId, encodeURIComponent(partyId))];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, creatorApi.getOffer(offerId)];
                    case 2:
                        getOfferResponse = _a.sent();
                        handleChangeOffer(getOfferResponse);
                        return [2 /*return*/];
                }
            });
        });
    }
    var _m = useState([]), pendingSyncData = _m[0], setPendingSyncData = _m[1];
    var isClientCFM = offer && offer.printOptions && offer.printOptions.includes('CFM');
    var _o = useState(undefined), calculations = _o[0], setCalculations = _o[1];
    var _p = useState(undefined), wallboxCalculations = _p[0], setWallboxCalculations = _p[1];
    var _q = useState(undefined), foundingProducts = _q[0], setFoundingProducts = _q[1];
    var _r = useState(undefined), foundingProductsConfiguration = _r[0], setFoundingProductsConfiguration = _r[1];
    var financingApi = useFinancingApi();
    var isReadOnly = offer && offer.status === 'DRAFT_READ_ONLY';
    function handleOnAddCalculation(type) {
        var id = uuid();
        switch (type) {
            case 'CAR':
                setCalculations(function (currentCalculations) {
                    return __spreadArray(__spreadArray([], currentCalculations, true), [
                        {
                            id: id,
                            forPrinting: isClientCFM ? false : true,
                            isCalculationIdRegister: false,
                            isCalculateError: false
                        }
                    ], false);
                });
                break;
            case 'WALLBOX':
                setWallboxCalculations(function (currentCalculations) {
                    return __spreadArray(__spreadArray([], currentCalculations, true), [
                        {
                            id: id,
                            forPrinting: true
                        }
                    ], false);
                });
                break;
        }
    }
    function handleOnPrintSwitchChange(checked, calculationId, type) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, editedCalculation, calculationsFromSessionStorage, getCalculationFromSessionStorageById, editedCalculationIndexFromSessionStorage, getCalculationFromSessionStorageById, editedCalculationIndexFromSessionStorage;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = type;
                        switch (_a) {
                            case 'CAR': return [3 /*break*/, 1];
                            case 'WALLBOX': return [3 /*break*/, 6];
                        }
                        return [3 /*break*/, 7];
                    case 1:
                        editedCalculation = calculations.find(function (calculation) { return calculation.id === calculationId; });
                        calculationsFromSessionStorage = SessionStorage.get('calculations');
                        if (!editedCalculation.isCalculationIdRegister) return [3 /*break*/, 5];
                        if (!checked) return [3 /*break*/, 3];
                        setStatus('pending');
                        return [4 /*yield*/, financingApi.enablePrint(calculationId)];
                    case 2:
                        _b.sent();
                        if (calculationsFromSessionStorage) {
                            getCalculationFromSessionStorageById = calculationsFromSessionStorage.find(function (calculate) { return calculate.id === calculationId; });
                            editedCalculationIndexFromSessionStorage = calculationsFromSessionStorage.findIndex(function (calculate) { return calculate.id === calculationId; });
                            SessionStorage.set('calculations', __spreadArray(__spreadArray(__spreadArray([], calculationsFromSessionStorage.slice(0, editedCalculationIndexFromSessionStorage), true), [
                                __assign(__assign({}, getCalculationFromSessionStorageById), { forPrinting: true })
                            ], false), calculationsFromSessionStorage.slice(editedCalculationIndexFromSessionStorage + 1), true));
                        }
                        setStatus('success');
                        return [3 /*break*/, 5];
                    case 3:
                        setStatus('pending');
                        return [4 /*yield*/, financingApi.disablePrint(calculationId)];
                    case 4:
                        _b.sent();
                        if (calculationsFromSessionStorage) {
                            getCalculationFromSessionStorageById = calculationsFromSessionStorage.find(function (calculate) { return calculate.id === calculationId; });
                            editedCalculationIndexFromSessionStorage = calculationsFromSessionStorage.findIndex(function (calculate) { return calculate.id === calculationId; });
                            SessionStorage.set('calculations', __spreadArray(__spreadArray(__spreadArray([], calculationsFromSessionStorage.slice(0, editedCalculationIndexFromSessionStorage), true), [
                                __assign(__assign({}, getCalculationFromSessionStorageById), { forPrinting: false })
                            ], false), calculationsFromSessionStorage.slice(editedCalculationIndexFromSessionStorage + 1), true));
                        }
                        setStatus('success');
                        _b.label = 5;
                    case 5:
                        setCalculations(function (currentCalculations) {
                            var editedCalculationIndex = currentCalculations.findIndex(function (calculation) { return calculation.id === calculationId; });
                            return __spreadArray(__spreadArray(__spreadArray([], currentCalculations.slice(0, editedCalculationIndex), true), [
                                __assign(__assign({}, currentCalculations[editedCalculationIndex]), { forPrinting: checked })
                            ], false), currentCalculations.slice(editedCalculationIndex + 1), true);
                        });
                        return [3 /*break*/, 7];
                    case 6:
                        setWallboxCalculations(function (currentCalculations) {
                            var editedCalculationIndex = currentCalculations.findIndex(function (calculation) { return calculation.id === calculationId; });
                            return __spreadArray(__spreadArray(__spreadArray([], currentCalculations.slice(0, editedCalculationIndex), true), [
                                __assign(__assign({}, currentCalculations[editedCalculationIndex]), { forPrinting: checked })
                            ], false), currentCalculations.slice(editedCalculationIndex + 1), true);
                        });
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    }
    function handleOnDeleteCalculation(id, type) {
        switch (type) {
            case 'CAR':
                var editedCalculation = calculations.find(function (calculation) { return calculation.id === id; });
                var calculationsFromSessionStorage = SessionStorage.get('calculations');
                if (editedCalculation.isCalculationIdRegister) {
                    setStatus('pending');
                    financingApi.deleteCalculate(id);
                    if (calculationsFromSessionStorage) {
                        var editedCalculationIndexFromSessionStorage = calculationsFromSessionStorage.findIndex(function (calculate) { return calculate.id === id; });
                        if (calculationsFromSessionStorage.length === 1 && editedCalculationIndexFromSessionStorage === 0) {
                            SessionStorage.remove('calculations');
                        }
                        else {
                            SessionStorage.set('calculations', __spreadArray(__spreadArray([], calculationsFromSessionStorage.slice(0, editedCalculationIndexFromSessionStorage), true), calculationsFromSessionStorage.slice(editedCalculationIndexFromSessionStorage + 1), true));
                        }
                    }
                    setStatus('success');
                }
                setCalculations(function (currentCalculation) {
                    return currentCalculation.filter(function (calculation) { return calculation.id !== id; });
                });
                break;
            case 'WALLBOX':
                setWallboxCalculations(function (currentCalculation) {
                    return currentCalculation.filter(function (calculation) { return calculation.id !== id; });
                });
                break;
        }
    }
    function handleEditCalculation(calculationId, key, value) {
        setCalculations(function (currentCalculations) {
            var _a;
            var editedCalculationIndex = currentCalculations.findIndex(function (calculation) { return calculation.id === calculationId; });
            return __spreadArray(__spreadArray(__spreadArray([], currentCalculations.slice(0, editedCalculationIndex), true), [
                __assign(__assign({}, currentCalculations[editedCalculationIndex]), (_a = {}, _a[key] = value, _a))
            ], false), currentCalculations.slice(editedCalculationIndex + 1), true);
        });
    }
    useEffect(function () {
        function fetchProducts() {
            return __awaiter(this, void 0, void 0, function () {
                var foundingProductsResponse, foundingProductsConfigurationResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, financingApi.getProductsList(offerId)];
                        case 1:
                            foundingProductsResponse = _a.sent();
                            return [4 /*yield*/, financingApi.getProductsConfigurations()];
                        case 2:
                            foundingProductsConfigurationResponse = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                setFoundingProducts(foundingProductsResponse);
                                setFoundingProductsConfiguration(foundingProductsConfigurationResponse);
                                setStatus('success');
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (pendingSyncData.includes('financing')) {
            fetchProducts();
            setPendingSyncData([]);
        }
    }, [pendingSyncData, offer]);
    useEffect(function () {
        function deleteAllCalculations(calculations) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, Promise.all(calculations.map(function (calculation) {
                                return financingApi.deleteCalculate(calculation.id);
                            }))];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (SessionStorage.has('calculations')) {
            var calculationsFromSessionStorage = SessionStorage.get('calculations');
            deleteAllCalculations(calculationsFromSessionStorage);
            SessionStorage.remove('calculations');
        }
        var id = uuid();
        setCalculations([
            {
                id: id,
                forPrinting: true,
                isCalculationIdRegister: false,
                isCalculateError: false
            },
        ]);
        // dodać jakiś warunek który powie czy można kalkulację wallboxa pokazać
        var wallboxId = uuid();
        setWallboxCalculations([
            {
                id: wallboxId,
                forPrinting: true
            }
        ]);
    }, [offer]);
    function handleOnCarModelIdChange(carModelId) {
        var id = uuid();
        var createOfferRequest = isFromVCP() ? creatorApi.createOfferVcp : creatorApi.createOffer;
        var createOfferWithClientContextRequest = isFromVCP() ? creatorApi.createOfferVcpWithClientContext : creatorApi.createOfferWithClientContext;
        var createOfferWithPartyClientContextRequest = isFromVCP() ? creatorApi.createOfferVcpWithPartyClientContext : creatorApi.createOfferWithPartyClientContext;
        if (searchParams.get('year') && searchParams.get('model')) {
            if (searchParams.get('context') === 'customer-service') {
                var clientId_1 = searchParams.get('clientId');
                handleChangeClientId(clientId_1);
                createOfferWithClientContextRequest(id, carModelId, clientId_1)
                    .then(function (response) {
                    if (response.status === 200) {
                        if (response.data && response.data.errorDesc) {
                            window.dispatchEvent(new ErrorEvent('error', {
                                error: response.data.errorCode,
                                message: response.data.description,
                            }));
                        }
                        ReactDOM.unstable_batchedUpdates(function () {
                            setOfferId(id);
                            SessionStorage.set('offerId', id);
                            setCarModelId(carModelId);
                        });
                    }
                    else {
                        throw new Error('nie można utworzyć nowej oferty');
                    }
                })
                    .catch(function (error) {
                    console.error(['[create-offer]', error]);
                });
            }
            if (searchParams.get('context') === 'fleet-service') {
                var clientId_2 = searchParams.get('clientId');
                var partyId = searchParams.get('partyId');
                handleChangeClientId(clientId_2);
                setSelectedParty(partyId);
                createOfferWithPartyClientContextRequest(id, carModelId, clientId_2, partyId)
                    .then(function (response) {
                    if (response.status === 200) {
                        if (response.data && response.data.errorDesc) {
                            window.dispatchEvent(new ErrorEvent('error', {
                                error: response.data.errorCode,
                                message: response.data.description,
                            }));
                        }
                        ReactDOM.unstable_batchedUpdates(function () {
                            setOfferId(id);
                            SessionStorage.set('offerId', id);
                            setCarModelId(carModelId);
                        });
                    }
                    else {
                        throw new Error('nie można utworzyć nowej oferty');
                    }
                })
                    .catch(function (error) {
                    console.error(['[create-offer]', error]);
                });
            }
        }
        else {
            createOfferRequest(id, carModelId)
                .then(function (response) {
                if (response.status === 200) {
                    if (response.data && response.data.errorDesc) {
                        window.dispatchEvent(new ErrorEvent('error', {
                            error: response.data.errorCode,
                            message: response.data.description,
                        }));
                    }
                    ReactDOM.unstable_batchedUpdates(function () {
                        setOfferId(id);
                        SessionStorage.set('offerId', id);
                        if (employeeLeasingMode) {
                            SessionStorage.set('employeeLeasingMode', employeeLeasingMode);
                        }
                        setCarModelId(carModelId);
                    });
                }
                else {
                    throw new Error('nie można utworzyć nowej oferty');
                }
            })
                .catch(function (error) {
                console.error(['[create-offer]', error]);
            });
        }
    }
    var _s = useState(false), isConfigurationFromTokenLoaded = _s[0], setIsConfigurationFromTokenLoaded = _s[1];
    var _t = useState(undefined), year = _t[0], setYear = _t[1];
    var _u = useState(undefined), model = _u[0], setModel = _u[1];
    var _v = useState(false), employeeLeasingMode = _v[0], setEmployeeLeasingMode = _v[1];
    var configurationId = isConfigurationFromTokenLoaded ? undefined : location.state && 'configurationId' in location.state ? location.state.configurationId : undefined;
    var _w = useState(undefined), offerIdToClone = _w[0], setOfferIdToClone = _w[1];
    var _x = useState(undefined), configurationIdWithContext = _x[0], setConfigurationIdWithContext = _x[1];
    var _y = useState(undefined), offerIdFromStock = _y[0], setOfferIdFromStock = _y[1];
    var priceListApi = usePricesListApi();
    var isSaveInSessionStorage = SessionStorage.has('offerId') && SessionStorage.has('currentTab');
    function setBasicDataFromOffer(offerData) {
        setYear(offerData.vehicle.year);
        setModel(offerData.vehicle.name);
        setCarModelId(offerData.vehicle.carModelId);
    }
    useEffect(function () {
        function createOfferOrRecreateFromConfigurationLinkOrStorage() {
            return __awaiter(this, void 0, void 0, function () {
                var id_1, getOfferResponse_1, offerIdFromStorage, getOfferResponse_2, priceListsToUse_1, getPriceListsFromStorage, priceListRequest, priceListResponse, sortedPriceLists;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!configurationId) return [3 /*break*/, 3];
                            id_1 = uuid();
                            setStatus('pending');
                            return [4 /*yield*/, creatorApi.createOfferFromConfigurationLink(configurationId, id_1)];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, creatorApi.getOffer(id_1)];
                        case 2:
                            getOfferResponse_1 = _a.sent();
                            SessionStorage.set('offerId', id_1);
                            handleChangeOffer(getOfferResponse_1);
                            ReactDOM.unstable_batchedUpdates(function () {
                                setStatus('success');
                                setBasicDataFromOffer(getOfferResponse_1);
                                setOfferId(id_1);
                                setToken(getOfferResponse_1.vehicle.tokenConfiguration);
                                setOffer(getOfferResponse_1);
                                SessionStorage.set('isConfigurationSourceLink', true);
                                onSelectedPriceListChange(getOfferResponse_1.vehicle.priceList);
                            });
                            return [3 /*break*/, 9];
                        case 3:
                            if (!isSaveInSessionStorage) return [3 /*break*/, 8];
                            offerIdFromStorage = SessionStorage.get('offerId');
                            setStatus('pending');
                            return [4 /*yield*/, creatorApi.getOffer(offerIdFromStorage)];
                        case 4:
                            getOfferResponse_2 = _a.sent();
                            getPriceListsFromStorage = SessionStorage.get('priceLists');
                            if (!getPriceListsFromStorage) return [3 /*break*/, 5];
                            priceListsToUse_1 = getPriceListsFromStorage;
                            return [3 /*break*/, 7];
                        case 5:
                            priceListRequest = isFromVCP() ? priceListApi.getPricesListVcp : priceListApi.getPricesList;
                            return [4 /*yield*/, priceListRequest(getOfferResponse_2.vehicle.year, getOfferResponse_2.vehicle.name)];
                        case 6:
                            priceListResponse = _a.sent();
                            sortedPriceLists = immutableSort(priceListResponse, function (a, b) { return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(); });
                            priceListsToUse_1 = sortedPriceLists;
                            _a.label = 7;
                        case 7:
                            ReactDOM.unstable_batchedUpdates(function () {
                                setStatus('success');
                                var employeeLeasingModeFromSessionStorage = SessionStorage.get('employeeLeasingMode');
                                if (employeeLeasingModeFromSessionStorage) {
                                    setEmployeeLeasingMode(employeeLeasingModeFromSessionStorage);
                                }
                                setBasicDataFromOffer(getOfferResponse_2);
                                setOfferId(getOfferResponse_2.id);
                                setToken(getOfferResponse_2.vehicle.tokenConfiguration);
                                setOffer(__assign(__assign({}, getOfferResponse_2), { diff: {
                                        addedElements: [''],
                                        subtractedElements: [''],
                                        differentVersion: false
                                    } }));
                                onPriceListsChange(priceListsToUse_1);
                                onSelectedPriceListChange(getOfferResponse_2.vehicle.priceList, priceListsToUse_1);
                                if (getOfferResponse_2.client.clientId) {
                                    handleChangeClientId(getOfferResponse_2.client.clientId);
                                }
                                if (getOfferResponse_2.client.clientPartyId) {
                                    setSelectedParty(getOfferResponse_2.client.clientPartyId);
                                }
                            });
                            return [3 /*break*/, 9];
                        case 8:
                            ReactDOM.unstable_batchedUpdates(function () {
                                if (searchParams.get('year') && searchParams.get('model')) {
                                    // gdy przychodzimy z aplikacji angularowej, np. od karty klienta
                                    setYear(searchParams.get('year'));
                                    setModel(searchParams.get('model'));
                                }
                                else if (searchParams.get('offerIdToClone')) {
                                    // gdy przychodzimy z klonowania oferty
                                    setOfferIdToClone(searchParams.get('offerIdToClone'));
                                }
                                else if (searchParams.get('configurationId')) {
                                    // gdy przychodzimy z linkiem z karty klienta lub floty
                                    setConfigurationIdWithContext(searchParams.get('configurationId'));
                                }
                                else if (searchParams.get('offerIdFromStock')) {
                                    // gdy przychodzimy z auta ze stocka/sprzedanego
                                    setOfferIdFromStock(searchParams.get('offerIdFromStock'));
                                    // gdy przychodzimy z rozchodniaka leasingu pracownika
                                }
                                else if (location.state && 'employeeLeasingMode' in location.state) {
                                    setYear(location.state && 'year' in location.state ? location.state.year : undefined);
                                    setModel(location.state && 'model' in location.state ? location.state.model : undefined);
                                    setEmployeeLeasingMode(location.state.employeeLeasingMode);
                                }
                                else {
                                    // gdy przychodzimy standardowo z rozchodniaka
                                    setYear(location.state && 'year' in location.state ? location.state.year : undefined);
                                    setModel(location.state && 'model' in location.state ? location.state.model : undefined);
                                }
                            });
                            _a.label = 9;
                        case 9: return [2 /*return*/];
                    }
                });
            });
        }
        createOfferOrRecreateFromConfigurationLinkOrStorage();
    }, []);
    useEffect(function () {
        function cloneOffer() {
            return __awaiter(this, void 0, void 0, function () {
                var newOfferId, getOfferResponse_3, priceListRequest, priceListResponse, sortedPriceLists_1, getClientPartsResponse_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!offerIdToClone) return [3 /*break*/, 5];
                            newOfferId = uuid();
                            setStatus('pending');
                            return [4 /*yield*/, creatorApi.cloneOffer(offerIdToClone, newOfferId)];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, creatorApi.getOffer(newOfferId)];
                        case 2:
                            getOfferResponse_3 = _a.sent();
                            SessionStorage.set('offerId', newOfferId);
                            priceListRequest = isFromVCP() ? priceListApi.getPricesListVcp : priceListApi.getPricesList;
                            return [4 /*yield*/, priceListRequest(getOfferResponse_3.vehicle.year, getOfferResponse_3.vehicle.name)];
                        case 3:
                            priceListResponse = _a.sent();
                            sortedPriceLists_1 = immutableSort(priceListResponse, function (a, b) { return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(); });
                            SessionStorage.set('priceLists', sortedPriceLists_1);
                            return [4 /*yield*/, creatorApi.getClientPartiesWithIdentificationNumber(getOfferResponse_3.client.clientId)];
                        case 4:
                            getClientPartsResponse_1 = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                setStatus('success');
                                setYear(getOfferResponse_3.vehicle.year);
                                setModel(getOfferResponse_3.vehicle.name);
                                setCarModelId(getOfferResponse_3.vehicle.carModelId);
                                setOfferId(getOfferResponse_3.id);
                                setToken(getOfferResponse_3.vehicle.tokenConfiguration);
                                setOffer(__assign(__assign({}, getOfferResponse_3), { diff: {
                                        addedElements: [''],
                                        subtractedElements: [''],
                                        differentVersion: false
                                    } }));
                                onPriceListsChange(sortedPriceLists_1);
                                onSelectedPriceListChange(getOfferResponse_3.vehicle.priceList);
                                setClientParties(getClientPartsResponse_1);
                                if (getOfferResponse_3.client.clientId) {
                                    handleChangeClientId(getOfferResponse_3.client.clientId);
                                }
                                if (getOfferResponse_3.client.clientPartyId) {
                                    setSelectedParty(getOfferResponse_3.client.clientPartyId);
                                }
                            });
                            _a.label = 5;
                        case 5: return [2 /*return*/];
                    }
                });
            });
        }
        cloneOffer();
    }, [offerIdToClone]);
    useEffect(function () {
        function createOfferByLinkWithContext() {
            return __awaiter(this, void 0, void 0, function () {
                var id_2, clientId_3, partyId, getOfferResponse_4;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!configurationIdWithContext) return [3 /*break*/, 3];
                            id_2 = uuid();
                            setStatus('pending');
                            partyId = void 0;
                            if (searchParams.get('context') === 'customer-service') {
                                clientId_3 = searchParams.get('clientId');
                                handleChangeClientId(clientId_3);
                                partyId = null;
                            }
                            if (searchParams.get('context') === 'fleet-service') {
                                clientId_3 = searchParams.get('clientId');
                                partyId = encodeURIComponent(searchParams.get('partyId'));
                                handleChangeClientId(clientId_3);
                                setSelectedParty(partyId);
                            }
                            return [4 /*yield*/, creatorApi.createOfferByConfigurationLinkAndContext(configurationIdWithContext, id_2, clientId_3, partyId)];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, creatorApi.getOffer(id_2)];
                        case 2:
                            getOfferResponse_4 = _a.sent();
                            SessionStorage.set('offerId', id_2);
                            handleChangeOffer(getOfferResponse_4);
                            ReactDOM.unstable_batchedUpdates(function () {
                                setStatus('success');
                                setBasicDataFromOffer(getOfferResponse_4);
                                setOfferId(id_2);
                                setToken(getOfferResponse_4.vehicle.tokenConfiguration);
                                setOffer(getOfferResponse_4);
                                SessionStorage.set('isConfigurationSourceLink', true);
                                onSelectedPriceListChange(getOfferResponse_4.vehicle.priceList);
                            });
                            _a.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }
        createOfferByLinkWithContext();
    }, [configurationIdWithContext]);
    useEffect(function () {
        function getOfferFromOfferIdFromStock() {
            return __awaiter(this, void 0, void 0, function () {
                var getOfferResponse_5, priceListRequest, priceListResponse, sortedPriceLists_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!offerIdFromStock) return [3 /*break*/, 3];
                            setStatus('pending');
                            return [4 /*yield*/, creatorApi.getOffer(searchParams.get('offerIdFromStock'))];
                        case 1:
                            getOfferResponse_5 = _a.sent();
                            priceListRequest = isFromVCP() ? priceListApi.getPricesListVcp : priceListApi.getPricesList;
                            return [4 /*yield*/, priceListRequest(getOfferResponse_5.vehicle.year, getOfferResponse_5.vehicle.name)];
                        case 2:
                            priceListResponse = _a.sent();
                            sortedPriceLists_2 = immutableSort(priceListResponse, function (a, b) { return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(); });
                            SessionStorage.set('priceLists', sortedPriceLists_2);
                            ReactDOM.unstable_batchedUpdates(function () {
                                setStatus('success');
                                setBasicDataFromOffer(getOfferResponse_5);
                                setOfferId(getOfferResponse_5.id);
                                setToken(getOfferResponse_5.vehicle.tokenConfiguration);
                                setOffer(__assign(__assign({}, getOfferResponse_5), { diff: {
                                        addedElements: [''],
                                        subtractedElements: [''],
                                        differentVersion: false
                                    } }));
                                onPriceListsChange(sortedPriceLists_2);
                                onSelectedPriceListChange(getOfferResponse_5.vehicle.priceList);
                                var clientId;
                                var partyId;
                                if (searchParams.get('context') === 'customer-service') {
                                    clientId = searchParams.get('clientId');
                                    handleChangeClientId(clientId);
                                    partyId = null;
                                }
                                if (searchParams.get('context') === 'fleet-service') {
                                    clientId = searchParams.get('clientId');
                                    partyId = encodeURIComponent(searchParams.get('partyId'));
                                    handleChangeClientId(clientId);
                                    setSelectedParty(partyId);
                                }
                            });
                            _a.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }
        getOfferFromOfferIdFromStock();
    }, [offerIdFromStock]);
    useEffect(function () {
        function getClientPartiesData() {
            return __awaiter(this, void 0, void 0, function () {
                var getClientPartsResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!clientId) return [3 /*break*/, 2];
                            return [4 /*yield*/, creatorApi.getClientPartiesWithIdentificationNumber(clientId)];
                        case 1:
                            getClientPartsResponse = _a.sent();
                            setClientParties(getClientPartsResponse);
                            if (searchParams.get('context') === 'fleet-service') {
                                setSelectedParty(offer.client.clientPartyId);
                            }
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        getClientPartiesData();
    }, [clientId]);
    useEffect(function () {
        return function () {
            SessionStorage.remove('offerId');
            SessionStorage.remove('currentTab');
            SessionStorage.remove('isConfigurationSourceLink');
            SessionStorage.remove('percentageValuesPrintType');
            SessionStorage.remove('envImpactPrintType');
            SessionStorage.remove('calculations');
            SessionStorage.remove('employeeLeasingMode');
            SessionStorage.remove('priceLists');
            SessionStorage.remove('changingPriceList');
            setAppError(undefined);
        };
    }, []);
    // cenniki
    var _z = usePriceListTab({
        year: year,
        model: model,
        handleOnCarModelIdChange: handleOnCarModelIdChange,
        configurationId: configurationId,
        isSaveInSessionStorage: isSaveInSessionStorage,
        offerIdToClone: offerIdToClone,
        configurationIdWithContext: configurationIdWithContext,
        offerIdFromStock: offerIdFromStock
    }), priceLists = _z.priceLists, selectedPriceList = _z.selectedPriceList, onSelectedPriceListChange = _z.onSelectedPriceListChange, onPriceListsChange = _z.onPriceListsChange;
    // napędy
    var _0 = useDriveTab({
        carModelId: carModelId,
        canBeSynced: true,
        onSynced: function () {
            setPendingSyncData(['versions']);
        },
        onDriveChange: function () {
            setPendingSyncData(['versions']);
        },
        handleOnTokenChange: handleOnTokenChange,
        offerId: offerId,
        handleChangeOffer: handleChangeOffer,
        configurationId: configurationId,
        offer: offer,
        handleChangeClientId: handleChangeClientId,
        offerIdToClone: offerIdToClone,
        configurationIdWithContext: configurationIdWithContext,
        offerIdFromStock: offerIdFromStock,
        isSaveInSessionStorage: isSaveInSessionStorage
    }), drives = _0.drives, onSelectedDriveChange = _0.onSelectedDriveChange, selectedDrive = _0.selectedDrive;
    // wersje i motywy
    var _1 = useVersionTab({
        selectedDrive: selectedDrive,
        carModelId: carModelId,
        token: token,
        canBeSynced: pendingSyncData.includes('versions') && offer !== undefined,
        onSynced: function () { },
        onThemeChange: function () {
            setPendingSyncData(['options']);
        },
        offer: offer,
        offerId: offerId,
        handleChangeOffer: handleChangeOffer,
        configurationId: configurationId,
        isSaveInSessionStorage: isSaveInSessionStorage,
        handleChangeClientId: handleChangeClientId,
        offerIdToClone: offerIdToClone,
        configurationIdWithContext: configurationIdWithContext,
        offerIdFromStock: offerIdFromStock
    }), versions = _1.versions, selectedVersion = _1.selectedVersion, selectedTheme = _1.selectedTheme, onSelectedThemeChange = _1.onSelectedThemeChange;
    // opcje
    var _2 = useOptionsTab({
        carModelId: carModelId,
        token: token,
        handleOnTokenChange: handleOnTokenChange,
        canBeSynced: pendingSyncData.includes('options'),
        onSynced: function () {
            setPendingSyncData(['exterior']);
        },
        onOptionChange: function () {
            setPendingSyncData(['options']);
        },
        offer: offer,
        handleChangeOffer: handleChangeOffer,
        handleChangeClientId: handleChangeClientId
    }), selectedPackages = _2.selectedPackages, selectedOptions = _2.selectedOptions, onSelectedOptionChange = _2.onSelectedOptionChange, onSelectedPackageChange = _2.onSelectedPackageChange, options = _2.options, onSelectedSubOptionChange = _2.onSelectedSubOptionChange, selectedSubOptions = _2.selectedSubOptions;
    // nadwozie
    var _3 = useExteriorTab({
        carModelId: carModelId,
        token: token,
        handleOnTokenChange: handleOnTokenChange,
        canBeSynced: pendingSyncData.includes('exterior'),
        onSynced: function () {
            setPendingSyncData(['interior']);
        },
        onExteriorChange: function () {
            setPendingSyncData(['options']);
        },
        offer: offer,
        handleChangeOffer: handleChangeOffer,
        handleSetAppError: handleSetAppError
    }), exteriors = _3.exteriors, selectedColor = _3.selectedColor, selectedWheels = _3.selectedWheels, onSelectedColorChange = _3.onSelectedColorChange, onSelectedWheelsChange = _3.onSelectedWheelsChange, selectedPno34 = _3.selectedPno34;
    // wnętrze
    var _4 = useInteriorTab({
        carModelId: carModelId,
        offer: offer,
        token: token,
        handleOnTokenChange: handleOnTokenChange,
        canBeSynced: pendingSyncData.includes('interior'),
        onSynced: function () {
            setPendingSyncData(['accessories']);
        },
        onInteriorChange: function () {
            setPendingSyncData(['options']);
        },
        handleChangeOffer: handleChangeOffer
    }), interiorElements = _4.interiorElements, selectedInterior = _4.selectedInterior, onSelectedInteriorChange = _4.onSelectedInteriorChange;
    // akcesoria
    var _5 = useAccessoriesTab({
        carModelId: carModelId,
        offer: offer,
        handleChangeOffer: handleChangeOffer,
        token: token,
        handleOnTokenChange: handleOnTokenChange,
        canBeSynced: pendingSyncData.includes('accessories'),
        onSynced: function () {
            setPendingSyncData(['attractiveness']);
        },
        onAccessoryChange: function () {
            setPendingSyncData(['options']);
        },
        isSaveInSessionStorage: isSaveInSessionStorage,
        offerIdToClone: offerIdToClone,
        offerIdFromStock: offerIdFromStock
    }), accessories = _5.accessories, selectedAccessoryPackages = _5.selectedAccessoryPackages, onSelectedAccessoryPackageChange = _5.onSelectedAccessoryPackageChange, selectedAccessories = _5.selectedAccessories, onSelectedAccessoryChange = _5.onSelectedAccessoryChange, additionalAccessories = _5.additionalAccessories, onAdditionalAccessoryAdd = _5.onAdditionalAccessoryAdd, onAdditionalAccessoryDelete = _5.onAdditionalAccessoryDelete, additionalServices = _5.additionalServices, onAdditionalServicesAdd = _5.onAdditionalServicesAdd, onAdditionalServicesDelete = _5.onAdditionalServicesDelete, onAccessoryPriceChange = _5.onAccessoryPriceChange, debouncedChangeAccessoryPrice = _5.debouncedChangeAccessoryPrice, accessoriessCustomPrices = _5.accessoriessCustomPrices, selectedWinterWheels = _5.selectedWinterWheels, handleChangeSelectedWinterWheels = _5.handleChangeSelectedWinterWheels;
    // atrakcyjnośc
    var _6 = useAttractiveness({
        offer: offer,
        handleChangeOffer: handleChangeOffer,
        canBeSynced: pendingSyncData.includes('attractiveness'),
        onSynced: function () {
            setPendingSyncData(['financing']);
            if (configurationId) {
                setIsConfigurationFromTokenLoaded(true);
            }
            ;
        },
        isSaveInSessionStorage: isSaveInSessionStorage,
        clientParties: clientParties,
        offerIdToClone: offerIdToClone
    }), businessOffer = _6.businessOffer, onBusinessOfferDiscountNameChange = _6.onBusinessOfferDiscountNameChange, onBusinessOfferDiscountAmountChange = _6.onBusinessOfferDiscountAmountChange, onBusinessOfferDiscountPercentChange = _6.onBusinessOfferDiscountPercentChange, specialOfferOrDiscountGroup = _6.specialOfferOrDiscountGroup, onSpecialOfferOrGroupChange = _6.onSpecialOfferOrGroupChange, percentageAdditionalDiscount = _6.percentageAdditionalDiscount, onPercentageAdditionalDiscountChange = _6.onPercentageAdditionalDiscountChange, amountAdditionalDiscount = _6.amountAdditionalDiscount, onAmountAdditionalDiscountChange = _6.onAmountAdditionalDiscountChange, percentageValues = _6.percentageValues, envImpactSection = _6.envImpactSection, leaseTotalCost = _6.leaseTotalCost, onPrintTypeChange = _6.onPrintTypeChange, debouncedChangeBusinessOfferName = _6.debouncedChangeBusinessOfferName, debouncedChangeBusinessOfferDiscount = _6.debouncedChangeBusinessOfferDiscount, debouncedChangeDiscount = _6.debouncedChangeDiscount, dropdownOptionsForParties = _6.dropdownOptionsForParties;
    // podgląd samochodów
    var swiperTabs = useCarPreview(token, selectedPno34);
    // podsumowanie
    var _7 = useSummaryTab({
        offer: offer,
        drives: drives,
        selectedDrive: selectedDrive,
        versions: versions,
        selectedTheme: selectedTheme,
        selectedVersion: selectedVersion,
        exteriors: exteriors,
        selectedWheels: selectedWheels,
        interiorElements: interiorElements,
        options: options,
        accessories: accessories,
        selectedPackages: selectedPackages,
        selectedAccessoryPackages: selectedAccessoryPackages
    }), additionalInformation = _7.additionalInformation, onChangeAdditionalInformation = _7.onChangeAdditionalInformation, onGetSheetClick = _7.onGetSheetClick, onRegisterClick = _7.onRegisterClick, onBlurAdditionalInformation = _7.onBlurAdditionalInformation;
    var onClientCFMChange = useFinancingTab({ offer: offer, handleChangeOffer: handleChangeOffer }).onClientCFMChange;
    var tabs = [
        {
            value: 'priceList',
            name: 'Cennik',
            content: (React.createElement(PricesList, { priceLists: priceLists, selectedPriceList: selectedPriceList, onSelectedPriceListChange: onSelectedPriceListChange })),
            isVisible: priceLists && priceLists.length >= 2 && !SessionStorage.get('isConfigurationSourceLink') && !employeeLeasingMode && !offerIdFromStock && !isReadOnly,
        },
        {
            value: 'drive',
            name: 'Napęd',
            content: (React.createElement(Drive, { drives: drives, selectedDrive: selectedDrive, onSelectedDriveChange: onSelectedDriveChange, handleOnTokenChange: handleOnTokenChange, isReadOnly: isReadOnly })),
            isVisible: true,
        },
        {
            value: 'version',
            name: 'Wersja',
            content: (React.createElement(Version, { versions: versions, selectedVersion: selectedVersion, onSelectedThemeChange: onSelectedThemeChange, selectedTheme: selectedTheme, onVersionChange: function () {
                    setPendingSyncData(['options']);
                }, handleOnTokenChange: handleOnTokenChange, isReadOnly: isReadOnly })),
            isVisible: true,
        },
        {
            value: 'options',
            name: 'Opcje',
            content: (React.createElement(Options, { options: options, selectedPackages: selectedPackages, onSelectedPackageChange: onSelectedPackageChange, selectedOptions: selectedOptions, onSelectedOptionsChange: onSelectedOptionChange, onSelectedSubOptionChange: onSelectedSubOptionChange, selectedSubOptions: selectedSubOptions, isReadOnly: isReadOnly })),
            isVisible: true,
        },
        {
            value: 'exterior',
            name: 'Nadwozie',
            content: (React.createElement(Exterior, { exteriors: exteriors, selectedColor: selectedColor, onSelectedColorChange: onSelectedColorChange, selectedWheels: selectedWheels, onSelectedWheelsChange: onSelectedWheelsChange, isReadOnly: isReadOnly, selectedPno34: selectedPno34 })),
            isVisible: true,
        },
        {
            value: 'interior',
            name: 'Wnętrze',
            content: (React.createElement(Interior, { interiorElements: interiorElements, selectedInterior: selectedInterior, onSelectedInteriorChange: onSelectedInteriorChange, isReadOnly: isReadOnly })),
            isVisible: true,
        },
        {
            value: 'accessories',
            name: 'Akcesoria',
            content: (React.createElement(AccessoriesTab, { accessories: accessories, selectedAccessoryPackages: selectedAccessoryPackages, onSelectedAccessoryPackageChange: onSelectedAccessoryPackageChange, selectedAccessories: selectedAccessories, onSelectedAccessoryChange: onSelectedAccessoryChange, additionalAccessories: additionalAccessories, onAdditionalAccessoryAdd: onAdditionalAccessoryAdd, onAdditionalAccessoryDelete: onAdditionalAccessoryDelete, additionalServices: additionalServices, onAdditionalServicesAdd: onAdditionalServicesAdd, onAdditionalServicesDelete: onAdditionalServicesDelete, onAccessoryPriceChange: onAccessoryPriceChange, debouncedChangeAccessoryPrice: debouncedChangeAccessoryPrice, accessoriessCustomPrices: accessoriessCustomPrices, selectedWinterWheels: selectedWinterWheels, handleChangeSelectedWinterWheels: handleChangeSelectedWinterWheels })),
            isVisible: true,
        },
        {
            value: 'attractiveness',
            name: 'Atrakcyjność',
            content: (React.createElement(Attractiveness, { offer: offer, parties: dropdownOptionsForParties, selectedParty: selectedParty, businessOffer: businessOffer, onBusinessOfferDiscountNameChange: onBusinessOfferDiscountNameChange, onBusinessOfferDiscountAmountChange: onBusinessOfferDiscountAmountChange, onBusinessOfferDiscountPercentChange: onBusinessOfferDiscountPercentChange, specialOfferOrDiscountGroup: specialOfferOrDiscountGroup, onSpecialOfferOrGroupChange: onSpecialOfferOrGroupChange, percentageAdditionalDiscount: percentageAdditionalDiscount, onPercentageAdditionalDiscountChange: onPercentageAdditionalDiscountChange, amountAdditionalDiscount: amountAdditionalDiscount, onAmountAdditionalDiscountChange: onAmountAdditionalDiscountChange, percentageValues: percentageValues, envImpactSection: envImpactSection, leaseTotalCost: leaseTotalCost, onPrintTypeChange: onPrintTypeChange, debouncedChangeBusinessOfferName: debouncedChangeBusinessOfferName, debouncedChangeBusinessOfferDiscount: debouncedChangeBusinessOfferDiscount, debouncedChangeDiscount: debouncedChangeDiscount, onSelectedPartyChange: handleChangeSelectedParty })),
            isVisible: !employeeLeasingMode,
        },
        {
            value: 'financing',
            name: 'Finansowanie',
            content: React.createElement(Financing, { offerId: offerId, offer: offer, drives: drives, onClientCFMChange: onClientCFMChange, calculations: calculations, foundingProducts: foundingProducts, handleOnAddCalculation: handleOnAddCalculation, handleOnPrintSwitchChange: handleOnPrintSwitchChange, handleOnDeleteCalculation: handleOnDeleteCalculation, handleEditCalculation: handleEditCalculation, foundingProductsConfiguration: foundingProductsConfiguration }),
            isVisible: !employeeLeasingMode,
        },
        {
            value: 'summary',
            name: 'Podsumowanie',
            content: (React.createElement(SummaryTab, { handleChangeTokenConfigurationVisible: handleChangeTokenConfigurationVisible, offer: offer, drives: drives, selectedDrive: selectedDrive, versions: versions, exteriors: exteriors, selectedWheels: selectedWheels, interiorElements: interiorElements, options: options, selectedPackages: selectedPackages, accessories: accessories, selectedAccessoryPackages: selectedAccessoryPackages, additionalInformation: additionalInformation, onChangeAdditionalInformation: onChangeAdditionalInformation, onGetSheetClick: onGetSheetClick, onBlurAdditionalInformation: onBlurAdditionalInformation, onRegisterClick: onRegisterClick, clientId: clientId, selectedParty: selectedParty })),
            isVisible: true,
        },
    ];
    function getFirstVisibleTabValue() {
        var firstVisibleTab = tabs.find(function (tab) { return tab.isVisible; }).value;
        SessionStorage.set('currentTab', firstVisibleTab);
        return firstVisibleTab;
    }
    function handleTabChange(value) {
        SessionStorage.set('currentTab', value);
    }
    if (!location.state && (!SessionStorage.get('offerId') && !SessionStorage.get('currentTab')) && !searchParams.get('year') && !searchParams.get('model') && !searchParams.get('offerIdToClone') && !searchParams.get('configurationId') && !searchParams.get('offerIdFromStock')) {
        return React.createElement(Redirect, { to: "/start-offer" });
    }
    var canShowDiffModal = pendingSyncData.length === 0 && status !== 'pending';
    function isFatalError() {
        return (appError === null || appError === void 0 ? void 0 : appError.redirect) === 'errorPage';
    }
    function isErrorPopup() {
        return (appError === null || appError === void 0 ? void 0 : appError.redirect) === 'errorPopup';
    }
    function isConfigErrorPopup() {
        return (appError === null || appError === void 0 ? void 0 : appError.redirect) === 'configErrorPopup';
    }
    if (isFatalError()) {
        return React.createElement(Redirect, { to: {
                pathname: '/error',
                state: {
                    errorId: appError.errorId,
                    errorDesc: appError.errorDesc
                }
            } });
    }
    return (React.createElement("div", null,
        React.createElement(CreatorHeader, { model: model, modelYear: year, priceList: selectedPriceList ? selectedPriceList : '', grossPrice: offer ? offer.priceVariant.totalPriceGross : '', netPrice: offer ? offer.priceVariant.totalPriceNet : '', isTokenConfigurationVisible: isTokenConfigurationVisible, tokenConfiguration: offer ? offer.vehicle.tokenConfiguration : '' }),
        React.createElement("div", { className: "tabsWrapper" },
            React.createElement("form", null,
                (pendingSyncData.length > 0 || (pendingSyncData.length === 0 && status === 'pending')) && (React.createElement(Spinner, null)),
                (priceLists && drives && versions && options && exteriors && interiorElements && accessories)
                    || (location.state && 'configurationId' in location.state && location.state.configurationId) || (configurationIdWithContext) ? (React.createElement(React.Fragment, null,
                    React.createElement(Tabs.Root, { defaultValue: SessionStorage.get('currentTab') || getFirstVisibleTabValue(), onValueChange: handleTabChange },
                        React.createElement("div", { style: { position: 'sticky',
                                top: '90px',
                                background: 'var(--white)',
                                zIndex: 10 } },
                            React.createElement(Tabs.List, null, tabs.map(function (tab, index) {
                                return tab.isVisible ? (React.createElement(Tabs.Trigger, { value: tab.value, key: index }, tab.name)) : null;
                            })),
                            React.createElement("div", { style: { position: 'absolute', top: '5px', right: '-43px' } },
                                React.createElement(CarPreview, { swiperTabs: swiperTabs }))),
                        tabs.map(function (tab, index) {
                            return tab.isVisible ? (React.createElement(Tabs.Content, { value: tab.value, key: index },
                                React.createElement("div", { id: "tabContentWrapper", className: "tabContentWrapper" }, tab.content))) : null;
                        })))) : null),
            React.createElement(Diff, { offer: offer, canDiffShow: canShowDiffModal, handleChangeOffer: handleChangeOffer })),
        isErrorPopup() ? React.createElement(ErrorPopup, { text: appError.errorDesc }) : null,
        isConfigErrorPopup() ? React.createElement(ConfigErrorPopup, null) : null));
});
