import { useIntl } from 'react-intl';
export function useFormattedCurrency(number, currency) {
    if (currency === void 0) { currency = 'PLN'; }
    var intl = useIntl();
    return intl.formatNumberToParts(number, {
        currency: currency,
        style: 'currency',
        currencyDisplay: 'code'
    }).map(function (v) {
        // domyślne formatowanie dla Polski nie oddziela tysięcy od setek dla liczbb 4-cyfrowych, np. 7350. Takie przypaadki są tutaj wyłapywane i ręcznie dodajemy spacje
        if (v.type === 'integer' && v.value.length === 4) {
            return "".concat(v.value[0], " ").concat(v.value.slice(1));
        }
        else {
            return v.value;
        }
    }).join('');
}
