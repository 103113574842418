var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { useStatus } from 'common/statusContext';
import { Button } from 'components/button/Button.component';
import { Spinner } from 'components/spinner/Spinner';
import React, { useEffect, useState } from 'react';
import { CommentTextArea } from '../additionalInfos/AdditionalInfos';
import { useAdditionalInfos } from '../additionalInfos/useAdditionalInfos';
import { ApplicantData } from '../applicantData/ApplicantData';
import { ClientSectionPreview } from '../clientSection/ClientSectionPreview';
import { HorizontalLine } from '../horizontalLine/HorizontalLine';
import { SalePotentialPreview } from '../salePotential/SalePotentialPreview';
import { useSpecialOfferApi } from '../useSpecialOfferApi';
import { ViewSection } from '../viewSection/ViewSection';
import './supplementSpecialOfferForm.css';
import sessionStorage from 'common/sessionStorage';
import { PreviewRequestSubjectsSection } from '../requestSubject/PreviewRequestSubjectSection';
import { AccordionViewSection } from '../accordionViewSection/AccordionViewSection';
import { useRequestSubject } from '../requestSubject/useRequestSubject';
import { PlusDisabled } from 'components/accessories/PlusDisabled.component';
import { NewRequestSubjectsSection } from '../requestSubject/NewRequestSubjectsSection';
import { Plus } from 'components/accessories/Plus.comonent';
import ModelDictionary from 'common/modelDictionary';
import uuid from 'react-uuid';
import { discountStatusName } from '../discountStatusName';
import { SettlementSection } from '../settlementSection/SettlementSection';
import UserContext from 'common/userContext';
import { InfoDialog } from '../InfoDialog';
export var SupplementSpecialOfferForm = withCancellation(function () {
    var _a = useState(), specialOffer = _a[0], setSpecialOffer = _a[1];
    var _b = useStatus(), status = _b.status, setStatus = _b.setStatus;
    var _c = useState(0), oldRequestSubjectsTabValue = _c[0], setOldRequestSubjectsTabValue = _c[1];
    var _d = useState(null), applicantData = _d[0], setApplicantData = _d[1];
    var _e = useState(), settlements = _e[0], setSettlements = _e[1];
    var _f = useState(), partyIdentificationNumber = _f[0], setPartyIdentificationNumber = _f[1];
    var _g = useState(0), rejectedTabValue = _g[0], setRejectedTabValue = _g[1];
    var _h = useState([]), otherRecipientRequests = _h[0], setOtherRecipientRequests = _h[1];
    var _j = useState(false), openConfirmDialog = _j[0], setOpenConfirmDialog = _j[1];
    var _k = useState(''), dialogMessage = _k[0], setDialogMessage = _k[1];
    function onOldRequestSubjectTabChange(value) {
        setOldRequestSubjectsTabValue(value);
    }
    function onNewRequestSubjectTabChange(value) {
        setNewRequestSubjectsTabValue(value);
    }
    function onRejectedTabChange(value) {
        setRejectedTabValue(value);
    }
    var additionalInfosSection = useAdditionalInfos();
    var specialOfferApi = useSpecialOfferApi();
    var _l = window.location.href.split('?'), searchParamsString = _l[1];
    var searchParams = new URLSearchParams(searchParamsString);
    var specialOfferNumber = searchParams.get('specialOfferNumber');
    useEffect(function () {
        function getSettlements(specialOfferNumber) {
            return __awaiter(this, void 0, void 0, function () {
                var rekoningsResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, specialOfferApi.getSettlementsForSpecialOffer(specialOfferNumber)];
                        case 1:
                            rekoningsResponse = _a.sent();
                            setSettlements(rekoningsResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getOtherSpecialOfferForParty(partyIdentificationValue) {
            return __awaiter(this, void 0, void 0, function () {
                var otherRequestsForPartyResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, specialOfferApi.otherRequestsForIdentificationNumber(partyIdentificationValue)];
                        case 1:
                            otherRequestsForPartyResponse = _a.sent();
                            setOtherRecipientRequests(otherRequestsForPartyResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getSpecialOffer() {
            return __awaiter(this, void 0, void 0, function () {
                var specialOfferResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!specialOfferNumber) return [3 /*break*/, 2];
                            setStatus('pending');
                            return [4 /*yield*/, specialOfferApi.getSpecialOffer(specialOfferNumber)];
                        case 1:
                            specialOfferResponse = _a.sent();
                            if (specialOfferResponse && specialOfferResponse.status === 200) {
                                getSettlements(specialOfferNumber);
                                setSpecialOffer(specialOfferResponse.data);
                                setPartyIdentificationNumber(specialOfferResponse.data.ordering.party.identificationNumber.value);
                                getOtherSpecialOfferForParty(specialOfferResponse.data.ordering.party.identificationNumber.value);
                                setStatus('success');
                            }
                            else {
                                setStatus('error');
                                console.error('Błąd pobierania oferty specjalnej');
                            }
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        getSpecialOffer();
    }, []);
    var currentSpecialOffer = otherRecipientRequests && otherRecipientRequests.find(function (specialOffer) { return specialOffer.number === specialOfferNumber; });
    var applicantSection = specialOffer && { applicantData: {
            applicantId: specialOffer.applicant.login,
            applicantName: specialOffer.applicant.name,
            organizationId: specialOffer.applicant.organizationId,
            organizationName: specialOffer.applicant.organizationName,
            applicationDate: specialOffer.applicant.applicationDate,
            validTo: currentSpecialOffer && (currentSpecialOffer.status !== 'SUBMITTED' && currentSpecialOffer.status !== 'DECLINED') ? specialOffer.validTo : null
        } };
    var clientSection = specialOffer && {
        party: specialOffer.ordering.party,
        partyCars: specialOffer.ownedCars,
        type: specialOffer.type,
        requestDate: specialOffer.queryDate,
        requestAttachments: specialOffer.requestAttachments,
        policyAttachments: specialOffer.policyAttachments,
        capitalGroup: specialOffer.capitalGroup
    };
    var salePotentialSection = specialOffer && {
        fleet: specialOffer.salePotentials
    };
    var requestSubjectSection = specialOffer && {
        requestSubjects: specialOffer.querySubject
    };
    var rejectedRequestSubjectSection = specialOffer && {
        requestSubjects: specialOffer.rejectedQuerySubject
    };
    var settlementSection = settlements && {
        settlements: settlements.map(function (settlement) {
            var _a = settlement.car.modelId.split('_'), modelCode = _a[0], modelYear = _a[1];
            return {
                orderDate: settlement.order.orderDate,
                dealerOrder: settlement.order.dealerOrder,
                vin: settlement.car.vin,
                car: ModelDictionary.nameFor(modelCode),
                modelYear: modelYear,
                drive: settlement.car.engine,
                specialOfferType: settlement.spiecialOfferType.valueSpecialOffer,
                declaredDiscountGroup: settlement.spiecialOfferType.valueDeclaredVista,
                catalogPriceWithoutAccessoriesNet: settlement.car.priceWithoutAccessoriesNet,
                clientDiscountNet: settlement.clientDiscountNet,
                specialOfferDiscountPercent: settlement.specialOfferDiscount.percentValue,
                specialOfferDiscountAmountNet: settlement.specialOfferDiscount.amountNet,
                funding: settlement.financing.valueSpecialOffer,
                fundingVISTA: settlement.financing.valueDeclaredVista,
                status: discountStatusName(settlement.status)
            };
        })
    };
    var excludedPno12 = requestSubjectSection ? requestSubjectSection.requestSubjects.map(function (requestSubject) { return requestSubject.pno12; }) : [];
    useEffect(function () {
        function getApplicantData() {
            return __awaiter(this, void 0, void 0, function () {
                var applicantDataResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, specialOfferApi.getApplicantData()];
                        case 1:
                            applicantDataResponse = _a.sent();
                            setApplicantData({
                                name: applicantDataResponse.name,
                                login: applicantDataResponse.login
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        getApplicantData();
    }, []);
    var newRequestSubjectSection = useRequestSubject(partyIdentificationNumber, excludedPno12);
    var _m = useState(), newRequestSubjectsTabValue = _m[0], setNewRequestSubjectsTabValue = _m[1];
    useEffect(function () {
        if (newRequestSubjectSection.requestSubjects.length > 0) {
            setNewRequestSubjectsTabValue(newRequestSubjectSection.requestSubjects[newRequestSubjectSection.requestSubjects.length - 1].id);
        }
    }, [newRequestSubjectSection.requestSubjects.length]);
    function addNewRequestSubjectClick() {
        var id = uuid();
        newRequestSubjectSection.addNewRequestSubject(id);
        setNewRequestSubjectsTabValue(id);
    }
    function fundingProviders(requestSubject) {
        return {
            CASH: '',
            LEASING: requestSubject.selectedLeasingProvider && requestSubject.selectedLeasingProvider.value,
            LEASING_OTHER: requestSubject.otherLeasingProvider,
            CFM: requestSubject.selectedCfmProvider && requestSubject.selectedCfmProvider.value,
            CFM_OTHER: requestSubject.otherCfmProvider,
        };
    }
    ;
    function areLastRequestDataNotFilled() {
        var lastRequestSubject = newRequestSubjectSection.requestSubjects[newRequestSubjectSection.requestSubjects.length - 1];
        var shouldFinancingProviderExist = lastRequestSubject && lastRequestSubject.selectedFinancing !== 'CASH';
        if (!lastRequestSubject) {
            return false;
        }
        return (!lastRequestSubject.selectedOffer ||
            !lastRequestSubject.offerCarAmount ||
            !lastRequestSubject.selectedDealerMargin ||
            !lastRequestSubject.selectedFleetCoofinancing ||
            lastRequestSubject.percentagesValues === null ||
            (shouldFinancingProviderExist && !fundingProviders(lastRequestSubject)[lastRequestSubject.selectedFinancing]));
    }
    function isAddOfferButtonDisabled() {
        return (areLastRequestDataNotFilled() ||
            newRequestSubjectSection.availableOffers.length <= 0);
    }
    function isSaveDisabled() {
        return areLastRequestDataNotFilled() || newRequestSubjectSection.requestSubjects.length === 0;
    }
    function onSupplementSaveClick() {
        return __awaiter(this, void 0, void 0, function () {
            var specialOfferSupplementData, saveNewSpecialOfferResponse, previousState, clientId, partyId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        specialOfferSupplementData = {
                            querySubject: newRequestSubjectSection.requestSubjects.map(function (requestSubject) {
                                var selectedOfferObject = newRequestSubjectSection.allOffers && requestSubject.selectedOffer
                                    ? newRequestSubjectSection.allOffers.find(function (offer) { return offer.id === requestSubject.selectedOffer; })
                                    : undefined;
                                var _a = selectedOfferObject ? selectedOfferObject.modelId.split('_') : [], modelCode = _a[0], modelYear = _a[1];
                                return {
                                    offerId: requestSubject.selectedOffer,
                                    pno12: selectedOfferObject.pno12,
                                    car: ModelDictionary.nameFor(modelCode),
                                    driver: selectedOfferObject.engine,
                                    version: selectedOfferObject.version,
                                    carCounter: Number(requestSubject.offerCarAmount),
                                    catalogPriceNet: selectedOfferObject.listPriceNet,
                                    catalogPriceGross: selectedOfferObject.listPriceGross,
                                    dealerShareMarginNet: requestSubject.selectedDealerMargin.value,
                                    vcpLevelNet: requestSubject.selectedFleetCoofinancing.value,
                                    finalPriceNet: requestSubject.percentagesValues
                                        ? requestSubject.percentagesValues.finalPriceNetto
                                        : 0,
                                    finalPriceGross: requestSubject.percentagesValues
                                        ? requestSubject.percentagesValues.finalPriceGross
                                        : 0,
                                    financingName: requestSubject.selectedFinancing,
                                    financingValue: fundingProviders(requestSubject)[requestSubject.selectedFinancing],
                                    modelYear: modelYear,
                                    offerNumber: selectedOfferObject.offerNo,
                                };
                            }),
                            additionalInfo: additionalInfosSection.additionalInfo,
                            applicant: applicantData
                        };
                        setStatus('pending');
                        return [4 /*yield*/, specialOfferApi.supplementSpecialOffer(specialOfferNumber, specialOfferSupplementData)];
                    case 1:
                        saveNewSpecialOfferResponse = _a.sent();
                        if (saveNewSpecialOfferResponse) {
                            setStatus('success');
                            previousState = sessionStorage.get('previousState');
                            clientId = sessionStorage.get('customer-service_client_id');
                            partyId = sessionStorage.get('fleet-service_party_id');
                            switch (previousState) {
                                case 'app.customer-service':
                                    window.location.href = "#/customer-service/".concat(clientId, "/");
                                    break;
                                case 'app.fleet-service':
                                    window.location.href = "#/fleet-service/".concat(encodeURIComponent(partyId));
                                    break;
                                default:
                                    window.location.href = '#/dashboard';
                                    break;
                            }
                        }
                        else {
                            setStatus('error');
                            console.error('Bład zapisu oferty specjalnej');
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleOnConfirmDialog() {
        setOpenConfirmDialog(false);
        setDialogMessage('');
    }
    var isOSFromMyOrganization = specialOffer && (UserContext.organizationId() === specialOffer.applicant.organizationId);
    useEffect(function () {
        if (specialOffer && !isOSFromMyOrganization) {
            setDialogMessage('Brak dostępu!');
            setOpenConfirmDialog(true);
        }
    }, [specialOffer, isOSFromMyOrganization]);
    return isOSFromMyOrganization ? (React.createElement("div", { className: "correctionSpecialOfferWrapper" },
        React.createElement(ViewSection, { title: "wnioskuj\u0105cy", dataTestid: "special-offer-applicant-section" },
            React.createElement(ApplicantData, { applicantSection: applicantSection, applicationId: searchParams.get('specialOfferNumber') })),
        React.createElement(ViewSection, { title: "zamawiaj\u0105cy", dataTestid: "special-offer-client-section" },
            React.createElement(ClientSectionPreview, { clientSection: clientSection })),
        React.createElement(ViewSection, { title: "posiadana flota pojazd\u00F3w osobowych", dataTestid: "special-offer-request-subject" },
            React.createElement(SalePotentialPreview, { salePotentialSection: salePotentialSection })),
        React.createElement(AccordionViewSection.Root, null,
            React.createElement(AccordionViewSection.Item, { value: 'special-offer-request-subject' },
                React.createElement(AccordionViewSection.Trigger, null, "przedmiot zapytania"),
                React.createElement(AccordionViewSection.Content, null,
                    React.createElement(PreviewRequestSubjectsSection, { requestSubjectSectionProp: requestSubjectSection, tabValue: oldRequestSubjectsTabValue, onTabChange: onOldRequestSubjectTabChange }))),
            rejectedRequestSubjectSection.requestSubjects.length > 0 ?
                React.createElement(AccordionViewSection.Item, { value: 'special-offer-rejected-request-subject' },
                    React.createElement(AccordionViewSection.Trigger, null, "odrzucone wnioski"),
                    React.createElement(AccordionViewSection.Content, null,
                        React.createElement(PreviewRequestSubjectsSection, { requestSubjectSectionProp: rejectedRequestSubjectSection, tabValue: rejectedTabValue, onTabChange: onRejectedTabChange })))
                : null,
            React.createElement(ViewSection, { title: "dodaj przedmiot zapytania", dataTestid: "special-offer-request-subject" },
                React.createElement("button", { onClick: addNewRequestSubjectClick, className: "specialOfferPlusButton ".concat(isAddOfferButtonDisabled() ? 'disabled' : ''), disabled: isAddOfferButtonDisabled() }, isAddOfferButtonDisabled() ? React.createElement(PlusDisabled, { className: "plus" }) : React.createElement(Plus, { className: "plus" })),
                React.createElement(NewRequestSubjectsSection, { tabValue: newRequestSubjectsTabValue, onTabChange: onNewRequestSubjectTabChange, idSelectedParty: partyIdentificationNumber, requestSubjectSectiona: newRequestSubjectSection })),
            settlements ?
                React.createElement(AccordionViewSection.Item, { value: 'settlement' },
                    React.createElement(AccordionViewSection.Trigger, null, "rozliczenie"),
                    React.createElement(AccordionViewSection.Content, null,
                        React.createElement(SettlementSection, { settlementSection: settlementSection })))
                : null),
        React.createElement(ViewSection, { title: "informacje dodatkowe", dataTestid: "special-offer-additional-infos" },
            React.createElement("div", { className: 'editSpecialOfferComment' },
                React.createElement(CommentTextArea, { additionalInfosSection: additionalInfosSection }))),
        React.createElement(HorizontalLine, { color: "#6D6D6D" }),
        React.createElement("div", { className: "specialOfferSubmitButtonWrapper" },
            React.createElement("div", { className: "specialOfferSubmitButton" },
                React.createElement(Button, { variant: "primary", type: "button", onClick: onSupplementSaveClick, disabled: isSaveDisabled() }, "wy\u015Blij zapytanie"))),
        status === 'pending' ? React.createElement(Spinner, null) : null)) : React.createElement(InfoDialog, { open: openConfirmDialog, message: dialogMessage, onConfirm: handleOnConfirmDialog });
});
