// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .labelComponent {
    all: unset;
    font-family: var(--font-family);
    font-weight: 400;
}

#new-dol .normal {
    font-size: 14px;
    line-height: 20px;
}

#new-dol .uppercase {
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
}

#new-dol .bold {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

#new-dol .nowrap {
    white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/label/label.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,+BAA+B;IAC/B,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".labelComponent {\n    all: unset;\n    font-family: var(--font-family);\n    font-weight: 400;\n}\n\n.normal {\n    font-size: 14px;\n    line-height: 20px;\n}\n\n.uppercase {\n    font-size: 12px;\n    line-height: 18px;\n    text-transform: uppercase;\n}\n\n.bold {\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 20px;\n}\n\n.nowrap {\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
