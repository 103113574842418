import { useEffect, useRef } from 'react';
export function useInterval(callback, delay, _a) {
    var _b = _a === void 0 ? {} : _a, maxRetries = _b.maxRetries, onMaxRetriesReached = _b.onMaxRetriesReached;
    var savedCallback = useRef();
    var id = useRef();
    var currentRetriesCount = useRef(0);
    useEffect(function () {
        savedCallback.current = callback;
    }, [callback]);
    useEffect(function () {
        function tick() {
            if (maxRetries !== undefined && currentRetriesCount.current === maxRetries) {
                if (onMaxRetriesReached) {
                    onMaxRetriesReached();
                }
                clearInterval(id.current);
                currentRetriesCount.current = 0;
                return;
            }
            savedCallback.current();
            currentRetriesCount.current++;
        }
        if (delay !== null) {
            id.current = window.setInterval(tick, delay);
            return function () {
                clearInterval(id.current);
            };
        }
    }, [callback, delay]);
}
