import React from 'react';
import './hoverCardPackageContent.css';
function Root(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'hoverCardContent' }, children);
}
function Element(_a) {
    var name = _a.name, id = _a.id;
    return React.createElement("div", null,
        React.createElement("span", { className: 'hoverCardPackageContentElementName' },
            name,
            " "),
        React.createElement("span", { className: 'hoverCardPackageContentElementId' }, id));
}
export var HoverCardPackageContent = {
    Root: Root,
    Element: Element
};
