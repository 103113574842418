import { useEffect, useState } from 'react';
import { useVersionApi } from './useVersionApi';
import ReactDOM from 'react-dom';
import { immutableSort } from 'utils/immutableSort';
import { Screen } from '../drive/useDriveApi';
import { useStatus } from '../../../common/statusContext';
import { useCreatorApi } from '../useCreatorApi';
import UserContext from 'common/userContext';
function isFromVCP() {
    return UserContext.isFromVCP() && !UserContext.hasAnyRole('VCP_EMPLOYEE_LEASING');
}
export function useVersionTab(_a) {
    var selectedDrive = _a.selectedDrive, carModelId = _a.carModelId, token = _a.token, canBeSynced = _a.canBeSynced, onSynced = _a.onSynced, onThemeChange = _a.onThemeChange, offerId = _a.offerId, handleChangeOffer = _a.handleChangeOffer, configurationId = _a.configurationId, isSaveInSessionStorage = _a.isSaveInSessionStorage, offer = _a.offer, handleChangeClientId = _a.handleChangeClientId, offerIdToClone = _a.offerIdToClone, configurationIdWithContext = _a.configurationIdWithContext, offerIdFromStock = _a.offerIdFromStock, employeeLeasingMode = _a.employeeLeasingMode;
    var _b = useState(undefined), versions = _b[0], setVersions = _b[1];
    var _c = useState(''), selectedVersion = _c[0], setSelectedVersion = _c[1];
    var _d = useState(''), selectedTheme = _d[0], setSelectedTheme = _d[1];
    var versionApi = useVersionApi();
    var creatorApi = useCreatorApi(employeeLeasingMode ? { context: 'employeeLeasing' } : undefined);
    var setStatus = useStatus().setStatus;
    var selectCarVersionRequest = creatorApi.selectCarVersion;
    function handleChangeSelectedTheme(value, token, userChoice) {
        setStatus('pending');
        selectCarVersionRequest(offerId, carModelId, token, Screen.VERSION)
            .finally(function () {
            creatorApi.getOffer(offerId).then(function (offer) {
                ReactDOM.unstable_batchedUpdates(function () {
                    setSelectedVersion(offer.vehicle.typeId + offer.vehicle.seatNo);
                    setSelectedTheme(offer.vehicle.theme);
                    if (userChoice) {
                        handleChangeOffer(offer);
                    }
                    setStatus('success');
                    onThemeChange();
                });
            });
        });
    }
    useEffect(function () {
        if (canBeSynced &&
            token &&
            carModelId &&
            offerId) {
            var getVersionsRequest = isFromVCP() ? versionApi.getVersionsVcp : versionApi.getVersions;
            getVersionsRequest(carModelId, selectedDrive).then(function (data) {
                ReactDOM.unstable_batchedUpdates(function () {
                    var unsortedData = data;
                    var sortedThemesByLowestPrice = immutableSort(Object.values(unsortedData).flat(), function (themeA, themeB) { return themeA.powerTrainThemePrice - themeB.powerTrainThemePrice; });
                    var sortedVersionIds = Array.from(new Set(sortedThemesByLowestPrice.map(function (data) { return data.versionId; })));
                    // sortowanie wersji
                    var sortedVersions = {};
                    var dataEntries = Object.entries(data);
                    sortedVersionIds.forEach(function (versionId) {
                        var dataEntry = dataEntries.find(function (entry) { return entry[1].some(function (theme) { return theme.versionId === versionId; }); });
                        sortedVersions[dataEntry[0]] = dataEntry[1];
                    });
                    // sortowanie motywów w obrębie ich wersji
                    Object.values(sortedVersions).map(function (version) {
                        return version.sort(function (verA, verB) { return verA.powerTrainThemePrice - verB.powerTrainThemePrice; });
                    });
                    setVersions(sortedVersions);
                    if (configurationId || offerIdToClone || configurationIdWithContext) {
                        creatorApi.getOffer(offerId).then(function (offer) {
                            setSelectedVersion(offer.vehicle.typeId + offer.vehicle.seatNo);
                            setSelectedTheme(offer.vehicle.theme);
                            onThemeChange();
                        });
                    }
                    else if (isSaveInSessionStorage || offerIdFromStock) {
                        setSelectedVersion(offer.vehicle.typeId + offer.vehicle.seatNo);
                        setSelectedTheme(offer.vehicle.theme);
                        onThemeChange();
                        if (offer.client.clientId) {
                            handleChangeClientId(offer.client.clientId);
                        }
                    }
                    else {
                        var versionFromToken = Object.values(sortedVersions).flat().find(function (version) { return version.token === token; });
                        handleChangeSelectedTheme("".concat(versionFromToken.versionId, " ").concat(versionFromToken.powerTrainThemeId), token, false);
                        if (offer.client.clientId) {
                            handleChangeClientId(offer.client.clientId);
                        }
                        onSynced();
                    }
                });
            }).catch(function (error) {
                console.error('[versions]', error);
            });
        }
    }, [
        canBeSynced,
        carModelId,
        token,
        offerId
    ]);
    return {
        versions: versions,
        selectedVersion: selectedVersion,
        selectedTheme: selectedTheme,
        onSelectedThemeChange: handleChangeSelectedTheme,
    };
}
