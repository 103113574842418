var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Accordion } from 'components/accordion/Accordion.component';
import { Checkbox } from 'components/checkbox/Checkbox.component';
import { ChipPlaceholder } from 'components/chip/ChipPlaceholder.component';
import { LackOfPriceChip } from 'components/chip/LackOfPriceChip.component';
import { OptionPackageChip } from 'components/chip/OptionPakietChip.component';
import { OptionStandardChip } from 'components/chip/OptionStandardChip.component';
import { HoverCard } from 'components/hoverCard/HoverCard.component';
import { Label } from 'components/label/Label.component';
import { List } from 'components/list/List.component';
import { ListItemElement } from 'components/listItemElement/ListItemElement.component';
import { TextAmount } from 'components/textAmount/TextAmount';
import { HoverCardPackageContent } from 'pages/demo/components/hoverCardPackageContent/HoverCardPackageContent.component';
import { HoverCardTriggerWrapper } from 'pages/demo/components/hoverCardTriggerWrapper/HoverCardTriggerWrapper.component';
import React from 'react';
import userContext from 'common/userContext';
import { PackageContent } from 'pages/demo/components/packageContent/PackageContent.component';
import { TextAmountPlaceholder } from 'components/textAmount/TextAmountPlaceholder';
import { immutableSort } from 'utils/immutableSort';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
function isFromVCP() {
    return userContext.isFromVCP();
}
function checkIfOptionIsIncludedInPackage(optionNumber, packagesAvailableOptions) {
    var allPackagesContentsId = [];
    packagesAvailableOptions.forEach(function (availableOption) {
        var _a;
        (_a = availableOption.content) === null || _a === void 0 ? void 0 : _a.forEach(function (content) {
            allPackagesContentsId.push(content.optionNo);
        });
    });
    return allPackagesContentsId.indexOf(optionNumber) !== -1;
}
export function Options(_a) {
    var options = _a.options, selectedPackages = _a.selectedPackages, onSelectedPackageChange = _a.onSelectedPackageChange, selectedOptions = _a.selectedOptions, onSelectedOptionsChange = _a.onSelectedOptionsChange, onSelectedSubOptionChange = _a.onSelectedSubOptionChange, selectedSubOptions = _a.selectedSubOptions, isReadOnly = _a.isReadOnly;
    // pakiety
    var packageOptions = options.find(function (option) { return option.category.toUpperCase() === 'PAKIETY'; });
    var sortedPackageAvailableOptions = packageOptions
        ? immutableSort(packageOptions.availableOptions, function (availableOptionA, availableOptionB) {
            return availableOptionA.name.localeCompare(availableOptionB.name);
        })
        : [];
    var sortedPackageAvailableOptionsWithSortedContents = sortedPackageAvailableOptions.map(function (availableOption) {
        return __assign(__assign({}, availableOption), { content: immutableSort(availableOption.content, function (contentOptionA, contentOptionB) {
                return contentOptionA.name.localeCompare(contentOptionB.name);
            }) });
    });
    var packages = packageOptions && __assign(__assign({}, packageOptions), { availableOptions: sortedPackageAvailableOptionsWithSortedContents });
    function isPackageWithExchangableOptions(availableOption) {
        var isMultiple = availableOption.content.some(function (option) { return option.token; });
        return isMultiple;
    }
    function onSubOptionChange(packageId, elementId) {
        onSelectedSubOptionChange(packageId, elementId);
    }
    function getSuboptionIdToSelect(availableOption, token) {
        if (selectedSubOptions.length === 0) {
            var elementId = availableOption.content.find(function (element) { return element.token === token; }).id;
            return elementId;
        }
        return getSelectedSubOption(availableOption.id);
    }
    function getSelectedSubOption(packageId) {
        return selectedSubOptions.find(function (option) { return option.optionId === packageId; }).subOptionId;
    }
    // inne poza pakietami i pozostałymi
    var restOptions = options.filter(function (option) { return option.category.toUpperCase() !== 'PAKIETY' && option.category.toUpperCase() !== 'POZOSTAŁE'; });
    var sortedRestOptions = restOptions
        ? immutableSort(restOptions, function (optionA, optionB) { return optionA.category.localeCompare(optionB.category); })
        : [];
    var sortedRestOptionsWithSortedAvailableOptions = sortedRestOptions.map(function (sortedRestOption) {
        return __assign(__assign({}, sortedRestOption), { availableOptions: immutableSort(sortedRestOption.availableOptions, function (availableOptionA, availableOptionB) {
                return availableOptionA.name.localeCompare(availableOptionB.name);
            }) });
    });
    // pozostałe
    var remainingOptions = options.find(function (option) { return option.category.toUpperCase() === 'POZOSTAŁE'; });
    var sortedRemainingAvailableOptions = remainingOptions
        ? immutableSort(remainingOptions.availableOptions, function (availableOptionA, availableOptionB) {
            return availableOptionA.name.localeCompare(availableOptionB.name);
        })
        : [];
    var remainings = remainingOptions && __assign(__assign({}, remainingOptions), { availableOptions: sortedRemainingAvailableOptions });
    function getOrderedOptionsByStandardProperty(availableOptions) {
        var standardAvailableOptions = availableOptions.filter(function (availableOptions) { return availableOptions.standard; });
        var nonStandardAvailableOptions = availableOptions.filter(function (availableOptions) { return !availableOptions.standard; });
        return __spreadArray(__spreadArray([], nonStandardAvailableOptions, true), standardAvailableOptions, true);
    }
    var selectedRemainingOptions = remainingOptions && remainingOptions.availableOptions.filter(function (option) { return selectedOptions.includes(option.id); });
    return (React.createElement("div", { className: "optionsWrapper" }, options ? (React.createElement(Accordion.Root, { defaultValue: options.map(function (option) { return option.category; }) },
        isReadOnly ?
            (selectedPackages.length > 0 ?
                React.createElement(Accordion.Item, { value: packages.category, key: packages.category },
                    React.createElement(Accordion.Trigger, null, packages.category),
                    React.createElement(Accordion.Content, null,
                        React.createElement(List.Root, null, getOrderedOptionsByStandardProperty(packages.availableOptions).map(function (availableOption) {
                            return selectedPackages.indexOf(availableOption.id) < 0 ? (null) : (React.createElement(List.Item, { isPrice: availableOption.price !== null, key: availableOption.id, isSelected: true, cursor: 'default' },
                                React.createElement(List.LayoutWithCodeAndChips, null,
                                    React.createElement(Checkbox.Layout, null,
                                        React.createElement(Checkbox.Placeholder, null),
                                        React.createElement(Label, { htmlFor: availableOption.id }, availableOption.name)),
                                    React.createElement(ListItemElement.OptionCode, null, availableOption.optionNo),
                                    React.createElement(ListItemElement.Chips, null,
                                        availableOption.standard ? (React.createElement(OptionStandardChip, null)) : (React.createElement(ChipPlaceholder, null)),
                                        React.createElement(ChipPlaceholder, null)),
                                    React.createElement(ListItemElement.Price, null, availableOption.price === null ? (React.createElement(LackOfPriceChip, null)) : isFromVCP() ? (React.createElement(TextAmount, { bold: true }, availableOption.price)) : availableOption.price === 0 ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, availableOption.price)))),
                                isPackageWithExchangableOptions(availableOption) && selectedSubOptions.length > 0 ?
                                    React.createElement(PackageContent.Root, null, availableOption.content.map(function (element) { return (!element.token || getSelectedSubOption(availableOption.id) === element.id ?
                                        React.createElement(RadioGroup.LayoutForExchengableOptions, { optionNo: element.optionNo, key: element.id },
                                            React.createElement(RadioGroup.Placeholder, null),
                                            React.createElement(Label, { htmlFor: element.id }, element.name))
                                        : null); }))
                                    :
                                        React.createElement(PackageContent.Root, null, availableOption.content.map(function (element) { return (React.createElement(PackageContent.Element, { key: element.id, name: element.name, id: element.optionNo })); }))));
                        }))))
                : null)
            : (packageOptions ? (React.createElement(Accordion.Item, { value: packages.category, key: packages.category },
                React.createElement(Accordion.Trigger, null, packages.category),
                React.createElement(Accordion.Content, null,
                    React.createElement(List.Root, null, getOrderedOptionsByStandardProperty(packages.availableOptions).map(function (availableOption) {
                        return selectedPackages.indexOf(availableOption.id) < 0 ? (React.createElement(HoverCard.Root, { key: availableOption.id },
                            React.createElement(List.Item, { isPrice: availableOption.price !== null, cursor: availableOption.standard ? 'default' : 'pointer', onClick: function (event) {
                                    if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                                        return;
                                    }
                                    if (!availableOption.standard) {
                                        isPackageWithExchangableOptions(availableOption) && selectedSubOptions.length > 0 ?
                                            onSelectedSubOptionChange(availableOption.id, getSuboptionIdToSelect(availableOption, availableOption.token)) :
                                            onSelectedPackageChange(!selectedPackages.includes(availableOption.id), availableOption.id);
                                    }
                                    else {
                                        return undefined;
                                    }
                                } },
                                React.createElement(HoverCard.Trigger, { asChild: true },
                                    React.createElement(HoverCardTriggerWrapper, null,
                                        React.createElement(List.LayoutWithCodeAndChips, null,
                                            React.createElement(Checkbox.Layout, null,
                                                availableOption.standard ? (React.createElement(Checkbox.Placeholder, null)) :
                                                    React.createElement(Checkbox.Root, { checked: selectedPackages.includes(availableOption.id), value: availableOption.id, id: availableOption.id, onCheckedChange: function (checked) {
                                                            return isPackageWithExchangableOptions(availableOption) && selectedSubOptions.length > 0 ?
                                                                onSelectedSubOptionChange(availableOption.id, getSuboptionIdToSelect(availableOption, availableOption.token)) :
                                                                onSelectedPackageChange(checked, availableOption.id);
                                                        } },
                                                        React.createElement(Checkbox.Indicator, null)),
                                                React.createElement(Label, { htmlFor: availableOption.id }, availableOption.name)),
                                            React.createElement(ListItemElement.OptionCode, null, availableOption.optionNo),
                                            React.createElement(ListItemElement.Chips, null,
                                                availableOption.standard ? (React.createElement(OptionStandardChip, null)) : (React.createElement(ChipPlaceholder, null)),
                                                React.createElement(ChipPlaceholder, null)),
                                            React.createElement(ListItemElement.Price, null, availableOption.price === null ? (React.createElement(LackOfPriceChip, null)) : isFromVCP() ? (React.createElement(TextAmount, { bold: true }, availableOption.price)) : availableOption.price === 0 ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, availableOption.price)))))),
                                React.createElement(HoverCard.Content, { align: "start", alignOffset: 190, sideOffset: -10 },
                                    React.createElement(HoverCardPackageContent.Root, null, availableOption.content.map(function (option) { return (React.createElement(HoverCardPackageContent.Element, { key: option.id, name: option.name, id: option.optionNo })); })))))) : (React.createElement(List.Item, { isPrice: availableOption.price !== null, key: availableOption.id, isSelected: true, cursor: availableOption.standard ? 'default' : 'pointer', onClick: function (event) {
                                if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                                    return;
                                }
                                if (!availableOption.standard) {
                                    onSelectedPackageChange(!selectedPackages.includes(availableOption.id), availableOption.id);
                                }
                                else {
                                    return undefined;
                                }
                            } },
                            React.createElement(List.LayoutWithCodeAndChips, null,
                                React.createElement(Checkbox.Layout, null,
                                    availableOption.standard ? (React.createElement(Checkbox.Placeholder, null)) :
                                        React.createElement(Checkbox.Root, { checked: selectedPackages.includes(availableOption.id), value: availableOption.id, id: availableOption.id, onCheckedChange: function (checked) {
                                                return onSelectedPackageChange(checked, availableOption.id);
                                            } },
                                            React.createElement(Checkbox.Indicator, null)),
                                    React.createElement(Label, { htmlFor: availableOption.id }, availableOption.name)),
                                React.createElement(ListItemElement.OptionCode, null, availableOption.optionNo),
                                React.createElement(ListItemElement.Chips, null,
                                    availableOption.standard ? (React.createElement(OptionStandardChip, null)) : (React.createElement(ChipPlaceholder, null)),
                                    React.createElement(ChipPlaceholder, null)),
                                React.createElement(ListItemElement.Price, null, availableOption.price === null ? (React.createElement(LackOfPriceChip, null)) : isFromVCP() ? (React.createElement(TextAmount, { bold: true }, availableOption.price)) : availableOption.price === 0 ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, availableOption.price)))),
                            isPackageWithExchangableOptions(availableOption) && selectedSubOptions.length > 0 ?
                                React.createElement(PackageContent.Root, null,
                                    React.createElement(RadioGroup.Root, { value: getSelectedSubOption(availableOption.id), onValueChange: function (value) {
                                            onSubOptionChange(availableOption.id, value);
                                        } }, availableOption.content.map(function (element) { return (React.createElement(RadioGroup.LayoutForExchengableOptions, { optionNo: element.optionNo, key: element.id },
                                        element.token ? (React.createElement(RadioGroup.Item, { id: element.id, value: element.id },
                                            React.createElement(RadioGroup.Indicator, null))) : (React.createElement(RadioGroup.Placeholder, null)),
                                        React.createElement(Label, { htmlFor: element.id }, element.name))); })))
                                :
                                    React.createElement(PackageContent.Root, null, availableOption.content.map(function (element) { return (React.createElement(PackageContent.Element, { key: element.id, name: element.name, id: element.optionNo })); }))));
                    })))))
                : null),
        sortedRestOptionsWithSortedAvailableOptions.map(function (optionCategory) { return (isReadOnly ?
            (optionCategory.availableOptions.some(function (option) { return option.standard || selectedOptions.includes(option.id); }) ?
                React.createElement(Accordion.Item, { value: optionCategory.category, key: optionCategory.category },
                    React.createElement(Accordion.Trigger, null, optionCategory.category),
                    React.createElement(Accordion.Content, null,
                        React.createElement(List.Root, null, getOrderedOptionsByStandardProperty(optionCategory.availableOptions).filter(function (option) { return option.standard || selectedOptions.includes(option.id); }).map(function (availableOption) { return (React.createElement(List.Item, { key: availableOption.id, isPrice: availableOption.price !== null, isSelected: true, cursor: 'default' },
                            React.createElement(List.LayoutWithCodeAndChips, null,
                                React.createElement(Checkbox.Layout, null,
                                    React.createElement(Checkbox.Placeholder, null),
                                    React.createElement(Label, { htmlFor: availableOption.id }, availableOption.name)),
                                React.createElement(ListItemElement.OptionCode, null, availableOption.optionNo),
                                React.createElement(ListItemElement.Chips, null, availableOption.standard ? (React.createElement(OptionStandardChip, null)) : packageOptions ? (checkIfOptionIsIncludedInPackage(availableOption.optionNo, options
                                    .find(function (element) {
                                    return element.category.toUpperCase() ===
                                        'PACKAGE' ||
                                        element.category.toUpperCase() === 'PAKIETY';
                                })
                                    .availableOptions.filter(function (option) {
                                    return selectedPackages.includes(option.id);
                                })) ? (React.createElement(OptionPackageChip, null)) : (React.createElement(ChipPlaceholder, null))) : (React.createElement(ChipPlaceholder, null))),
                                React.createElement(ListItemElement.Price, null, availableOption.price === null ? (React.createElement(LackOfPriceChip, null)) : isFromVCP() ? (React.createElement(TextAmount, { bold: true }, availableOption.price)) : availableOption.price === 0 ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, availableOption.price)))))); }))))
                : null)
            : (React.createElement(Accordion.Item, { value: optionCategory.category, key: optionCategory.category },
                React.createElement(Accordion.Trigger, null, optionCategory.category),
                React.createElement(Accordion.Content, null,
                    React.createElement(List.Root, null, getOrderedOptionsByStandardProperty(optionCategory.availableOptions).map(function (availableOption) { return (React.createElement(List.Item, { key: availableOption.id, isPrice: availableOption.price !== null, isSelected: selectedOptions.includes(availableOption.id), cursor: availableOption.standard ? 'default' : 'pointer', onClick: function (event) {
                            if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                                return;
                            }
                            if (!availableOption.standard) {
                                onSelectedOptionsChange(!selectedOptions.includes(availableOption.id), availableOption.id);
                            }
                            else {
                                return undefined;
                            }
                        } },
                        React.createElement(List.LayoutWithCodeAndChips, null,
                            React.createElement(Checkbox.Layout, null,
                                availableOption.standard ? (React.createElement(Checkbox.Placeholder, null)) : (React.createElement(Checkbox.Root, { checked: selectedOptions.includes(availableOption.id), id: availableOption.id, value: availableOption.id, onCheckedChange: function (checked) {
                                        return onSelectedOptionsChange(checked, availableOption.id);
                                    } },
                                    React.createElement(Checkbox.Indicator, null))),
                                React.createElement(Label, { htmlFor: availableOption.id }, availableOption.name)),
                            React.createElement(ListItemElement.OptionCode, null, availableOption.optionNo),
                            React.createElement(ListItemElement.Chips, null, availableOption.standard ? (React.createElement(OptionStandardChip, null)) : packageOptions ? (checkIfOptionIsIncludedInPackage(availableOption.optionNo, options
                                .find(function (element) {
                                return element.category.toUpperCase() ===
                                    'PACKAGE' ||
                                    element.category.toUpperCase() === 'PAKIETY';
                            })
                                .availableOptions.filter(function (option) {
                                return selectedPackages.includes(option.id);
                            })) ? (React.createElement(OptionPackageChip, null)) : (React.createElement(ChipPlaceholder, null))) : (React.createElement(ChipPlaceholder, null))),
                            React.createElement(ListItemElement.Price, null, availableOption.price === null ? (React.createElement(LackOfPriceChip, null)) : isFromVCP() ? (React.createElement(TextAmount, { bold: true }, availableOption.price)) : availableOption.price === 0 ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, availableOption.price)))))); })))))); }),
        isReadOnly ?
            (selectedRemainingOptions ?
                React.createElement(Accordion.Item, { value: remainings.category, key: remainings.category },
                    React.createElement(Accordion.Trigger, null, remainings.category),
                    React.createElement(Accordion.Content, null,
                        React.createElement(List.Root, null, getOrderedOptionsByStandardProperty(selectedRemainingOptions).map(function (availableOption) { return (React.createElement(List.Item, { key: availableOption.id, isPrice: availableOption.price !== null, isSelected: true, cursor: 'default' },
                            React.createElement(List.LayoutWithCodeAndChips, null,
                                React.createElement(Checkbox.Layout, null,
                                    React.createElement(Checkbox.Placeholder, null),
                                    React.createElement(Label, { htmlFor: availableOption.id }, availableOption.name)),
                                React.createElement(ListItemElement.OptionCode, null, availableOption.optionNo),
                                React.createElement(ListItemElement.Chips, null, availableOption.standard ? (React.createElement(OptionStandardChip, null)) : packageOptions ? (checkIfOptionIsIncludedInPackage(availableOption.optionNo, options
                                    .find(function (element) {
                                    return element.category.toUpperCase() ===
                                        'PACKAGE' ||
                                        element.category.toUpperCase() === 'PAKIETY';
                                })
                                    .availableOptions.filter(function (option) {
                                    return selectedPackages.includes(option.id);
                                })) ? (React.createElement(OptionPackageChip, null)) : (React.createElement(ChipPlaceholder, null))) : (React.createElement(ChipPlaceholder, null))),
                                React.createElement(ListItemElement.Price, null, availableOption.price === null ? (React.createElement(LackOfPriceChip, null)) : isFromVCP() ? (React.createElement(TextAmount, { bold: true }, availableOption.price)) : availableOption.price === 0 ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, availableOption.price)))))); }))))
                : null)
            : (remainingOptions ? (React.createElement(Accordion.Item, { value: remainings.category, key: remainings.category },
                React.createElement(Accordion.Trigger, null, remainings.category),
                React.createElement(Accordion.Content, null,
                    React.createElement(List.Root, null, getOrderedOptionsByStandardProperty(remainings.availableOptions).map(function (availableOption) { return (React.createElement(List.Item, { key: availableOption.id, isPrice: availableOption.price !== null, isSelected: selectedOptions.includes(availableOption.id), cursor: availableOption.standard ? 'default' : 'pointer', onClick: function (event) {
                            if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                                return;
                            }
                            if (!availableOption.standard) {
                                onSelectedOptionsChange(!selectedOptions.includes(availableOption.id), availableOption.id);
                            }
                            else {
                                return undefined;
                            }
                        } },
                        React.createElement(List.LayoutWithCodeAndChips, null,
                            React.createElement(Checkbox.Layout, null,
                                availableOption.standard ? (React.createElement(Checkbox.Placeholder, null)) : (React.createElement(Checkbox.Root, { checked: selectedOptions.includes(availableOption.id), id: availableOption.id, value: availableOption.id, onCheckedChange: function (checked) {
                                        return onSelectedOptionsChange(checked, availableOption.id);
                                    } },
                                    React.createElement(Checkbox.Indicator, null))),
                                React.createElement(Label, { htmlFor: availableOption.id }, availableOption.name)),
                            React.createElement(ListItemElement.OptionCode, null, availableOption.optionNo),
                            React.createElement(ListItemElement.Chips, null, availableOption.standard ? (React.createElement(OptionStandardChip, null)) : packageOptions ? (checkIfOptionIsIncludedInPackage(availableOption.optionNo, options
                                .find(function (element) {
                                return element.category.toUpperCase() ===
                                    'PACKAGE' ||
                                    element.category.toUpperCase() === 'PAKIETY';
                            })
                                .availableOptions.filter(function (option) {
                                return selectedPackages.includes(option.id);
                            })) ? (React.createElement(OptionPackageChip, null)) : (React.createElement(ChipPlaceholder, null))) : (React.createElement(ChipPlaceholder, null))),
                            React.createElement(ListItemElement.Price, null, availableOption.price === null ? (React.createElement(LackOfPriceChip, null)) : isFromVCP() ? (React.createElement(TextAmount, { bold: true }, availableOption.price)) : availableOption.price === 0 ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, availableOption.price)))))); }))))) : null))) : null));
}
