var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useState } from 'react';
export function useSalePotential() {
    var _a = useState(''), mark = _a[0], setMark = _a[1];
    var _b = useState(''), model = _b[0], setModel = _b[1];
    var _c = useState(''), amount = _c[0], setAmount = _c[1];
    var _d = useState([]), fleet = _d[0], setFleet = _d[1];
    function handleOnMarkChange(event) {
        setMark(event.currentTarget.value);
    }
    function handleOnModelChange(event) {
        setModel(event.currentTarget.value);
    }
    function handleOnAmountChange(event) {
        var result = event.target.value.replace(/\D/g, '');
        setAmount(result);
    }
    function handleOnAddCarFleet() {
        setFleet(function (currentFleet) {
            return __spreadArray(__spreadArray([], currentFleet, true), [{
                    mark: mark,
                    model: model,
                    amount: amount
                }], false);
        });
        setMark('');
        setModel('');
        setAmount('');
    }
    function handleOnDeleteCarFleet(carMark, carModel) {
        var carFleetToDelete = fleet.find(function (car) { return car.mark === carMark && car.model === carModel; });
        setFleet((function (currentFleet) {
            return __spreadArray([], currentFleet.filter(function (car) { return car !== carFleetToDelete; }), true);
        }));
    }
    return {
        mark: mark,
        handleOnMarkChange: handleOnMarkChange,
        model: model,
        handleOnModelChange: handleOnModelChange,
        amount: amount,
        handleOnAmountChange: handleOnAmountChange,
        fleet: fleet,
        handleOnAddCarFleet: handleOnAddCarFleet,
        handleOnDeleteCarFleet: handleOnDeleteCarFleet
    };
}
