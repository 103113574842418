// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .applicantDataWrapper {
    padding: 32px;
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    gap: 32px;
    font-family: var(--font-family);
    color: var(--main-text-color);
}

#new-dol .applicantDataSection {
    width: 240px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    font-size: 14px;
    line-height: 20px;
}

#new-dol .applicantDataSectionLabel {
    font-weight: 500;
}

#new-dol .editApplicantDataSectionValue {
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/specialOffer/applicantData/applicantData.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,SAAS;IACT,+BAA+B;IAC/B,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".applicantDataWrapper {\n    padding: 32px;\n    margin-bottom: 8px;\n    width: 100%;\n    display: flex;\n    gap: 32px;\n    font-family: var(--font-family);\n    color: var(--main-text-color);\n}\n\n.applicantDataSection {\n    width: 240px;\n    display: flex;\n    flex-direction: column;\n    gap: 14px;\n    font-size: 14px;\n    line-height: 20px;\n}\n\n.applicantDataSectionLabel {\n    font-weight: 500;\n}\n\n.editApplicantDataSectionValue {\n    width: 100%;\n    min-height: 40px;\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
