import React from 'react';
import { useLocation } from 'react-router-dom';
import { SpecialOfferSearch } from '../specialOfferSearch/SpecialOfferSearch';
import { SpecialOfferPreview } from './SpecialOfferPreview';
export function SpecialOfferSearchPreview() {
    var location = useLocation();
    var _a = location.search.split('?'), searchParamsString = _a[1];
    var searchParams = new URLSearchParams(searchParamsString);
    var specialOfferNumber = searchParams.get('specialOfferNumber');
    return (React.createElement("div", null,
        React.createElement("div", null,
            React.createElement(SpecialOfferSearch, null)),
        React.createElement("div", null,
            React.createElement(SpecialOfferPreview, { key: specialOfferNumber }))));
}
