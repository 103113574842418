import React, { useEffect, useState } from 'react';
import { StartOfferHeader } from './startOfferHeader.component';
import { Dropdown } from 'components/dropdown/Dropdown.component';
import { Button } from 'components/button/Button.component';
import { useStartOffer } from './useStartOffer';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { useHistory } from 'react-router-dom';
export var EmployeeLeasingAdminStartOffer = withCancellation(function () {
    var _a = useState([]), availableYears = _a[0], setAvailableYears = _a[1];
    var _b = useState([]), availableModels = _b[0], setAvailableModels = _b[1];
    var _c = useState(''), selectedYear = _c[0], setSelectedYear = _c[1];
    var _d = useState(''), selectedModel = _d[0], setSelectedModel = _d[1];
    var StartOffer = useStartOffer();
    var history = useHistory();
    function isCreateButtonDisabled() {
        return !selectedModel;
    }
    function isModelDropdownDisabled() {
        return !selectedYear;
    }
    function handleChangeYear(value) {
        setSelectedYear(value);
        StartOffer.getModelsVcpEL(value).then(function (models) {
            setAvailableModels(models);
        });
    }
    function handleChangeModel(value) {
        setSelectedModel(value);
    }
    function dropdownYearsOptions() {
        return availableYears
            .map(function (year) {
            return {
                value: year,
                label: year,
            };
        })
            .sort(function (a, b) { return b.label.localeCompare(a.label); });
    }
    function dropdownModelsOptions() {
        return availableModels
            .map(function (model) {
            if ('name' in model) {
                return {
                    value: model.name,
                    label: model.name.toUpperCase(),
                };
            }
            else {
                return {
                    value: model.named,
                    label: model.named.toUpperCase(),
                };
            }
        })
            .sort(function (a, b) { return a.label.localeCompare(b.label); });
    }
    function handleCreate(event) {
        event.preventDefault();
        var formData = new FormData(event.currentTarget);
        var modelYear = formData.get('modelYear');
        var model = formData.get('model');
        history.push({
            pathname: '/employee-leasing-creator',
            state: { year: modelYear, model: model },
        });
    }
    useEffect(function () {
        StartOffer.getYearsVcpEL().then(function (years) {
            setAvailableYears(years);
        });
    }, []);
    return (React.createElement("div", { className: "startOfferWrapper" },
        React.createElement(StartOfferHeader, { text: "nowa oferta" }),
        React.createElement("div", { className: "startOfferContentWrapper" },
            React.createElement("form", { className: "startOfferSelects", onSubmit: handleCreate, "data-testid": "start-offer-selects-form" },
                React.createElement("div", { className: "startOfferDropdown", "data-testid": "years-dropdown" },
                    React.createElement(Dropdown, { options: dropdownYearsOptions(), onChange: handleChangeYear, placeholder: "Wybierz rok modelowy", name: "modelYear" })),
                React.createElement("div", { className: "startOfferDropdown", "data-testid": "models-dropdown" },
                    React.createElement(Dropdown, { options: dropdownModelsOptions(), onChange: handleChangeModel, placeholder: "Wybierz model", disabled: isModelDropdownDisabled(), name: "model" })),
                React.createElement("div", { className: "statrOfferButton" },
                    React.createElement(Button, { size: 32, disabled: isCreateButtonDisabled(), type: "submit" }, "utw\u00F3rz")))),
        React.createElement("img", { src: "/assets/images/new-dol/background_start_offer.png", alt: "", className: "startOfferBackground" })));
});
