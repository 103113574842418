var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
var CancelTokenSourceContext = React.createContext(undefined);
export function withCancellation(WrappedComponent) {
    return function (props) {
        var cancelTokenSource = useState(axios.CancelToken.source())[0];
        return React.createElement(CancelTokenSourceContext.Provider, { value: cancelTokenSource },
            React.createElement(CancelledOnUnmount, null,
                React.createElement(WrappedComponent, __assign({}, props))));
    };
}
function CancelledOnUnmount(props) {
    var cancelTokenSourceContext = useCancelTokenSourceContext();
    useEffect(function () { return function () { return cancelTokenSourceContext.cancel('Requests canceled'); }; }, [cancelTokenSourceContext]);
    return React.createElement(React.Fragment, null, props.children);
}
export function useCancelTokenSourceContext() {
    var cancelTokenSource = useContext(CancelTokenSourceContext);
    if (!cancelTokenSource) {
        throw new Error('Component beyond CancelTokenSourceContext');
    }
    return cancelTokenSource;
}
