// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .infoDialogWrapper {
    width: 600px;
    height: 196px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
}

#new-dol .infoDialogMessage {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: var(--text-grey);
}

#new-dol .dialogImportActionButtonsWrapper {
    display: flex;
    flex-direction: row;
    gap: 98px;
}

#new-dol .dialogImportActionButton {
    width: 80px;
}`, "",{"version":3,"sources":["webpack://./src/pages/specialOffer/infoDialog.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,+BAA+B;IAC/B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,WAAW;AACf","sourcesContent":[".infoDialogWrapper {\n    width: 600px;\n    height: 196px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 60px;\n}\n\n.infoDialogMessage {\n    font-family: var(--font-family);\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 18px;\n    color: var(--text-grey);\n}\n\n.dialogImportActionButtonsWrapper {\n    display: flex;\n    flex-direction: row;\n    gap: 98px;\n}\n\n.dialogImportActionButton {\n    width: 80px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
