// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .hoverCardContent {
    background-color: #FFFFFF; 
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.3));
    padding: 24px 24px 24px 20px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

#new-dol .hoverCardPackageContentElementName {
    color: var(--main-text-color);
}

#new-dol .hoverCardPackageContentElementId {
    color: var(--text-grey);
}`, "",{"version":3,"sources":["webpack://./src/pages/demo/components/hoverCardPackageContent/hoverCardPackageContent.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mDAAmD;IACnD,4BAA4B;IAC5B,+BAA+B;IAC/B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".hoverCardContent {\n    background-color: #FFFFFF; \n    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.3));\n    padding: 24px 24px 24px 20px;\n    font-family: var(--font-family);\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n}\n\n.hoverCardPackageContentElementName {\n    color: var(--main-text-color);\n}\n\n.hoverCardPackageContentElementId {\n    color: var(--text-grey);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
