// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .checkboxRoot {
    width: var(--checkbox-width);
    height: var(--checkbox-height);
    background: #FFFFFF;
    border: 1px solid #707070;
    border-radius: 4px;
    padding: 0;
    flex-shrink: 0;
}

#new-dol .checkboxRoot[data-state="checked"] {
    background-color: var(--blue);
    border: 1px solid var(--blue);
}

#new-dol .checkboxRoot[data-state="checked"][data-disabled] {
    background-color: var(--middle-grey);
    border: 1px solid var(--middle-grey);
}

#new-dol .layoutCheckbox {
    display: flex;
    gap: 10px;
    width: 812px;
}

#new-dol .layoutCheckboxWithAmount {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

#new-dol .checkboxIndicator {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#new-dol .placeholderCheckbox {
    width: var(--checkbox-width);
    height: var(--checkbox-height);
}
`, "",{"version":3,"sources":["webpack://./src/components/checkbox/checkbox.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,8BAA8B;IAC9B,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,UAAU;IACV,cAAc;AAClB;;AAEA;IACI,6BAA6B;IAC7B,6BAA6B;AACjC;;AAEA;IACI,oCAAoC;IACpC,oCAAoC;AACxC;;AAEA;IACI,aAAa;IACb,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,4BAA4B;IAC5B,8BAA8B;AAClC","sourcesContent":[".checkboxRoot {\n    width: var(--checkbox-width);\n    height: var(--checkbox-height);\n    background: #FFFFFF;\n    border: 1px solid #707070;\n    border-radius: 4px;\n    padding: 0;\n    flex-shrink: 0;\n}\n\n.checkboxRoot[data-state=\"checked\"] {\n    background-color: var(--blue);\n    border: 1px solid var(--blue);\n}\n\n.checkboxRoot[data-state=\"checked\"][data-disabled] {\n    background-color: var(--middle-grey);\n    border: 1px solid var(--middle-grey);\n}\n\n.layoutCheckbox {\n    display: flex;\n    gap: 10px;\n    width: 812px;\n}\n\n.layoutCheckboxWithAmount {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n}\n\n.checkboxIndicator {\n    width: 100%;\n    height: 100%;\n    border-radius: 4px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.placeholderCheckbox {\n    width: var(--checkbox-width);\n    height: var(--checkbox-height);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
