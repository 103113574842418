// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .headerComponent {
    width: 100%;
    background-color: var(--light-grey);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 16px 64px 20px 64px;
    top: 0;
    z-index: 100;
    position: sticky;
    height: 90px;
}

#new-dol .configurationCodeContainer {
    grid-column: 1;
    justify-self: start;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

#new-dol .modelAndPriceListContainer {
    grid-column: 2;
    justify-self: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

#new-dol .pricesContainer {
    grid-column: 3;
    justify-self: end;
    text-align: end;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

#new-dol .model, #new-dol .grossPrice, #new-dol .emptyElement {
    font-family: 'Volvo Novum';
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--main-text-color);
}

#new-dol .priceList, #new-dol .netPrice {
    font-family: 'Volvo Novum';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--main-text-color);
}

#new-dol .modelYear {
    color: #707070;
}`, "",{"version":3,"sources":["webpack://./src/components/creatorHeader/creatorHeader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mCAAmC;IACnC,aAAa;IACb,qCAAqC;IACrC,4BAA4B;IAC5B,MAAM;IACN,YAAY;IACZ,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,6BAA6B;AACjC;;AAEA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,6BAA6B;AACjC;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".headerComponent {\n    width: 100%;\n    background-color: var(--light-grey);\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    padding: 16px 64px 20px 64px;\n    top: 0;\n    z-index: 100;\n    position: sticky;\n    height: 90px;\n}\n\n.configurationCodeContainer {\n    grid-column: 1;\n    justify-self: start;\n    text-align: start;\n    display: flex;\n    flex-direction: column;\n    gap: 6px;\n}\n\n.modelAndPriceListContainer {\n    grid-column: 2;\n    justify-self: center;\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    gap: 6px;\n}\n\n.pricesContainer {\n    grid-column: 3;\n    justify-self: end;\n    text-align: end;\n    display: flex;\n    flex-direction: column;\n    gap: 6px;\n}\n\n.model, .grossPrice, .emptyElement {\n    font-family: 'Volvo Novum';\n    font-weight: 500;\n    font-size: 20px;\n    line-height: 28px;\n    color: var(--main-text-color);\n}\n\n.priceList, .netPrice {\n    font-family: 'Volvo Novum';\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 18px;\n    color: var(--main-text-color);\n}\n\n.modelYear {\n    color: #707070;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
