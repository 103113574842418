// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .specialOfferPreviewWrapper {
    width: 1280px;
    padding: 32px 0;
    margin: 0 auto;
}

#new-dol .specialOfferPreviewDecision {
    color: var(--black-text-color);
    font-size: 14px;
    font-family: var(--font-family);
    line-height: 20px;
    padding: 36px 16px 32px 16px;
}

#new-dol .specialOfferSupplementButton {
    width: 260px;
}

#new-dol .modificationSpecialOfferActionButtonsWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/specialOffer/specialOfferPreview/specialOfferPreview.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,8BAA8B;IAC9B,eAAe;IACf,+BAA+B;IAC/B,iBAAiB;IACjB,4BAA4B;AAChC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,SAAS;AACb","sourcesContent":[".specialOfferPreviewWrapper {\n    width: 1280px;\n    padding: 32px 0;\n    margin: 0 auto;\n}\n\n.specialOfferPreviewDecision {\n    color: var(--black-text-color);\n    font-size: 14px;\n    font-family: var(--font-family);\n    line-height: 20px;\n    padding: 36px 16px 32px 16px;\n}\n\n.specialOfferSupplementButton {\n    width: 260px;\n}\n\n.modificationSpecialOfferActionButtonsWrapper {\n    display: flex;\n    justify-content: flex-end;\n    gap: 16px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
