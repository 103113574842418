// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .auditDocumentsSectionWrapper {
    padding: 8px 0 16px 0;
    font-family: var(--font-family);
    color: var(--black-text-color);
}

#new-dol .auditDocumentsLabel {
    font-weight: 500;
    padding: 14px 16px;
}

#new-dol .auditDocumentsList {
    min-height: 24px;
    margin-bottom: 8px;
}

#new-dol .returnAuditDocumentsButtonWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

#new-dol .returnAuditDocumentsButton {
    width: 208px;
}`, "",{"version":3,"sources":["webpack://./src/pages/specialOffer/auditDocumentsSection/auditDocumentsSection.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,+BAA+B;IAC/B,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".auditDocumentsSectionWrapper {\n    padding: 8px 0 16px 0;\n    font-family: var(--font-family);\n    color: var(--black-text-color);\n}\n\n.auditDocumentsLabel {\n    font-weight: 500;\n    padding: 14px 16px;\n}\n\n.auditDocumentsList {\n    min-height: 24px;\n    margin-bottom: 8px;\n}\n\n.returnAuditDocumentsButtonWrapper {\n    display: flex;\n    justify-content: flex-end;\n    align-items: flex-end;\n}\n\n.returnAuditDocumentsButton {\n    width: 208px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
