import { useHttp } from 'http/httpService';
import SessionStorage from 'common/sessionStorage';
import { creatorContextPath } from '../CreatorContextPath';
export function useInteriorApi() {
    var http = useHttp();
    var context = SessionStorage.get('employeeLeasingMode') ? 'employeeLeasing' : 'creator';
    return {
        getInteriorDetailsDealer: function (carModelId, token) {
            return http
                .get("/api/".concat(creatorContextPath(context), "/car-interior/").concat(carModelId, "?token=").concat(token))
                .then(function (response) { return response.data; });
        },
        getInteriorDetailsVcp: function (carModelId, token) {
            return http
                .get("/api/".concat(creatorContextPath(context), "/car-interior/vcp/").concat(carModelId, "?token=").concat(token))
                .then(function (response) { return response.data; });
        }
    };
}
