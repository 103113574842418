var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Button } from 'components/button/Button.component';
import React, { useEffect, useState } from 'react';
import { ApplicantData } from '../applicantData/ApplicantData';
import { HorizontalLine } from '../horizontalLine/HorizontalLine';
import { ViewSection } from '../viewSection/ViewSection';
import './specialOfferPreview.css';
import UserContext from 'common/userContext';
import { ActiveVcpDiscounts } from '../activeVcpDiscounts/ActiveVcpDiscounts';
import { OtherRequests } from '../otherRequests/OtherRequests';
import { AccordionViewSection } from '../accordionViewSection/AccordionViewSection';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { useSpecialOfferApi } from '../useSpecialOfferApi';
import { useStatus } from 'common/statusContext';
import { Spinner } from 'components/spinner/Spinner';
import { ClientSectionPreview } from '../clientSection/ClientSectionPreview';
import { SalePotentialPreview } from '../salePotential/SalePotentialPreview';
import sessionStorage from 'common/sessionStorage';
import { PreviewRequestSubjectsSection } from '../requestSubject/PreviewRequestSubjectSection';
import { useHistory, useLocation } from 'react-router-dom';
import { SettlementSection } from '../settlementSection/SettlementSection';
import ModelDictionary from 'common/modelDictionary';
import { discountStatusName } from '../discountStatusName';
import ReactDOM from 'react-dom';
import { AuditDocumentsSection } from '../auditDocumentsSection/AuditDocumentsSection';
import { InfoDialog } from '../InfoDialog';
function hasAccessToOtherDiscountsData() {
    return UserContext.hasAnyRole('VCP_FLEET_SALES', 'VCP_SALES_MANAGER', 'VCP_SALES', 'VCP_ACCOUNTING', 'VCP_AUDIT', 'VCP_HEAD_OF_ACCOUNTING');
}
export var SpecialOfferPreview = withCancellation(function () {
    var _a = useState(), specialOffer = _a[0], setSpecialOffer = _a[1];
    var _b = useStatus(), status = _b.status, setStatus = _b.setStatus;
    var _c = useState([]), otherRecipientRequests = _c[0], setOtherRecipientRequests = _c[1];
    var _d = useState([]), otherVcpDiscountRequests = _d[0], setOtherVcpDiscountRequests = _d[1];
    var _e = useState([]), historicalVcpDiscountRequests = _e[0], setHistoricalVcpDiscountRequests = _e[1];
    var _f = useState([]), otherCapitalGroupRequests = _f[0], setOtherCapitalGroupRequests = _f[1];
    var _g = useState([]), dealerRequests = _g[0], setDealerRequests = _g[1];
    var _h = useState(), settlements = _h[0], setSettlements = _h[1];
    var _j = useState(), auditDocuments = _j[0], setAuditDocuments = _j[1];
    var _k = useState(false), openConfirmDialog = _k[0], setOpenConfirmDialog = _k[1];
    var _l = useState(''), dialogMessage = _l[0], setDialogMessage = _l[1];
    var location = useLocation();
    var history = useHistory();
    var _m = useState(0), tabValue = _m[0], setTabValue = _m[1];
    var _o = useState(0), rejectedTabValue = _o[0], setRejectedTabValue = _o[1];
    function onTabChange(value) {
        setTabValue(value);
    }
    function onRejectedTabChange(value) {
        setRejectedTabValue(value);
    }
    function onCloseClick() {
        if (location.pathname === '/special-offer/search-preview') {
            history.push('/special-offer/search-preview');
            return;
        }
        var previousState = sessionStorage.get('previousState');
        var clientId = sessionStorage.get('customer-service_client_id');
        var partyId = sessionStorage.get('fleet-service_party_id');
        switch (previousState) {
            case 'app.customer-service':
                window.location.href = "#/customer-service/".concat(clientId, "/");
                break;
            case 'app.fleet-service':
                window.location.href = "#/fleet-service/".concat(encodeURIComponent(partyId));
                break;
            default:
                window.location.href = '#/dashboard';
                break;
        }
    }
    var specialOfferApi = useSpecialOfferApi();
    var _p = location.search.split('?'), searchParamsString = _p[1];
    var searchParams = new URLSearchParams(searchParamsString);
    var specialOfferNumber = searchParams.get('specialOfferNumber');
    function onSupplementClick() {
        var searchParams = new URLSearchParams();
        searchParams.set('specialOfferNumber', specialOfferNumber);
        window.location.hash = '#/special-offer/supplement?' + searchParams.toString();
    }
    function onEditClick() {
        var searchParams = new URLSearchParams();
        searchParams.set('specialOfferNumber', specialOfferNumber);
        window.location.hash = '#/special-offer/edit?' + searchParams.toString();
    }
    useEffect(function () {
        function getOtherSpecialOfferForParty(partyIdentificationValue) {
            return __awaiter(this, void 0, void 0, function () {
                var otherRequestsForPartyResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, specialOfferApi.otherRequestsForIdentificationNumber(partyIdentificationValue)];
                        case 1:
                            otherRequestsForPartyResponse = _a.sent();
                            setOtherRecipientRequests(otherRequestsForPartyResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getOtherVcpDiscountsForParty(partyIdentificationValue) {
            return __awaiter(this, void 0, void 0, function () {
                var otherVCPRequestsForPartyResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, specialOfferApi.vcpRequestsForIdentificationNumber(partyIdentificationValue)];
                        case 1:
                            otherVCPRequestsForPartyResponse = _a.sent();
                            setOtherVcpDiscountRequests(otherVCPRequestsForPartyResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getHistoricalVCPDiscountsForParty(partyIdentificationValue) {
            return __awaiter(this, void 0, void 0, function () {
                var historicalVCPRequestsForPartyResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, specialOfferApi.historicalVcpRequestsForIdentificationNumber(partyIdentificationValue)];
                        case 1:
                            historicalVCPRequestsForPartyResponse = _a.sent();
                            setHistoricalVcpDiscountRequests(historicalVCPRequestsForPartyResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getOtherRequestsForCapitalGroup(capitalGroupIdentificationValue) {
            return __awaiter(this, void 0, void 0, function () {
                var otherRequestsForCapitalGroup;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, specialOfferApi.otherRequestsForIdentificationNumber(capitalGroupIdentificationValue, true)];
                        case 1:
                            otherRequestsForCapitalGroup = _a.sent();
                            setOtherCapitalGroupRequests(otherRequestsForCapitalGroup);
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getDealerRequests(dealerId, partyIdentificationValue) {
            return __awaiter(this, void 0, void 0, function () {
                var dealerRequestsResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, specialOfferApi.requestsForDealer(dealerId)];
                        case 1:
                            dealerRequestsResponse = _a.sent();
                            setDealerRequests(dealerRequestsResponse.filter(function (offer) { return offer.number && (offer.identificationNumber.value === partyIdentificationValue); }));
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getPercentages(requestSubject, settlement) {
            return __awaiter(this, void 0, void 0, function () {
                var percentagesValuesForNewAmountNetResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, specialOfferApi.getPercentagesValues(requestSubject.dealerShareMarginNet, settlement.specialOfferDiscount.percentValue, settlement.specialOfferDiscount.netListPriceWithWebcon, requestSubject.catalogPriceGross)];
                        case 1:
                            percentagesValuesForNewAmountNetResponse = _a.sent();
                            return [2 /*return*/, percentagesValuesForNewAmountNetResponse.vcpLevelNetto];
                    }
                });
            });
        }
        function getSettlements(specialOfferNumber, specialOffer) {
            return __awaiter(this, void 0, void 0, function () {
                var rekoningsResponse, _loop_1, _i, rekoningsResponse_1, settlement;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, specialOfferApi.getSettlementsForSpecialOffer(specialOfferNumber)];
                        case 1:
                            rekoningsResponse = _a.sent();
                            _loop_1 = function (settlement) {
                                var requestSubject, percentagesValuesForNewAmountNetResponse;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            if (!(settlement.status === 'RECKONING_CORRECTED' || settlement.status === 'ADJUSTED')) return [3 /*break*/, 2];
                                            requestSubject = specialOffer.querySubject.find(function (querySubject) { return querySubject.pno12 === settlement.car.pno12; });
                                            return [4 /*yield*/, getPercentages(requestSubject, settlement)];
                                        case 1:
                                            percentagesValuesForNewAmountNetResponse = _b.sent();
                                            settlement.correctedSpecialOfferDiscountAmountNet = percentagesValuesForNewAmountNetResponse;
                                            _b.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            };
                            _i = 0, rekoningsResponse_1 = rekoningsResponse;
                            _a.label = 2;
                        case 2:
                            if (!(_i < rekoningsResponse_1.length)) return [3 /*break*/, 5];
                            settlement = rekoningsResponse_1[_i];
                            return [5 /*yield**/, _loop_1(settlement)];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4:
                            _i++;
                            return [3 /*break*/, 2];
                        case 5:
                            setSettlements(rekoningsResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getSpecialOffer() {
            return __awaiter(this, void 0, void 0, function () {
                var specialOfferResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!specialOfferNumber) return [3 /*break*/, 2];
                            setStatus('pending');
                            return [4 /*yield*/, specialOfferApi.getSpecialOffer(specialOfferNumber)];
                        case 1:
                            specialOfferResponse = _a.sent();
                            if (specialOfferResponse && specialOfferResponse.status === 200) {
                                setStatus('success');
                                setSpecialOffer(specialOfferResponse.data);
                                getOtherSpecialOfferForParty(specialOfferResponse.data.ordering.party.identificationNumber.value);
                                getSettlements(specialOfferNumber, specialOfferResponse.data);
                                if (isFromVCP()) {
                                    getOtherVcpDiscountsForParty(specialOfferResponse.data.ordering.party.identificationNumber.value);
                                    getHistoricalVCPDiscountsForParty(specialOfferResponse.data.ordering.party.identificationNumber.value);
                                    if (specialOfferResponse.data.capitalGroup) {
                                        getOtherRequestsForCapitalGroup(specialOfferResponse.data.capitalGroup.identificationNumber.value);
                                    }
                                    getDealerRequests(specialOfferResponse.data.applicant.organizationId, specialOfferResponse.data.ordering.party.identificationNumber.value);
                                }
                            }
                            else {
                                setStatus('error');
                                console.error('Błąd pobierania oferty specjalnej');
                            }
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        getSpecialOffer();
    }, [specialOfferNumber]);
    function clearAuditDocumentsState() {
        setAuditDocuments(null);
    }
    function getAuditDocuments(offerId) {
        return __awaiter(this, void 0, void 0, function () {
            var auditDocumentsResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (isCustomerServiceUser()) {
                            return [2 /*return*/];
                        }
                        setStatus('pending');
                        return [4 /*yield*/, specialOfferApi.getAuditDocuments(offerId)];
                    case 1:
                        auditDocumentsResponse = _a.sent();
                        if (auditDocumentsResponse && auditDocumentsResponse.status === 200) {
                            ReactDOM.unstable_batchedUpdates(function () {
                                setAuditDocuments(auditDocumentsResponse.data);
                                setStatus('success');
                            });
                        }
                        else {
                            setStatus('error');
                            console.error('Błąd pobierania dokumentów audytowych');
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    var currentSpecialOffer = otherRecipientRequests && otherRecipientRequests.find(function (specialOffer) { return specialOffer.number === specialOfferNumber; });
    var applicantSection = specialOffer && { applicantData: {
            applicantId: specialOffer.applicant.login,
            applicantName: specialOffer.applicant.name,
            organizationId: specialOffer.applicant.organizationId,
            organizationName: specialOffer.applicant.organizationName,
            applicationDate: specialOffer.applicant.applicationDate,
            validTo: currentSpecialOffer && (currentSpecialOffer.status !== 'SUBMITTED' && currentSpecialOffer.status !== 'DECLINED') ? specialOffer.validTo : null
        } };
    var clientSection = specialOffer && {
        party: specialOffer.ordering.party,
        partyCars: specialOffer.ownedCars,
        type: specialOffer.type,
        requestDate: specialOffer.queryDate,
        requestAttachments: specialOffer.requestAttachments,
        policyAttachments: specialOffer.policyAttachments,
        capitalGroup: specialOffer.capitalGroup
    };
    var salePotentialSection = specialOffer && {
        fleet: specialOffer.salePotentials
    };
    var requestSubjectSection = specialOffer && {
        requestSubjects: specialOffer.querySubject
    };
    var rejectedRequestSubjectSection = specialOffer && {
        requestSubjects: specialOffer.rejectedQuerySubject
    };
    var settlementSection = settlements && {
        settlements: settlements.map(function (settlement) {
            var _a = settlement.car.modelId.split('_'), modelCode = _a[0], modelYear = _a[1];
            return {
                orderDate: settlement.order.orderDate,
                dealerOrder: settlement.order.dealerOrder,
                vin: settlement.car.vin,
                car: ModelDictionary.nameFor(modelCode),
                modelYear: modelYear,
                drive: settlement.car.engine,
                specialOfferType: settlement.spiecialOfferType.valueSpecialOffer,
                declaredDiscountGroup: settlement.spiecialOfferType.valueDeclaredVista,
                catalogPriceWithoutAccessoriesNet: settlement.car.priceWithoutAccessoriesNet,
                clientDiscountNet: settlement.clientDiscountNet,
                specialOfferDiscountPercent: settlement.specialOfferDiscount.percentValue,
                specialOfferDiscountAmountNet: settlement.specialOfferDiscount.amountNet,
                funding: settlement.financing.valueSpecialOffer,
                fundingVISTA: settlement.financing.valueDeclaredVista,
                status: discountStatusName(settlement.status),
                rawStatus: settlement.status,
                orderId: settlement.order.orderId,
                netListPriceWithWebcon: settlement.specialOfferDiscount.netListPriceWithWebcon ? settlement.specialOfferDiscount.netListPriceWithWebcon : null,
                correctedSpecialOfferDiscountAmountNet: settlement.correctedSpecialOfferDiscountAmountNet ? settlement.correctedSpecialOfferDiscountAmountNet : null
            };
        })
    };
    function isFromVCP() {
        return UserContext.isFromVCP();
    }
    function isCustomerServiceUser() {
        return UserContext.hasAnyRole('CUSTOMER_SERVICE');
    }
    var isSearchPreview = location.pathname === '/special-offer/search-preview';
    var isSalesRole = UserContext.hasAnyRole('SALES');
    var submittedStatuses = ['SUBMITTED', 'SUBMITTED_CONDITION', 'SUBMITTED_DURATION_EXTENSION', 'TENTATIVELY_ACCEPTED'];
    var isSpecialOfferNotSubmitted = currentSpecialOffer && !submittedStatuses.includes(currentSpecialOffer.status);
    function supplementionAllowed() {
        return isSalesRole && isSpecialOfferNotSubmitted && !isSearchPreview;
    }
    var allRequestsForParty = __spreadArray(__spreadArray(__spreadArray([], otherRecipientRequests, true), otherVcpDiscountRequests, true), historicalVcpDiscountRequests, true);
    function editingAllowed() {
        return isSpecialOfferNotSubmitted && isSalesRole;
    }
    var isOSFromMyOrganization = specialOffer && (UserContext.organizationId() === specialOffer.applicant.organizationId);
    var canPreview = isOSFromMyOrganization || (specialOffer && isFromVCP());
    function handleOnConfirmDialog() {
        setOpenConfirmDialog(false);
        setDialogMessage('');
    }
    useEffect(function () {
        if (specialOffer && !canPreview) {
            setDialogMessage('Brak dostępu!');
            setOpenConfirmDialog(true);
        }
    }, [specialOffer, canPreview]);
    return canPreview ?
        React.createElement("div", { className: 'specialOfferPreviewWrapper' },
            React.createElement(ViewSection, { title: 'wnioskuj\u0105cy', dataTestid: 'special-offer-applicant-section' },
                React.createElement(ApplicantData, { applicantSection: applicantSection, applicationId: specialOfferNumber })),
            React.createElement(ViewSection, { title: 'zamawiaj\u0105cy', dataTestid: 'special-offer-client-section' },
                React.createElement(ClientSectionPreview, { clientSection: clientSection })),
            React.createElement(ViewSection, { title: 'posiadana flota pojazd\u00F3w osobowych', dataTestid: 'special-offer-sale-potential-section' },
                React.createElement(SalePotentialPreview, { salePotentialSection: salePotentialSection })),
            React.createElement(AccordionViewSection.Root, { defaultValue: ['settlement', 'audit-documents'] },
                React.createElement(AccordionViewSection.Item, { value: 'special-offer-request-subject' },
                    React.createElement(AccordionViewSection.Trigger, null, "przedmiot zapytania"),
                    React.createElement(AccordionViewSection.Content, null,
                        React.createElement(PreviewRequestSubjectsSection, { requestSubjectSectionProp: requestSubjectSection, tabValue: tabValue, onTabChange: onTabChange }))),
                rejectedRequestSubjectSection.requestSubjects.length > 0 ?
                    React.createElement(AccordionViewSection.Item, { value: 'special-offer-rejected-request-subject' },
                        React.createElement(AccordionViewSection.Trigger, null, "odrzucone wnioski"),
                        React.createElement(AccordionViewSection.Content, null,
                            React.createElement(PreviewRequestSubjectsSection, { requestSubjectSectionProp: rejectedRequestSubjectSection, tabValue: rejectedTabValue, onTabChange: onRejectedTabChange })))
                    : null,
                hasAccessToOtherDiscountsData() ?
                    (React.createElement(React.Fragment, null,
                        React.createElement(AccordionViewSection.Item, { value: 'active-party-discounts' },
                            React.createElement(AccordionViewSection.Trigger, null, "aktywne rabaty VCP dla strony"),
                            React.createElement(AccordionViewSection.Content, null,
                                React.createElement(ActiveVcpDiscounts, { identificationNumber: clientSection.party.identificationNumber.value }))),
                        React.createElement(AccordionViewSection.Item, { value: 'other-party-discounts' },
                            React.createElement(AccordionViewSection.Trigger, null, "inne wnioski dla strony"),
                            React.createElement(AccordionViewSection.Content, null,
                                React.createElement(OtherRequests, { otherRequests: allRequestsForParty }))),
                        React.createElement(AccordionViewSection.Item, { value: 'other-capital-group-discounts' },
                            React.createElement(AccordionViewSection.Trigger, null, "inne wnioski dla grupy kapita\u0142owej"),
                            React.createElement(AccordionViewSection.Content, null,
                                React.createElement(OtherRequests, { otherRequests: otherCapitalGroupRequests }))),
                        React.createElement(AccordionViewSection.Item, { value: 'dealer-requests-history' },
                            React.createElement(AccordionViewSection.Trigger, null, "historia wniosk\u00F3w dealera"),
                            React.createElement(AccordionViewSection.Content, null,
                                React.createElement(OtherRequests, { otherRequests: dealerRequests })))))
                    :
                        null,
                settlements ?
                    React.createElement(AccordionViewSection.Item, { value: 'settlement' },
                        React.createElement(AccordionViewSection.Trigger, null, "rozliczenie"),
                        React.createElement(AccordionViewSection.Content, null,
                            React.createElement(SettlementSection, { settlementSection: settlementSection, onSettlementClick: function (offerId) { return getAuditDocuments(offerId); } })))
                    : null,
                auditDocuments && !isCustomerServiceUser() ?
                    React.createElement(AccordionViewSection.Item, { value: 'audit-documents' },
                        React.createElement(AccordionViewSection.Trigger, null, "dokumenty audytowe"),
                        React.createElement(AccordionViewSection.Content, null,
                            React.createElement(AuditDocumentsSection, { auditDocuments: auditDocuments, clearAuditDocumentsState: clearAuditDocumentsState })))
                    : null),
            React.createElement(HorizontalLine, { color: '#6D6D6D' }),
            supplementionAllowed() || editingAllowed() ?
                React.createElement("div", { className: 'modificationSpecialOfferActionButtonsWrapper' },
                    editingAllowed() ?
                        React.createElement("div", { className: 'specialOfferSubmitButtonWrapper' },
                            React.createElement("div", { className: 'specialOfferSupplementButton' },
                                React.createElement(Button, { variant: 'primary', type: 'button', onClick: onEditClick }, "Wniosek o zmian\u0119 warunk\u00F3w")))
                        : null,
                    supplementionAllowed() ?
                        React.createElement("div", { className: 'specialOfferSubmitButtonWrapper' },
                            React.createElement("div", { className: 'specialOfferSupplementButton' },
                                React.createElement(Button, { variant: 'primary', type: 'button', onClick: onSupplementClick }, "Wniosek o rozszerzenie oferty specjalnej")))
                        : null)
                :
                    React.createElement("div", { className: 'specialOfferSubmitButtonWrapper' },
                        React.createElement("div", { className: 'specialOfferSubmitButton' },
                            React.createElement(Button, { variant: 'primary', type: 'button', onClick: onCloseClick }, "zamknij"))),
            status === 'pending' ? React.createElement(Spinner, null) : null)
        : React.createElement(InfoDialog, { open: openConfirmDialog, message: dialogMessage, onConfirm: handleOnConfirmDialog });
});
