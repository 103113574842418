var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { useStatus } from 'common/statusContext';
import { Button } from 'components/button/Button.component';
import { Spinner } from 'components/spinner/Spinner';
import React, { useCallback, useEffect, useState } from 'react';
import { AccordionViewSection } from '../accordionViewSection/AccordionViewSection';
import { CommentTextArea } from '../additionalInfos/AdditionalInfos';
import { useAdditionalInfos } from '../additionalInfos/useAdditionalInfos';
import { ApplicantData } from '../applicantData/ApplicantData';
import { ClientSectionPreview } from '../clientSection/ClientSectionPreview';
import { HorizontalLine } from '../horizontalLine/HorizontalLine';
import { PreviewRequestSubjectsSection } from '../requestSubject/PreviewRequestSubjectSection';
import { SalePotentialPreview } from '../salePotential/SalePotentialPreview';
import { SettlementSection } from '../settlementSection/SettlementSection';
import { useSpecialOfferApi } from '../useSpecialOfferApi';
import { ViewSection } from '../viewSection/ViewSection';
import './settlementSpecialOfferForm.css';
import ReactDOM from 'react-dom';
import { InfoDialog } from '../InfoDialog';
import { fundingFilter } from 'common/fundingFilter';
import { useInterval } from 'pages/priceListImport/priceListImportContainer/dataImport/useInterval';
import UserContext from 'common/userContext';
import SessionStorage from '../../../common/sessionStorage';
export var SettlementSpecialOfferForm = withCancellation(function () {
    var _a = useStatus(), status = _a.status, setStatus = _a.setStatus;
    var _b = useState(), specialOffer = _b[0], setSpecialOffer = _b[1];
    var _c = useState(0), tabValue = _c[0], setTabValue = _c[1];
    var _d = useState(0), rejectedTabValue = _d[0], setRejectedTabValue = _d[1];
    function onTabChange(value) {
        setTabValue(value);
    }
    function onRejectedTabChange(value) {
        setRejectedTabValue(value);
    }
    var _e = useState(), order = _e[0], setOrder = _e[1];
    var _f = useState(), percentagesValues = _f[0], setPercentagesValues = _f[1];
    var _g = useState(false), openConfirmDialog = _g[0], setOpenConfirmDialog = _g[1];
    var _h = useState(''), dialogMessage = _h[0], setDialogMessage = _h[1];
    var _j = useState(null), pollingDelay = _j[0], setPollingDelay = _j[1];
    var _k = useState([]), otherRecipientRequests = _k[0], setOtherRecipientRequests = _k[1];
    var _l = useState(), applicantName = _l[0], setApplicantName = _l[1];
    var specialOfferApi = useSpecialOfferApi();
    var _m = window.location.href.split('?'), searchParamsString = _m[1];
    var searchParams = new URLSearchParams(searchParamsString);
    var specialOfferNumber = searchParams.get('specialOfferNumber');
    var specialOfferId = searchParams.get('specialOfferId');
    var orderId = searchParams.get('orderId');
    useEffect(function () {
        function getOtherSpecialOfferForParty(partyIdentificationValue) {
            return __awaiter(this, void 0, void 0, function () {
                var otherRequestsForPartyResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, specialOfferApi.otherRequestsForIdentificationNumber(partyIdentificationValue)];
                        case 1:
                            otherRequestsForPartyResponse = _a.sent();
                            setOtherRecipientRequests(otherRequestsForPartyResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getApplicantName() {
            return __awaiter(this, void 0, void 0, function () {
                var applicantDataResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, specialOfferApi.getApplicantData()];
                        case 1:
                            applicantDataResponse = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                setApplicantName(applicantDataResponse.name);
                                setStatus('success');
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getSpecialOffer() {
            return __awaiter(this, void 0, void 0, function () {
                var specialOfferForSettlementResponse_1, orderResponse_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(specialOfferNumber && specialOfferId && orderId)) return [3 /*break*/, 3];
                            setStatus('pending');
                            return [4 /*yield*/, specialOfferApi.getSpecialOfferForSettlement(specialOfferNumber, specialOfferId)];
                        case 1:
                            specialOfferForSettlementResponse_1 = _a.sent();
                            return [4 /*yield*/, specialOfferApi.getOrder(orderId)];
                        case 2:
                            orderResponse_1 = _a.sent();
                            if (specialOfferForSettlementResponse_1 && specialOfferForSettlementResponse_1.status === 200 && orderResponse_1 && orderResponse_1.status === 200) {
                                ReactDOM.unstable_batchedUpdates(function () {
                                    setSpecialOffer(specialOfferForSettlementResponse_1.data);
                                    setOrder(orderResponse_1.data);
                                    getOtherSpecialOfferForParty(specialOfferForSettlementResponse_1.data.ordering.party.identificationNumber.value);
                                    setStatus('success');
                                });
                            }
                            else {
                                setStatus('error');
                                console.error('Błąd pobierania oferty specjalnej');
                            }
                            _a.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }
        getSpecialOffer();
        getApplicantName();
    }, [specialOfferNumber]);
    useEffect(function () {
        function getPercentagesValues() {
            return __awaiter(this, void 0, void 0, function () {
                var percentageValuesResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, specialOfferApi.getPercentagesValues(specialOffer.querySubject[0].dealerShareMarginNet, specialOffer.querySubject[0].vcpLevelNet, specialOffer.querySubject[0].catalogPriceNet, specialOffer.querySubject[0].catalogPriceGross)];
                        case 1:
                            percentageValuesResponse = _a.sent();
                            setPercentagesValues(percentageValuesResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (specialOffer) {
            getPercentagesValues();
        }
    }, [specialOffer]);
    var currentSpecialOffer = otherRecipientRequests && otherRecipientRequests.find(function (specialOffer) { return specialOffer.number === specialOfferNumber; });
    var applicantSection = specialOffer && {
        applicantData: {
            applicantId: specialOffer.applicant.login,
            applicantName: specialOffer.applicant.name,
            organizationId: specialOffer.applicant.organizationId,
            organizationName: specialOffer.applicant.organizationName,
            applicationDate: specialOffer.applicant.applicationDate,
            validTo: currentSpecialOffer && (currentSpecialOffer.status !== 'SUBMITTED' && currentSpecialOffer.status !== 'DECLINED') ? specialOffer.validTo : null
        },
    };
    var clientSection = specialOffer && {
        party: specialOffer.ordering.party,
        partyCars: specialOffer.ownedCars,
        type: specialOffer.type,
        requestDate: specialOffer.queryDate,
        requestAttachments: specialOffer.requestAttachments,
        policyAttachments: specialOffer.policyAttachments,
        capitalGroup: specialOffer.capitalGroup
    };
    var salePotentialSection = specialOffer && {
        fleet: specialOffer.salePotentials
    };
    var requestSubjectSection = specialOffer && {
        requestSubjects: specialOffer.querySubject
    };
    var rejectedRequestSubjectSection = specialOffer && {
        requestSubjects: specialOffer.rejectedQuerySubject
    };
    var listPriceForClientDiscountNet = order ? (order.listPriceFromOffer ? order.listPriceFromOffer : order.listPrice) : null;
    var orderListPriceWithoutAccessories = order ? (order.listPriceWithoutAccessories ? order.listPriceWithoutAccessories : (order.listPriceFromOffer ? order.listPriceFromOffer : order.listPrice)) : null;
    var settlementSection = (order && percentagesValues) && {
        settlements: [
            {
                orderDate: order.date,
                dealerOrder: order.dealerOrder,
                vin: order.vin,
                car: specialOffer.querySubject[0].car,
                modelYear: specialOffer.querySubject[0].modelYear,
                drive: specialOffer.querySubject[0].driver,
                specialOfferType: "".concat(specialOffer.type.label).concat(specialOffer.type.subType && specialOffer.type.subType.label ? ' - ' + specialOffer.type.subType.label : ''),
                declaredDiscountGroup: order.declaredDiscountGroup,
                catalogPriceWithoutAccessoriesNet: orderListPriceWithoutAccessories,
                clientDiscountNet: Number((listPriceForClientDiscountNet * (listPriceForClientDiscountNet - order.finalPrice) / listPriceForClientDiscountNet).toFixed(2)),
                specialOfferDiscountPercent: specialOffer.querySubject[0].vcpLevelNet,
                specialOfferDiscountAmountNet: Number((orderListPriceWithoutAccessories * specialOffer.querySubject[0].vcpLevelNet / 100).toFixed(2)),
                funding: fundingFilter(order.funding),
                fundingVISTA: order.declaredFunding,
                status: '',
                orderId: order.id,
                rawStatus: ''
            }
        ]
    };
    var additionalInfosSection = useAdditionalInfos();
    var handleInterval = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var settlementStatusResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, specialOfferApi.getSettlementStatus(orderId)];
                case 1:
                    settlementStatusResponse = _a.sent();
                    if (settlementStatusResponse && settlementStatusResponse.concessionApplicationId && settlementStatusResponse.concessionApplicationNumber) {
                        setPollingDelay(null);
                        setStatus('success');
                        window.location.href = "#/orders/".concat(orderId, "/summary");
                    }
                    return [2 /*return*/];
            }
        });
    }); }, []);
    function onStopPolling() {
        setPollingDelay(null);
        setStatus('error');
        setOpenConfirmDialog(true);
        setDialogMessage('Nie udało się wysłać rozliczenia oferty specjalnej');
    }
    useInterval(handleInterval, pollingDelay, { maxRetries: 8, onMaxRetriesReached: function () { return onStopPolling(); } });
    function onSettlementSaveClick() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var settlementData = {
            order: {
                orderDate: order.date,
                orderId: orderId,
                dealerOrder: order.dealerOrder,
                commonOrder: order.commonOrderNumber
            },
            car: {
                vin: order.vin,
                modelId: order.modelId,
                engine: order.engine,
                priceWithoutAccessoriesNet: orderListPriceWithoutAccessories,
                pno12: specialOffer.querySubject[0].pno12,
                version: specialOffer.querySubject[0].version
            },
            spiecialOfferType: {
                valueSpecialOffer: "".concat(specialOffer.type.label).concat(specialOffer.type.subType && specialOffer.type.subType.label ? ' - ' + specialOffer.type.subType.label : ''),
                valueDeclaredVista: order.declaredDiscountGroup
            },
            clientDiscountNet: Number((order.listPrice * (listPriceForClientDiscountNet - order.finalPrice) / listPriceForClientDiscountNet).toFixed(2)),
            specialOfferDiscount: {
                percentValue: specialOffer.querySubject[0].vcpLevelNet,
                amountNet: Number((orderListPriceWithoutAccessories * specialOffer.querySubject[0].vcpLevelNet / 100).toFixed(2))
            },
            financing: {
                valueSpecialOffer: fundingFilter(order.funding),
                valueDeclaredVista: order.declaredFunding,
                netPrice: (_b = (_a = order.invoice) === null || _a === void 0 ? void 0 : _a.netPrice) !== null && _b !== void 0 ? _b : null,
                grossPrice: (_d = (_c = order.invoice) === null || _c === void 0 ? void 0 : _c.totalGross) !== null && _d !== void 0 ? _d : null,
                vatAmount: (_f = (_e = order.invoice) === null || _e === void 0 ? void 0 : _e.VATAmount) !== null && _f !== void 0 ? _f : null,
                origin: ((_g = order.invoice) === null || _g === void 0 ? void 0 : _g.netPrice) ? 'BILL_BIRD' : null,
                priceDate: (_j = (_h = order.invoice) === null || _h === void 0 ? void 0 : _h.invoiceDate) !== null && _j !== void 0 ? _j : null
            },
            applicant: {
                organizationId: SessionStorage.get('organizationId'),
                organizationName: SessionStorage.get('organizationName'),
                login: SessionStorage.get('login'),
                name: applicantName
            },
            additionalInfo: additionalInfosSection.additionalInfo
        };
        setStatus('pending');
        var sendSpecialOfferSettlementResponse = specialOfferApi.sendSettlement(specialOfferNumber, specialOfferId, settlementData);
        if (sendSpecialOfferSettlementResponse) {
            setPollingDelay(5000);
        }
        else {
            setStatus('error');
            setDialogMessage('Nie udało się wysłać rozliczenia oferty specjalnej');
            setOpenConfirmDialog(true);
            console.error('Bład zapisu rozliczenia oferty specjalnej');
        }
    }
    function handleOnConfirmDialog() {
        setOpenConfirmDialog(false);
        setDialogMessage('');
    }
    function isSaveDisabled() {
        return !orderListPriceWithoutAccessories;
    }
    var isOSFromMyOrganization = specialOffer && (UserContext.organizationId() === specialOffer.applicant.organizationId);
    var isOrderFromMyOrganization = order && (UserContext.organizationId() === order.dealerId);
    var canPreview = isOSFromMyOrganization && isOrderFromMyOrganization;
    useEffect(function () {
        if (specialOffer && !canPreview) {
            setDialogMessage('Brak dostępu!');
            setOpenConfirmDialog(true);
        }
    }, [specialOffer, canPreview]);
    return (specialOffer && order && percentagesValues && canPreview ?
        React.createElement("div", { className: "specialOfferSettlementWrapper" },
            React.createElement(ViewSection, { title: "wnioskuj\u0105cy", dataTestid: "special-offer-applicant-section" },
                React.createElement(ApplicantData, { applicantSection: applicantSection, applicationId: specialOfferNumber })),
            React.createElement(ViewSection, { title: 'zamawiaj\u0105cy', dataTestid: 'special-offer-client-section' },
                React.createElement(ClientSectionPreview, { clientSection: clientSection })),
            React.createElement(ViewSection, { title: 'posiadana flota pojazd\u00F3w osobowych', dataTestid: 'special-offer-sale-potential-section' },
                React.createElement(SalePotentialPreview, { salePotentialSection: salePotentialSection })),
            React.createElement(AccordionViewSection.Root, null,
                React.createElement(AccordionViewSection.Item, { value: 'special-offer-request-subject' },
                    React.createElement(AccordionViewSection.Trigger, null, "przedmiot zapytania"),
                    React.createElement(AccordionViewSection.Content, null,
                        React.createElement(PreviewRequestSubjectsSection, { requestSubjectSectionProp: requestSubjectSection, tabValue: tabValue, onTabChange: onTabChange }))),
                rejectedRequestSubjectSection.requestSubjects.length > 0 ?
                    React.createElement(AccordionViewSection.Item, { value: 'special-offer-rejected-request-subject' },
                        React.createElement(AccordionViewSection.Trigger, null, "odrzucone wnioski"),
                        React.createElement(AccordionViewSection.Content, null,
                            React.createElement(PreviewRequestSubjectsSection, { requestSubjectSectionProp: rejectedRequestSubjectSection, tabValue: rejectedTabValue, onTabChange: onRejectedTabChange })))
                    : null),
            React.createElement(ViewSection, { title: 'rozliczenie', dataTestid: 'special-offer-settlement-section' },
                React.createElement(SettlementSection, { settlementSection: settlementSection })),
            React.createElement(ViewSection, { title: "informacje dodatkowe", dataTestid: "special-offer-additional-infos" },
                React.createElement("div", { className: "newSpecialOfferComment" },
                    React.createElement(CommentTextArea, { additionalInfosSection: additionalInfosSection }))),
            React.createElement(HorizontalLine, { color: "#6D6D6D" }),
            React.createElement("div", { className: "specialOfferSubmitButtonWrapper" },
                React.createElement("div", { className: "specialOfferSubmitButton" },
                    React.createElement(Button, { variant: "primary", type: "button", onClick: onSettlementSaveClick, disabled: isSaveDisabled() }, "wy\u015Blij do VCP"))),
            React.createElement(InfoDialog, { open: openConfirmDialog, message: dialogMessage, onConfirm: handleOnConfirmDialog }),
            status === 'pending' ? React.createElement(Spinner, null) : null)
        : React.createElement(InfoDialog, { open: openConfirmDialog, message: dialogMessage, onConfirm: handleOnConfirmDialog }));
});
