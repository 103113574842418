// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .arrow {
    fill: #FFFFFF;
    filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.3));
}`, "",{"version":3,"sources":["webpack://./src/components/hoverCard/hoverCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oDAAoD;AACxD","sourcesContent":[".arrow {\n    fill: #FFFFFF;\n    filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.3));\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
