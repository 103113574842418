// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .financialProductsTabsRoot {
    font-family: 'Volvo Novum';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-text-color);
}

#new-dol .financialProductsTabsList {
    display: flex;
    border-bottom: 1px solid #EBEBEB;
    gap: 29px;
    padding-top: 12px;
    margin-left: 76px;
    margin-right: 50px;
}

#new-dol .financialProductsTabsTrigger {
    flex-basis: 0;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    padding-bottom: 16px;
    user-select: none;
    padding-left: 32px;
    padding-right: 32px;
}

#new-dol .financialProductsTabsTrigger[data-state="active"] {
    color: var(--blue);
    border-bottom: 2px solid var(--blue);
    padding-bottom: 14px;
}`, "",{"version":3,"sources":["webpack://./src/pages/financialProducts/financialProductsTabs/financialProductsTabs.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,gCAAgC;IAChC,SAAS;IACT,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,cAAc;IACd,YAAY;IACZ,UAAU;IACV,oBAAoB;IACpB,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,oCAAoC;IACpC,oBAAoB;AACxB","sourcesContent":[".financialProductsTabsRoot {\n    font-family: 'Volvo Novum';\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    color: var(--main-text-color);\n}\n\n.financialProductsTabsList {\n    display: flex;\n    border-bottom: 1px solid #EBEBEB;\n    gap: 29px;\n    padding-top: 12px;\n    margin-left: 76px;\n    margin-right: 50px;\n}\n\n.financialProductsTabsTrigger {\n    flex-basis: 0;\n    background: none;\n    color: inherit;\n    border: none;\n    padding: 0;\n    padding-bottom: 16px;\n    user-select: none;\n    padding-left: 32px;\n    padding-right: 32px;\n}\n\n.financialProductsTabsTrigger[data-state=\"active\"] {\n    color: var(--blue);\n    border-bottom: 2px solid var(--blue);\n    padding-bottom: 14px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
