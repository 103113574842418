var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import UserContext from 'common/userContext';
import React, { useState, useEffect } from 'react';
import { useAccessoriesApi } from './useAccessoriesApi';
import ReactDOM from 'react-dom';
import { useCreatorApi } from '../useCreatorApi';
import { useStatus } from '../../../common/statusContext';
import debounce from 'lodash.debounce';
var debounceWait = 500;
function isFromVCP() {
    return UserContext.isFromVCP();
}
function getSelectedAccessories(allSelectedAccessories, availableAccessories) {
    var accessoriesToSelect = availableAccessories.filter(function (availableAccessory) {
        return allSelectedAccessories.includes(availableAccessory.id);
    });
    return accessoriesToSelect.map(function (option) { return option.id; });
}
export function useAccessoriesTab(_a) {
    var _this = this;
    var canBeSynced = _a.canBeSynced, carModelId = _a.carModelId, offer = _a.offer, handleChangeOffer = _a.handleChangeOffer, token = _a.token, handleOnTokenChange = _a.handleOnTokenChange, onSynced = _a.onSynced, onAccessoryChange = _a.onAccessoryChange, isSaveInSessionStorage = _a.isSaveInSessionStorage, offerIdToClone = _a.offerIdToClone, offerIdFromStock = _a.offerIdFromStock;
    var _b = useState(undefined), accessories = _b[0], setAccessories = _b[1];
    var _c = useState([]), selectedAccessoryPackages = _c[0], setSelectedAccessoryPackages = _c[1];
    var _d = useState([]), selectedAccessories = _d[0], setSelectedAccessories = _d[1];
    var _e = useState([]), additionalAccessories = _e[0], setAdditionalAccessories = _e[1];
    var _f = useState([]), additionalServices = _f[0], setAdditionalServices = _f[1];
    var _g = useState({}), accessoriessCustomPrices = _g[0], setAccessoriesCustomPrices = _g[1];
    var _h = useState(''), selectedWinterWheels = _h[0], setSelectedWinterWheels = _h[1];
    var accessoriesApi = useAccessoriesApi();
    var creatorApi = useCreatorApi();
    var addOptionRequest = creatorApi.addOption;
    var deleteOptionRequest = creatorApi.deleteOption;
    var addCustomOptionRequest = creatorApi.addCustomOption;
    var deleteCustomOption = creatorApi.deleteCustomOption;
    var setStatus = useStatus().setStatus;
    function handleChangeSelectedAccessoryPackage(checked, id, price) {
        return __awaiter(this, void 0, void 0, function () {
            var getOfferResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        if (!checked) return [3 /*break*/, 2];
                        return [4 /*yield*/, addOptionRequest(offer.id, id, carModelId, price)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, deleteOptionRequest(offer.id, id, carModelId)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                    case 5:
                        getOfferResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setStatus('success');
                            if (checked) {
                                setSelectedAccessoryPackages(function (currentSelectedAccessoryPackages) { return __spreadArray(__spreadArray([], currentSelectedAccessoryPackages, true), [
                                    id,
                                ], false); });
                            }
                            else {
                                setSelectedAccessoryPackages(function (currentSelectedAccessoryPackages) {
                                    return __spreadArray([], currentSelectedAccessoryPackages, true).filter(function (accessory) { return accessory !== id; });
                                });
                            }
                            handleChangeOffer(getOfferResponse);
                            handleOnTokenChange(getOfferResponse.vehicle.tokenConfiguration);
                            onAccessoryChange();
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleChangeSelectedAccessories(checked, id, price) {
        return __awaiter(this, void 0, void 0, function () {
            var getOfferResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        if (!checked) return [3 /*break*/, 2];
                        return [4 /*yield*/, addOptionRequest(offer.id, id, carModelId, price)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, deleteOptionRequest(offer.id, id, carModelId)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                    case 5:
                        getOfferResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setStatus('success');
                            if (checked) {
                                setSelectedAccessories(function (currentSelectedAccessories) { return __spreadArray(__spreadArray([], currentSelectedAccessories, true), [id], false); });
                            }
                            else {
                                setSelectedAccessories(function (currentSelectedAccessories) {
                                    return __spreadArray([], currentSelectedAccessories, true).filter(function (accessory) { return accessory !== id; });
                                });
                            }
                            handleChangeOffer(getOfferResponse);
                            handleOnTokenChange(getOfferResponse.vehicle.tokenConfiguration);
                            onAccessoryChange();
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleChangeSelectedWinterWheels(accessoryId, price) {
        return __awaiter(this, void 0, void 0, function () {
            var getOfferResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        if (!(accessoryId === 'any')) return [3 /*break*/, 2];
                        return [4 /*yield*/, deleteOptionRequest(offer.id, selectedWinterWheels, carModelId)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, addOptionRequest(offer.id, accessoryId, carModelId, price)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                    case 5:
                        getOfferResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setStatus('success');
                            var selectedOptions = getOfferResponse.vehicle.selectedOptions;
                            var selectedWinterWheels = selectedOptions.find(function (option) { return option === accessoryId; });
                            if (selectedWinterWheels) {
                                setSelectedWinterWheels(accessoryId);
                            }
                            else {
                                setSelectedWinterWheels('any');
                            }
                            handleChangeOffer(getOfferResponse);
                            handleOnTokenChange(getOfferResponse.vehicle.tokenConfiguration);
                            onAccessoryChange();
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    var debouncedChangeAccessoryPrice = React.useRef(debounce(function (offer, value, accessoryId, carModelId) { return __awaiter(_this, void 0, void 0, function () {
        var getOfferResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setStatus('pending');
                    return [4 /*yield*/, deleteOptionRequest(offer.id, accessoryId, carModelId)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, addOptionRequest(offer.id, accessoryId, carModelId, value)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                case 3:
                    getOfferResponse = _a.sent();
                    ReactDOM.unstable_batchedUpdates(function () {
                        setStatus('success');
                        handleChangeOffer(getOfferResponse);
                        onAccessoryChange();
                    });
                    return [2 /*return*/];
            }
        });
    }); }, debounceWait)).current;
    function handleChangeAccessoryPrice(values, accessoryId) {
        var _a;
        var newAccessoryPrice = (_a = values.floatValue) !== null && _a !== void 0 ? _a : 0;
        setAccessoriesCustomPrices(function (current) {
            var _a;
            return __assign(__assign({}, current), (_a = {}, _a[accessoryId] = newAccessoryPrice, _a));
        });
        debouncedChangeAccessoryPrice(offer, newAccessoryPrice, accessoryId, carModelId);
    }
    function handleAddAdditionalAccessory(accessory, price) {
        return __awaiter(this, void 0, void 0, function () {
            var getOfferResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, addCustomOptionRequest(offer.id, accessory.id, price, accessory.name, 'ACCESSORIES')];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                    case 2:
                        getOfferResponse = _a.sent();
                        setStatus('success');
                        handleChangeOffer(getOfferResponse);
                        setAdditionalAccessories(function (currentAccessories) {
                            return __spreadArray(__spreadArray([], currentAccessories, true), [accessory], false);
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleDeleteAdditionalAccessory(accessoryId) {
        return __awaiter(this, void 0, void 0, function () {
            var getOfferResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, deleteCustomOption(offer.id, accessoryId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                    case 2:
                        getOfferResponse = _a.sent();
                        setStatus('success');
                        handleChangeOffer(getOfferResponse);
                        setAdditionalAccessories(function (currentAccessories) {
                            return currentAccessories.filter(function (accessory) { return accessory.id !== accessoryId; });
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleAddAdditionalServices(accessory, price) {
        return __awaiter(this, void 0, void 0, function () {
            var getOfferResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, addCustomOptionRequest(offer.id, accessory.id, price, accessory.name, 'SERVICES')];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                    case 2:
                        getOfferResponse = _a.sent();
                        setStatus('success');
                        handleChangeOffer(getOfferResponse);
                        setAdditionalServices(function (currentAccessories) {
                            return __spreadArray(__spreadArray([], currentAccessories, true), [accessory], false);
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleDeleteAdditionalServices(accessoryId) {
        return __awaiter(this, void 0, void 0, function () {
            var getOfferResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, deleteCustomOption(offer.id, accessoryId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                    case 2:
                        getOfferResponse = _a.sent();
                        setStatus('success');
                        handleChangeOffer(getOfferResponse);
                        setAdditionalServices(function (currentAccessories) {
                            return currentAccessories.filter(function (accessory) { return accessory.id !== accessoryId; });
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        if (canBeSynced &&
            carModelId &&
            offer &&
            token) {
            var getAccessoriesRequest = isFromVCP()
                ? accessoriesApi.getAccessoriesVcp
                : accessoriesApi.getAccessories;
            getAccessoriesRequest(carModelId, token).then(function (data) {
                ReactDOM.unstable_batchedUpdates(function () {
                    var _a, _b, _c, _d;
                    setAccessories(data);
                    // pakiety defaultowe na starcie
                    var packageAccessories = data.find(function (accessory) { return accessory.category.toUpperCase() === 'PAKIETY'; });
                    if (packageAccessories) {
                        setSelectedAccessoryPackages(getSelectedAccessories(offer.vehicle.selectedOptions, packageAccessories.accessories));
                    }
                    // koła zimowe
                    var winterWheelsAccessories = data.find(function (accessory) { return accessory.category.toUpperCase() === 'KOŁA ZIMOWE'; });
                    if (winterWheelsAccessories) {
                        var selectedWinterWheel = winterWheelsAccessories.accessories.find(function (accessory) {
                            return offer.vehicle.selectedOptions.includes(accessory.id);
                        });
                        setSelectedWinterWheels(selectedWinterWheel ? selectedWinterWheel.id : 'any');
                    }
                    // reszta akcesoriów na starcie
                    var restAccessories = data.filter(function (accessory) { return accessory.category.toUpperCase() !== 'PAKIETY' && accessory.category.toUpperCase() !== 'KOŁA ZIMOWE'; }).flatMap(function (accessory) { return accessory.accessories; });
                    setSelectedAccessories(getSelectedAccessories(offer.vehicle.selectedOptions, restAccessories));
                    if (isSaveInSessionStorage || offerIdToClone || offerIdFromStock) {
                        setAccessoriesCustomPrices(offer.vehicle.selectedAccessories.reduce(function (obj, accessory) {
                            obj[accessory.id] = accessory.price;
                            return obj;
                        }, {}));
                        var additionalAccFromOffer = (_b = (_a = offer.vehicle.customOptions) === null || _a === void 0 ? void 0 : _a.find(function (custom) { return custom.type === 'ACCESSORIES'; })) === null || _b === void 0 ? void 0 : _b.option;
                        if (additionalAccFromOffer && additionalAccFromOffer.length > 0) {
                            setAdditionalAccessories(additionalAccFromOffer);
                        }
                        var additionalServFromOffer = (_d = (_c = offer.vehicle.customOptions) === null || _c === void 0 ? void 0 : _c.find(function (custom) { return custom.type === 'SERVICES'; })) === null || _d === void 0 ? void 0 : _d.option;
                        if (additionalServFromOffer && additionalServFromOffer.length > 0) {
                            setAdditionalServices(additionalServFromOffer);
                        }
                    }
                    onSynced();
                });
            });
        }
    }, [
        canBeSynced,
        carModelId,
        token,
        offer
    ]);
    return {
        accessories: accessories,
        selectedAccessoryPackages: selectedAccessoryPackages,
        selectedAccessories: selectedAccessories,
        selectedWinterWheels: selectedWinterWheels,
        onSelectedAccessoryPackageChange: handleChangeSelectedAccessoryPackage,
        onSelectedAccessoryChange: handleChangeSelectedAccessories,
        additionalAccessories: additionalAccessories,
        onAdditionalAccessoryAdd: handleAddAdditionalAccessory,
        onAdditionalAccessoryDelete: handleDeleteAdditionalAccessory,
        additionalServices: additionalServices,
        onAdditionalServicesAdd: handleAddAdditionalServices,
        onAdditionalServicesDelete: handleDeleteAdditionalServices,
        onAccessoryPriceChange: handleChangeAccessoryPrice,
        debouncedChangeAccessoryPrice: debouncedChangeAccessoryPrice,
        accessoriessCustomPrices: accessoriessCustomPrices,
        handleChangeSelectedWinterWheels: handleChangeSelectedWinterWheels
    };
}
