import React from 'react';
import './percentInput.css';
import { NumericFormat } from 'react-number-format';
import { useIsFirstRender } from '../../hooks/useIsFirstRender';
export function PercentInput(_a) {
    var name = _a.name, value = _a.value, onValueChange = _a.onValueChange, id = _a.id, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var isFirstRender = useIsFirstRender();
    function isAllowed(values) {
        if (values.floatValue >= 0 && values.floatValue <= 100 || values.value === '') {
            return true;
        }
        return false;
    }
    var stringValue = value.toString();
    return React.createElement("div", { className: 'percentInputContainer' },
        React.createElement(NumericFormat, { id: id, isAllowed: isAllowed, onValueChange: onValueChange, name: name, value: stringValue === '0' && isFirstRender.current ? '0.00' : stringValue, valueIsNumericString: true, allowNegative: false, decimalScale: 2, decimalSeparator: ',', thousandSeparator: ' ', onBlur: function () {
                if (value === '0' || value === '') {
                    onValueChange({
                        value: '0.00',
                        floatValue: 0,
                        formattedValue: '0,00'
                    });
                }
            }, disabled: disabled }),
        React.createElement("span", { className: 'percentSymbol' }, "%"));
}
