import { useHttp } from 'http/httpService';
export function useFinancingApi() {
    var http = useHttp();
    return {
        changeFinalValueRatio: function (productId, offerId, installment, protocolDeliveryDate, millageLimitId) {
            return http
                .post("/api/creator/zps/".concat(productId, "/finalValueRatio?offerContext=").concat(offerId), {
                installmentNo: installment,
                deliveryProtocolDate: protocolDeliveryDate,
                millageLimitId: millageLimitId,
            })
                .then(function (response) { return response.data; });
        },
        calculate: function (productName, productId, offerId, installment, protocolDeliveryDate, mileageLimitId, entryFeeRatio, finalValueRatio, appealLevel, currencyCode, interestMethodCode, entryFeeDate, mileageLimitValue, calculateId, toPrint, order) {
            return http
                .post("/api/creator/zps/".concat(productId, "/calculate?offerContext=").concat(offerId, "&calculatorId=").concat(calculateId, "&toPrint=").concat(toPrint, "&order=").concat(order), {
                productName: productName,
                installment: installment,
                protocolDeliveryDate: protocolDeliveryDate,
                mileageLimitId: mileageLimitId,
                entryFeeRatio: entryFeeRatio,
                finalValueRatio: finalValueRatio,
                appealLevel: appealLevel,
                currencyCode: currencyCode,
                interestMethodCode: interestMethodCode,
                entryFeeDate: entryFeeDate,
                mileageLimitValue: mileageLimitValue
            })
                .then(function (response) { return response.data; });
        },
        getProductsList: function (offerId) {
            return http.get("/api/creator/zps/list?offerContext=".concat(offerId)).then(function (response) { return response.data; });
        },
        getProductsConfigurations: function () {
            return http.get('/api/creator/leasing/cfg/products/fe').then(function (response) { return response.data; });
        },
        enablePrint: function (calculateId) {
            return http
                .put("/api/creator/zps/calculator/".concat(calculateId, "/enable-print"))
                .then(function (response) { return response.data; });
        },
        disablePrint: function (calculateId) {
            return http
                .put("/api/creator/zps/calculator/".concat(calculateId, "/disable-print"))
                .then(function (response) { return response.data; });
        },
        deleteCalculate: function (calculateId) {
            return http
                .delete("/api/creator/zps/calculator/".concat(calculateId))
                .then(function (response) { return response.data; });
        }
    };
}
