// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .textAmount {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: inherit;
    width: 100%;
}

#new-dol .boldTextAmount {
    font-weight: 500;
}

#new-dol .textAlignEnd {
    text-align: end;
}`, "",{"version":3,"sources":["webpack://./src/components/textAmount/textAmount.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".textAmount {\n    font-family: var(--font-family);\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    color: inherit;\n    width: 100%;\n}\n\n.boldTextAmount {\n    font-weight: 500;\n}\n\n.textAlignEnd {\n    text-align: end;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
