import React from 'react';
import { Label } from 'components/label/Label.component';
import { TextAmount } from './TextAmount';
import { NetPrice } from './NetPrice';
import './netPrice.css';
export function TextAmountWithNetAndLabel(_a) {
    var netPrice = _a.netPrice, grossPrice = _a.grossPrice, labelText = _a.labelText;
    return (React.createElement("div", { style: { textAlign: 'end' } },
        React.createElement(Label, { uppercase: true }, labelText),
        React.createElement("div", { style: { height: '32px', display: 'flex', alignItems: 'center' } },
            React.createElement(TextAmount, { bold: true }, grossPrice)),
        React.createElement("div", null,
            React.createElement("span", { className: 'netPriceWrapper' },
                "netto ",
                React.createElement(NetPrice, null, netPrice)))));
}
