import React from 'react';
import './list.css';
function Root(_a) {
    var children = _a.children;
    return React.createElement("ul", { className: 'listRoot' }, children);
}
function Item(_a) {
    var _b = _a.isSelected, isSelected = _b === void 0 ? false : _b, _c = _a.isPrice, isPrice = _c === void 0 ? true : _c, _d = _a.height, height = _d === void 0 ? 'auto' : _d, _e = _a.cursor, cursor = _e === void 0 ? 'pointer' : _e, _f = _a.bordered, bordered = _f === void 0 ? false : _f, _g = _a.reverseBackground, reverseBackground = _g === void 0 ? false : _g, onClick = _a.onClick, children = _a.children;
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    return React.createElement("li", { style: { cursor: cursor }, className: "item ".concat(isSelected ? 'selected' : '', " ").concat(height === 'auto' ? 'autoHeight' : '', " ").concat(height === 'fixed' ? 'fixedHeight' : '', " ").concat(!isPrice ? 'noPrice' : '', " ").concat(bordered ? 'bordered' : '', " ").concat(reverseBackground ? 'reverseBackground' : ''), onClick: onClick }, children);
}
function DiscountItem(_a) {
    var children = _a.children;
    return React.createElement("li", { className: 'discountItem' }, children);
}
function LayoutWithCodeAndChips(_a) {
    var children = _a.children, isSummary = _a.isSummary;
    return React.createElement("div", { className: "layoutWithCodeAndChip ".concat(isSummary ? 'isSummary' : '') }, children);
}
function LayoutWithAmountInput(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'layoutWithAmountInput' }, children);
}
function AccessoriesListHeading() {
    return React.createElement("div", { className: 'accessoriesListHeadingWrapper' },
        React.createElement("div", { className: 'accessoriesListHeadingCatalogPrice' }, "Cena katalogowa"),
        React.createElement("div", { className: 'accessoriesListHeadingSalesPrice' }, "Cena sprzeda\u017Cy"));
}
function LayoutStandardEquipment(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'layoutStandardEquipment' }, children);
}
function LayoutStandardEquipmentForInteriorFinish(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'layoutStandardEquipmentForInteriorFinish' }, children);
}
function LayoutDiscounts(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'layoutDiscount' }, children);
}
function LayoutSummaryBasicInfo(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'layoutBasicInfo' }, children);
}
export var List = {
    Root: Root,
    Item: Item,
    DiscountItem: DiscountItem,
    LayoutWithCodeAndChips: LayoutWithCodeAndChips,
    LayoutWithAmountInput: LayoutWithAmountInput,
    AccessoriesListHeading: AccessoriesListHeading,
    LayoutStandardEquipment: LayoutStandardEquipment,
    LayoutStandardEquipmentForInteriorFinish: LayoutStandardEquipmentForInteriorFinish,
    LayoutDiscounts: LayoutDiscounts,
    LayoutSummaryBasicInfo: LayoutSummaryBasicInfo
};
