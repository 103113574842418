import { Label } from 'components/label/Label.component';
import { List } from 'components/list/List.component';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
import React from 'react';
import '../financialProducts.css';
import { Checkbox } from 'components/checkbox/Checkbox.component';
export function FinancialProductConfigurationTab(_a) {
    var cfmProducts = _a.cfmProducts, isCfm = _a.isCfm, selectedCfmProduct = _a.selectedCfmProduct, onCfpProductChange = _a.onCfpProductChange, isTotalFeesVisible = _a.isTotalFeesVisible, handleChangeTotalFeesVisible = _a.handleChangeTotalFeesVisible;
    return (React.createElement("div", { className: 'financialProductConfigurationWrapper' },
        cfmProducts && cfmProducts.length > 0 ? (React.createElement("div", { className: 'financialProductConfigurationPackagesWrapper' },
            React.createElement("div", { className: 'financialProductCategoryHeader' }, "Pakiet serwisowy"),
            React.createElement(RadioGroup.Root, { value: selectedCfmProduct.toString(), onValueChange: function (value) { return onCfpProductChange(Number(value)); } },
                React.createElement(List.Root, null, cfmProducts.map(function (cfmProduct) { return (React.createElement(List.Item, { key: cfmProduct.id },
                    React.createElement("div", { className: 'financialProductConfigurationItem' },
                        React.createElement(RadioGroup.Item, { value: cfmProduct.id.toString(), id: cfmProduct.id.toString() },
                            React.createElement(RadioGroup.Indicator, null)),
                        React.createElement(Label, { htmlFor: cfmProduct.id.toString() }, cfmProduct.name)))); }))))) : null,
        !isCfm ?
            React.createElement("div", null,
                React.createElement("div", { className: 'financialProductCategoryHeader' }, "Suma op\u0142at"),
                React.createElement(List.Root, null,
                    React.createElement(List.Item, null,
                        React.createElement("div", { className: 'financialProductCheckboxWrapper' },
                            React.createElement(Checkbox.Root, { id: 'totalFeesVisible', checked: isTotalFeesVisible, value: 'totalFeesVisible', onCheckedChange: function (checked) { return handleChangeTotalFeesVisible(checked); } },
                                React.createElement(Checkbox.Indicator, null)),
                            React.createElement(Label, { htmlFor: 'totalFeesVisible' }, "Wy\u015Bwietlenie warto\u015Bci sumy op\u0142at")))))
            : null));
}
