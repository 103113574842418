import { Button } from 'components/button/Button.component';
import { TextInput } from 'components/textInput/TextInput.component';
import React from 'react';
import { GarbageIcon } from '../GarbageIcon';
import './salePotential.css';
export function SalePotential(_a) {
    var mode = _a.mode, salePotentialSection = _a.salePotentialSection;
    var mark = salePotentialSection.mark, handleOnMarkChange = salePotentialSection.handleOnMarkChange, model = salePotentialSection.model, handleOnModelChange = salePotentialSection.handleOnModelChange, amount = salePotentialSection.amount, handleOnAmountChange = salePotentialSection.handleOnAmountChange, fleet = salePotentialSection.fleet, handleOnAddCarFleet = salePotentialSection.handleOnAddCarFleet, handleOnDeleteCarFleet = salePotentialSection.handleOnDeleteCarFleet;
    var isAddButtonDisabled = mark === '' || model === '' || amount === '';
    function isCreatingMode() {
        return mode === 'CREATE';
    }
    return React.createElement("div", { className: 'salePotentialSectionWrapper' },
        React.createElement("div", { className: 'salePotentialSectionFleetWrapper', "data-testid": "sale-potential-inputs" },
            fleet && fleet.length > 0 ? React.createElement("div", { className: 'salePotentialSectionLabel' }, "Posiadana flota pojazd\u00F3w osobowych:") : null,
            isCreatingMode() ?
                React.createElement("div", { className: 'salePotentialInputsWrapper' },
                    React.createElement("div", { className: 'salePotentialSectionInputMark' },
                        React.createElement(TextInput, { id: 'mark', name: 'mark', placeholder: 'Marka', value: mark, onChange: handleOnMarkChange })),
                    React.createElement("div", { className: 'salePotentialSectionInputModel' },
                        React.createElement(TextInput, { id: 'model', name: 'model', placeholder: 'Model', value: model, onChange: handleOnModelChange })),
                    React.createElement("div", { className: 'salePotentialSectionInputAmount' },
                        React.createElement(TextInput, { id: 'amount', name: 'amount', placeholder: 'Sztuk', value: amount, onChange: handleOnAmountChange })),
                    React.createElement("div", { className: 'salePotentialSectionButtonWrapper' },
                        React.createElement(Button, { variant: 'secondary', size: 32, onClick: handleOnAddCarFleet, disabled: isAddButtonDisabled }, "dodaj")))
                : null),
        React.createElement("div", { className: 'salePotentialSectionTableWrapper', "data-testid": "sale-potential-table" }, fleet && fleet.length > 0 ?
            React.createElement("table", { className: 'salePotentialSectionTable' },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: 'salePotentialSectionTableHeader headerMarkCell' }, "Marka"),
                        React.createElement("th", { className: 'salePotentialSectionTableHeader headerModelCell' }, "Model"),
                        React.createElement("th", { className: 'salePotentialSectionTableHeader headerAmountCell' }, "Sztuk"),
                        React.createElement("th", { className: 'salePotentialSectionTableHeader headerDeleteIconCell' }))),
                React.createElement("tbody", null, fleet.map(function (car) { return (React.createElement("tr", { className: 'salePotentialtSectionFleetTableRow', key: "".concat(car.mark, "_").concat(car.model) },
                    React.createElement("td", { className: 'markCell' }, car.mark),
                    React.createElement("td", { className: 'modelCell' }, car.model),
                    React.createElement("td", { className: 'amountCell' }, car.amount),
                    React.createElement("td", { className: 'deleteIconCell', "data-testid": 'sale-potential-delete-car' }, isCreatingMode() ?
                        React.createElement("button", { className: 'salePotentialSectionDeleteButton', type: 'button', onClick: function () { return handleOnDeleteCarFleet(car.mark, car.model); } },
                            React.createElement(GarbageIcon, null))
                        : null))); })))
            : null));
}
