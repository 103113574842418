// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .basicInfoCategoryName {
    width: 244px;
}

#new-dol .basicInfoElements {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 28px;
}

#new-dol .basicInfoElementWrapper {
    display: grid;
    grid-template-columns: 300px repeat(2, 225px);
    gap: 22px;
}

#new-dol .elementNetAmount {
    justify-self: end;
}

#new-dol .interiorFinishWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#new-dol .interiorFinishElement {
    display: grid;
    grid-template-columns: 632px 80px 238px;
    gap: 12px;
}

#new-dol .technicalDataAndEnvImpact {
    margin-top: 40px;
}

#new-dol .envImpactWrapper {
    margin-top: 48px;
}

#new-dol .equipmentWrapper {
    margin-top: 54px;
}

#new-dol .additionalInformationWrapper {
    border-bottom: 1px solid #B0B0B0;
    padding-bottom: 24px;
}

#new-dol .additionalInformationTextAreaWrapper {
    width: 904px;
    height: 96px;
}

#new-dol .buttonsWrapper {
    padding-block: 24px;
}

#new-dol .buttons {
    display: flex;
    justify-content: end;
    gap: 8px;
}

#new-dol .getSheetButton, #new-dol .backButton, #new-dol .sendButton, #new-dol .registerButton {
    min-width: 200px;
}

#new-dol .employeeLeasingLimitsMessages {
    display: flex;
    justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/pages/creator/summary/summaryTab.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,6CAA6C;IAC7C,SAAS;AACb;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,uCAAuC;IACvC,SAAS;AACb;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gCAAgC;IAChC,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,QAAQ;AACZ;;AAEA;IAII,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":[".basicInfoCategoryName {\n    width: 244px;\n}\n\n.basicInfoElements {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n    gap: 28px;\n}\n\n.basicInfoElementWrapper {\n    display: grid;\n    grid-template-columns: 300px repeat(2, 225px);\n    gap: 22px;\n}\n\n.elementNetAmount {\n    justify-self: end;\n}\n\n.interiorFinishWrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.interiorFinishElement {\n    display: grid;\n    grid-template-columns: 632px 80px 238px;\n    gap: 12px;\n}\n\n.technicalDataAndEnvImpact {\n    margin-top: 40px;\n}\n\n.envImpactWrapper {\n    margin-top: 48px;\n}\n\n.equipmentWrapper {\n    margin-top: 54px;\n}\n\n.additionalInformationWrapper {\n    border-bottom: 1px solid #B0B0B0;\n    padding-bottom: 24px;\n}\n\n.additionalInformationTextAreaWrapper {\n    width: 904px;\n    height: 96px;\n}\n\n.buttonsWrapper {\n    padding-block: 24px;\n}\n\n.buttons {\n    display: flex;\n    justify-content: end;\n    gap: 8px;\n}\n\n.getSheetButton,\n.backButton,\n.sendButton,\n.registerButton {\n    min-width: 200px;\n}\n\n.employeeLeasingLimitsMessages {\n    display: flex;\n    justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
