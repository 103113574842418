// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .netAmount {
    font-family: var(--font-family); 
    font-weight: 500; 
    font-size: 10px; 
    color: var(--text-grey);
    line-height: 20px; 
}`, "",{"version":3,"sources":["webpack://./src/pages/demo/components/netAmount/netAmount.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,gBAAgB;IAChB,eAAe;IACf,uBAAuB;IACvB,iBAAiB;AACrB","sourcesContent":[".netAmount {\n    font-family: var(--font-family); \n    font-weight: 500; \n    font-size: 10px; \n    color: var(--text-grey);\n    line-height: 20px; \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
