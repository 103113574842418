var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useState, useEffect } from 'react';
import { useExteriorApi } from './useExteriorApi';
import UserContext from 'common/userContext';
import ReactDOM from 'react-dom';
import { useCreatorApi } from '../useCreatorApi';
import { useStatus } from '../../../common/statusContext';
function isFromVCP() {
    return UserContext.isFromVCP() && !UserContext.hasAnyRole('VCP_EMPLOYEE_LEASING');
}
function getSelectedOption(allSelectedOptions, availableOptions) {
    if (!availableOptions) {
        return '';
    }
    var optionToSelect = availableOptions.find(function (availableOption) {
        return allSelectedOptions.includes(availableOption.id);
    });
    return optionToSelect ? optionToSelect.id : '';
}
export function useExteriorTab(_a) {
    var carModelId = _a.carModelId, token = _a.token, handleOnTokenChange = _a.handleOnTokenChange, canBeSynced = _a.canBeSynced, onSynced = _a.onSynced, onExteriorChange = _a.onExteriorChange, offer = _a.offer, handleChangeOffer = _a.handleChangeOffer, handleSetAppError = _a.handleSetAppError, employeeLeasingMode = _a.employeeLeasingMode;
    var _b = useState(''), selectedColor = _b[0], setSelectedColor = _b[1];
    var _c = useState(''), selectedWheels = _c[0], setSelectedWheels = _c[1];
    var _d = useState(undefined), exteriors = _d[0], setExteriors = _d[1];
    var _e = useState(undefined), selectedPno34 = _e[0], setSelectedPno34 = _e[1];
    var exteriorApi = useExteriorApi();
    var creatorApi = useCreatorApi(employeeLeasingMode ? { context: 'employeeLeasing' } : undefined);
    var addOptionRequest = creatorApi.addOption;
    var setStatus = useStatus().setStatus;
    function handleChangeSelectedColor(value) {
        return __awaiter(this, void 0, void 0, function () {
            var getOfferResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, addOptionRequest(offer.id, value, carModelId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                    case 2:
                        getOfferResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setStatus('success');
                            setSelectedColor(value);
                            handleChangeOffer(getOfferResponse);
                            handleOnTokenChange(getOfferResponse.vehicle.tokenConfiguration);
                            onExteriorChange();
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleChangeSelectedWheels(value) {
        return __awaiter(this, void 0, void 0, function () {
            var getOfferResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, addOptionRequest(offer.id, value, carModelId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                    case 2:
                        getOfferResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setStatus('success');
                            setSelectedWheels(value);
                            handleChangeOffer(getOfferResponse);
                            handleOnTokenChange(getOfferResponse.vehicle.tokenConfiguration);
                            onExteriorChange();
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        if (carModelId &&
            token &&
            canBeSynced &&
            offer) {
            var getExteriorRequest = isFromVCP() ? exteriorApi.getExteriorVcp : exteriorApi.getExterior;
            getExteriorRequest(carModelId, token).then(function (exteriorsData) {
                ReactDOM.unstable_batchedUpdates(function () {
                    if (!exteriorsData || exteriorsData.length === 0) {
                        handleSetAppError({
                            redirect: 'errorPage',
                            errorId: "no-interior-options-for-token-".concat(token),
                            errorDesc: 'Wystąpił niespodziewany błąd.'
                        });
                    }
                    setExteriors(exteriorsData);
                    setSelectedPno34(offer.vehicle.pno34PlusOption);
                    var colors = exteriorsData.find(function (exterior) { return exterior.category.toUpperCase() === 'COLOR' || exterior.category.toUpperCase() === 'KOLOR'; });
                    if (colors) {
                        var color = getSelectedOption(offer.vehicle.selectedOptions, colors.availableOptions);
                        setSelectedColor(color);
                    }
                    var wheels = exteriorsData.find(function (exterior) { return exterior.category.toUpperCase() !== 'COLOR' && exterior.category.toUpperCase() !== 'KOLOR'; });
                    if (wheels) {
                        var wheel = wheels.availableOptions.find(function (wheels) { return offer.vehicle.selectedOptions.includes(wheels.id); }) || wheels.availableOptions.find(function (wheels) { return wheels.standard; });
                        setSelectedWheels(wheel.id);
                    }
                    onSynced();
                });
            });
        }
    }, [
        canBeSynced,
        carModelId,
        offer,
        token
    ]);
    return {
        exteriors: exteriors,
        selectedColor: selectedColor,
        selectedWheels: selectedWheels,
        onSelectedColorChange: handleChangeSelectedColor,
        onSelectedWheelsChange: handleChangeSelectedWheels,
        selectedPno34: selectedPno34
    };
}
