import { TextArea } from 'components/textArea/TextArea.component';
import React from 'react';
import './additionalInfos.css';
export function CommentTextArea(_a) {
    var additionalInfosSection = _a.additionalInfosSection;
    var additionalInfo = additionalInfosSection.additionalInfo, onAdditionalInfoChange = additionalInfosSection.onAdditionalInfoChange;
    return React.createElement("div", { className: 'additionalInfosSectionWrapper' },
        React.createElement("div", { className: 'additionalInfoTextArea' },
            React.createElement(TextArea, { id: 'additionalInfoSpecialOffer', name: 'additionalInfoSpecialOffer', placeholder: 'Wprowad\u017A komentarz', onChange: onAdditionalInfoChange, value: additionalInfo, rows: 3, disableResize: true })));
}
