import { withCancellation } from 'common/requestCancellation/requestCancellation';
import React, { useEffect, useState } from 'react';
import './newOfferFromStock.css';
import { NewConfigurationSection } from './sections/NewConfiguration/NewConfigurationSection.component';
import { SearchCarSection } from './sections/SearchCar/SearchCarSection.component';
import { CarsListSection } from './sections/CarsList/CarsListSection.component';
import { useStatus } from 'common/statusContext';
import { Spinner } from 'components/spinner/Spinner';
import { useAppError } from 'common/appErrorContext';
import { ErrorPopupWithRetry } from 'pages/creator/popup/ErrorPopupWithRetry';
var mockCars = [
    {
        reservation: true,
        stockCategory: 'OWN_STOCK',
        model: 'EX30',
        modelYear: '2025',
        listPrice: 200000,
        type: 'OWN',
        dealerOrder: '7135323',
        vin: 'LYVUZK9V5PB317867',
        deliveryDate: '2024-04-14',
        lastDateForOrderChange: '2024-03-31',
        drive: 'B4 (197 + 14 KM), 8-stopniowa automatyczna skrzynia biegów, Benzyna',
        version: 'Wersja: Plus, Motyw: Bright Dust',
        upholstery: 'Tapicerka tekstylna w tonacji czerni (Charcoal)',
        interior: 'wnętrze w tonacji czerni (Charcoal) (R100)',
        dealerName: 'DOM VOLVO Warszawa Puławska',
        commonOrderNumber: '11332156',
        productionDate: '2024-01-01',
        registrationNumber: null,
        comment: 'Doposażeniu auta w akcesoria pozafabryczne',
        warnings: [
            'Minęła data zamrożenia specyfikacji.',
            'Konfiguracja zostanie przedstawiona wg najnowszego cennika dla roku modelowego 2016.'
        ],
        oldCreatorAllowed: false,
        factoryOrderDate: '2023-08-04',
        fyon: '688936871',
        fixedPlannedDate: '2024-02-10'
    },
    {
        reservation: false,
        stockCategory: 'OTHER_DEALERS_STOCK',
        model: 'V60',
        modelYear: '2024',
        listPrice: 280000,
        type: 'TRADEABLE',
        dealerOrder: '7135666',
        vin: 'LYVUZK9V5PB317666',
        deliveryDate: '2024-12-25',
        lastDateForOrderChange: '2024-11-30',
        drive: 'B4 (197 + 14 KM), 8-stopniowa automatyczna skrzynia biegów, Benzyna',
        version: 'Wersja: Plus, Motyw: Bright Dust',
        upholstery: 'Tapicerka tekstylna w tonacji czerni (Charcoal)',
        interior: 'wnętrze w tonacji czerni (Charcoal) (R100)',
        dealerName: 'DOM VOLVO Warszawa Puławska',
        commonOrderNumber: '10288662',
        productionDate: '2024-01-01',
        registrationNumber: 'ABC12312',
        comment: null,
        warnings: [
            'Minęła data zamrożenia specyfikacji.',
            'Konfiguracja zostanie przedstawiona wg najnowszego cennika dla roku modelowego 2016.',
            'Brak zapisanej konfiguracji w systemie. Utwórz ofertę samodzielnie.'
        ],
        oldCreatorAllowed: true,
        factoryOrderDate: '2022-06-24',
        fyon: '679825851',
        modelId: '225_2024',
        carOnLineDate: '2023-06-09',
        structureWeek: '202324'
    },
    {
        reservation: false,
        stockCategory: 'OWN_STOCK',
        model: 'XC40',
        modelYear: '2024',
        listPrice: 200000,
        type: 'OWN',
        dealerOrder: '7135333',
        vin: 'LYVUZK9V5PB317333',
        deliveryDate: '2024-10-10',
        lastDateForOrderChange: '2024-09-01',
        drive: 'B4 (197 + 14 KM), 8-stopniowa automatyczna skrzynia biegów, Benzyna',
        version: 'Wersja: Plus, Motyw: Bright Dust',
        upholstery: 'Tapicerka tekstylna w tonacji czerni (Charcoal)',
        interior: 'wnętrze w tonacji czerni (Charcoal) (R100)',
        dealerName: 'DOM VOLVO Warszawa Puławska',
        commonOrderNumber: '10937333',
        productionDate: '2024-01-01',
        registrationNumber: null,
        comment: 'Doposażeniu auta w akcesoria pozafabryczne',
        warnings: [],
        oldCreatorAllowed: false,
        factoryOrderDate: '2023-10-04',
        fyon: '688936111'
    },
    {
        reservation: true,
        stockCategory: 'IMPORTER_STOCK',
        model: 'EC40',
        modelYear: '2024',
        listPrice: 330000,
        type: 'OTHER',
        dealerOrder: '7135777',
        vin: 'LYVUZK9V5PB317777',
        deliveryDate: '2024-07-07',
        lastDateForOrderChange: '2024-06-01',
        drive: 'B4 (197 + 14 KM), 8-stopniowa automatyczna skrzynia biegów, Benzyna',
        version: 'Wersja: Plus, Motyw: Bright Dust',
        upholstery: 'Tapicerka tekstylna w tonacji czerni (Charcoal)',
        interior: 'wnętrze w tonacji czerni (Charcoal) (R100)',
        dealerName: 'DOM VOLVO Warszawa Puławska',
        commonOrderNumber: '10937777',
        productionDate: '2024-01-01',
        registrationNumber: null,
        comment: null,
        warnings: [],
        oldCreatorAllowed: false,
        factoryOrderDate: '2023-01-01',
        fyon: '688936000'
    },
];
export var NewOfferFromStock = withCancellation(function () {
    var _a = useStatus(), status = _a.status, setStatus = _a.setStatus;
    var _b = useAppError(), appError = _b.appError, setAppError = _b.setAppError;
    var _c = useState(), stockCars = _c[0], setStockCars = _c[1];
    function handleShowSelectedCar(car) {
        setStockCars(car);
    }
    function handleOnStockCarSearchClick(body) {
        //TODO: strzał na BE z wybranymi filtrami i sortowaniem VOLVODOL-4664
        setStockCars(mockCars);
    }
    function clearStockCars() {
        setStockCars(undefined);
    }
    function isErrorPopup() {
        return (appError === null || appError === void 0 ? void 0 : appError.redirect) === 'errorPopup';
    }
    useEffect(function () {
        return function () {
            setAppError(undefined);
        };
    }, []);
    function handleSetAppError(newAppError) {
        setAppError(newAppError);
    }
    return (React.createElement(React.Fragment, null, status === 'pending' ?
        React.createElement(Spinner, null)
        :
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'newOfferFromStockContainer' },
                    React.createElement(NewConfigurationSection, null),
                    React.createElement(SearchCarSection, { onShowSelectedCar: handleShowSelectedCar, onStockCarSearchClick: handleOnStockCarSearchClick, clearStockCars: clearStockCars, sortingAvailable: stockCars && stockCars.length > 1 }),
                    React.createElement(CarsListSection, { stockCars: stockCars, handleSetAppError: handleSetAppError })),
                isErrorPopup() ? React.createElement(ErrorPopupWithRetry, { text: appError.errorDesc }) : null)));
});
