// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .radioWithWheels {
	max-width: 760px;
	display: flex;
	gap: 10px;
}

#new-dol .wheelsIcon {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	
}

#new-dol .wheelsIconImage {
	display: block;
	width: 32px;
	height: 32px;
	object-fit: cover;
}

#new-dol .wheelsPreview {
	width: 378px;
	height: 378px;
	border: 1px solid var(--light-grey);
	box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.2);
	background-color: var(--white);
	padding: 28px;
	justify-content: center;
	align-items: center;
}

#new-dol .wheelsPreviewImage {
	display: block;
	width: 350px;
	height: 350px;
	object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/pages/demo/components/layoutRadioButtonsWithWheels/layoutRadioButtonsWithWheels.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,aAAa;CACb,SAAS;AACV;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,iBAAiB;;AAElB;;AAEA;CACC,cAAc;CACd,WAAW;CACX,YAAY;CACZ,iBAAiB;AAClB;;AAEA;CACC,YAAY;CACZ,aAAa;CACb,mCAAmC;CACnC,+CAA+C;CAC/C,8BAA8B;CAC9B,aAAa;CACb,uBAAuB;CACvB,mBAAmB;AACpB;;AAEA;CACC,cAAc;CACd,YAAY;CACZ,aAAa;CACb,iBAAiB;AAClB","sourcesContent":[".radioWithWheels {\n\tmax-width: 760px;\n\tdisplay: flex;\n\tgap: 10px;\n}\n\n.wheelsIcon {\n\twidth: 40px;\n\theight: 40px;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tmargin-left: 10px;\n\t\n}\n\n.wheelsIconImage {\n\tdisplay: block;\n\twidth: 32px;\n\theight: 32px;\n\tobject-fit: cover;\n}\n\n.wheelsPreview {\n\twidth: 378px;\n\theight: 378px;\n\tborder: 1px solid var(--light-grey);\n\tbox-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.2);\n\tbackground-color: var(--white);\n\tpadding: 28px;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.wheelsPreviewImage {\n\tdisplay: block;\n\twidth: 350px;\n\theight: 350px;\n\tobject-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
