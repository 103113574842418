import React from 'react';
export function FileInput(_a) {
    var label = _a.label, fileRef = _a.fileRef, onFileChange = _a.onFileChange, acceptFile = _a.acceptFile, value = _a.value, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    return React.createElement("button", { type: 'button', className: "clientSectionAttachmentButton ".concat(disabled ? 'clientSectionAttachmentsButtonDisabled' : ''), disabled: disabled, onClick: function () {
            if (fileRef.current) {
                fileRef.current.click();
            }
        } },
        React.createElement("input", { type: 'file', accept: acceptFile, id: 'requestAttachments', onChange: onFileChange, className: 'clientSectionAttachmentsInput', ref: fileRef, value: value, "data-testid": 'file-input' }),
        label);
}
