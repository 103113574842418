export function fundingFilter(funding) {
    if (!funding || !funding.fundingForms || !funding.fundingForms.selected) {
        return '';
    }
    function hasCfmFundingFormSelected() {
        return funding.fundingForms.selected === 'CFM_FUNDING';
    }
    function hasCfmProviderSelected() {
        return funding.cfmProviders && funding.cfmProviders.selected;
    }
    function hasLeasingFundingFormSelected() {
        return funding.fundingForms.selected === 'DETAILED_LEASING';
    }
    function hasLeasingProviderSelected() {
        return funding.leasingProviders && funding.leasingProviders.selected;
    }
    var fundingFormName = funding.fundingForms.available.find(function (option) { return option.id === funding.fundingForms.selected; }).name;
    if (hasCfmFundingFormSelected() && hasCfmProviderSelected()) {
        fundingFormName += ' - ' + funding.cfmProviders.available.find(function (option) { return option.id === funding.cfmProviders.selected; }).name;
    }
    if (hasLeasingFundingFormSelected() && hasLeasingProviderSelected()) {
        fundingFormName += ' - ' + funding.leasingProviders.available.find(function (option) { return option.id === funding.leasingProviders.selected; }).name;
    }
    return fundingFormName;
}
