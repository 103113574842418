// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .swiperWrapper {
    width: 100%; 
    height: 100%; 
    display: flex; 
    justify-content: center; 
    align-items: center;
}

#new-dol .swiperjs .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

#new-dol .swiperjs .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal{
    bottom: 14px;
}

#new-dol .swiperjs .swiper-button-next, #new-dol .swiperjs .swiper-button-prev {
    background-color: rgba(255, 255, 255, 0.5);
    color: #707070;
    width: 28px;
    height: 28px;
    border-radius: 50%;
}

#new-dol .swiperjs .swiper-button-next {
    right: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    color: #707070;
    width: 28px;
    height: 28px;
    border-radius: 50%;
}

#new-dol .swiperjs .swiper-button-prev {
    left: 20px;
}

#new-dol .swiperjs .swiper-pagination-bullet {
    background: var(--white);
    width: 5px;
    height: 5px;
    opacity: 1;
}

#new-dol .swiperjs .swiper-pagination-bullet-active {
    background: var(--blue);
    width: 7px;
    height: 7px;
    border: 1px solid var(--white);
}

#new-dol .swiperjs .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0;
}

#new-dol .swiperjs .swiper-slide {
    height: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/swiper/swiper.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,YAAY;AAChB;;AAEA;IAEI,0CAA0C;IAC1C,cAAc;IACd,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,0CAA0C;IAC1C,cAAc;IACd,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,wBAAwB;IACxB,UAAU;IACV,WAAW;IACX,UAAU;AACd;;AAEA;IACI,uBAAuB;IACvB,UAAU;IACV,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".swiperWrapper {\n    width: 100%; \n    height: 100%; \n    display: flex; \n    justify-content: center; \n    align-items: center;\n}\n\n.swiperjs .swiper-pagination {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 8px;\n}\n\n.swiperjs .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal{\n    bottom: 14px;\n}\n\n.swiperjs .swiper-button-next,\n.swiperjs .swiper-button-prev {\n    background-color: rgba(255, 255, 255, 0.5);\n    color: #707070;\n    width: 28px;\n    height: 28px;\n    border-radius: 50%;\n}\n\n.swiperjs .swiper-button-next {\n    right: 20px;\n    background-color: rgba(255, 255, 255, 0.5);\n    color: #707070;\n    width: 28px;\n    height: 28px;\n    border-radius: 50%;\n}\n\n.swiperjs .swiper-button-prev {\n    left: 20px;\n}\n\n.swiperjs .swiper-pagination-bullet {\n    background: var(--white);\n    width: 5px;\n    height: 5px;\n    opacity: 1;\n}\n\n.swiperjs .swiper-pagination-bullet-active {\n    background: var(--blue);\n    width: 7px;\n    height: 7px;\n    border: 1px solid var(--white);\n}\n\n.swiperjs .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {\n    margin: 0;\n}\n\n.swiperjs .swiper-slide {\n    height: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
