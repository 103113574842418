import React from 'react';
import './wizard.css';
var PRIMARY_STEP_ICON_SIZE = 16;
export function Wizard(_a) {
    var steps = _a.steps, stepIndex = _a.stepIndex, header = _a.header;
    var primarySteps = steps.filter(function (step) { return step.type === 'PRIMARY'; });
    function getStepIndex(value) {
        var valueStepIndex = steps.indexOf(steps.find(function (step) { return step.value === value; }));
        return valueStepIndex;
    }
    function getIndexOfCurrentPrimaryStep(currentPrimaryStep) {
        return primarySteps.indexOf(currentPrimaryStep);
    }
    var doneStatusPercentForPrimaryStep = (getIndexOfCurrentPrimaryStep(steps[stepIndex]) /
        (steps.filter(function (step) { return step.type === 'PRIMARY'; }).length - 1)) *
        100;
    var doneStatusPercentForSecondaryStep = (getIndexOfCurrentPrimaryStep(steps[stepIndex + 1]) /
        (steps.filter(function (step) { return step.type === 'PRIMARY'; }).length - 1)) *
        100;
    return (React.createElement("div", { className: "wizardWrapper" },
        React.createElement("div", { className: "wizardHeaderAndSteps" },
            header ? React.createElement("div", { className: "wizardHeader" }, header) : null,
            React.createElement("div", { className: "wizardStepsShowingWrapper" },
                React.createElement("div", { className: "primaryStepsWrapper" }, steps.map(function (step) {
                    return step.type === 'PRIMARY' ? (React.createElement("div", { className: "stepPrimary", key: step.value },
                        React.createElement("div", { className: "stepPrimaryIcon", style: {
                                backgroundColor: "".concat(stepIndex >= getStepIndex(step.value)
                                    ? 'var(--wizard-step-done)'
                                    : 'var(--wizard-step-inprogress)'),
                                width: PRIMARY_STEP_ICON_SIZE,
                                height: PRIMARY_STEP_ICON_SIZE,
                            } }),
                        step.label ? (React.createElement("div", { className: "stepPrimaryLabel" }, step.label)) : (React.createElement("div", { className: "stepLabelPlaceholder" })))) : null;
                })),
                React.createElement("div", { className: "secodaryStepsNotDoneLine" }),
                React.createElement("div", { className: "secondaryStepsDoneLine", style: {
                        width: stepIndex === 0
                            ? '0%'
                            : steps[stepIndex].type === 'PRIMARY'
                                ? "calc(\n\t\t\t\t\t\t\t\t".concat(doneStatusPercentForPrimaryStep, "%\n\t\t\t\t\t\t\t\t- ").concat((doneStatusPercentForPrimaryStep / 100) * 215, "px)")
                                : "calc(".concat(doneStatusPercentForSecondaryStep, "%\n\t\t\t\t\t\t\t\t- ").concat((doneStatusPercentForSecondaryStep / 100) * 215, "px)"),
                    } }))),
        React.createElement("div", { className: "wizardContent" }, steps.find(function (step, index) { return index === stepIndex; }).content)));
}
