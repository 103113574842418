// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .priceLists {
    padding: 24px 43px;
}

#new-dol .priceListsActions {
    display: flex;
    justify-content: space-between;
}

#new-dol .priceListsModifyButton {
    width: 280px;
}

#new-dol .priceListsFilterWrapper {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
}

#new-dol .priceListsFilterDropdown {
    width: 180px;
}

#new-dol .priceListMessage {
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--validate-color);
    padding-top: 18px;
    padding-bottom: 18px;
}`, "",{"version":3,"sources":["webpack://./src/pages/priceListImport/PriceListTab/priceLists.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,+BAA+B;IAC/B,eAAe;IACf,4BAA4B;IAC5B,iBAAiB;IACjB,oBAAoB;AACxB","sourcesContent":[".priceLists {\n    padding: 24px 43px;\n}\n\n.priceListsActions {\n    display: flex;\n    justify-content: space-between;\n}\n\n.priceListsModifyButton {\n    width: 280px;\n}\n\n.priceListsFilterWrapper {\n    display: flex;\n    gap: 8px;\n    justify-content: center;\n    align-items: center;\n}\n\n.priceListsFilterDropdown {\n    width: 180px;\n}\n\n.priceListMessage {\n    font-family: var(--font-family);\n    font-size: 14px;\n    color: var(--validate-color);\n    padding-top: 18px;\n    padding-bottom: 18px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
