import React from 'react';
import { Button } from 'components/button/Button.component';
import '../newOfferSections.css';
import { useHistory } from 'react-router-dom';
export function NewConfigurationSection() {
    var history = useHistory();
    function onNewConfigurationClick() {
        var _a = window.location.href.split('?'), searchParamsString = _a[1];
        var searchParams = new URLSearchParams(searchParamsString);
        var context = searchParams.get('context');
        var clientId = searchParams.get('clientId');
        var partyId = searchParams.get('partyId');
        history.push({
            pathname: '/stock-start-offer',
            state: { context: context, clientId: clientId, partyId: partyId }
        });
    }
    ;
    return (React.createElement("div", { className: 'newConfigurationSection' },
        React.createElement("div", { className: 'newConfigurationButtonWrapper' },
            React.createElement(Button, { onClick: onNewConfigurationClick }, "utw\u00F3rz now\u0105 konfiguracj\u0119"))));
}
