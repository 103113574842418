// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .settlementSectionWrapper {
    padding: 32px 0;
    font-family: var(--font-family);
    color: var(--black-text-color);
    margin: 8px 0;
}

#new-dol .settlementSectionTable {
    width: 100%;
}

#new-dol .settlementSectionHeadCell {
    font-weight: 500;
    padding: 6px 8px;
}

#new-dol .settlementSectionTableRow:nth-child(odd) {
    background-color: var(--background-very-light-grey);
}

#new-dol .settlementSectionBodyCell {
    padding: 14px 8px;
}

#new-dol .settlementOrderDateCell {
    padding-left: 16px;
    width: 9%;
}

#new-dol .settlementDOVINCell {
    width: 10%;
}

#new-dol .settlementCarCell {
    width: 15%;
}

#new-dol .settlementSpecialOfferCell {
    width: 10%;
}

#new-dol .settlementCatalogPriceCell {
    width: 10%;
}

#new-dol .settlementCatalogPriceFromInvoiceCell{ 
    width: 10%;
}

#new-dol .settlementSpecialOfferHeader {
    border-bottom: 2px solid var(--line-light-grey);
}

#new-dol .settlementClientDiscountNet {
    width: 10%;
}

#new-dol .settlementClientDiscountPercent {
    width: 5%;
}

#new-dol .settlementAmountNet {
    width: 10%;
}

#new-dol .settlementFunding {
    width: 11%;
}

#new-dol .settlementStatus {
    padding-right: 16px;
    width: 10%;
}

#new-dol .clickableSettlementRow {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/specialOffer/settlementSection/settlementSection.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,+BAA+B;IAC/B,8BAA8B;IAC9B,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,mDAAmD;AACvD;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,+CAA+C;AACnD;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".settlementSectionWrapper {\n    padding: 32px 0;\n    font-family: var(--font-family);\n    color: var(--black-text-color);\n    margin: 8px 0;\n}\n\n.settlementSectionTable {\n    width: 100%;\n}\n\n.settlementSectionHeadCell {\n    font-weight: 500;\n    padding: 6px 8px;\n}\n\n.settlementSectionTableRow:nth-child(odd) {\n    background-color: var(--background-very-light-grey);\n}\n\n.settlementSectionBodyCell {\n    padding: 14px 8px;\n}\n\n.settlementOrderDateCell {\n    padding-left: 16px;\n    width: 9%;\n}\n\n.settlementDOVINCell {\n    width: 10%;\n}\n\n.settlementCarCell {\n    width: 15%;\n}\n\n.settlementSpecialOfferCell {\n    width: 10%;\n}\n\n.settlementCatalogPriceCell {\n    width: 10%;\n}\n\n.settlementCatalogPriceFromInvoiceCell{ \n    width: 10%;\n}\n\n.settlementSpecialOfferHeader {\n    border-bottom: 2px solid var(--line-light-grey);\n}\n\n.settlementClientDiscountNet {\n    width: 10%;\n}\n\n.settlementClientDiscountPercent {\n    width: 5%;\n}\n\n.settlementAmountNet {\n    width: 10%;\n}\n\n.settlementFunding {\n    width: 11%;\n}\n\n.settlementStatus {\n    padding-right: 16px;\n    width: 10%;\n}\n\n.clickableSettlementRow {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
