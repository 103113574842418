import { useAppError } from 'common/appErrorContext';
import { Button } from 'components/button/Button.component';
import { Dialog } from 'components/dialog/Dialog.component';
import React, { useState } from 'react';
export function ErrorPopupWithRetry(_a) {
    var text = _a.text;
    var _b = useState(true), open = _b[0], setOpen = _b[1];
    var _c = useAppError(), appError = _c.appError, setAppError = _c.setAppError;
    function handleClose() {
        setOpen(false);
        setAppError(null);
    }
    function onRetry() {
        setOpen(false);
        setAppError(null);
        if (appError.retryHandler) {
            appError.retryHandler();
        }
    }
    return (React.createElement(Dialog.RootOpen, { open: open },
        React.createElement(Dialog.PopupContent, null,
            React.createElement(Dialog.PopupContentLayout, { withRetryLayout: true },
                React.createElement(Dialog.PopupTextWrapper, { styledText: true }, text),
                React.createElement(Dialog.PopupButtonWrapper, { variant: 'multiple-centered' },
                    appError.retryHandler ?
                        React.createElement("div", { style: { width: 180 } },
                            React.createElement(Button, { onClick: onRetry }, "pon\u00F3w pr\u00F3b\u0119"))
                        : null,
                    React.createElement("div", { style: { width: 180 } },
                        React.createElement(Button, { variant: 'secondary', onClick: function () { return handleClose(); } }, "zamknij")))))));
}
;
