import React, { createContext, useContext, useState } from 'react';
var AppErrorContext = createContext({
    appError: undefined,
    setAppError: null
});
export function AppErrorProvider(_a) {
    var children = _a.children, initialError = _a.initialError;
    var _b = useState(initialError), appError = _b[0], setAppError = _b[1];
    var value = { appError: appError, setAppError: setAppError };
    return React.createElement(AppErrorContext.Provider, { value: value }, children);
}
export function useAppError() {
    var context = useContext(AppErrorContext);
    if (!context) {
        throw new Error('useAppError must be used within AppErrorProvider');
    }
    return context;
}
