import React from 'react';
import * as Slider from '@radix-ui/react-slider';
import './slider.css';
var SliderComponent = function (_a) {
    var printedValue = _a.printedValue, onValueChange = _a.onValueChange, onValueCommit = _a.onValueCommit, value = _a.value, maxValue = _a.maxValue, minValue = _a.minValue, step = _a.step;
    return (React.createElement(Slider.Root, { className: "SliderRoot", onValueChange: onValueChange, onValueCommit: onValueCommit, value: value, max: maxValue, min: minValue, step: step, "aria-label": "Volume" },
        React.createElement(Slider.Track, { className: "SliderTrack" },
            React.createElement(Slider.Range, { className: 'SliderRange' })),
        React.createElement(Slider.Thumb, { className: 'SliderThumb' },
            React.createElement("span", { className: 'slider-printed-value' }, printedValue))));
};
export default SliderComponent;
