import React from 'react';
import './amountInput.css';
import { NumericFormat } from 'react-number-format';
import { useIsFirstRender } from '../../hooks/useIsFirstRender';
export function AmountInput(_a) {
    var name = _a.name, value = _a.value, onValueChange = _a.onValueChange, id = _a.id, max = _a.max, _b = _a.validate, validate = _b === void 0 ? false : _b, onBlur = _a.onBlur, _c = _a.disabled, disabled = _c === void 0 ? false : _c, defaultValue = _a.defaultValue;
    var isFirstRender = useIsFirstRender();
    function isAllowed(values) {
        if (max === undefined)
            return true;
        if (values.floatValue <= max || values.value === '') {
            return true;
        }
        return false;
    }
    var stringValue = value ? value.toString() : defaultValue ? defaultValue.toString() : 0;
    return React.createElement("div", { className: "amountInputContainer ".concat(validate ? 'validate' : '') },
        React.createElement(NumericFormat, { isAllowed: isAllowed, id: id, onValueChange: onValueChange, name: name, value: stringValue === '0' && isFirstRender.current ? '0.00' : stringValue, valueIsNumericString: true, allowNegative: false, decimalScale: 2, decimalSeparator: ',', thousandSeparator: ' ', onBlur: function () {
                if (value === '0' || value === '') {
                    onValueChange({
                        value: '0.00',
                        floatValue: 0,
                        formattedValue: '0,00'
                    });
                }
                onBlur === null || onBlur === void 0 ? void 0 : onBlur();
            }, disabled: disabled, defaultValue: defaultValue }),
        React.createElement("span", { className: 'amountCurrencySymbol' }, "PLN"));
}
