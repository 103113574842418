// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .layoutCommercialOffers {
    display: grid;
    width: 100%;
    height: 68px;
    grid-template-columns: 720px repeat(2, 160px) 144px;
    gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
    align-items: center;
}

#new-dol .layoutCommercialOffers > div {
    height: 52px;
}`, "",{"version":3,"sources":["webpack://./src/pages/demo/components/layoutCommercialOffers/layoutCommercialOffers.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,mDAAmD;IACnD,SAAS;IACT,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".layoutCommercialOffers {\n    display: grid;\n    width: 100%;\n    height: 68px;\n    grid-template-columns: 720px repeat(2, 160px) 144px;\n    gap: 16px;\n    padding-left: 16px;\n    padding-right: 16px;\n    align-items: center;\n}\n\n.layoutCommercialOffers > div {\n    height: 52px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
