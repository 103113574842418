import React from 'react';
import './listItemElement.css';
function OptionCode(_a) {
    var _b = _a.gridColumnStart, gridColumnStart = _b === void 0 ? 2 : _b, children = _a.children;
    return React.createElement("div", { className: 'listItemOptionCodeElement', "data-testid": 'option-code', style: { gridColumnStart: gridColumnStart } }, children);
}
function OptionCodePlaceholder(_a) {
    var _b = _a.gridColumnStart, gridColumnStart = _b === void 0 ? 2 : _b;
    return React.createElement("div", { className: 'listItemOptionCodeElement', style: { gridColumnStart: gridColumnStart } });
}
function Chips(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'listItemChipsElement' }, children);
}
function Price(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'listItemPriceElement' }, children);
}
function CatalogPrice(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'listItemCatalogPrice' }, children);
}
export var ListItemElement = {
    OptionCode: OptionCode,
    OptionCodePlaceholder: OptionCodePlaceholder,
    Chips: Chips,
    Price: Price,
    CatalogPrice: CatalogPrice
};
