import { useStatus } from 'common/statusContext';
import { Button } from 'components/button/Button.component';
import { Checkbox } from 'components/checkbox/Checkbox.component';
import { Label } from 'components/label/Label.component';
import { Spinner } from 'components/spinner/Spinner';
import { TextArea } from 'components/textArea/TextArea.component';
import React from 'react';
export function FinancialProductExclusionsTab(_a) {
    var selectedAllModels = _a.selectedAllModels, onAllItemsChecked = _a.onAllItemsChecked, models = _a.models, selectedModels = _a.selectedModels, onModelCheckedChange = _a.onModelCheckedChange, selectedAllPowerTrains = _a.selectedAllPowerTrains, powerTrains = _a.powerTrains, selectedPowerTrains = _a.selectedPowerTrains, onPowerTrainCheckedChange = _a.onPowerTrainCheckedChange, onPnoCodesChanges = _a.onPnoCodesChanges, pnoCodes = _a.pnoCodes, onCancelClick = _a.onCancelClick, hasAnyChange = _a.hasAnyChange, onSaveClick = _a.onSaveClick;
    var status = useStatus().status;
    return (React.createElement("div", null,
        React.createElement("div", { className: "financialProductFiltersWrapper", "data-testid": "excluded-car-models" },
            React.createElement("div", { className: "financialProductCategoryHeader" }, "Wykluczone modele samochod\u00F3w"),
            React.createElement("div", { className: "financialProductCheckbox financialProductAllModelsCheckbox" },
                React.createElement(Checkbox.Root, { value: "allModels", checked: selectedAllModels, id: "allModels", onCheckedChange: function (checked) { return onAllItemsChecked(checked, 'MODELS'); } },
                    React.createElement(Checkbox.Indicator, null)),
                React.createElement(Label, { htmlFor: "allModels", cursor: "pointer" }, "Zaznacz wszystkie")),
            React.createElement("div", { className: "financialProductModelsCheckboxes" }, models
                ? models.map(function (model, index) { return (React.createElement("div", { className: "financialProductCheckbox", key: index },
                    React.createElement(Checkbox.Root, { id: model.pno, value: model.pno, checked: selectedModels.includes(model.pno), onCheckedChange: function (checked) { return onModelCheckedChange(checked, model.pno); } },
                        React.createElement(Checkbox.Indicator, null)),
                    React.createElement(Label, { htmlFor: model.pno, cursor: "pointer" },
                        model.model,
                        " (",
                        model.pno,
                        ")"))); })
                : null)),
        React.createElement("div", { className: "financialProductFiltersWrapper", "data-testid": "excluded-power-trains" },
            React.createElement("div", { className: "financialProductCategoryHeader" }, "Wykluczone nap\u0119dy"),
            React.createElement("div", { className: "financialProductCheckbox financialProductAllPowerTrainsCheckbox" },
                React.createElement(Checkbox.Root, { value: "allPowerTrains", checked: selectedAllPowerTrains, id: "allPowerTrains", onCheckedChange: function (checked) { return onAllItemsChecked(checked, 'POWER_TRAINS'); } },
                    React.createElement(Checkbox.Indicator, null)),
                React.createElement(Label, { htmlFor: "allPowerTrains", cursor: "pointer" }, "Zaznacz wszystkie")),
            React.createElement("div", { className: "financialProductPowerTrainssCheckboxes" }, powerTrains
                ? powerTrains.map(function (powerTrain, index) { return (React.createElement("div", { className: "financialProductCheckbox", key: index },
                    React.createElement(Checkbox.Root, { id: powerTrain, value: powerTrain, checked: selectedPowerTrains.includes(powerTrain), onCheckedChange: function (checked) { return onPowerTrainCheckedChange(checked, powerTrain); } },
                        React.createElement(Checkbox.Indicator, null)),
                    React.createElement(Label, { htmlFor: powerTrain, cursor: "pointer" }, powerTrain))); })
                : null)),
        React.createElement("div", { className: "financialProductFiltersWrapper", "data-testid": "excluded-pno12s" },
            React.createElement("div", { className: "financialProductCategoryHeader" }, "Wykluczone kody PNO12"),
            React.createElement("div", { className: "financialProductPNOsTextArea" },
                React.createElement(TextArea, { id: "PNOCodes", name: "PNOCodes", onChange: onPnoCodesChanges, placeholder: "Podaj kody", value: pnoCodes, disableResize: true }))),
        React.createElement("div", { className: "financialProductActionButtonsWrapper" },
            React.createElement("div", { className: "financialProductActionButton" },
                React.createElement(Button, { variant: "secondary", onClick: onCancelClick }, "Anuluj")),
            React.createElement("div", { className: "financialProductActionButton" },
                React.createElement(Button, { disabled: !hasAnyChange, onClick: onSaveClick }, "Zapisz"))),
        status === 'pending' ? React.createElement(Spinner, null) : null));
}
