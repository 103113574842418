import React from 'react';
import { CarDamageOCIssueInputSearch } from './CarDamageOCIssueInputSearch.component';
import './carDamageOCIssues.css';
export function CarDamageOCIssuesHeader(_a) {
    var searchPhrase = _a.searchPhrase, applyPhraseFilter = _a.applyPhraseFilter;
    return (React.createElement("div", { className: "carDamageOCIssuesHeader" },
        React.createElement("div", { className: "headerTitle" }, "Zg\u0142oszenia szk\u00F3d"),
        React.createElement("div", { "data-testid": "input-search" },
            React.createElement(CarDamageOCIssueInputSearch, { phrase: searchPhrase, onApplyPhraseFilter: applyPhraseFilter }))));
}
