// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .percentInputContainer{
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    padding: 6px 16px;
    background-color: #FFFFFF;
}
#new-dol .percentInputContainer input {
    border: none;
    text-align: end;
    box-sizing: border-box;
    width: 100%;
}

#new-dol .percentInputContainer input::placeholder {
    color: var(--main-text-color);
}

#new-dol .percentInputContainer input:focus {
    outline: none;
}

#new-dol .percentSymbol {
    margin-left: 2px;
}

#new-dol .percentInputPlaceholder {
    width: 100%;
    height: 32px;
}`, "",{"version":3,"sources":["webpack://./src/components/percentInput/percentInput.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,+BAA+B;IAC/B,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,yBAAyB;AAC7B;AACA;IACI,YAAY;IACZ,eAAe;IACf,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".percentInputContainer{\n    width: 100%;\n    height: 32px;\n    display: flex;\n    align-items: center;\n    font-family: var(--font-family);\n    font-size: 14px;\n    line-height: 20px;\n    font-weight: 400;\n    border: 1px solid #D5D5D5;\n    border-radius: 4px;\n    padding: 6px 16px;\n    background-color: #FFFFFF;\n}\n.percentInputContainer input {\n    border: none;\n    text-align: end;\n    box-sizing: border-box;\n    width: 100%;\n}\n\n.percentInputContainer input::placeholder {\n    color: var(--main-text-color);\n}\n\n.percentInputContainer input:focus {\n    outline: none;\n}\n\n.percentSymbol {\n    margin-left: 2px;\n}\n\n.percentInputPlaceholder {\n    width: 100%;\n    height: 32px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
