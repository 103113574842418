export function uppercasedFirstLetter(text) {
    var textArray = text.split(' ');
    var newTextArray = textArray.map(function (textElement, index) {
        if (index === 0) {
            return textElement[0].toUpperCase() + textElement.slice(1);
        }
        else {
            return textElement;
        }
    });
    return newTextArray.join(' ');
}
