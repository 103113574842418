var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Checkbox } from 'components/checkbox/Checkbox.component';
import { Label } from 'components/label/Label.component';
import React, { useEffect, useState } from 'react';
import { FinancialProductsSearch } from './FinancialProductsSearch.component';
import './financialProducts.css';
import { useStatus } from 'common/statusContext';
import { PaginatedFinancialProducts } from './PaginatedFinancialProducts.component';
import ReactDOM from 'react-dom';
import { useFinancialProductsApi } from './useFinancialProductsApi';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { Spinner } from 'components/spinner/Spinner';
export var FinancialProducts = withCancellation(function () {
    var _a = useState(undefined), financialProducts = _a[0], setFinancialProducts = _a[1];
    var _b = useState(false), hideDisabledProducts = _b[0], setHideDisabledProducts = _b[1];
    var _c = useState(''), searchPhrase = _c[0], setSearchPhrase = _c[1];
    var _d = useState(0), currentPage = _d[0], setCurrentPage = _d[1];
    var _e = useState(0), totalPages = _e[0], setTotalPages = _e[1];
    var _f = useStatus(), status = _f.status, setStatus = _f.setStatus;
    var financialProductsApi = useFinancialProductsApi();
    var isActive = hideDisabledProducts ? 'TRUE' : 'TRUE,FALSE';
    useEffect(function () {
        function getFinancialProductsList() {
            return __awaiter(this, void 0, void 0, function () {
                var financialProductsListResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, financialProductsApi.getFinancialProductsList(0, isActive)];
                        case 1:
                            financialProductsListResponse = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                setFinancialProducts(financialProductsListResponse.content);
                                setTotalPages(financialProductsListResponse.totalPages);
                                setStatus('success');
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        getFinancialProductsList();
    }, []);
    function changePageHandler(selectedPage) {
        return __awaiter(this, void 0, void 0, function () {
            var financialProductsListResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, financialProductsApi.getFinancialProductsList(selectedPage, isActive, searchPhrase ? searchPhrase : null)];
                    case 1:
                        financialProductsListResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setFinancialProducts(financialProductsListResponse.content);
                            setCurrentPage(selectedPage);
                            setStatus('success');
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function onHideDisabledProductsChange(checked) {
        return __awaiter(this, void 0, void 0, function () {
            function setStates(financialProductsListResponse) {
                setFinancialProducts(financialProductsListResponse.content);
                setCurrentPage(0);
                setTotalPages(financialProductsListResponse.totalPages);
                setStatus('success');
            }
            var financialProductsListResponse_1, financialProductsListResponse_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!checked) return [3 /*break*/, 2];
                        setStatus('pending');
                        return [4 /*yield*/, financialProductsApi.getFinancialProductsList(0, 'TRUE', searchPhrase ? searchPhrase : null)];
                    case 1:
                        financialProductsListResponse_1 = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setHideDisabledProducts(true);
                            setStates(financialProductsListResponse_1);
                        });
                        _a.label = 2;
                    case 2:
                        if (!!checked) return [3 /*break*/, 4];
                        setStatus('pending');
                        return [4 /*yield*/, financialProductsApi.getFinancialProductsList(0, 'TRUE,FALSE', searchPhrase ? searchPhrase : null)];
                    case 3:
                        financialProductsListResponse_2 = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setHideDisabledProducts(false);
                            setStates(financialProductsListResponse_2);
                        });
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function applyPhraseFilter(phrase) {
        return __awaiter(this, void 0, void 0, function () {
            function setStates(financialProductsListResponse) {
                ReactDOM.unstable_batchedUpdates(function () {
                    setFinancialProducts(financialProductsListResponse.content);
                    setCurrentPage(0);
                    setTotalPages(financialProductsListResponse.totalPages);
                    setStatus('success');
                });
            }
            var financialProductsListResponse, financialProductsListResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setSearchPhrase(phrase);
                        if (!(phrase.length >= 3)) return [3 /*break*/, 2];
                        setStatus('pending');
                        return [4 /*yield*/, financialProductsApi.getFinancialProductsList(0, isActive, phrase)];
                    case 1:
                        financialProductsListResponse = _a.sent();
                        setStates(financialProductsListResponse);
                        return [3 /*break*/, 4];
                    case 2:
                        if (!(phrase === '')) return [3 /*break*/, 4];
                        setStatus('pending');
                        return [4 /*yield*/, financialProductsApi.getFinancialProductsList(0, isActive)];
                    case 3:
                        financialProductsListResponse = _a.sent();
                        setStates(financialProductsListResponse);
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function setDefaultStates() {
        return __awaiter(this, void 0, void 0, function () {
            var financialProductsListResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, financialProductsApi.getFinancialProductsList(currentPage, isActive, searchPhrase ? searchPhrase : null)];
                    case 1:
                        financialProductsListResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setFinancialProducts(financialProductsListResponse.content);
                            setStatus('success');
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function onProductActivitySwitchChange(checked, productId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, financialProductsApi.activate(productId, checked ? 'activate' : 'deactivate')];
                    case 1:
                        _a.sent();
                        setDefaultStates();
                        return [2 /*return*/];
                }
            });
        });
    }
    function onDefaultCalculateSwitchChange(checked, productId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, financialProductsApi.default(productId, checked ? 'default' : 'notDefault')];
                    case 1:
                        _a.sent();
                        setDefaultStates();
                        return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement("div", { className: "financialProductsContainer" },
        React.createElement("div", { className: "financialProductsHeaderWrapepr" },
            React.createElement("h2", { className: "financialProductsHeader" }, "Produkty finansowe"),
            React.createElement("div", { className: "hideFinancialProductWrapper" },
                React.createElement(Checkbox.Root, { checked: hideDisabledProducts, value: "hide", id: "hideDisabledProductCheckbox", onCheckedChange: onHideDisabledProductsChange },
                    React.createElement(Checkbox.Indicator, null)),
                React.createElement(Label, { htmlFor: "hideDisabledProductCheckbox", cursor: "pointer" }, "Ukryj produkty wy\u0142\u0105czone")),
            React.createElement("div", null,
                React.createElement(FinancialProductsSearch, { phrase: searchPhrase, onApplyPhraseFilter: applyPhraseFilter }))),
        React.createElement("div", { className: "financialProductsListWrapper" },
            React.createElement("div", { className: "financialProductsListHeaders" },
                React.createElement("div", null, "Stan"),
                React.createElement("div", { className: 'financialProductsListHeaderCenter' }, "Domy\u015Blny"),
                React.createElement("div", null, "Nazwa produktu")),
            React.createElement(PaginatedFinancialProducts, { products: financialProducts, currentPage: currentPage, totalPages: totalPages, onProductActivitySwitchChange: onProductActivitySwitchChange, changePageHandler: changePageHandler, onDefaultCalculateSwitchChange: onDefaultCalculateSwitchChange })),
        status === 'pending' ? React.createElement(Spinner, null) : null));
});
