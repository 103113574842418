import { useStatus } from 'common/statusContext';
import { Button } from 'components/button/Button.component';
import { Spinner } from 'components/spinner/Spinner';
import { TextArea } from 'components/textArea/TextArea.component';
import React from 'react';
import '../financialProducts.css';
export function FinancialProductDisclaimerTab(_a) {
    var defaultDisclaimer = _a.defaultDisclaimer, onDefaultDisclaimerChanges = _a.onDefaultDisclaimerChanges, dedicatedDisclaimer = _a.dedicatedDisclaimer, onDedicatedDisclaimerChanges = _a.onDedicatedDisclaimerChanges, onCancelClick = _a.onCancelClick, hasAnyChange = _a.hasAnyChange, onSaveClick = _a.onSaveClick;
    var status = useStatus().status;
    return (React.createElement("div", null,
        React.createElement("div", { className: "financialProductFiltersWrapper", "data-testid": "defaultDisclaimer" },
            React.createElement("div", { className: "financialProductCategoryHeader" }, "Disclaimer - kalkulacja domy\u015Blna"),
            React.createElement("div", { className: "financialProductDisclaimerTextArea" },
                React.createElement(TextArea, { id: "defaultDisclaimer", name: "defaultDisclaimer", onChange: onDefaultDisclaimerChanges, placeholder: "Wprowad\u017A tre\u015B\u0107", value: defaultDisclaimer, disableResize: true }))),
        React.createElement("div", { className: "financialProductFiltersWrapper", "data-testid": "dedicatedDisclaimer" },
            React.createElement("div", { className: "financialProductCategoryHeader" }, "Disclaimer - kalkulacja dedykowana"),
            React.createElement("div", { className: "financialProductDisclaimerTextArea" },
                React.createElement(TextArea, { id: "dedicatedDisclaimer", name: "dedicatedDisclaimer", onChange: onDedicatedDisclaimerChanges, placeholder: "Wprowad\u017A tre\u015B\u0107", value: dedicatedDisclaimer, disableResize: true }))),
        React.createElement("div", { className: "financialProductActionButtonsWrapper" },
            React.createElement("div", { className: "financialProductActionButton" },
                React.createElement(Button, { variant: "secondary", onClick: onCancelClick }, "Anuluj")),
            React.createElement("div", { className: "financialProductActionButton" },
                React.createElement(Button, { disabled: !hasAnyChange, onClick: onSaveClick }, "Zapisz"))),
        status === 'pending' ? React.createElement(Spinner, null) : null));
}
