// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .horizontalLine {
    width: 100%;
    height: 1px;
}`, "",{"version":3,"sources":["webpack://./src/pages/specialOffer/horizontalLine/horizontalLine.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;AACf","sourcesContent":[".horizontalLine {\n    width: 100%;\n    height: 1px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
