import React from 'react';
import './creatorHeader.css';
import { useFormattedCurrency } from 'utils/formatCurrency';
import { ConfigurationCode } from 'components/configurationCode/ConfigurationCode.component';
export function CreatorHeader(_a) {
    var model = _a.model, modelYear = _a.modelYear, priceList = _a.priceList, grossPrice = _a.grossPrice, netPrice = _a.netPrice, isTokenConfigurationVisible = _a.isTokenConfigurationVisible, tokenConfiguration = _a.tokenConfiguration;
    var formattedGrossPrice = useFormattedCurrency(grossPrice);
    var formattedNetPrice = useFormattedCurrency(netPrice);
    return (React.createElement("div", { className: "headerComponent" },
        React.createElement("div", { className: "configurationCodeContainer" },
            React.createElement("div", { className: "emptyElement", style: { height: '28px' } }),
            isTokenConfigurationVisible ? (React.createElement(ConfigurationCode, { configurationCode: tokenConfiguration })) : (React.createElement("div", { className: "configurationCodePlaceholder" }))),
        React.createElement("div", { className: "modelAndPriceListContainer" },
            model ?
                React.createElement("div", { className: "model" },
                    "Volvo ",
                    model.toUpperCase(),
                    React.createElement("span", { className: "modelYear" },
                        " (",
                        modelYear,
                        ")"))
                : null,
            React.createElement("div", { className: "priceList" },
                "Cennik: ",
                priceList)),
        React.createElement("div", { className: "pricesContainer" },
            React.createElement("div", { className: "grossPrice" }, grossPrice ? React.createElement("span", null, formattedGrossPrice) : null),
            React.createElement("div", { className: "netPrice" }, netPrice ? React.createElement("span", null,
                formattedNetPrice,
                " netto") : null))));
}
