import React from 'react';
import { Label } from 'components/label/Label.component';
import { List } from 'components/list/List.component';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
export function PricesList(_a) {
    var selectedPriceList = _a.selectedPriceList, onSelectedPriceListChange = _a.onSelectedPriceListChange, priceLists = _a.priceLists;
    return (React.createElement("div", { className: "priceListWrapper" }, priceLists ? (React.createElement(RadioGroup.Root, { value: selectedPriceList, onValueChange: onSelectedPriceListChange },
        React.createElement(List.Root, null, priceLists.map(function (priceList) { return (React.createElement(List.Item, { key: priceList.priceListName, isSelected: selectedPriceList === priceList.priceListName, onClick: function (event) {
                if (['INPUT', 'SPAN'].includes(event.target.tagName)) {
                    return;
                }
                onSelectedPriceListChange(priceList.priceListName);
            } },
            React.createElement(RadioGroup.Layout, null,
                React.createElement(RadioGroup.Item, { value: priceList.priceListName, id: priceList.priceListName },
                    React.createElement(RadioGroup.Indicator, null)),
                React.createElement(Label, { htmlFor: priceList.priceListName }, priceList.priceListName)))); })))) : null));
}
