// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .chevronDropdown path{
    stroke: var(--text-grey);
}

#new-dol .chevronMultiDropdown path{
	stroke: '#6D6D6D';
}

#new-dol .chevronDisabled path{
    stroke: var(--text-disabled-input);
}

#new-dol .multiDropdownValueContainerRemoveButton {
    all: unset;
    cursor: pointer;
    width: 10px;
    height: 10px;
}

#new-dol .multiDropdownSeparator {
    width: 1px;
    border-left: 1px solid #D9D9D9;
    height: 20px;
}

#new-dol .indicatorsContainerWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    align-self: flex-start;
    height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/dropdown/dropdown.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;CACC,iBAAiB;AAClB;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,UAAU;IACV,eAAe;IACf,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;AAChB","sourcesContent":[".chevronDropdown path{\n    stroke: var(--text-grey);\n}\n\n.chevronMultiDropdown path{\n\tstroke: '#6D6D6D';\n}\n\n.chevronDisabled path{\n    stroke: var(--text-disabled-input);\n}\n\n.multiDropdownValueContainerRemoveButton {\n    all: unset;\n    cursor: pointer;\n    width: 10px;\n    height: 10px;\n}\n\n.multiDropdownSeparator {\n    width: 1px;\n    border-left: 1px solid #D9D9D9;\n    height: 20px;\n}\n\n.indicatorsContainerWrapper {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n    align-self: flex-start;\n    height: 24px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
