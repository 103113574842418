import React, { useEffect, useRef } from 'react';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { List } from 'components/list/List.component';
import { FileInput } from 'components/fileInput/FileInput';
import { GarbageIcon } from '../GarbageIcon';
import { formatDate } from 'utils/formatDate';
import ModelDictionary from 'common/modelDictionary';
export var EditClientSection = withCancellation(function (_a) {
    var clientSection = _a.clientSection, editClientSection = _a.editClientSection, canAddAttachment = _a.canAddAttachment;
    var party = clientSection.party, type = clientSection.type, capitalGroup = clientSection.capitalGroup, requestDate = clientSection.requestDate, partyCars = clientSection.partyCars, requestAttachments = clientSection.requestAttachments, policyAttachments = clientSection.policyAttachments;
    var newRequestAttachments = editClientSection.newRequestAttachments, handleAddNewRequestAttachment = editClientSection.handleAddNewRequestAttachment, handleDeleteNewRequestAttachment = editClientSection.handleDeleteNewRequestAttachment, newPolicyAttachments = editClientSection.newPolicyAttachments, handleAddNewPolicAttachment = editClientSection.handleAddNewPolicAttachment, handleDeleteNewPolicyAttachment = editClientSection.handleDeleteNewPolicyAttachment, handleFileNameClick = editClientSection.handleFileNameClick, handleDeleteAllNewAttachment = editClientSection.handleDeleteAllNewAttachment;
    var inputRequestFileRef = useRef(null);
    var inputPolicyFileRef = useRef(null);
    function shouldGUSOrKRSDataShow() {
        return party && party.type !== 'INDIVIDUAL';
    }
    var cars = partyCars
        ? partyCars.map(function (car) {
            var modelCode = car.modelId.split('_')[0];
            return {
                // chwilowo nie pokazujemy daty zamówienia
                // orderDate: car.handoverDate,
                // FIXME: https://altkomsoftware.atlassian.net/jira/software/c/projects/VOLVODOL/boards/125/backlog?selectedIssue=VOLVODOL-3255
                handoverDate: car.handoverDate,
                vin: car.vin,
                carModel: ModelDictionary.nameFor(modelCode),
                modelYear: car.modelId.split('_')[1],
            };
        })
        : [];
    useEffect(function () {
        if (!canAddAttachment) {
            handleDeleteAllNewAttachment('REQUEST_ATTACHMENTS');
            handleDeleteAllNewAttachment('POLICY_ATTACHMENTS');
        }
    }, [canAddAttachment]);
    return (React.createElement("div", { className: "clientSectionWrapper" },
        React.createElement("div", { className: "clientSectionGridContainer" },
            React.createElement("div", { className: "clientSectionLeftContainer" },
                React.createElement("div", { className: "clientSectionParty" },
                    React.createElement("div", { className: "clientSectionLabel" }, "Strona klienta:"),
                    party ? (React.createElement("div", { className: "clientSectionValue", "data-testid": "client-section-party-value" },
                        party.dolName,
                        " ",
                        party.identificationNumber.type,
                        ": ",
                        party.identificationNumber.value)) : null,
                    React.createElement("div", null,
                        React.createElement("div", { className: "clientSectionPrivatePurchaseValueWrapper" },
                            React.createElement("div", { className: "clientSectionLabel", "data-testid": "client-section-private-purchase" }, "Typ oferty:"),
                            React.createElement("div", { className: "clientSectionValue", "data-testid": "client-section-type" },
                                React.createElement("span", null, type && type.label ? type.label : null),
                                React.createElement("span", null, type && type.subType && type.subType.label ? " - ".concat(type.subType.label) : null))))),
                shouldGUSOrKRSDataShow() ?
                    React.createElement("div", { className: "clientSectionBusinessType" },
                        React.createElement("div", { className: "clientSectionLabel" }, "Nazwa strony:"),
                        React.createElement("div", { className: "clientSectionValue clientSectionBusinessTypeValue", "data-testid": "client-section-business-name" }, party && party.name ? party.name : 'brak danych'),
                        React.createElement("div", { className: "clientSectionLabel" }, "Charakter dzia\u0142alno\u015Bci strony:"),
                        React.createElement("div", { className: "clientSectionValue clientSectionBusinessTypeValue", "data-testid": "client-section-business-type" }, party && party.dominantPKDCode ? party.dominantPKDCode : 'brak danych'))
                    : null,
                React.createElement("div", { className: "clientSectionCompanyName", style: { paddingBottom: 0 } },
                    React.createElement("div", { className: "clientSectionLabel" }, "Nazwa grupy kapita\u0142owej:"),
                    React.createElement("div", { className: "clientSectionValue", "data-testid": "client-section-group-name" }, capitalGroup && capitalGroup.name ? capitalGroup.name : '')),
                React.createElement("div", { className: "clientSectionRequestDate" },
                    React.createElement("div", { className: "clientSectionLabel" }, "Data zapytania:"),
                    React.createElement("div", { className: "clientSectionValue", "data-testid": "client-section-request-date" }, formatDate(requestDate)))),
            React.createElement("div", { className: "clientSectionRightContainer" },
                React.createElement("div", { className: "clientSectionCars" },
                    React.createElement("div", { className: "clientSectionLabel" }, "Samochody posiadane przez stron\u0119:"),
                    React.createElement("div", { className: "clientSectionCarsTableWrapper" },
                        React.createElement("table", { className: "clientSectionCarsTable" },
                            React.createElement("thead", null,
                                React.createElement("tr", null,
                                    React.createElement("th", { className: "clientSectionCarTableHeader clientSectionCarOrderDateCell" }, "Data wydania"),
                                    React.createElement("th", { className: "clientSectionCarTableHeader" }, "Model"),
                                    React.createElement("th", { className: "clientSectionCarTableHeader clientSectionCarVinCell" }, "VIN"))),
                            React.createElement("tbody", null, cars && cars.length > 0
                                ? cars.map(function (car) { return (React.createElement("tr", { className: "clientSectionCarTableRow", key: car.vin },
                                    React.createElement("td", { className: "clientSectionCarTableCell clientSectionCarOrderDateCell" }, car.handoverDate),
                                    React.createElement("td", { className: "clientSectionCarTableCell" }, "".concat(car.carModel, " (").concat(car.modelYear, ")")),
                                    React.createElement("td", { className: "clientSectionCarTableCell clientSectionCarVinCell" }, car.vin))); })
                                : null)))),
                React.createElement("div", { className: "clientSectionRequestAttachments" },
                    React.createElement("div", { className: "clientSectionLabel" }, "Zapytanie*:"),
                    React.createElement("div", null, requestAttachments.length > 0 ? (React.createElement(List.Root, null, requestAttachments.map(function (file) { return (React.createElement(List.Item, { key: file.fileUrl },
                        React.createElement("div", { className: "clientSectionAttachmentItem" },
                            React.createElement("div", { onClick: function () { return handleFileNameClick(file.fileUrl, file.fileName); }, "data-testid": 'client-section-request-attachment' }, file.fileName)))); }))) : null)),
                React.createElement("div", { className: "clientSectionRequestAttachments" },
                    React.createElement("div", { className: "clientSectionLabel", "data-testid": "add-new-request-attachment" },
                        React.createElement(FileInput, { acceptFile: ".pdf", fileRef: inputRequestFileRef, onFileChange: handleAddNewRequestAttachment, label: "DODAJ", value: "", disabled: !canAddAttachment })),
                    React.createElement("div", null, newRequestAttachments.length > 0 ? (React.createElement(List.Root, null, newRequestAttachments.map(function (file) { return (React.createElement(List.Item, { key: file.fileUrl },
                        React.createElement("div", { className: "clientSectionAttachmentItem" },
                            React.createElement("div", { onClick: function () { return handleFileNameClick(file.fileUrl, file.fileName); } }, file.fileName),
                            React.createElement("button", { type: "button", className: "clientSectionGarbageIconWrapper", onClick: function () { return handleDeleteNewRequestAttachment(file.fileUrl); } },
                                React.createElement(GarbageIcon, null))))); }))) : null)),
                React.createElement("div", { className: "clientSectionPolicyAttachments" },
                    React.createElement("div", { className: "clientSectionLabel" }, "Polisa:"),
                    React.createElement("div", null, policyAttachments.length > 0 ? (React.createElement(List.Root, null, policyAttachments.map(function (file) { return (React.createElement(List.Item, { key: file.fileUrl },
                        React.createElement("div", { className: "clientSectionAttachmentItem" },
                            React.createElement("div", { onClick: function () { return handleFileNameClick(file.fileUrl, file.fileName); } }, file.fileName)))); }))) : null)),
                React.createElement("div", { className: "clientSectionPolicyAttachments" },
                    React.createElement("div", { className: "clientSectionLabel", "data-testid": "add-new-policy-attachment" },
                        React.createElement(FileInput, { acceptFile: ".pdf", fileRef: inputPolicyFileRef, onFileChange: handleAddNewPolicAttachment, label: "DODAJ", value: "", disabled: !canAddAttachment })),
                    React.createElement("div", null, newPolicyAttachments.length > 0 ? (React.createElement(List.Root, null, newPolicyAttachments.map(function (file) { return (React.createElement(List.Item, { key: file.fileUrl },
                        React.createElement("div", { className: "clientSectionAttachmentItem" },
                            React.createElement("div", { onClick: function () { return handleFileNameClick(file.fileUrl, file.fileName); } }, file.fileName),
                            React.createElement("button", { type: "button", className: "clientSectionGarbageIconWrapper", onClick: function () { return handleDeleteNewPolicyAttachment(file.fileUrl); } },
                                React.createElement(GarbageIcon, null))))); }))) : null))))));
});
