// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .correctionSpecialOfferWrapper {
    width: 1280px;
    padding: 32px 0;
    margin: 0 auto;
}

#new-dol .editSpecialOfferComment {
    padding: 32px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/specialOffer/supplementSpecialOfferForm/supplementSpecialOfferForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".correctionSpecialOfferWrapper {\n    width: 1280px;\n    padding: 32px 0;\n    margin: 0 auto;\n}\n\n.editSpecialOfferComment {\n    padding: 32px 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
