import React from 'react';
import * as RadixTabs from '@radix-ui/react-tabs';
import './tabs.css';
function Root(_a) {
    var defaultValue = _a.defaultValue, onValueChange = _a.onValueChange, children = _a.children, value = _a.value;
    return React.createElement(RadixTabs.Root, { defaultValue: defaultValue, onValueChange: onValueChange, value: value, className: 'tabsRoot', "data-testid": 'tab-root' }, children);
}
function List(_a) {
    var children = _a.children, style = _a.style;
    return React.createElement(RadixTabs.List, { className: 'tabsList', style: style }, children);
}
// value from Trigger should match with the value from Content
function Trigger(_a) {
    var value = _a.value, children = _a.children, style = _a.style, borderBottom = _a.borderBottom;
    return React.createElement(RadixTabs.Trigger, { "data-testid": 'tab-trigger', value: value, className: "tabsTrigger ".concat(borderBottom ? 'borderBottom' : ''), style: style }, children);
}
function Content(_a) {
    var value = _a.value, children = _a.children;
    return React.createElement(RadixTabs.Content, { value: value }, children);
}
export var Tabs = {
    Root: Root,
    List: List,
    Trigger: Trigger,
    Content: Content
};
