import { useHttp } from 'http/httpService';
var searchMock = [
    {
        'commonOrderNumber': '10916786',
        'dealerOrder': '9999999',
        'vin': 'YV1XZK7V3R2215899',
        'modelId': '536_2024',
        'registrationNumber': null,
        'stockCategory': 'OWN_STOCK'
    },
    {
        'commonOrderNumber': '10939999',
        'dealerOrder': '8888888',
        'vin': 'LYVUZK9V5PB318888',
        'modelId': '236_2024',
        'registrationNumber': null,
        'stockCategory': 'IMPORTER_STOCK'
    },
    {
        'commonOrderNumber': '10935555',
        'dealerOrder': '7778889',
        'vin': 'LYVUZK9V5PB317777',
        'modelId': '137_2024',
        'registrationNumber': null,
        'stockCategory': 'OTHER_DEALERS_STOCK'
    }
];
var selectedCarMock = [{
        reservation: true,
        stockCategory: 'OWN_STOCK',
        model: 'XC40',
        modelYear: '2024',
        listPrice: 200000,
        type: 'OWN',
        dealerOrder: '7135323',
        vin: 'LYVUZK9V5PB317867',
        deliveryDate: '2024-04-14',
        lastDateForOrderChange: '2024-03-31',
        drive: 'B4 (197 + 14 KM), 8-stopniowa automatyczna skrzynia biegów, Benzyna',
        version: 'Wersja: Plus, Motyw: Bright Dust',
        upholstery: 'Tapicerka tekstylna w tonacji czerni (Charcoal)',
        interior: 'wnętrze w tonacji czerni (Charcoal) (R100)',
        dealerName: 'DOM VOLVO Warszawa Puławska',
        commonOrderNumber: '10937770',
        productionDate: '2024-01-01',
        registrationNumber: null,
        comment: 'Doposażeniu auta w akcesoria pozafabryczne',
        warnings: [
            'Minęła data zamrożenia specyfikacji.',
            'Konfiguracja zostanie przedstawiona wg najnowszego cennika dla roku modelowego 2016.'
        ],
        oldCreatorAllowed: false
    }];
var modelsMock = ['536', '246', '256', '235', '225', '227', '234', '384', '356', '416', '536BEV', '539'];
var modelYearsMock = [
    {
        label: '2019',
        value: 'v2019'
    },
    {
        label: '2020',
        value: 'v2020'
    },
    {
        label: '2021',
        value: 'v2021'
    },
    {
        label: '2022',
        value: 'v2022'
    },
    {
        label: '2023',
        value: 'v2023'
    },
    {
        label: '2024',
        value: 'v2024'
    },
    {
        label: '2025',
        value: 'v2025'
    },
    {
        label: '2026',
        value: 'v2026'
    }
];
var driveTypesMock = [
    {
        label: 'Spalinowy',
        value: 'ICE'
    },
    {
        label: 'Hybrydowy',
        value: 'MHEV'
    },
    {
        label: 'Elektryczny',
        value: 'BEV'
    }
];
var engineMock = [
    {
        label: 'B3 Mild Hybrid',
        value: 'K6'
    },
    {
        label: 'B4 Mild Hybrid',
        value: 'K9'
    },
    {
        label: 'T8 Plug-In Hybrid',
        value: 'BM'
    },
    {
        label: 'Rechage T8 Plug-in',
        value: 'H7'
    },
    {
        label: 'Single Motor',
        value: 'EM'
    },
    {
        label: 'Twin Motor',
        value: 'EV'
    },
];
var orderTypesMock = [
    {
        label: 'Stock',
        value: '30A'
    },
    {
        label: 'Ekspozycja',
        value: '31A'
    },
];
var carLocationsMock = [
    {
        label: 'Plac importera',
        value: 'importer'
    },
    {
        label: 'Plac dealera',
        value: 'dealer'
    },
];
var versionsMock = [
    {
        label: 'Base',
        value: 'base'
    },
    {
        label: 'Core',
        value: 'core'
    },
    {
        label: 'Plus Dark',
        value: 'plus dark'
    },
    {
        label: 'Plus Bright',
        value: 'plus bright'
    },
    {
        label: 'Polestar',
        value: 'polestar'
    }
];
var colorsMock = [
    {
        label: 'Vapour Grey',
        value: '74000'
    },
    {
        label: 'Crystal White',
        value: '70700'
    },
    {
        label: 'Fusion Red',
        value: '72500'
    },
    {
        label: 'Onyx Black',
        value: '71700'
    },
    {
        label: 'Sage Green',
        value: '73500'
    }
];
var upholsteryColorsMock = [
    {
        label: 'Tapicerka tekstylna City Block w tonacji szarości (Grey Melange)',
        value: 'R7C000'
    },
    {
        label: 'Tapicerka Connect z tkaniny zamszowej/Microtech w tonacji czerni (Charcoal)',
        value: 'R98000'
    },
    {
        label: 'Tapicerka skórzana w tonacji czerni (Charcoal)',
        value: 'RD0000'
    },
];
var priceRangesMock = [
    {
        label: '100 tys - 150 tys',
        value: '0'
    },
    {
        label: '150 tys - 200 tys',
        value: '1'
    },
    {
        label: '200 tys - 250 tys',
        value: '2'
    },
    {
        label: '250 tys - 300 tys',
        value: '3'
    },
    {
        label: 'pow. 300 tys',
        value: '4'
    },
];
var stockOptionsMock = [
    {
        label: 'Dealera',
        value: 'dealer'
    },
    {
        label: 'Innego dealera',
        value: 'other dealer'
    },
];
export function useNewOfferFromStockApi() {
    var http = useHttp();
    return {
        searchCars: function (type, phrase) {
            return http
                .get("/api/creator/".concat(type, "?phrase=").concat(phrase))
                .then(function (response) { return searchMock; });
        },
        getCar: function (type, con) {
            return http
                .get("/api/creator/".concat(type, "/commonOrderNumber/").concat(con))
                .then(function (response) { return selectedCarMock; });
        },
        checkCar: function (offerId, fyon, clientId, partyId, modelYear, factoryOrderDate, con, fixedPlannedDate) {
            return http
                .post('/api/creator/offer/stock', {
                offerId: offerId,
                fyon: fyon,
                clientId: clientId,
                partyId: partyId,
                modelYear: modelYear,
                factoryOrderDate: factoryOrderDate,
                con: con,
                fixedPlannedDate: fixedPlannedDate
            });
        },
        getAvailableModels: function () {
            return http
                .get('/api/creator/stock-cars/models')
                .then(function (response) { return modelsMock; });
        },
        getAvailableOptions: function (filterType, pno3) {
            return http
                .post("/api/creator/stock-cars/".concat(filterType, "?model=").concat(pno3))
                .then(function (response) {
                if (filterType === 'modelYears') {
                    return modelYearsMock;
                }
                if (filterType === 'driveTypes') {
                    return driveTypesMock;
                }
                if (filterType === 'engines') {
                    return engineMock;
                }
                if (filterType === 'orderTypes') {
                    return orderTypesMock;
                }
                if (filterType === 'carLocations') {
                    return carLocationsMock;
                }
                if (filterType === 'versions') {
                    return versionsMock;
                }
                if (filterType === 'colors') {
                    return colorsMock;
                }
                if (filterType === 'upholsteryColors') {
                    return upholsteryColorsMock;
                }
                if (filterType === 'priceRanges') {
                    return priceRangesMock;
                }
                if (filterType === 'stock') {
                    return stockOptionsMock;
                }
            });
        }
    };
}
