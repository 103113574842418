import React from 'react';
import { Label } from 'components/label/Label.component';
import { NetTextAmountSummary } from 'components/textAmount/NetTextAmountSummary';
import './summary.css';
import { useFormattedCurrency } from 'utils/formatCurrency';
export function BasicInformationSummary(_a) {
    var netTotal = _a.netTotal, grossTotal = _a.grossTotal;
    return (React.createElement("div", { className: 'basicInformationSummaryWrapper', "data-testid": 'basicInformationSummaryWrapper' },
        React.createElement("div", null,
            React.createElement(Label, { bold: true, uppercase: true }, "suma")),
        React.createElement("div", { className: 'basicInformationSummaryNet' },
            React.createElement(NetTextAmountSummary, null, netTotal)),
        React.createElement("div", { className: 'basicInformationSummaryGross' }, useFormattedCurrency(grossTotal))));
}
