import React from 'react';
import { Dialog } from '../../../components/dialog/Dialog.component';
import { CarPreviewButton } from '../../../components/carPreviewButton/CarPreviewButton.component';
import { CarPreviewTabs } from '../../../components/carPreviewTabs/carPreviewTabs.component';
import { Swiper } from '../../../components/swiper/Swiper.component';
import { CarPreviewImage } from '../../../components/carPreviewImage/CarPreviewImage.component';
export function CarPreview(_a) {
    var swiperTabs = _a.swiperTabs;
    return (React.createElement(Dialog.Root, null,
        React.createElement(Dialog.Trigger, null,
            React.createElement(CarPreviewButton, null)),
        React.createElement(Dialog.Content, null,
            React.createElement(Dialog.CloseButton, null),
            React.createElement(Dialog.CarPreviewContentLayout, null, !!swiperTabs ? (React.createElement(CarPreviewTabs.Root, { defaultValue: swiperTabs[0].value },
                React.createElement(CarPreviewTabs.List, null, swiperTabs.map(function (tab, index) { return (React.createElement(CarPreviewTabs.Trigger, { value: tab.value, key: index }, tab.name)); })),
                swiperTabs.map(function (tab, index) { return (React.createElement(CarPreviewTabs.Content, { value: tab.value, key: index },
                    React.createElement(Swiper, { items: tab.photos.map(function (item) { return (React.createElement(CarPreviewImage, { src: item })); }) }))); }))) : null))));
}
