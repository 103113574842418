// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .listItemOptionCodeElement {
    width: 80px;
    text-align: end;
    grid-column-start: 2;
}

#new-dol .listItemChipsElement {
    display: flex;
    gap: 8px;
    width: 100%;
    grid-column-start: 3;
}

#new-dol .listItemPriceElement{
    justify-self: end;
    grid-column-start: 4;
}

#new-dol .listItemCatalogPrice {
    justify-self: end;
    grid-column: 3;
}`, "",{"version":3,"sources":["webpack://./src/components/listItemElement/listItemElement.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,WAAW;IACX,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".listItemOptionCodeElement {\n    width: 80px;\n    text-align: end;\n    grid-column-start: 2;\n}\n\n.listItemChipsElement {\n    display: flex;\n    gap: 8px;\n    width: 100%;\n    grid-column-start: 3;\n}\n\n.listItemPriceElement{\n    justify-self: end;\n    grid-column-start: 4;\n}\n\n.listItemCatalogPrice {\n    justify-self: end;\n    grid-column: 3;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
