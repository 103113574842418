import { Chip } from 'components/chip/Chip.component';
import { ChipPlaceholder } from 'components/chip/ChipPlaceholder.component';
import { List } from 'components/list/List.component';
import { Switch } from 'components/switch/Switch';
import React from 'react';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import { formatDate } from 'utils/formatDate';
import { immutableSort } from 'utils/immutableSort';
import { ActionButton } from './ActionButton.component';
import './financialProducts.css';
export function PaginatedFinancialProducts(_a) {
    var products = _a.products, totalPages = _a.totalPages, changePageHandler = _a.changePageHandler, currentPage = _a.currentPage, onProductActivitySwitchChange = _a.onProductActivitySwitchChange, onDefaultCalculateSwitchChange = _a.onDefaultCalculateSwitchChange;
    var history = useHistory();
    function onPageChange(event) {
        var selectedPage = event.selected;
        changePageHandler(selectedPage);
    }
    function onGoToProductPreviewClick(productId) {
        history.push("/financial-products/".concat(productId));
    }
    var sortedProducts = products ? immutableSort(products, function (productA, productB) { return productA.name.localeCompare(productB.name); }) : null;
    return (React.createElement("div", { className: "paginatedFinancingProductsWrapper" },
        React.createElement("div", null,
            React.createElement(List.Root, null, sortedProducts ? sortedProducts.map(function (product) { return (React.createElement(List.Item, { key: product.id },
                React.createElement("div", { className: 'financialProductsList' },
                    React.createElement("div", { "data-testid": 'activity-switch-wrapper' },
                        React.createElement(Switch, { checked: product.isActive, onCheckedChange: function (checked) { return onProductActivitySwitchChange(checked, product.id); }, size: 'small', disabled: product.isRevert })),
                    React.createElement("div", { className: 'financialProductsListCenter', "data-testid": 'default-product-switch-wrapper' },
                        React.createElement(Switch, { checked: product.calculateAsDefault, onCheckedChange: function (checked) { return onDefaultCalculateSwitchChange(checked, product.id); }, size: 'small', disabled: product.isRevert })),
                    React.createElement("div", { "data-testid": 'product-name' }, product.name),
                    React.createElement("div", null, product.isRevert ? React.createElement(Chip, { color: '#BF2012' }, "wycofany") : React.createElement(ChipPlaceholder, null)),
                    product.lastModifiedConfig ? React.createElement("div", null,
                        "Zmodyfikowano: ",
                        formatDate(new Date(product.lastModifiedConfig)),
                        " ") : React.createElement("div", null),
                    React.createElement("div", null,
                        React.createElement(ActionButton, { onClick: function () { return onGoToProductPreviewClick(product.id); } }))))); }) : null)),
        totalPages > 1 ? (React.createElement("div", { className: "pagination", "data-testid": "pagination" },
            React.createElement(ReactPaginate, { breakLabel: "...", nextLabel: "Nast\u0119pna strona", onPageChange: onPageChange, pageRangeDisplayed: 5, pageCount: totalPages, previousLabel: "Poprzednia strona", renderOnZeroPageCount: function () { return null; }, pageLinkClassName: "page-link", previousClassName: "page-item", previousLinkClassName: "page-link-prev", nextClassName: "page-item", nextLinkClassName: "page-link-next", breakClassName: "page-item", breakLinkClassName: "page-link", containerClassName: "pagination", activeClassName: "active", activeLinkClassName: "active-link", forcePage: currentPage }))) : null));
}
