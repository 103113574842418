import React from 'react';
import { formatDate } from 'utils/formatDate';
import { statusDictionary } from '../requestSubject/RequestSubjectPreview';
import './changesHistory.css';
import { immutableSort } from 'utils/immutableSort';
export function ChangesHistory(_a) {
    var changesHistory = _a.changesHistory;
    var historyData = changesHistory;
    var sortedHistoryData = immutableSort(historyData, function (a, b) {
        var dateA = new Date(a.eventDate).getTime();
        var dateB = new Date(b.eventDate).getTime();
        return dateA - dateB;
    });
    function getDate(timestamp) {
        var date = timestamp.split(' ')[0];
        var formattedDate = formatDate(new Date(date));
        return formattedDate;
    }
    function getTime(timestamp) {
        var time = timestamp.split(' ')[1];
        var _a = time.split(':'), hour = _a[0], minutes = _a[1], seconds = _a[2];
        var formattedTime = hour + ':' + minutes;
        return formattedTime;
    }
    return React.createElement("div", { className: 'changesHistoryWrapper' },
        React.createElement("div", { className: 'changesHistoryHeader' }, "historia zmian"),
        React.createElement("div", { className: 'changesHistoryTableWrapper', "data-testid": 'changes-history-table-wrapper' },
            React.createElement("table", { className: 'changesHistoryTable' },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: 'changesHistoryTableHeadCell changesHistoryTableDateCell' }, "Data i godzina"),
                        React.createElement("th", { className: 'changesHistoryTableHeadCell changesHistoryTableStatusCell', style: { paddingRight: '12px' } }, "Status"),
                        React.createElement("th", { className: 'changesHistoryTableHeadCell changesHistoryTableCommentCell' }, "Komentarz"),
                        React.createElement("th", { className: 'changesHistoryTableHeadCell changesHistoryTableCarCell' }, "Samoch\u00F3d"),
                        React.createElement("th", { className: 'changesHistoryTableHeadCell changesHistoryTableAuthorCell' }, "Wprowadzaj\u0105cy"))),
                React.createElement("tbody", null, sortedHistoryData.map(function (entry, index) { return (React.createElement("tr", { className: 'changesHistoryTableRow', key: index },
                    React.createElement("td", { className: 'changesHistoryTableBodyCell changesHistoryTableDateCell' },
                        React.createElement("div", { className: 'changesHistoryDateAndTimeWrapper' }, entry.eventDate ?
                            React.createElement(React.Fragment, null,
                                React.createElement("div", null, getDate(entry.eventDate)),
                                React.createElement("div", null, getTime(entry.eventDate)))
                            : null)),
                    React.createElement("td", { className: 'changesHistoryTableBodyCell changesHistoryTableStatusCell' }, statusDictionary[entry.status]),
                    React.createElement("td", { className: 'changesHistoryTableBodyCell changesHistoryTableCommentCell' }, entry.additionalInfo),
                    React.createElement("td", { className: 'changesHistoryTableBodyCell changesHistoryTableCarCell' }, entry.dealerOrder),
                    React.createElement("td", { className: 'changesHistoryTableBodyCell changesHistoryTableAuthorCell' }, entry.applicantId))); })))));
}
