var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import { useAttractivenessApi } from './useAttractivenessApi';
import ReactDOM from 'react-dom';
import { useCreatorApi } from '../useCreatorApi';
import debounce from 'lodash.debounce';
import { useStatus } from 'common/statusContext';
var debounceWait = 500;
export function useAttractiveness(_a) {
    var _this = this;
    var offer = _a.offer, handleChangeOffer = _a.handleChangeOffer, canBeSynced = _a.canBeSynced, onSynced = _a.onSynced, isSaveInSessionStorage = _a.isSaveInSessionStorage, clientParties = _a.clientParties, offerIdToClone = _a.offerIdToClone;
    var _b = useState(false), percentageValues = _b[0], setPercentageValues = _b[1];
    var _c = useState(false), envImpactSection = _c[0], setEnvImpactSection = _c[1];
    var _d = useState(false), leaseTotalCost = _d[0], setLeaseTotalCost = _d[1];
    var _e = useState({
        name: '',
        discountAmount: 0,
        discountPercent: 0,
    }), businessOffer = _e[0], setBussinesOffer = _e[1];
    var _f = useState(0), specialOfferOrDiscountGroup = _f[0], setSpecialOfferOrDiscountGroup = _f[1];
    var _g = useState(0), percentageAdditionalDiscount = _g[0], setPercentageAdditionalDiscount = _g[1];
    var _h = useState(0), amountAdditionalDiscount = _h[0], setAmountAdditionalDiscount = _h[1];
    var attractivenessApi = useAttractivenessApi();
    var creatorApi = useCreatorApi();
    var _j = useStatus(), status = _j.status, setStatus = _j.setStatus;
    function clientPartyTypeDescription(partyType) {
        switch (partyType) {
            case 'INDIVIDUAL':
                return 'Klient indywidualny';
            case 'SELF_EMPLOYED':
                return 'Działalność';
            case 'COMPANY':
                return 'Spółka';
            default:
                return '';
        }
    }
    function hasDiscount(offer, discountValue) {
        return (offer.discounts.length === 0 ||
            !offer.discounts.find(function (discount) { return discount.discountValue === discountValue; }));
    }
    var dropdownOptionsForParties = clientParties ? clientParties.map(function (party) {
        return {
            value: party.id,
            label: clientPartyTypeDescription(party.type) +
                ' ' +
                party.name +
                ' ' +
                party.identificationNumber.type +
                ': ' +
                party.identificationNumber.value,
        };
    }) : [];
    function handleChangePrintType(checked, printType) {
        return __awaiter(this, void 0, void 0, function () {
            var getOfferResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        if (!checked) return [3 /*break*/, 2];
                        return [4 /*yield*/, attractivenessApi.addPrintTypeOption(offer.id, printType)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, attractivenessApi.deletePrintTypeOption(offer.id, printType)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                    case 5:
                        getOfferResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setStatus('success');
                            switch (printType) {
                                case 'OFFERS_AND_DISCOUNTS_EXPRESSED_IN_PERCENTAGES':
                                    setPercentageValues(getOfferResponse.printOptions.includes('OFFERS_AND_DISCOUNTS_EXPRESSED_IN_PERCENTAGES'));
                                    break;
                                case 'ENVIRONMENTAL_IMPACT':
                                    setEnvImpactSection(getOfferResponse.printOptions.includes('ENVIRONMENTAL_IMPACT'));
                                    break;
                                case 'LEASE_TOTAL_COST':
                                    setLeaseTotalCost(getOfferResponse.printOptions.includes('LEASE_TOTAL_COST'));
                            }
                            handleChangeOffer(getOfferResponse);
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    var debouncedChangeBusinessOfferName = React.useRef(debounce(function (offer, name) { return __awaiter(_this, void 0, void 0, function () {
        var discountName, discountValue, businessOffer_1, getOfferResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    discountName = name === '' ? 'Oferta handlowa' : name;
                    discountValue = 'BUSINESS_OFFER';
                    setStatus('pending');
                    if (!hasDiscount(offer, discountValue)) return [3 /*break*/, 2];
                    return [4 /*yield*/, attractivenessApi.addDiscount(offer.id, discountValue, 'PERCENTAGE', 0, discountName)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2:
                    businessOffer_1 = offer.discounts.find(function (discount) { return discount.discountValue === discountValue; });
                    return [4 /*yield*/, attractivenessApi.modifyDiscount(offer.id, discountValue, businessOffer_1.type, businessOffer_1.value, discountName)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                case 5:
                    getOfferResponse = _a.sent();
                    ReactDOM.unstable_batchedUpdates(function () {
                        setStatus('success');
                        handleChangeOffer(getOfferResponse);
                    });
                    return [2 /*return*/];
            }
        });
    }); }, debounceWait)).current;
    var debouncedChangeBusinessOfferDiscount = React.useRef(debounce(function (offer, value, discountType) { return __awaiter(_this, void 0, void 0, function () {
        var discountValue, businessOffer_2, getOfferResponse, newBusinessOffer;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    discountValue = 'BUSINESS_OFFER';
                    setStatus('pending');
                    if (!hasDiscount(offer, discountValue)) return [3 /*break*/, 2];
                    return [4 /*yield*/, attractivenessApi.addDiscount(offer.id, discountValue, discountType, value, 'Oferta handlowa')];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2:
                    businessOffer_2 = offer.discounts.find(function (discount) { return discount.discountValue === discountValue; });
                    return [4 /*yield*/, attractivenessApi.modifyDiscount(offer.id, discountValue, discountType, value, businessOffer_2.name)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                case 5:
                    getOfferResponse = _a.sent();
                    newBusinessOffer = getOfferResponse.discounts.find(function (discount) { return discount.discountValue === 'BUSINESS_OFFER'; });
                    ReactDOM.unstable_batchedUpdates(function () {
                        setStatus('success');
                        handleChangeOffer(getOfferResponse);
                        setBussinesOffer(function (currentBusinessOffer) {
                            return __assign(__assign({}, currentBusinessOffer), { discountAmount: newBusinessOffer.amountDiscountGross, discountPercent: newBusinessOffer.percentageDiscount });
                        });
                    });
                    onSynced();
                    return [2 /*return*/];
            }
        });
    }); }, debounceWait)).current;
    var debouncedChangeDiscount = React.useRef(debounce(function (offer, value, discountValue, discountType) { return __awaiter(_this, void 0, void 0, function () {
        var getOfferResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setStatus('pending');
                    if (!hasDiscount(offer, discountValue)) return [3 /*break*/, 2];
                    return [4 /*yield*/, attractivenessApi.addDiscount(offer.id, discountValue, discountType, value)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, attractivenessApi.modifyDiscount(offer.id, discountValue, discountType, value)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                case 5:
                    getOfferResponse = _a.sent();
                    ReactDOM.unstable_batchedUpdates(function () {
                        setStatus('success');
                        handleChangeOffer(getOfferResponse);
                        switch (discountValue) {
                            case 'SPECIAL_OFFER_OR_DISCOUNT_GROUP_VCP':
                                setSpecialOfferOrDiscountGroup(value);
                                break;
                            case 'ADDITIONAL_PERCENTAGE_DISCOUNT':
                                setPercentageAdditionalDiscount(value);
                                break;
                            case 'QUANTITATIVE_ADDITIONAL_DISCOUNT':
                                setAmountAdditionalDiscount(value);
                                break;
                        }
                    });
                    onSynced();
                    return [2 /*return*/];
            }
        });
    }); }, debounceWait)).current;
    useEffect(function () {
        if (status === 'pending') {
            var handler_1 = function (event) {
                event.stopPropagation();
                event.preventDefault();
            };
            window.addEventListener('keydown', handler_1, { capture: true });
            return function () {
                window.removeEventListener('keydown', handler_1, { capture: true });
            };
        }
    }, [status]);
    function preventDebounce() {
        if (status === 'pending') {
            return;
        }
    }
    function handleChangeBusinessOfferName(event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                preventDebounce();
                debouncedChangeBusinessOfferName(offer, event.target.value);
                return [2 /*return*/];
            });
        });
    }
    function handleChangeBusinessOfferDiscountAmount(values) {
        var _a;
        var newDiscoutAmount = (_a = values.floatValue) !== null && _a !== void 0 ? _a : 0;
        preventDebounce();
        debouncedChangeBusinessOfferDiscount(offer, newDiscoutAmount, 'AMOUNT');
    }
    function handleChangeBusinessOfferDiscountPercent(values) {
        var _a;
        var newDiscountPercent = (_a = values.floatValue) !== null && _a !== void 0 ? _a : 0;
        preventDebounce();
        debouncedChangeBusinessOfferDiscount(offer, newDiscountPercent, 'PERCENTAGE');
    }
    function handleChangeSpecialOfferOrGroup(values) {
        var _a;
        var newSpecialOfferValue = (_a = values.floatValue) !== null && _a !== void 0 ? _a : 0;
        preventDebounce();
        debouncedChangeDiscount(offer, newSpecialOfferValue, 'SPECIAL_OFFER_OR_DISCOUNT_GROUP_VCP', 'PERCENTAGE');
    }
    function handleChangePercentageAdditionalDiscount(values) {
        var _a;
        var newPercentageAdditionalDiscount = (_a = values.floatValue) !== null && _a !== void 0 ? _a : 0;
        preventDebounce();
        debouncedChangeDiscount(offer, newPercentageAdditionalDiscount, 'ADDITIONAL_PERCENTAGE_DISCOUNT', 'PERCENTAGE');
    }
    function handleChangeAmountAdditionalDiscount(values) {
        var _a;
        var newAmountAdditionalDiscount = (_a = values.floatValue) !== null && _a !== void 0 ? _a : 0;
        preventDebounce();
        debouncedChangeDiscount(offer, newAmountAdditionalDiscount, 'QUANTITATIVE_ADDITIONAL_DISCOUNT', 'AMOUNT');
    }
    useEffect(function () {
        function selectDefaultDiscountsAndPrintTypes() {
            return __awaiter(this, void 0, void 0, function () {
                var getOfferResponse_1, discounts, printOptions, bussinesOfferFromStorage;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(offer && canBeSynced)) return [3 /*break*/, 9];
                            if (!(offer.discounts.length === 0)) return [3 /*break*/, 8];
                            return [4 /*yield*/, attractivenessApi.addPrintTypeOption(offer.id, 'OFFERS_AND_DISCOUNTS_EXPRESSED_IN_PERCENTAGES')];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, attractivenessApi.addPrintTypeOption(offer.id, 'ENVIRONMENTAL_IMPACT')];
                        case 2:
                            _a.sent();
                            return [4 /*yield*/, attractivenessApi.addPrintTypeOption(offer.id, 'LEASE_TOTAL_COST')];
                        case 3:
                            _a.sent();
                            return [4 /*yield*/, attractivenessApi.addDiscount(offer.id, 'SPECIAL_OFFER_OR_DISCOUNT_GROUP_VCP', 'PERCENTAGE', 0)];
                        case 4:
                            _a.sent();
                            return [4 /*yield*/, attractivenessApi.addDiscount(offer.id, 'ADDITIONAL_PERCENTAGE_DISCOUNT', 'PERCENTAGE', 0)];
                        case 5:
                            _a.sent();
                            return [4 /*yield*/, attractivenessApi.addDiscount(offer.id, 'QUANTITATIVE_ADDITIONAL_DISCOUNT', 'AMOUNT', 0)];
                        case 6:
                            _a.sent();
                            return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                        case 7:
                            getOfferResponse_1 = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                handleChangeOffer(getOfferResponse_1);
                                setPercentageValues(getOfferResponse_1.printOptions.includes('OFFERS_AND_DISCOUNTS_EXPRESSED_IN_PERCENTAGES'));
                                setEnvImpactSection(getOfferResponse_1.printOptions.includes('ENVIRONMENTAL_IMPACT'));
                                setLeaseTotalCost(getOfferResponse_1.printOptions.includes('LEASE_TOTAL_COST'));
                                onSynced();
                            });
                            return [3 /*break*/, 9];
                        case 8:
                            if (isSaveInSessionStorage || offerIdToClone) {
                                discounts = offer.discounts;
                                printOptions = offer.printOptions;
                                setSpecialOfferOrDiscountGroup(discounts.find(function (discount) { return discount.discountValue === 'SPECIAL_OFFER_OR_DISCOUNT_GROUP_VCP'; }).percentageDiscount);
                                setPercentageAdditionalDiscount(discounts.find(function (discount) { return discount.discountValue === 'ADDITIONAL_PERCENTAGE_DISCOUNT'; }).percentageDiscount);
                                setAmountAdditionalDiscount(discounts.find(function (discount) { return discount.discountValue === 'QUANTITATIVE_ADDITIONAL_DISCOUNT'; }).amountDiscountGross);
                                bussinesOfferFromStorage = discounts.find(function (discount) { return discount.discountValue === 'BUSINESS_OFFER'; });
                                if (bussinesOfferFromStorage) {
                                    setBussinesOffer({
                                        name: bussinesOfferFromStorage.name === 'Oferta handlowa' ? '' : bussinesOfferFromStorage.name,
                                        discountAmount: bussinesOfferFromStorage.amountDiscountGross,
                                        discountPercent: bussinesOfferFromStorage.percentageDiscount,
                                    });
                                }
                                setPercentageValues(printOptions.includes('OFFERS_AND_DISCOUNTS_EXPRESSED_IN_PERCENTAGES'));
                                setEnvImpactSection(printOptions.includes('ENVIRONMENTAL_IMPACT'));
                                setLeaseTotalCost(printOptions.includes('LEASE_TOTAL_COST'));
                                onSynced();
                            }
                            else {
                                onSynced();
                            }
                            _a.label = 9;
                        case 9: return [2 /*return*/];
                    }
                });
            });
        }
        selectDefaultDiscountsAndPrintTypes();
    }, [canBeSynced, offer]);
    return {
        businessOffer: businessOffer,
        onBusinessOfferDiscountNameChange: handleChangeBusinessOfferName,
        onBusinessOfferDiscountAmountChange: handleChangeBusinessOfferDiscountAmount,
        onBusinessOfferDiscountPercentChange: handleChangeBusinessOfferDiscountPercent,
        specialOfferOrDiscountGroup: specialOfferOrDiscountGroup,
        onSpecialOfferOrGroupChange: handleChangeSpecialOfferOrGroup,
        percentageAdditionalDiscount: percentageAdditionalDiscount,
        onPercentageAdditionalDiscountChange: handleChangePercentageAdditionalDiscount,
        amountAdditionalDiscount: amountAdditionalDiscount,
        onAmountAdditionalDiscountChange: handleChangeAmountAdditionalDiscount,
        percentageValues: percentageValues,
        envImpactSection: envImpactSection,
        leaseTotalCost: leaseTotalCost,
        onPrintTypeChange: handleChangePrintType,
        debouncedChangeBusinessOfferName: debouncedChangeBusinessOfferName,
        debouncedChangeBusinessOfferDiscount: debouncedChangeBusinessOfferDiscount,
        debouncedChangeDiscount: debouncedChangeDiscount,
        dropdownOptionsForParties: dropdownOptionsForParties
    };
}
