import { withCancellation } from 'common/requestCancellation/requestCancellation';
import React from 'react';
import { formatDate } from 'utils/formatDate';
import './applicantData.css';
export var ApplicantData = withCancellation(function (_a) {
    var applicantSection = _a.applicantSection, applicationId = _a.applicationId;
    var applicantData = applicantSection.applicantData;
    return (applicantData ?
        React.createElement("div", { className: "applicantDataWrapper" },
            React.createElement("div", { className: "applicantDataSection" },
                React.createElement("div", { className: "applicantDataSectionLabel" }, "Data wniosku:"),
                React.createElement("div", { className: "applicantDataSectionValue", "data-testid": "applicant-data-form-date" }, applicantData ? formatDate(applicantData.applicationDate) : null)),
            applicantData.validTo ?
                React.createElement("div", { className: "applicantDataSection" },
                    React.createElement("div", { className: "applicantDataSectionLabel" }, "Data wa\u017Cno\u015Bci:"),
                    React.createElement("div", { className: "applicantDataSectionValue", "data-testid": "applicant-data-to-date" }, applicantData ? formatDate(applicantData.validTo) : null))
                : null,
            React.createElement("div", { className: "applicantDataSection" },
                React.createElement("div", { className: "applicantDataSectionLabel" }, "Nazwa dealera:"),
                React.createElement("div", { className: "applicantDataSectionValue", "data-testid": "applicant-data-dealer" }, applicantData ? applicantData.organizationName : null)),
            React.createElement("div", { className: "applicantDataSection" },
                React.createElement("div", { className: "applicantDataSectionLabel" }, "Opiekun Strony:"),
                React.createElement("div", { className: "applicantDataSectionValue", "data-testid": "applicant-data-assistant" }, applicantData ? applicantData.applicantName : null)),
            applicationId && (React.createElement("div", { className: "applicantDataSection" },
                React.createElement("div", { className: "applicantDataSectionLabel" }, "Numer wniosku:"),
                React.createElement("div", { className: "applicantDataSectionValue", "data-testid": "applicant-data-special-offer-number" }, applicationId))))
        : null);
});
