import { useHttp } from 'http/httpService';
import SessionStorage from 'common/sessionStorage';
import { creatorContextPath } from '../CreatorContextPath';
export function useDriveApi() {
    var http = useHttp();
    var context = SessionStorage.get('employeeLeasingMode') ? 'employeeLeasing' : 'creator';
    return {
        getDrives: function (carModelId) {
            return http
                .get("/api/".concat(creatorContextPath(context), "/car-power-trains/").concat(carModelId))
                .then(function (response) { return response.data; });
        },
        getDrivesVcp: function (carModelId) {
            return http
                .get("/api/".concat(creatorContextPath(context), "/car-power-trains/vcp/").concat(carModelId))
                .then(function (response) { return response.data; });
        }
    };
}
export var Screen;
(function (Screen) {
    Screen["POWER_TRAIN"] = "POWER_TRAIN";
    Screen["VERSION"] = "VERSION";
})(Screen || (Screen = {}));
