import React from 'react';
import './swiper.css';
import { Swiper as SwiperJs, SwiperSlide as SwiperJsSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper';
export function Swiper(_a) {
    var items = _a.items;
    return React.createElement("div", { className: 'swiperWrapper' },
        React.createElement(SwiperJs, { navigation: true, pagination: { clickable: true }, loop: true, modules: [Navigation, Pagination], className: 'swiperjs' }, items.map(function (item, index) { return (React.createElement(SwiperJsSlide, { key: index }, item)); })));
}
