var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { useStatus } from 'common/statusContext';
import { Dropdown } from 'components/dropdown/Dropdown.component';
import { Label } from 'components/label/Label.component';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { immutableSort } from 'utils/immutableSort';
import './modelsList.css';
import { usePriceListApi } from '../usePriceListApi';
import { Spinner } from 'components/spinner/Spinner';
import { Switch } from 'components/switch/Switch';
import { formatDate } from 'utils/formatDate';
import { Plus } from 'components/accessories/Plus.comonent';
import { PriceListCheckboxesDialog } from './PriceListCheckboxesDialog.component';
export var ModelsList = withCancellation(function () {
    var _a = useState([
        {
            value: 'all',
            label: 'Wszystkie cenniki',
        },
    ]), filterDropdownOptions = _a[0], setFilterDropdownOptions = _a[1];
    var _b = useState(undefined), selectedFilter = _b[0], setSelectedFilter = _b[1];
    var _c = useState(undefined), priceListData = _c[0], setPriceListData = _c[1];
    var _d = useState(undefined), modelsListData = _d[0], setModelsListData = _d[1];
    var _e = useState([]), priceListNamesToPreview = _e[0], setPriceListNamesToPreview = _e[1];
    var _f = useState(false), openPriceListDialog = _f[0], setOpenPriceListDialog = _f[1];
    var _g = useStatus(), status = _g.status, setStatus = _g.setStatus;
    var priceListsApi = usePriceListApi();
    function handleChangeOpenPriceListDialog(open) {
        setOpenPriceListDialog(open);
    }
    function handleChangeSelectedPriceLists(priceListNames) {
        var newPriceListNamesToPreview = immutableSort(__spreadArray(__spreadArray([], lastThreePriceList, true), priceListNames, true), function (priceListNameA, priceListNameB) { return priceListNameA.localeCompare(priceListNameB); });
        ReactDOM.unstable_batchedUpdates(function () {
            setPriceListNamesToPreview(newPriceListNamesToPreview);
        });
    }
    function handleChangeSelectedFilter(value) {
        return __awaiter(this, void 0, void 0, function () {
            var arrayOfAllModelYears, priceListNamesParam, priceListsResponse, modelsListResponse, sortedPriceListNames, priceListNameToPreview;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        arrayOfAllModelYears = getArrayOfAllDropdownModelYears();
                        priceListNamesParam = value === 'all' ? arrayOfAllModelYears.join(',') : "20".concat(value);
                        return [4 /*yield*/, priceListsApi.getPrices(priceListNamesParam).catch(function (error) {
                                console.error(error);
                                setStatus('error');
                            })];
                    case 1:
                        priceListsResponse = _a.sent();
                        return [4 /*yield*/, priceListsApi.getModels(priceListNamesParam).catch(function (error) {
                                console.error(error);
                                setStatus('error');
                            })];
                    case 2:
                        modelsListResponse = _a.sent();
                        sortedPriceListNames = sortPriceLists(priceListsResponse);
                        priceListNameToPreview = getLastThreePrices(sortedPriceListNames);
                        ReactDOM.unstable_batchedUpdates(function () {
                            setSelectedFilter(value);
                            setPriceListData(priceListsResponse);
                            setModelsListData(modelsListResponse);
                            setPriceListNamesToPreview(priceListNameToPreview);
                            setStatus('success');
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        function getDefaultData() {
            return __awaiter(this, void 0, void 0, function () {
                var priceListYearsResponse, yearsForFilterDropdownArray, priceListNamesParam, priceListsResponse, modelsListResponse, sortedPriceListNames, priceListNameToPreview;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, priceListsApi.getPriceListYears()];
                        case 1:
                            priceListYearsResponse = _a.sent();
                            yearsForFilterDropdownArray = immutableSort(priceListYearsResponse, function (yearA, yearB) { return yearB.year - yearA.year; }).map(function (year) {
                                return {
                                    value: year.year,
                                    label: "20".concat(year.year),
                                };
                            });
                            priceListNamesParam = priceListYearsResponse.map(function (year) { return "20".concat(year.year); }).join(',');
                            return [4 /*yield*/, priceListsApi.getPrices(priceListNamesParam).catch(function (error) {
                                    console.error(error);
                                    setStatus('error');
                                })];
                        case 2:
                            priceListsResponse = _a.sent();
                            return [4 /*yield*/, priceListsApi.getModels(priceListNamesParam).catch(function (error) {
                                    console.error(error);
                                    setStatus('error');
                                })];
                        case 3:
                            modelsListResponse = _a.sent();
                            sortedPriceListNames = sortPriceLists(priceListsResponse);
                            priceListNameToPreview = getLastThreePrices(sortedPriceListNames);
                            ReactDOM.unstable_batchedUpdates(function () {
                                setFilterDropdownOptions(function (current) {
                                    return __spreadArray(__spreadArray([], current, true), yearsForFilterDropdownArray, true);
                                });
                                setSelectedFilter('all');
                                setPriceListData(priceListsResponse);
                                setModelsListData(modelsListResponse);
                                setPriceListNamesToPreview(priceListNameToPreview);
                                setStatus('success');
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        getDefaultData();
    }, []);
    function handlePublishSwitchChange(checked, modelId) {
        return __awaiter(this, void 0, void 0, function () {
            var arrayOfAllModelYears, priceListNamesParam, priceListsResponse, modelsListResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, priceListsApi.putPublish(modelId, checked ? 'publish' : 'unpublish')];
                    case 1:
                        _a.sent();
                        arrayOfAllModelYears = getArrayOfAllDropdownModelYears();
                        priceListNamesParam = selectedFilter === 'all' ? arrayOfAllModelYears.join(',') : "20".concat(selectedFilter);
                        return [4 /*yield*/, priceListsApi.getPrices(priceListNamesParam).catch(function (error) {
                                console.error(error);
                                setStatus('error');
                            })];
                    case 2:
                        priceListsResponse = _a.sent();
                        return [4 /*yield*/, priceListsApi.getModels(priceListNamesParam).catch(function (error) {
                                console.error(error);
                                setStatus('error');
                            })];
                    case 3:
                        modelsListResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setPriceListData(priceListsResponse);
                            setModelsListData(modelsListResponse);
                            setStatus('success');
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    var filteredPriceListNamesToPreview = priceListNamesToPreview.filter(function (priceListId) { return priceListData[priceListId]; });
    var visiblePriceLists = Object.fromEntries(filteredPriceListNamesToPreview.map(function (priceListId) {
        return [priceListId, priceListData[priceListId]];
    }));
    var modelsArray = Object.values(visiblePriceLists)
        .flat()
        .map(function (priceListValue) { return priceListValue.name; });
    var modelsSet = new Set(modelsArray);
    var deduplicatedModelsArray = Array.from(modelsSet);
    var deduplicatedModelsWithPno = deduplicatedModelsArray.map(function (model) {
        return {
            name: model,
            pno: modelsListData.find(function (car) { return car.name === model; }).pno
        };
    });
    var sortedDeduplicatedModelsWithPno = immutableSort(deduplicatedModelsWithPno, function (modelA, modelB) { return modelA.name.localeCompare(modelB.name); });
    function getLastThreePrices(sortedPriceListNames) {
        var lastThreePrices = [];
        if (sortedPriceListNames.length > 3) {
            lastThreePrices = sortedPriceListNames ? sortedPriceListNames.slice(sortedPriceListNames.length - 3) : [];
        }
        else {
            lastThreePrices = sortedPriceListNames;
        }
        return lastThreePrices;
    }
    function sortPriceLists(priceListsToSort) {
        var sortedPriceListNames = priceListsToSort
            ? immutableSort(Object.keys(priceListsToSort), function (priceListNameA, priceListNameB) {
                return priceListNameA.localeCompare(priceListNameB);
            })
            : [];
        return sortedPriceListNames;
    }
    var lastThreePriceList = getLastThreePrices(sortPriceLists(priceListData));
    function getArrayOfAllDropdownModelYears() {
        var arrayOfAllModelYears = filterDropdownOptions
            .filter(function (modelYear) { return modelYear.value !== 'all'; })
            .map(function (filter) { return filter.label; });
        return arrayOfAllModelYears;
    }
    return (React.createElement("div", { className: "modelsListWrapper" },
        React.createElement("div", null,
            React.createElement("div", { className: "modelsListFilterWrapper" },
                React.createElement(Label, null, "MY:"),
                React.createElement("div", { className: "modelsListFilterDropdown" },
                    React.createElement(Dropdown, { options: filterDropdownOptions, onChange: handleChangeSelectedFilter, name: "modelsPriceListsFilter", value: filterDropdownOptions.find(function (filter) { return filter.value === selectedFilter; }) }))),
            React.createElement("div", { className: "modelsListTableWrapper", role: "region" },
                React.createElement("table", { className: "modelsListTable" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { className: "modelsListTableHeadCell modelsListTableHeaderCell modelsListTableLeftTableHeadCell" },
                                React.createElement("div", { className: "addPriceListWrapper" },
                                    React.createElement("button", { className: "plusButton", onClick: function () { return handleChangeOpenPriceListDialog(true); }, type: "button", "data-testid": "plus-button" },
                                        React.createElement(Plus, { className: "plus" })))),
                            filteredPriceListNamesToPreview.map(function (priceListName) { return (React.createElement("th", { className: "modelsListTableHeadCell modelsListTableHeaderCell", key: priceListName }, priceListName)); }))),
                    React.createElement("tbody", null, sortedDeduplicatedModelsWithPno.map(function (model) { return (React.createElement("tr", { key: model.pno, className: "modelsListTableRow" },
                        React.createElement("th", { className: "modelsListTableHeadCell modelsListTableBodyCell modelListTableBodyHeaderCell modelsListTableLeftTableHeadCell" },
                            model.name,
                            " (",
                            model.pno,
                            ")"),
                        filteredPriceListNamesToPreview.map(function (priceListId) {
                            var currentVisiblePriceList = visiblePriceLists[priceListId];
                            var carModel = currentVisiblePriceList.find(function (carModel) { return carModel.pno === model.pno; });
                            return (React.createElement("td", { key: priceListId, className: "modelsListTableCell" },
                                React.createElement("div", { className: "modelsListTableCellContent ".concat(carModel &&
                                        !carModel.firstPublicationDate &&
                                        !carModel.published
                                        ? 'disabledModelListTableCellContent'
                                        : '') },
                                    carModel &&
                                        carModel.fromDate !== undefined &&
                                        formatDate(new Date(carModel.fromDate)) + ' - ',
                                    carModel &&
                                        carModel.toDate !== undefined &&
                                        formatDate(new Date(carModel.toDate)),
                                    carModel && (React.createElement(Switch, { checked: carModel.published, onCheckedChange: function (checked) {
                                            return handlePublishSwitchChange(checked, carModel.id);
                                        }, size: 'small' })))));
                        }))); })))),
            React.createElement(PriceListCheckboxesDialog, { key: selectedFilter, openPriceListDialog: openPriceListDialog, onCloseDialog: function () { return handleChangeOpenPriceListDialog(false); }, availablePriceLists: priceListData, lastThreePriceLists: lastThreePriceList, onChangeSelectedPriceLists: handleChangeSelectedPriceLists })),
        status === 'pending' ? React.createElement(Spinner, null) : null));
});
