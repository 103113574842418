import * as _ from 'lodash';
import SessionStorage from './sessionStorage';
var AuthToken = /** @class */ (function () {
    function AuthToken() {
    }
    AuthToken.prototype.set = function (authToken) {
        SessionStorage.set('authToken', authToken);
    };
    AuthToken.prototype.get = function () {
        return SessionStorage.get('authToken');
    };
    AuthToken.prototype.clear = function () {
        SessionStorage.remove('authToken');
    };
    AuthToken.prototype.exists = function () {
        return !!this.get();
    };
    AuthToken.prototype.decodeLogin = function () {
        return this.decodeJwtTokenPayload(this.get()).login;
    };
    AuthToken.prototype.decodeOrganizationId = function () {
        return this.decodeJwtTokenPayload(this.get()).organizationId;
    };
    AuthToken.prototype.decodeRoles = function () {
        return this.decodeJwtTokenPayload(this.get()).roles;
    };
    AuthToken.prototype.decodeApplicationFingerprint = function () {
        var authToken = this.get();
        if (authToken) {
            return this.decodeJwtTokenPayload(authToken).applicationFingerprint;
        }
        return undefined;
    };
    AuthToken.prototype.decodeJwtTokenPayload = function (token) {
        return JSON.parse(this.decodeBase64(_.split(token, '.')[1]));
    };
    AuthToken.prototype.decodeBase64 = function (payload) {
        return atob(payload);
    };
    return AuthToken;
}());
export default new AuthToken();
