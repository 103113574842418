var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Button } from 'components/button/Button.component';
import { DatePickerInput } from 'components/datepicker/DatePickerInput.component';
import { Dropdown } from 'components/dropdown/Dropdown.component';
import { Label } from 'components/label/Label.component';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
import SliderComponent from 'components/slider/Slider.component';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useFormattedCurrency } from 'utils/formatCurrency';
import { EyeIcon } from './EyeIcon';
import { EyeIconSlashed } from './EyeIconSlashed';
import { useStatus } from 'common/statusContext';
import { useFinancingApi } from './useFinancingApi';
import isBefore from 'date-fns/isBefore';
import { Switch } from 'components/switch/Switch';
import { GarbageIcon } from './GarbageIcon.component';
import './financing.css';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import SessionStorage from '../../../common/sessionStorage';
import { useAppError } from 'common/appErrorContext';
function mapInterestMethodCodesNames(methodCode) {
    switch (methodCode) {
        case 'VARIABLE':
            return 'zmienne';
        case 'FIXED':
            return 'stałe';
        default:
            return '';
    }
}
function calculateFromPercentage(value, percentage) {
    return value * (percentage / 100);
}
function mapMileageLimitToKmValue(mileageLimitArr, selected) {
    if (mileageLimitArr.length === 0) {
        return null;
    }
    return mileageLimitArr.filter(function (m) { return m.id === selected; }).map(function (m) { return m.limit; })[0] / 1000;
}
function mileageLimitSelectedExists(foundingProduct) {
    return foundingProduct.foundingProducts[0].mileageLimit.selected !== undefined;
}
function getMileageLimitSliderValues(mileageLimitArray) {
    if (mileageLimitArray.length > 0) {
        return mileageLimitArray
            .map(function (l) {
            return l.limit / 1000;
        })
            .sort(function (a, b) {
            return a - b;
        });
    }
    return [];
}
function greatestCommonDivisor(a, b) {
    if (a == 0)
        return b;
    return greatestCommonDivisor(b % a, a);
}
function findGreatestCommonDivisor(arr, n) {
    if (arr.length === 0) {
        return null;
    }
    var result = arr[0];
    for (var i = 1; i < n; i++) {
        result = greatestCommonDivisor(arr[i], result);
        if (result == 1) {
            return 1;
        }
    }
    return result;
}
function getDefaultDealerCommission(product) {
    return product.dealerCommission.find(function (dc) { return dc.value === product.defaultDealerCommission; });
}
function mapMileageLimitToIdValue(mileageLimitArr, kms) {
    if (mileageLimitArr.length === 0) {
        return null;
    }
    return mileageLimitArr.filter(function (m) { return m.limit === kms; }).map(function (m) { return m.id; })[0];
}
function getMileageLimitValue(availableMilageLimitValues, selectedMileageId) {
    return availableMilageLimitValues.find(function (limit) { return limit.id === selectedMileageId; }).limit;
}
export var Calculate = withCancellation(function (_a) {
    var foundingProducts = _a.foundingProducts, offer = _a.offer, offerId = _a.offerId, id = _a.id, forPrinting = _a.forPrinting, canDelete = _a.canDelete, calculationNumber = _a.calculationNumber, onPrintSwitchChange = _a.onPrintSwitchChange, onDeleteCalculationClick = _a.onDeleteCalculationClick, canScroll = _a.canScroll, handleChangeIsCalculationIdRegister = _a.handleChangeIsCalculationIdRegister, isClientCFM = _a.isClientCFM, handleOnCalculationError = _a.handleOnCalculationError, isCalculateError = _a.isCalculateError, foundingProductsConfiguration = _a.foundingProductsConfiguration;
    var setStatus = useStatus().setStatus;
    var financingApi = useFinancingApi();
    var calculateElement = useRef(null);
    // produkty mLeasingowe, opcje dropdown-u
    var _b = useState(undefined), productsDropdownList = _b[0], setProductsDropdownList = _b[1];
    // defaultowa wartość dla dropdown-u z produktami mLeasingowymi
    var _c = useState({
        value: undefined,
        label: undefined,
    }), productsDefaultInitialValue = _c[0], setProductsDefaultInitialValue = _c[1];
    // opcje do dropdown-u z liczbą rat
    var _d = useState(undefined), installmentsDropdownList = _d[0], setInstallmentsDropdownList = _d[1];
    // defaultowa wartość liczby rat
    var _e = useState({
        value: 0,
        label: 0,
    }), installmentsDefaultValue = _e[0], setInstallmentsDefaultValue = _e[1];
    // defaultowa waluta na start, do zaznaczenia radio
    var _f = useState('PLN'), currencyDefaultValue = _f[0], setCurrencyDefaultValue = _f[1];
    // możliwe waluty do radio grupy
    var _g = useState([
        {
            value: undefined,
            label: undefined,
        },
    ]), currenciesRadioGroupValues = _g[0], setCurrenciesRadioGroupValues = _g[1];
    // defaultowa wartość rodzaju oprocentowania dla zaznaczenia na start radio
    var _h = useState('VARIABLE'), interestRateDefaultValue = _h[0], setInterestRateDefaultValue = _h[1];
    // możliwe wartości rodzaju oprocentowania stałe/zmienne
    var _j = useState([
        {
            value: undefined,
            label: undefined,
        },
    ]), interestRateRadioGroupValues = _j[0], setInterestRateRadioGroupValues = _j[1];
    // planowana data protokołu odbioru, jednocześnie to data maksymalna dla opłaty wstępnej
    var _k = useState(undefined), protocolDeliveryDate = _k[0], setProtocolDeliveryDate = _k[1];
    // minimalna możliwa data protokołu odbioru
    var _l = useState(undefined), minProtocolDeliveryDate = _l[0], setMinProtocolDeliveryDate = _l[1];
    // data opłaty wstępnej
    var _m = useState(undefined), entryFeeDate = _m[0], setEntryFeeDate = _m[1];
    // minimalna możliwa data opłaty wstępnej
    var _o = useState(undefined), minEntryFeeDate = _o[0], setMinEntryFeeDate = _o[1];
    // wartości min i max do labelki nad sliderem, do pokazania przedziału opłaty wstępnej
    var _p = useState(undefined), entryFeeRatioLabel = _p[0], setEntryFeeRatioLabel = _p[1];
    // kwota opłaty wstępnej w netto, wyświetlana nad sliderem
    var _q = useState(0), entryFee = _q[0], setEntryFee = _q[1];
    // wartość wybranego procenta opłaty wstępnej na sliderze
    var _r = useState(undefined), entryFeeRatioSelectedValue = _r[0], setEntryFeeRatioSelectedValue = _r[1];
    // wartośli min i max i default dla slidera z opłatą wstępną, wartości w %
    var _s = useState({
        default: undefined,
        min: undefined,
        max: undefined,
    }), entryFeeRatioSliderValues = _s[0], setEntryFeeRatioSliderValues = _s[1];
    // boolean mówiący o tym czy jest to produkt cfm
    var _t = useState(undefined), cfmProduct = _t[0], setCfmProduct = _t[1];
    // tablica z wartościami rocznego limitu km
    var _u = useState(undefined), mileageLimitValuesArr = _u[0], setMileageLimitValuesArr = _u[1];
    // wartoś wybranego rocznego limitu km na sliderze wyrażony w tys. km
    var _v = useState(undefined), mileageLimitSelectedValue = _v[0], setMileageLimitSelectedValue = _v[1];
    // wartości min, max i default dla rocznego limitu km
    var _w = useState({
        default: undefined,
        min: undefined,
        max: undefined,
    }), mileageLimitSliderValues = _w[0], setMileageLimitSliderValues = _w[1];
    // krok dla slidera z rocznym limitem km, które są wyliczane tutaj na froncie
    var _x = useState(undefined), mileageLimitStep = _x[0], setMileageLimitStep = _x[1];
    // obiekt z wartościami min, max i default dla slidera z wartością końcową
    var _y = useState({
        default: undefined,
        min: undefined,
        max: undefined,
    }), finalValueRatioSliderValues = _y[0], setFinalValueRatioSliderValues = _y[1];
    // wartość wybranej wartości końcowej wyrażona w pln po przeliczeniu na froncie i wyświetlana w labelce slidera
    var _z = useState(0), finalValueRatioAmountValue = _z[0], setFinalValueRatioAmountValue = _z[1];
    // wartość wybranej wartości końcowej na sliderze w %
    var _0 = useState(undefined), finalValueRatioSelectedValue = _0[0], setFinalValueRatioSelectedValue = _0[1];
    // do kontrolowania pokazywania dropdown-u z prowizją
    var _1 = useState(true), eyeIconSlashed = _1[0], setEyeIconSlashed = _1[1];
    // opcje do dropdown-u z możliwymi wartościami prowizji dealera
    var _2 = useState([
        {
            value: undefined,
            label: undefined,
        },
    ]), dealerCommission = _2[0], setDealerCommission = _2[1];
    // wybrana wartość prowizji dla dealera
    var _3 = useState(undefined), dealerCommissionDefault = _3[0], setDealerCommissionDefault = _3[1];
    // boolean wskazujący czy była dokonana kalkulacja czy zaszły jakieś zmiany i trzeba odblokować guzik do kalkulajci
    var _4 = useState(false), isCalculateDisabled = _4[0], setIsCalculateDisabled = _4[1];
    // niesformatowana wartość opłaty wstępnej wyświetlanej po prawo w kalkulacji raty
    var _5 = useState({
        currency: undefined,
        value: 0,
    }), entryFeeDetail = _5[0], setEntryFeeDetail = _5[1];
    // niesformatowana wartość wartości końcowej wyświetlanej po prawo w kalkulacji raty
    var _6 = useState({
        currency: undefined,
        value: 0,
    }), finalValueDetail = _6[0], setFinalValueDetail = _6[1];
    // niesformatowana wartość raty miesięcznej netto, wyświetlanej po prawo w kalkulacji raty
    var _7 = useState({
        currency: undefined,
        value: 0,
    }), leaseInstalmentDetail = _7[0], setLeaseInstalmentDetail = _7[1];
    // wartości dla wybranego produktu
    var _8 = useState({
        productId: null,
        productName: null,
        installment: null,
        currency: null,
        interestRate: null,
        protocolDeliveryDate: null,
        entryFeeDate: null,
        entryFeeRatio: null,
        mileageLimit: null,
        finalValueRatio: null,
        dealerCommission: null,
        isCfmProduct: null,
        mileageLimitValue: null
    }), selectedProduct = _8[0], setSelectedProduct = _8[1];
    // obiekt z produktem
    var zpsProducts = useRef([]).current;
    // całkowity koszt, potrzebny do wyliczenia opłaty wstępnej i wartości końcowej
    var _9 = useState(undefined), totalPrice = _9[0], setTotalPrice = _9[1];
    // to koszt netto rzeczywisty z którego liczony jest leasing
    var _10 = useState(0), priceSentToLeasing = _10[0], setPriceSentToLeasing = _10[1];
    // suma kosztów
    var _11 = useState(0), totalLeaseValue = _11[0], setTotalLeaseValue = _11[1];
    var _12 = useState(null), errorDescription = _12[0], setErrorDescription = _12[1];
    var _13 = useAppError(), appError = _13.appError, setAppError = _13.setAppError;
    useEffect(function () {
        if (calculateElement.current && canScroll) {
            calculateElement.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        ReactDOM.unstable_batchedUpdates(function () {
            setProductsDropdownList(foundingProducts.foundingProducts.map(function (product) {
                return {
                    value: product.productId,
                    label: product.name,
                };
            }));
            setEyeIconSlashed(true);
            setTotalPrice(foundingProducts.totalPrice);
            var calculationsFromSessionStorage = SessionStorage.get('calculations');
            var getCalculationFromSessionStorageById = calculationsFromSessionStorage ? calculationsFromSessionStorage.find(function (calculate) { return calculate.id === id; }) : null;
            if (getCalculationFromSessionStorageById) {
                setIsCalculateDisabled(getCalculationFromSessionStorageById.isCalculationIdRegister);
                setProductsDefaultInitialValue(getCalculationFromSessionStorageById.parameters.productsDefaultInitialValue);
                var selectedProduct_1 = foundingProducts.foundingProducts.find(function (product) { return product.productId === getCalculationFromSessionStorageById.parameters.productsDefaultInitialValue.value; });
                setInstallmentsDropdownList(selectedProduct_1.installments.available.map(function (installment) {
                    return {
                        value: installment,
                        label: installment,
                    };
                }));
                setInstallmentsDefaultValue(getCalculationFromSessionStorageById.parameters.installments);
                setCurrenciesRadioGroupValues(selectedProduct_1.currencies.map(function (currency) {
                    return {
                        value: currency,
                        label: currency,
                    };
                }));
                setCurrencyDefaultValue(getCalculationFromSessionStorageById.parameters.currency);
                setInterestRateRadioGroupValues(selectedProduct_1.interestMethodCodes.map(function (code) {
                    return {
                        value: code,
                        label: mapInterestMethodCodesNames(code),
                    };
                }));
                setInterestRateDefaultValue(getCalculationFromSessionStorageById.parameters.interestRateDefaultValue);
                setProtocolDeliveryDate(new Date(getCalculationFromSessionStorageById.parameters.protocolDeliveryDate));
                setMinProtocolDeliveryDate(new Date(selectedProduct_1.minProtocolDeliveryDate));
                setEntryFeeDate(new Date(getCalculationFromSessionStorageById.parameters.entryFeeDate));
                setMinEntryFeeDate(new Date(selectedProduct_1.minEntryFeeDate));
                setEntryFeeRatioLabel('Opłata wstępna (' +
                    Number.parseInt(selectedProduct_1.entryFeeRatio.min) +
                    '-' +
                    Number.parseInt(selectedProduct_1.entryFeeRatio.max) +
                    '%)');
                setEntryFee(calculateFromPercentage(foundingProducts.totalPrice, Number.parseFloat(getCalculationFromSessionStorageById.parameters.entryFeeRatio)));
                setEntryFeeRatioSelectedValue(Number.parseFloat(getCalculationFromSessionStorageById.parameters.entryFeeRatio));
                setEntryFeeRatioSliderValues({
                    default: Number.parseFloat(getCalculationFromSessionStorageById.parameters.entryFeeRatio),
                    min: Number.parseFloat(selectedProduct_1.entryFeeRatio.min),
                    max: Number.parseFloat(selectedProduct_1.entryFeeRatio.max),
                });
                setCfmProduct(selectedProduct_1.cfmProduct);
                setMileageLimitValuesArr(selectedProduct_1.mileageLimit.available);
                var availableMilageLimitValues = selectedProduct_1.mileageLimit.available;
                setMileageLimitSelectedValue(mapMileageLimitToKmValue(availableMilageLimitValues, getCalculationFromSessionStorageById.parameters.mileageLimit));
                setMileageLimitSliderValues({
                    default: mileageLimitSelectedExists(foundingProducts)
                        ? mapMileageLimitToKmValue(availableMilageLimitValues, getCalculationFromSessionStorageById.parameters.mileageLimit)
                        : null,
                    min: availableMilageLimitValues.length > 0
                        ? Math.min.apply(Math, getMileageLimitSliderValues(availableMilageLimitValues)) : null,
                    max: availableMilageLimitValues.length > 0
                        ? Math.max.apply(Math, getMileageLimitSliderValues(availableMilageLimitValues)) : null,
                });
                setMileageLimitStep(findGreatestCommonDivisor(getMileageLimitSliderValues(availableMilageLimitValues), getMileageLimitSliderValues(availableMilageLimitValues).length));
                setFinalValueRatioSliderValues({
                    default: Number.parseFloat(getCalculationFromSessionStorageById.parameters.finalValueRatio),
                    min: Number.parseFloat(selectedProduct_1.finalValueRatio.min),
                    max: Number.parseFloat(selectedProduct_1.finalValueRatio.max),
                });
                setFinalValueRatioAmountValue(calculateFromPercentage(foundingProducts.totalPrice, Number.parseFloat(getCalculationFromSessionStorageById.parameters.finalValueRatio)));
                setFinalValueRatioSelectedValue(Number.parseFloat(getCalculationFromSessionStorageById.parameters.finalValueRatio));
                var getDealerCommission = selectedProduct_1.appeals.available
                    .map(function (appeal) {
                    return {
                        value: appeal.level,
                        label: appeal.dealerCommission,
                    };
                })
                    .sort(function (a, b) { return a.value - b.value; });
                setDealerCommission(getDealerCommission);
                setDealerCommissionDefault(getDealerCommission.find(function (c) { return c.value === getCalculationFromSessionStorageById.parameters.dealerCommission; }));
                setSelectedProduct({
                    productId: getCalculationFromSessionStorageById.parameters.productsDefaultInitialValue.value,
                    productName: getCalculationFromSessionStorageById.parameters.productsDefaultInitialValue.label,
                    installment: getCalculationFromSessionStorageById.parameters.installments.value,
                    currency: getCalculationFromSessionStorageById.parameters.currency,
                    interestRate: getCalculationFromSessionStorageById.parameters.interestRateDefaultValue,
                    protocolDeliveryDate: getCalculationFromSessionStorageById.parameters.protocolDeliveryDate,
                    entryFeeDate: getCalculationFromSessionStorageById.parameters.entryFeeDate,
                    entryFeeRatio: Number.parseFloat(getCalculationFromSessionStorageById.parameters.entryFeeRatio),
                    mileageLimit: getCalculationFromSessionStorageById.parameters.mileageLimit,
                    finalValueRatio: Number.parseFloat(getCalculationFromSessionStorageById.parameters.finalValueRatio),
                    dealerCommission: getCalculationFromSessionStorageById.parameters.dealerCommission,
                    isCfmProduct: selectedProduct_1.cfmProduct,
                    mileageLimitValue: getCalculationFromSessionStorageById.parameters.mileageLimitValue && getCalculationFromSessionStorageById.parameters.mileageLimitValue
                });
                setLeaseInstalmentDetail({
                    currency: getCalculationFromSessionStorageById.calculateResult.leaseInstalmentDetail.currencyCode,
                    value: getCalculationFromSessionStorageById.calculateResult.leaseInstalmentDetail.valueInCurrency,
                });
                setFinalValueDetail({
                    currency: getCalculationFromSessionStorageById.calculateResult.finalValueDetail.currencyCode,
                    value: getCalculationFromSessionStorageById.calculateResult.finalValueDetail.valueInCurrency,
                });
                setEntryFeeDetail({
                    currency: getCalculationFromSessionStorageById.calculateResult.entryFeeDetail.currencyCode,
                    value: getCalculationFromSessionStorageById.calculateResult.entryFeeDetail.valueInCurrency,
                });
                setPriceSentToLeasing(getCalculationFromSessionStorageById.calculateResult.priceSentToLeasing);
                setTotalLeaseValue((getCalculationFromSessionStorageById.calculateResult.totalLeaseValueDetail.value / getCalculationFromSessionStorageById.calculateResult.priceSentToLeasing) * 100);
            }
            else {
                setProductsDefaultInitialValue({
                    value: foundingProducts.foundingProducts[0].productId,
                    label: foundingProducts.foundingProducts[0].name,
                });
                setInstallmentsDropdownList(foundingProducts.foundingProducts[0].installments.available.map(function (installment) {
                    return {
                        value: installment,
                        label: installment,
                    };
                }));
                setInstallmentsDefaultValue({
                    value: foundingProducts.foundingProducts[0].installments.selected,
                    label: foundingProducts.foundingProducts[0].installments.selected,
                });
                setCurrenciesRadioGroupValues(foundingProducts.foundingProducts[0].currencies.map(function (currency) {
                    return {
                        value: currency,
                        label: currency,
                    };
                }));
                setInterestRateRadioGroupValues(foundingProducts.foundingProducts[0].interestMethodCodes.map(function (code) {
                    return {
                        value: code,
                        label: mapInterestMethodCodesNames(code),
                    };
                }));
                setProtocolDeliveryDate(new Date(foundingProducts.foundingProducts[0].protocolDeliveryDate));
                setMinProtocolDeliveryDate(new Date(foundingProducts.foundingProducts[0].minProtocolDeliveryDate));
                setEntryFeeDate(new Date(foundingProducts.foundingProducts[0].entryFeeDate));
                setMinEntryFeeDate(new Date(foundingProducts.foundingProducts[0].minEntryFeeDate));
                setEntryFeeRatioLabel('Opłata wstępna (' +
                    Number.parseInt(foundingProducts.foundingProducts[0].entryFeeRatio.min) +
                    '-' +
                    Number.parseInt(foundingProducts.foundingProducts[0].entryFeeRatio.max) +
                    '%)');
                setEntryFee(calculateFromPercentage(foundingProducts.totalPrice, Number.parseFloat(foundingProducts.foundingProducts[0].entryFeeRatio.default)));
                setEntryFeeRatioSelectedValue(Number.parseFloat(foundingProducts.foundingProducts[0].entryFeeRatio.default));
                setEntryFeeRatioSliderValues({
                    default: Number.parseFloat(foundingProducts.foundingProducts[0].entryFeeRatio.default),
                    min: Number.parseFloat(foundingProducts.foundingProducts[0].entryFeeRatio.min),
                    max: Number.parseFloat(foundingProducts.foundingProducts[0].entryFeeRatio.max),
                });
                setCfmProduct(foundingProducts.foundingProducts[0].cfmProduct);
                setMileageLimitValuesArr(foundingProducts.foundingProducts[0].mileageLimit.available);
                var availableMilageLimitValues = foundingProducts.foundingProducts[0].mileageLimit.available;
                setMileageLimitSelectedValue(mapMileageLimitToKmValue(availableMilageLimitValues, foundingProducts.foundingProducts[0].mileageLimit.selected));
                setMileageLimitSliderValues({
                    default: mileageLimitSelectedExists(foundingProducts)
                        ? mapMileageLimitToKmValue(availableMilageLimitValues, foundingProducts.foundingProducts[0].mileageLimit.selected)
                        : null,
                    min: availableMilageLimitValues.length > 0
                        ? Math.min.apply(Math, getMileageLimitSliderValues(availableMilageLimitValues)) : null,
                    max: availableMilageLimitValues.length > 0
                        ? Math.max.apply(Math, getMileageLimitSliderValues(availableMilageLimitValues)) : null,
                });
                setMileageLimitStep(findGreatestCommonDivisor(getMileageLimitSliderValues(availableMilageLimitValues), getMileageLimitSliderValues(availableMilageLimitValues).length));
                setFinalValueRatioSliderValues({
                    default: Number.parseFloat(foundingProducts.foundingProducts[0].finalValueRatio.default),
                    min: Number.parseFloat(foundingProducts.foundingProducts[0].finalValueRatio.min),
                    max: Number.parseFloat(foundingProducts.foundingProducts[0].finalValueRatio.max),
                });
                setFinalValueRatioAmountValue(calculateFromPercentage(foundingProducts.totalPrice, Number.parseFloat(foundingProducts.foundingProducts[0].finalValueRatio.default)));
                setFinalValueRatioSelectedValue(Number.parseFloat(foundingProducts.foundingProducts[0].finalValueRatio.default));
                var getDealerCommission = foundingProducts.foundingProducts[0].appeals.available
                    .map(function (appeal) {
                    return {
                        value: appeal.level,
                        label: appeal.dealerCommission,
                    };
                })
                    .sort(function (a, b) { return a.value - b.value; });
                setDealerCommission(getDealerCommission);
                setDealerCommissionDefault(getDealerCommission.find(function (c) { return c.value === foundingProducts.foundingProducts[0].appeals.defaultLevel; }));
                setSelectedProduct({
                    productId: foundingProducts.foundingProducts[0].productId,
                    productName: foundingProducts.foundingProducts[0].name,
                    installment: foundingProducts.foundingProducts[0].installments.selected,
                    currency: foundingProducts.foundingProducts[0].defaultCurrencyCode,
                    interestRate: foundingProducts.foundingProducts[0].defaultInterestMethodCode,
                    protocolDeliveryDate: foundingProducts.foundingProducts[0].protocolDeliveryDate,
                    entryFeeDate: foundingProducts.foundingProducts[0].entryFeeDate,
                    entryFeeRatio: Number.parseFloat(foundingProducts.foundingProducts[0].entryFeeRatio.default),
                    mileageLimit: foundingProducts.foundingProducts[0].mileageLimit.selected,
                    finalValueRatio: Number.parseFloat(foundingProducts.foundingProducts[0].finalValueRatio.default),
                    dealerCommission: foundingProducts.foundingProducts[0].appeals.defaultLevel,
                    isCfmProduct: foundingProducts.foundingProducts[0].cfmProduct,
                    mileageLimitValue: foundingProducts.foundingProducts[0].mileageLimit.available.length > 0 ? getMileageLimitValue(foundingProducts.foundingProducts[0].mileageLimit.available, foundingProducts.foundingProducts[0].mileageLimit.selected) : undefined
                });
                setEntryFeeDetail({ value: 0, currency: undefined });
                setFinalValueDetail({ value: 0, currency: undefined });
                setLeaseInstalmentDetail({ value: 0, currency: undefined });
            }
        });
        foundingProducts.foundingProducts.forEach(function (product) {
            zpsProducts.push({
                productId: product.productId,
                name: product.name,
                cfmProduct: product.cfmProduct,
                installments: product.installments.available.map(function (installment) {
                    return {
                        value: installment,
                        label: installment,
                    };
                }),
                defaultInstallment: {
                    value: product.installments.selected,
                    label: product.installments.selected,
                },
                currencies: product.currencies.map(function (currency) {
                    return {
                        value: currency,
                        label: currency,
                    };
                }),
                defaultCurrency: product.defaultCurrencyCode,
                interestRates: product.interestMethodCodes.map(function (code) {
                    return {
                        value: code,
                        label: mapInterestMethodCodesNames(code),
                    };
                }),
                defaultInterestRate: product.defaultInterestMethodCode,
                protocolDeliveryDate: new Date(product.protocolDeliveryDate),
                minProtocolDeliveryDate: new Date(product.minProtocolDeliveryDate),
                entryFeeDate: new Date(product.entryFeeDate),
                minEntryFeeDate: new Date(product.minEntryFeeDate),
                entryFeeRatio: {
                    min: Number.parseFloat(product.entryFeeRatio.min),
                    max: Number.parseFloat(product.entryFeeRatio.max),
                    default: Number.parseFloat(product.entryFeeRatio.default),
                },
                defaultMileageLimit: product.mileageLimit.selected ? product.mileageLimit.selected : undefined,
                mileageLimit: product.mileageLimit.available,
                finalValueRatio: {
                    min: Number.parseFloat(product.finalValueRatio.min),
                    max: Number.parseFloat(product.finalValueRatio.max),
                    default: Number.parseFloat(product.finalValueRatio.default),
                },
                dealerCommission: product.appeals.available
                    .map(function (appeal) {
                    return {
                        value: appeal.level,
                        label: appeal.dealerCommission,
                    };
                })
                    .sort(function (a, b) { return a.value - b.value; }),
                defaultDealerCommission: product.appeals.defaultLevel,
            });
        });
    }, []);
    useEffect(function () {
        if (appError && isCalculateError && !errorDescription) {
            setErrorDescription(getErrorDesc());
        }
    }, [appError, isCalculateError, errorDescription]);
    function changeFinalValueRatioAfterChangeProduct(product) {
        return __awaiter(this, void 0, void 0, function () {
            var finaleValueResponse_1, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, financingApi.changeFinalValueRatio(product.productId, offerId, product.defaultInstallment.value, product.protocolDeliveryDate, product.mileageLimit.length > 0
                                ? product.mileageLimit.find(function (limit) { return limit.id === product.defaultMileageLimit; }).id
                                : undefined)];
                    case 2:
                        finaleValueResponse_1 = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setStatus('success');
                            setFinalValueRatioSliderValues({
                                min: Number.parseFloat(finaleValueResponse_1.min),
                                max: Number.parseFloat(finaleValueResponse_1.max),
                                default: Number.parseFloat(finaleValueResponse_1.default),
                            });
                            setFinalValueRatioSelectedValue(Number.parseFloat(finaleValueResponse_1.default));
                            setSelectedProduct(function (selectedProduct) { return (__assign(__assign({}, selectedProduct), { finalValueRatio: Number.parseFloat(finaleValueResponse_1.default) })); });
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        handleOnCalculationError(id, true);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function productChangeHandler(value) {
        // zmiana produktu
        var product = zpsProducts.find(function (product) { return product.productId === value; });
        ReactDOM.unstable_batchedUpdates(function () {
            setIsCalculateDisabled(false);
            setEntryFeeRatioLabel('Opłata wstępna (' + product.entryFeeRatio.min + '-' + product.entryFeeRatio.max + '%)');
            // zmiana wybranego produktu
            setSelectedProduct({
                productId: product.productId,
                productName: product.name,
                installment: product.defaultInstallment.value,
                currency: product.defaultCurrency,
                interestRate: product.defaultInterestRate,
                protocolDeliveryDate: product.protocolDeliveryDate,
                entryFeeDate: product.entryFeeDate,
                entryFeeRatio: Number.parseFloat(product.entryFeeRatio.default),
                mileageLimit: product.defaultMileageLimit,
                finalValueRatio: Number.parseFloat(product.finalValueRatio.default),
                dealerCommission: product.defaultDealerCommission,
                isCfmProduct: product.cfmProduct,
                mileageLimitValue: product.defaultMileageLimit && getMileageLimitValue(product.mileageLimit, product.defaultMileageLimit)
            });
            // zmiana wartości początkowych wyświetlanych
            setCfmProduct(product.cfmProduct);
            setInstallmentsDropdownList(product.installments);
            setInstallmentsDefaultValue(product.defaultInstallment);
            setCurrenciesRadioGroupValues(product.currencies);
            setCurrencyDefaultValue(product.defaultCurrency);
            setInterestRateRadioGroupValues(product.interestRates);
            setInterestRateDefaultValue(product.defaultInterestRate);
            setProtocolDeliveryDate(product.protocolDeliveryDate);
            setMinProtocolDeliveryDate(product.minProtocolDeliveryDate);
            setEntryFeeDate(product.entryFeeDate);
            setMinEntryFeeDate(product.minEntryFeeDate);
            setEntryFeeRatioSliderValues({
                min: Number.parseFloat(product.entryFeeRatio.min),
                max: Number.parseFloat(product.entryFeeRatio.max),
                default: Number.parseFloat(product.entryFeeRatio.default),
            });
            setEntryFeeRatioSelectedValue(Number.parseFloat(product.entryFeeRatio.default));
            setMileageLimitValuesArr(product.mileageLimit);
            setMileageLimitStep(findGreatestCommonDivisor(getMileageLimitSliderValues(product.mileageLimit), getMileageLimitSliderValues(product.mileageLimit).length));
            setMileageLimitSelectedValue(mapMileageLimitToKmValue(product.mileageLimit, product.defaultMileageLimit));
            setMileageLimitSliderValues({
                min: product.mileageLimit.length > 0
                    ? Math.min.apply(Math, getMileageLimitSliderValues(product.mileageLimit)) : null,
                max: product.mileageLimit.length > 0
                    ? Math.max.apply(Math, getMileageLimitSliderValues(product.mileageLimit)) : null,
                default: product.defaultMileageLimit !== null
                    ? mapMileageLimitToKmValue(product.mileageLimit, product.defaultMileageLimit)
                    : 0,
            });
            setFinalValueRatioSliderValues({
                default: Number.parseFloat(product.finalValueRatio.default),
                min: Number.parseFloat(product.finalValueRatio.min),
                max: Number.parseFloat(product.finalValueRatio.max),
            });
            setFinalValueRatioSelectedValue(Number.parseFloat(product.finalValueRatio.default));
            changeFinalValueRatioAfterChangeProduct(product);
            setDealerCommission(product.dealerCommission);
            setDealerCommissionDefault(getDefaultDealerCommission(product));
            setEntryFee(calculateFromPercentage(totalPrice, Number.parseFloat(product.entryFeeRatio.default)));
            setFinalValueRatioAmountValue(calculateFromPercentage(totalPrice, Number.parseFloat(product.finalValueRatio.default)));
        });
    }
    function changeFinalValueRatio(product) {
        return __awaiter(this, void 0, void 0, function () {
            var finaleValueResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, financingApi.changeFinalValueRatio(product.productId, offerId, product.installment, new Date(product.protocolDeliveryDate), product.mileageLimit)];
                    case 1:
                        finaleValueResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setStatus('success');
                            setFinalValueRatioSliderValues({
                                min: Number.parseFloat(finaleValueResponse.min),
                                max: Number.parseFloat(finaleValueResponse.max),
                                default: Number.parseFloat(finaleValueResponse.default),
                            });
                            setFinalValueRatioSelectedValue(Number.parseFloat(finaleValueResponse.default));
                            setSelectedProduct(function (selectedProduct) { return (__assign(__assign({}, selectedProduct), { finalValueRatio: Number.parseFloat(finaleValueResponse.default) })); });
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function installmentsChangeHandler(value) {
        // zmiana ilości rat
        setIsCalculateDisabled(false);
        setInstallmentsDefaultValue({
            value: value,
            label: value,
        });
        setSelectedProduct(__assign(__assign({}, selectedProduct), { installment: value }));
        changeFinalValueRatio(__assign(__assign({}, selectedProduct), { installment: value }));
    }
    function currencyChangeHandler(value) {
        // zmiana waluty
        setIsCalculateDisabled(false);
        setCurrencyDefaultValue(value);
        setSelectedProduct(__assign(__assign({}, selectedProduct), { currency: value }));
    }
    function interestRateChangeHandler(value) {
        // zmiana rodzaju oprocentowania
        setIsCalculateDisabled(false);
        setInterestRateDefaultValue(value);
        setSelectedProduct(__assign(__assign({}, selectedProduct), { interestRate: value }));
    }
    function protocolDeliveryDateChangeHandler(value) {
        // zmiana planowanej daty protokołu odbioru w datepickerze
        setIsCalculateDisabled(false);
        setProtocolDeliveryDate(value);
        if (isBefore(new Date(value), new Date(entryFeeDate))) {
            setEntryFeeDate(value);
            setSelectedProduct(__assign(__assign({}, selectedProduct), { entryFeeDate: new Date(value), protocolDeliveryDate: new Date(value) }));
            changeFinalValueRatio(__assign(__assign({}, selectedProduct), { protocolDeliveryDate: value, entryFeeDate: value }));
        }
        else {
            setSelectedProduct(__assign(__assign({}, selectedProduct), { protocolDeliveryDate: value }));
            changeFinalValueRatio(__assign(__assign({}, selectedProduct), { protocolDeliveryDate: value }));
        }
    }
    function entryFeeDateChangeHandler(value) {
        // zmiana daty opłaty wstępnej w datepickerze
        setIsCalculateDisabled(false);
        setEntryFeeDate(value);
        setSelectedProduct(__assign(__assign({}, selectedProduct), { entryFeeDate: new Date(value) }));
    }
    function sliderEntryFeeOnChangeHandler(value) {
        // zmiana wartości opłaty wstępnej na sliderze
        setIsCalculateDisabled(false);
        setEntryFeeRatioSelectedValue(value[0]);
        setSelectedProduct(__assign(__assign({}, selectedProduct), { entryFeeRatio: value[0] }));
        setEntryFee(calculateFromPercentage(totalPrice, value[0]));
    }
    function sliderMileageLimitOnChangeHandler(value) {
        // zmiana wartości rocznego limitu na sliderze
        ReactDOM.unstable_batchedUpdates(function () {
            setIsCalculateDisabled(false);
            setMileageLimitSelectedValue(value[0]);
            setSelectedProduct(__assign(__assign({}, selectedProduct), { mileageLimit: mapMileageLimitToIdValue(mileageLimitValuesArr, value[0] * 1000), mileageLimitValue: value[0] * 1000 }));
        });
    }
    function sliderMileageLimitOnCommitHandler(value) {
        // zmiana wartości rocznego limitu na sliderze, na puszczenie slidera
        var newSelectedProduct = __assign(__assign({}, selectedProduct), { mileageLimit: mapMileageLimitToIdValue(mileageLimitValuesArr, value[0] * 1000), mileageLimitValue: value[0] * 1000 });
        ReactDOM.unstable_batchedUpdates(function () {
            setMileageLimitSelectedValue(value[0]);
            setSelectedProduct(newSelectedProduct);
        });
        changeFinalValueRatio(newSelectedProduct);
    }
    function sliderFinalValueRatioOnChangeHandler(value) {
        // zmiana wartości końcowej na sliderze
        setIsCalculateDisabled(false);
        setFinalValueRatioSelectedValue(value[0]);
        setSelectedProduct(__assign(__assign({}, selectedProduct), { finalValueRatio: value[0] }));
        setFinalValueRatioAmountValue(calculateFromPercentage(totalPrice, value[0]));
    }
    function eyeIconSlashedClickHandler() {
        // zmiana widoczności prowizji
        setEyeIconSlashed(function () { return !eyeIconSlashed; });
    }
    function getDealerCommissionLabel(value) {
        return dealerCommission.filter(function (dc) { return dc.value === value; }).map(function (commission) { return commission.label; });
    }
    function dealerCommissionChangeHandler(value) {
        // zmiana wartości prowizji dealera
        setIsCalculateDisabled(false);
        setDealerCommissionDefault({
            value: value,
            label: getDealerCommissionLabel(value)[0],
        });
        setSelectedProduct(__assign(__assign({}, selectedProduct), { dealerCommission: value }));
    }
    function reset() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                // resetowanie kontrolek
                productChangeHandler(selectedProduct.productId);
                return [2 /*return*/];
            });
        });
    }
    function calculate() {
        return __awaiter(this, void 0, void 0, function () {
            var calculateResult_1, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, financingApi.calculate(selectedProduct.productName, selectedProduct.productId, offerId, selectedProduct.installment, selectedProduct.protocolDeliveryDate, selectedProduct.mileageLimit, selectedProduct.entryFeeRatio, selectedProduct.finalValueRatio, selectedProduct.dealerCommission, selectedProduct.currency, interestRateDefaultValue, selectedProduct.entryFeeDate, selectedProduct.mileageLimitValue, id, forPrinting, calculationNumber)];
                    case 2:
                        calculateResult_1 = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setStatus('success');
                            var calculationFromSessionStorage = SessionStorage.get('calculations');
                            var parametersToSetInSessionStorage = {
                                productsDefaultInitialValue: {
                                    label: selectedProduct.productName,
                                    value: selectedProduct.productId
                                },
                                installments: {
                                    label: selectedProduct.installment,
                                    value: selectedProduct.installment
                                },
                                currency: selectedProduct.currency,
                                interestRateDefaultValue: interestRateDefaultValue,
                                protocolDeliveryDate: selectedProduct.protocolDeliveryDate,
                                entryFeeDate: selectedProduct.entryFeeDate,
                                entryFeeRatio: selectedProduct.entryFeeRatio,
                                mileageLimit: selectedProduct.mileageLimit,
                                mileageLimitValue: selectedProduct.mileageLimitValue,
                                finalValueRatio: selectedProduct.finalValueRatio,
                                dealerCommission: selectedProduct.dealerCommission
                            };
                            if (calculationFromSessionStorage) {
                                var editedCalculationIndex = calculationFromSessionStorage.findIndex(function (calculate) { return calculate.id === id; });
                                if (editedCalculationIndex !== -1) {
                                    SessionStorage.set('calculations', __spreadArray(__spreadArray(__spreadArray([], calculationFromSessionStorage.slice(0, editedCalculationIndex), true), [
                                        __assign(__assign({}, calculationFromSessionStorage[editedCalculationIndex]), { isCalculationIdRegister: true, parameters: parametersToSetInSessionStorage })
                                    ], false), calculationFromSessionStorage.slice(editedCalculationIndex + 1), true));
                                }
                                else {
                                    SessionStorage.set('calculations', __spreadArray(__spreadArray([], calculationFromSessionStorage, true), [
                                        {
                                            id: id,
                                            isCalculationIdRegister: true,
                                            forPrinting: forPrinting,
                                            parameters: parametersToSetInSessionStorage
                                        }
                                    ], false));
                                }
                            }
                            else {
                                SessionStorage.set('calculations', [
                                    {
                                        id: id,
                                        isCalculationIdRegister: true,
                                        forPrinting: forPrinting,
                                        parameters: parametersToSetInSessionStorage
                                    }
                                ]);
                            }
                            setCalculateResultToSessionStorage(calculateResult_1);
                            setLeaseInstalmentDetail({
                                currency: calculateResult_1.leaseInstalmentDetail.currencyCode,
                                value: calculateResult_1.leaseInstalmentDetail.valueInCurrency,
                            });
                            setFinalValueDetail({
                                currency: calculateResult_1.finalValueDetail.currencyCode,
                                value: calculateResult_1.finalValueDetail.valueInCurrency,
                            });
                            setEntryFeeDetail({
                                currency: calculateResult_1.entryFeeDetail.currencyCode,
                                value: calculateResult_1.entryFeeDetail.valueInCurrency,
                            });
                            setPriceSentToLeasing(calculateResult_1.priceSentToLeasing);
                            setTotalLeaseValue((calculateResult_1.totalLeaseValueDetail.value / calculateResult_1.priceSentToLeasing) * 100);
                        });
                        handleChangeIsCalculationIdRegister(id);
                        setIsCalculateDisabled(true);
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        handleOnCalculationError(id, true);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function setCalculateResultToSessionStorage(calculateResult) {
        var calculationFromSessionStorage = SessionStorage.get('calculations');
        if (calculateResult && calculationFromSessionStorage) {
            var editedCalculationIndex = calculationFromSessionStorage.findIndex(function (calculate) { return calculate.id === id; });
            SessionStorage.set('calculations', __spreadArray(__spreadArray(__spreadArray([], calculationFromSessionStorage.slice(0, editedCalculationIndex), true), [
                __assign(__assign({}, calculationFromSessionStorage[editedCalculationIndex]), { calculateResult: calculateResult })
            ], false), calculationFromSessionStorage.slice(editedCalculationIndex + 1), true));
        }
    }
    var formattedEntryFee = useFormattedCurrency(entryFee);
    var formattedFinalValue = useFormattedCurrency(finalValueRatioAmountValue);
    var formattedEntryFeeDetail = useFormattedCurrency(entryFeeDetail.value, entryFeeDetail.currency);
    var formattedFinalValueDetail = useFormattedCurrency(finalValueDetail.value, finalValueDetail.currency);
    var monthlyInstallment = useFormattedCurrency(leaseInstalmentDetail.value, leaseInstalmentDetail.currency);
    var formattedPriceSentToLeasing = useFormattedCurrency(priceSentToLeasing);
    var formattedTotalLeaseValue = new Intl.NumberFormat('pl-PL', { maximumFractionDigits: 2 }).format(totalLeaseValue);
    function shouldShowTotalFees(productName) {
        var selectedConfiguration = foundingProductsConfiguration.find(function (configuration) { return configuration.name === productName; });
        return selectedConfiguration && selectedConfiguration.isTotalFeesVisible;
    }
    function getErrorDesc() {
        return appError === null || appError === void 0 ? void 0 : appError.errorDesc;
    }
    function resetCalculationError() {
        handleOnCalculationError(id, false);
        setAppError(undefined);
        setErrorDescription(null);
    }
    return errorDescription ? (React.createElement("div", { className: 'financing_calculation_error_wrapper' },
        React.createElement("div", { className: "financing_calculation_header_wrapper" },
            React.createElement("div", { className: "financing_calculation_header" },
                "Kalkulacja nr ",
                calculationNumber),
            React.createElement("div", { className: "financing_calculation_actions", style: { height: '27px' } })),
        React.createElement("div", { className: 'financing_calculation_error_message' },
            React.createElement("div", { className: "errorHeader", style: { marginTop: '25px' } }, errorDescription),
            React.createElement("div", { className: 'dailyPlan', style: { marginTop: '25px' } },
                React.createElement("button", { className: "transparentBtn", style: { fontSize: '20px' }, onClick: resetCalculationError }, "Powr\u00F3\u0107 do poprzedniej kalkulacji"))),
        React.createElement("div", { className: "financing_horizontal_line" }))) :
        React.createElement("div", { ref: calculateElement },
            React.createElement("div", { className: "financing_calculation_header_wrapper" },
                React.createElement("div", { className: "financing_calculation_header" },
                    "Kalkulacja nr ",
                    calculationNumber),
                React.createElement("div", { className: "financing_calculation_actions" },
                    React.createElement("div", { className: "calculation_printing_action" },
                        React.createElement("span", { className: "".concat(isClientCFM ? 'calculation_printing_disabled_action' : '') }, "Drukuj"),
                        React.createElement(Switch, { checked: forPrinting, onCheckedChange: function (checked) {
                                onPrintSwitchChange(checked, id, 'CAR');
                            }, disabled: isClientCFM })),
                    React.createElement("div", { className: "calculation_delete_wrapper", "data-testid": 'delete-calculator-wrapper' }, canDelete ? (React.createElement("button", { type: "button", className: "calculation_delete_button", onClick: onDeleteCalculationClick, name: 'deleteCalculator' },
                        React.createElement(GarbageIcon, null))) : null))),
            React.createElement("div", { className: "financing_main__wrapper" },
                React.createElement("div", { className: "financing_left-center-div__wrapper" },
                    React.createElement("div", { className: "financing_left-div__wrapper" },
                        React.createElement("div", { className: "financing_label-input__wrapper financing_padding-7-10-8-10 financing_border-top-1 financing_border-bottom" },
                            React.createElement("div", { className: "financing_display-flex-row-flex-start-flex-start financing_width-30-percent" },
                                React.createElement(Label, null, "Produkt")),
                            React.createElement("div", { className: "financing_width-70-percent" },
                                React.createElement(Dropdown, { options: productsDropdownList, keyValue: productsDefaultInitialValue.value, defaultValue: productsDefaultInitialValue, onChange: productChangeHandler, name: "mleasing-products" }))),
                        React.createElement("div", { className: "financing_label-input__wrapper financing_padding-8-10 financing_border-bottom" },
                            React.createElement("div", { className: "financing_display-flex-row-flex-start-flex-start financing_width-30-percent" },
                                React.createElement(Label, null, "Liczba rat")),
                            React.createElement("div", { className: "financing_display-flex-row-flex-start-flex-start financing_width-70-percent" },
                                React.createElement("div", { className: "financing_width-30-percent" },
                                    React.createElement(Dropdown, { options: installmentsDropdownList, keyValue: installmentsDefaultValue.value.toString(), defaultValue: installmentsDefaultValue, onChange: installmentsChangeHandler, name: "mleasing-installments" })))),
                        React.createElement("div", { className: "financing_label-input__wrapper financing_border-bottom", "data-testid": 'currency-radio-group-wrapper' },
                            React.createElement("div", { className: "financing_display-flex-row-flex-start-flex-start financing_width-40-percent financing_padding-14-10" },
                                React.createElement(Label, null, "Waluta")),
                            React.createElement("div", { className: "financing_width-60-percent" },
                                React.createElement(RadioGroup.FinancingRoot, { value: currencyDefaultValue, onValueChange: currencyChangeHandler }, currenciesRadioGroupValues.map(function (currency, index) { return (React.createElement(RadioGroup.LayoutHorizontal, { key: index },
                                    React.createElement(RadioGroup.Item, { value: currency.value, id: currency.value },
                                        React.createElement(RadioGroup.Indicator, null)),
                                    React.createElement(Label, { htmlFor: currency.value }, currency.label))); })))),
                        React.createElement("div", { className: "financing_label-input__wrapper financing_border-bottom", "data-testid": 'interest-rate-wrapper' },
                            React.createElement("div", { className: "financing_display-flex-row-flex-start-flex-start financing_width-40-percent financing_padding-14-10 " },
                                React.createElement(Label, null, "Oprocentowanie")),
                            React.createElement("div", { className: "financing_width-60-percent" },
                                React.createElement(RadioGroup.FinancingRoot, { value: interestRateDefaultValue, onValueChange: interestRateChangeHandler }, interestRateRadioGroupValues.map(function (interestRate, index) { return (React.createElement(RadioGroup.LayoutHorizontal, { key: index },
                                    React.createElement(RadioGroup.Item, { value: interestRate.value, id: interestRate.value },
                                        React.createElement(RadioGroup.Indicator, null)),
                                    React.createElement(Label, { htmlFor: interestRate.value }, interestRate.label))); })))),
                        React.createElement("div", { className: "financing_label-input__wrapper financing_padding-8-10 financing_border-bottom" },
                            React.createElement("div", { className: "financing_display-flex-row-flex-start-flex-start financing_width-70-percent" },
                                React.createElement(Label, null, "Planowana data protoko\u0142u odbioru")),
                            React.createElement("div", { className: "financing_datepicker__wrapper financing_width-30-percent", "data-testid": "protocol-delivery-date-datepicker" },
                                React.createElement(DatePickerInput, { date: protocolDeliveryDate, minDate: minProtocolDeliveryDate, dateOnChangeHandler: protocolDeliveryDateChangeHandler }))),
                        React.createElement("div", { className: "financing_label-input__wrapper financing_padding-8-10 financing_border-bottom" },
                            React.createElement("div", { className: "financing_display-flex-row-flex-start-flex-start financing_width-70-percent" },
                                React.createElement(Label, null, "Data op\u0142aty wst\u0119pnej")),
                            React.createElement("div", { className: "financing_datepicker__wrapper financing_width-30-percent", "data-testid": "entry-fee-date-datepicker" },
                                React.createElement(DatePickerInput, { date: entryFeeDate, minDate: minEntryFeeDate, maxDate: protocolDeliveryDate, dateOnChangeHandler: entryFeeDateChangeHandler })))),
                    React.createElement("div", { className: "financing_center-div__wrapper" },
                        React.createElement("div", { className: "financing_width-100-percent financing_border-bottom" },
                            React.createElement("div", { className: "financing_entry-fee__wrapper financing_border-top-1 financing_padding-13-10-0-10" },
                                React.createElement("div", null,
                                    React.createElement(Label, null, entryFeeRatioLabel)),
                                React.createElement("div", null,
                                    React.createElement("span", { "data-testid": 'entry-fee-value' },
                                        formattedEntryFee,
                                        " netto"))),
                            React.createElement("div", { className: "financing_slider__wrapper", "data-testid": "entry-fee-ratio-slider" },
                                React.createElement(SliderComponent, { printedValue: entryFeeRatioSelectedValue + '%', value: [entryFeeRatioSelectedValue], minValue: entryFeeRatioSliderValues.min, maxValue: entryFeeRatioSliderValues.max, step: 1, onValueChange: sliderEntryFeeOnChangeHandler }))),
                        cfmProduct && mileageLimitValuesArr.length > 0 && (React.createElement("div", { className: "financing_width-100-percent financing_border-bottom" },
                            React.createElement("div", { className: "financing_mileage-limit__wrapper financing_padding-14-10-0-10" },
                                React.createElement("div", null,
                                    React.createElement(Label, null, "Limit kilometr\u00F3w (\u0142\u0105czny przez okres trwania umowy)"))),
                            React.createElement("div", { className: "financing_slider__wrapper", "data-testid": "mileage-limit-slider" },
                                React.createElement(SliderComponent, { printedValue: mileageLimitSelectedValue + ' tys.', value: [mileageLimitSelectedValue], minValue: mileageLimitSliderValues.min, maxValue: mileageLimitSliderValues.max, step: mileageLimitStep, onValueChange: sliderMileageLimitOnChangeHandler, onValueCommit: sliderMileageLimitOnCommitHandler })))),
                        finalValueRatioSliderValues.min !== finalValueRatioSliderValues.max && (React.createElement("div", { className: "financing_width-100-percent financing_border-bottom" },
                            React.createElement("div", { className: "financing_final-value__wrapper financing_padding-14-10-0-10" },
                                React.createElement("div", null,
                                    React.createElement(Label, null, "Warto\u015B\u0107 ko\u0144cowa")),
                                React.createElement("div", null,
                                    React.createElement("span", { "data-testid": 'final-value-value' }, formattedFinalValue))),
                            React.createElement("div", { className: "financing_slider__wrapper", "data-testid": "final-value-ratio-slider" },
                                React.createElement(SliderComponent, { printedValue: finalValueRatioSelectedValue + '%', value: [finalValueRatioSelectedValue], minValue: finalValueRatioSliderValues.min, maxValue: finalValueRatioSliderValues.max, step: 1, onValueChange: sliderFinalValueRatioOnChangeHandler })))))),
                React.createElement("div", { className: "financing_right-div" },
                    React.createElement("div", null,
                        React.createElement("div", { className: "financing_right-div__header" },
                            React.createElement("div", { className: "financing_header-title" },
                                React.createElement("span", null, "KALKULACJA RATY")),
                            React.createElement("div", { className: "financing_header-eye" },
                                React.createElement("span", { className: "financing_header-eye__wrapper", onClick: eyeIconSlashedClickHandler }, eyeIconSlashed ? React.createElement(EyeIconSlashed, null) : React.createElement(EyeIcon, null))),
                            React.createElement("div", { className: "financing_header-commission" }, !eyeIconSlashed && (React.createElement("div", { className: "financing_dealer-commission__wrapper" },
                                React.createElement("div", { className: "dealer_commission-label__wrapper" },
                                    React.createElement(Label, null, "Prowizja")),
                                React.createElement("div", { className: "dealer_commission-dropdown__wrapper", "data-testid": 'dealer-commission-wrapper' },
                                    React.createElement(Dropdown, { options: dealerCommission, keyValue: dealerCommissionDefault.label, defaultValue: dealerCommissionDefault, onChange: dealerCommissionChangeHandler, name: "mleasing-dealer-commission" }))))))),
                    React.createElement("div", { className: "financing_right-div__footer" },
                        React.createElement("div", { style: { width: '100%' } },
                            React.createElement("div", { className: "financing_label-input__wrapper financing_padding-14-16" },
                                React.createElement(Label, null, "PNO:"),
                                " ",
                                offer.vehicle.pno12),
                            React.createElement("div", { className: "financing_label-input__wrapper financing_padding-14-16" },
                                React.createElement(Label, null, "Eurocode:"),
                                " ",
                                offer.vehicle.euroCode)),
                        React.createElement("div", { className: "financing_summary-amount__wrapper financing_border-bottom" },
                            React.createElement("div", { className: "financing_summary-amount__content financing_padding-14-16" },
                                React.createElement(Label, null, "Warto\u015B\u0107 netto przedmiotu finansowania:"),
                                React.createElement("div", { className: "financing_price_detail__wrapper" }, isCalculateDisabled ? React.createElement("span", null, formattedPriceSentToLeasing) : null)),
                            React.createElement("div", { className: "financing_summary-amount__content financing_padding-14-16" },
                                React.createElement(Label, null, "Op\u0142ata wst\u0119pna netto:"),
                                React.createElement("div", { className: "financing_price_detail__wrapper" }, isCalculateDisabled ? React.createElement("span", null, formattedEntryFeeDetail) : null)),
                            selectedProduct && !selectedProduct.isCfmProduct &&
                                React.createElement("div", { className: "financing_summary-amount__content financing_padding-14-16" },
                                    React.createElement(Label, null, "Warto\u015B\u0107 ko\u0144cowa netto:"),
                                    React.createElement("div", { className: "financing_price_detail__wrapper" }, isCalculateDisabled ? React.createElement("span", null, formattedFinalValueDetail) : null)),
                            (selectedProduct && shouldShowTotalFees(selectedProduct.productName)) &&
                                React.createElement("div", { className: "financing_summary-amount__content financing_padding-14-16" },
                                    React.createElement(Label, null, "Suma op\u0142at:"),
                                    React.createElement("div", { className: "financing_price_detail__wrapper" }, isCalculateDisabled ? React.createElement("span", null,
                                        formattedTotalLeaseValue,
                                        " %") : null)),
                            React.createElement("div", { className: "financing_summary-amount__content financing_padding-10-16" },
                                React.createElement(Label, null, "Rata miesi\u0119czna netto:"),
                                React.createElement("div", { className: "financing_netto-price-summary__wrapper" }, isCalculateDisabled ? React.createElement("span", null, monthlyInstallment) : null)))),
                    React.createElement("div", null,
                        React.createElement("div", { className: "financing_reset-calculate-buttons__wrapper" },
                            React.createElement("div", { className: "financing_width-42-percent" },
                                React.createElement(Button, { onClick: reset, variant: "secondary", transparentBackground: true }, "Zresetuj")),
                            React.createElement("div", { className: "financing_width-42-percent" },
                                React.createElement(Button, { onClick: calculate, disabled: isCalculateDisabled }, "Przelicz")))))),
            React.createElement("div", { className: "financing_horizontal_line" }));
});
