// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .configurationCodeWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: 'Volvo Novum';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

#new-dol .configurationCodeWrapper a {
    width: 16px;
    height: 16px;
}

#new-dol .copyIcon {
    all: unset;
    width: 16px;
    height: 16px;
}

#new-dol .copyHoverCardContent {
    width: 81px;
    background-color: var(--dropdown-bg);
    font-family: 'Volvo Novum';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    padding-top: 2px;
    padding-bottom: 2px;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.3));
}`, "",{"version":3,"sources":["webpack://./src/components/configurationCode/configurationCode.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,0BAA0B;IAC1B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,oCAAoC;IACpC,0BAA0B;IAC1B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,mDAAmD;AACvD","sourcesContent":[".configurationCodeWrapper {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    font-family: 'Volvo Novum';\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 18px;\n}\n\n.configurationCodeWrapper a {\n    width: 16px;\n    height: 16px;\n}\n\n.copyIcon {\n    all: unset;\n    width: 16px;\n    height: 16px;\n}\n\n.copyHoverCardContent {\n    width: 81px;\n    background-color: var(--dropdown-bg);\n    font-family: 'Volvo Novum';\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 18px;\n    text-align: center;\n    padding-top: 2px;\n    padding-bottom: 2px;\n    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.3));\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
