var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect } from 'react';
import { Label } from '../../../components/label/Label.component';
import './financing.css';
import { useAppError } from 'common/appErrorContext';
import { SectionHeader } from 'components/sectionHeader/SectionHeader';
import { Checkbox } from 'components/checkbox/Checkbox.component';
import { Calculate } from './Calculate.component';
import { Plus } from 'components/accessories/Plus.comonent';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
var ERROR_CODES = ['eurocode-not-exist', 'token-mLeasing', 'mLeasing-error', 'api-error-zps'];
export var Financing = withCancellation(function (_a) {
    var offerId = _a.offerId, offer = _a.offer, drives = _a.drives, onClientCFMChange = _a.onClientCFMChange, calculations = _a.calculations, foundingProducts = _a.foundingProducts, handleOnAddCalculation = _a.handleOnAddCalculation, handleOnPrintSwitchChange = _a.handleOnPrintSwitchChange, handleOnDeleteCalculation = _a.handleOnDeleteCalculation, handleEditCalculation = _a.handleEditCalculation, foundingProductsConfiguration = _a.foundingProductsConfiguration;
    var isClientCFM = offer.printOptions.includes('CFM');
    var _b = useAppError(), appError = _b.appError, setAppError = _b.setAppError;
    function isFinancingError() {
        return ERROR_CODES.some(function (e) { return e.includes(appError === null || appError === void 0 ? void 0 : appError.errorId); });
    }
    function getErrorDesc() {
        return appError === null || appError === void 0 ? void 0 : appError.errorDesc;
    }
    useEffect(function () {
        isFinancingError();
    }, [appError]);
    function handleChangeIsCalculationIdRegister(calculationId) {
        handleEditCalculation(calculationId, 'isCalculationIdRegister', true);
    }
    function clientCFMHandler(checked) {
        return __awaiter(this, void 0, void 0, function () {
            var _i, calculations_1, calculation;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        onClientCFMChange(checked);
                        _i = 0, calculations_1 = calculations;
                        _a.label = 1;
                    case 1:
                        if (!(_i < calculations_1.length)) return [3 /*break*/, 6];
                        calculation = calculations_1[_i];
                        if (!checked) return [3 /*break*/, 3];
                        return [4 /*yield*/, handleOnPrintSwitchChange(false, calculation.id, 'CAR')];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, handleOnPrintSwitchChange(true, calculation.id, 'CAR')];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 1];
                    case 6: return [2 /*return*/];
                }
            });
        });
    }
    var selectedDriveData = drives.find(function (drive) { return drive.powerTrainId === offer.vehicle.powerTrainId; });
    var drive = selectedDriveData.displayName.split(',')[0];
    function handleOnCalculationError(id, value) {
        handleEditCalculation(id, 'isCalculateError', value);
    }
    var isSomeCalculationWithError = calculations && calculations.some(function (calculation) { return calculation.isCalculateError === true; });
    return isFinancingError() && !isSomeCalculationWithError ? (React.createElement("div", null,
        React.createElement("div", { className: "row", style: { marginTop: '104px' } },
            React.createElement("img", { src: "/assets/images/new-dol/volvo_error_car.png", alt: "volvo_car" })),
        React.createElement("hr", null),
        React.createElement("div", { className: "errorHeader", style: { marginTop: '25px' } }, getErrorDesc()))) : foundingProducts && foundingProducts.foundingProducts.length < 1 ? (React.createElement("div", null,
        React.createElement("div", { className: "row", style: { marginTop: '104px' } },
            React.createElement("img", { src: "/assets/images/new-dol/volvo_error_car.png", alt: "volvo_car" })),
        React.createElement("hr", null),
        React.createElement("div", { className: "errorHeader", style: { marginTop: '25px' } }, "Brak aktywnych produkt\u00F3w finansowych"))) : foundingProducts ? (React.createElement("div", { className: "financing" },
        React.createElement("div", { className: "clientCFM_wrapper" },
            React.createElement(SectionHeader, null,
                React.createElement("div", { className: "financing_clientCFM_header_wrapper", "data-testid": "financing_clientCFM_header_wrapper" },
                    React.createElement("div", null,
                        "kalkulacja finansowania twojego volvo ",
                        offer.vehicle.name,
                        " ",
                        drive),
                    React.createElement("div", { className: "clientCFM_checkbox_wrapper" },
                        React.createElement(Checkbox.Root, { id: "clientCFM", value: "CFM", checked: isClientCFM, onCheckedChange: function (checked) { return clientCFMHandler(checked); } },
                            React.createElement(Checkbox.Indicator, null)),
                        React.createElement(Label, { htmlFor: "clientCFM" }, "Klient CFM / Konsument"))))),
        calculations
            ? calculations.map(function (calculation, index) { return (React.createElement(Calculate, { key: calculation.id, foundingProducts: foundingProducts, offer: offer, offerId: offerId, id: calculation.id, forPrinting: calculation.forPrinting, canDelete: calculations.length > 1, calculationNumber: index + 1, onPrintSwitchChange: function (checked) { return handleOnPrintSwitchChange(checked, calculation.id, 'CAR'); }, onDeleteCalculationClick: function () {
                    handleOnDeleteCalculation(calculation.id, 'CAR');
                }, canScroll: calculations.length > 1, handleChangeIsCalculationIdRegister: handleChangeIsCalculationIdRegister, isClientCFM: isClientCFM, handleOnCalculationError: handleOnCalculationError, isCalculateError: calculation.isCalculateError, foundingProductsConfiguration: foundingProductsConfiguration })); })
            : null,
        React.createElement("div", { className: "financing_add_calculation_wrapper" },
            React.createElement("button", { type: "button", className: "financing_add_calculation_button", onClick: function () { return handleOnAddCalculation('CAR'); } },
                React.createElement(Plus, { className: "plus" }),
                React.createElement("span", null, "Dodaj kalkulacj\u0119 finansowania auta"))))) : null;
});
