// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .textAreaWrapper {
padding-inline: 16px;
width: 100%;
height: 100%;
}

#new-dol .textAreaWrapper textarea {
    padding: 16px;
    border: 1px solid var(--line-light-grey);
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    height: 100%;
}

#new-dol .textAreaWrapper textarea::placeholder {
    color: var(--text-light-grey);
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 20px;
}

#new-dol .textAreaWrapper textarea:focus {
    outline: none;
}

#new-dol .textAreaDisableResize {
    resize: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/textArea/textArea.css"],"names":[],"mappings":"AAAA;AACA,oBAAoB;AACpB,WAAW;AACX,YAAY;AACZ;;AAEA;IACI,aAAa;IACb,wCAAwC;IACxC,+BAA+B;IAC/B,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,6BAA6B;IAC7B,+BAA+B;IAC/B,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".textAreaWrapper {\npadding-inline: 16px;\nwidth: 100%;\nheight: 100%;\n}\n\n.textAreaWrapper textarea {\n    padding: 16px;\n    border: 1px solid var(--line-light-grey);\n    font-family: var(--font-family);\n    font-size: 14px;\n    line-height: 20px;\n    width: 100%;\n    height: 100%;\n}\n\n.textAreaWrapper textarea::placeholder {\n    color: var(--text-light-grey);\n    font-family: var(--font-family);\n    font-size: 14px;\n    line-height: 20px;\n}\n\n.textAreaWrapper textarea:focus {\n    outline: none;\n}\n\n.textAreaDisableResize {\n    resize: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
