import { useHttp } from 'http/httpService';
import SessionStorage from 'common/sessionStorage';
import { creatorContextPath } from '../CreatorContextPath';
export function useExteriorApi() {
    var http = useHttp();
    var context = SessionStorage.get('employeeLeasingMode') ? 'employeeLeasing' : 'creator';
    return {
        getExterior: function (carModelId, token) {
            return http
                .get("/api/".concat(creatorContextPath(context), "/car-body/").concat(carModelId, "?token=").concat(token))
                .then(function (response) { return response.data; });
        },
        getExteriorVcp: function (carModelId, token) {
            return http
                .get("/api/".concat(creatorContextPath(context), "/car-body/vcp/").concat(carModelId, "?token=").concat(token))
                .then(function (response) { return response.data; });
        },
        getColor: function (token, versionToken, carModelId) {
            return http
                .get("api/".concat(creatorContextPath(context), "/car/images/").concat(carModelId, "?token=").concat(token, "&versionToken=").concat(versionToken))
                .then(function (response) { return response.data; });
        },
        getColorVcp: function (token, versionToken, carModelId) {
            return http
                .get("api/".concat(creatorContextPath(context), "/car/images/").concat(carModelId, "/vcp?token=").concat(token, "&versionToken=").concat(versionToken))
                .then(function (response) { return response.data; });
        },
        getColorByPno34: function (pno34) {
            return http
                .get("api/".concat(creatorContextPath(context), "/car/images/pno34/").concat(pno34))
                .then(function (response) { return response.data; });
        },
        getColorByPno34Vcp: function (pno34) {
            return http
                .get("api/".concat(creatorContextPath(context), "/car/images/pno34/").concat(pno34, "/vcp"))
                .then(function (response) { return response.data; });
        }
    };
}
