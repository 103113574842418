import React from 'react';
import * as RadixTabs from '@radix-ui/react-tabs';
import './carPreviewTabs.css';
function Root(_a) {
    var defaultValue = _a.defaultValue, children = _a.children;
    return (React.createElement(RadixTabs.Root, { defaultValue: defaultValue, className: "carPreviewTabsRoot" }, children));
}
function List(_a) {
    var children = _a.children;
    return React.createElement(RadixTabs.List, { className: "carPreviewTabsList" }, children);
}
// value from Trigger should match with the value from Content
function Trigger(_a) {
    var value = _a.value, children = _a.children;
    return (React.createElement(RadixTabs.Trigger, { value: value, className: "carPreviewTabsTrigger" }, children));
}
function Content(_a) {
    var value = _a.value, children = _a.children;
    return (React.createElement(RadixTabs.Content, { value: value, className: "carPreviewTabsContent" }, children));
}
export var CarPreviewTabs = {
    Root: Root,
    List: List,
    Trigger: Trigger,
    Content: Content,
};
