import { Button } from 'components/button/Button.component';
import { Dialog } from 'components/dialog/Dialog.component';
import React from 'react';
import './confirmDialog.css';
export function ConfirmDialog(_a) {
    var message = _a.message, open = _a.open, onCancel = _a.onCancel, onConfirm = _a.onConfirm;
    return (React.createElement(Dialog.RootOpen, { open: open },
        React.createElement(Dialog.Content, null,
            React.createElement("div", { className: 'confirmDialogWrapper' },
                React.createElement("div", { className: 'confirmDialogMessage' }, message),
                React.createElement("div", { className: "dialogImportActionButtonsWrapper" },
                    React.createElement("div", { className: "dialogImportActionButton" },
                        React.createElement(Button, { type: "button", onClick: onConfirm }, "tak")),
                    React.createElement("div", { className: "dialogImportActionButton" },
                        React.createElement(Button, { type: "button", variant: "secondary", onClick: onCancel }, "nie")))))));
}
