var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import './datepicker.css';
import { CalendarRangeIcon } from './CalendarRangeIcon';
function DateRangePicker(_a) {
    var dates = _a.dates, onDateRangeChange = _a.onDateRangeChange, disabled = _a.disabled, minDate = _a.minDate, _b = _a.filterWeekend, filterWeekend = _b === void 0 ? true : _b;
    var days = ['NDZ.', 'PON.', 'WT.', 'ŚR.', 'CZW.', 'PT.', 'SB.'];
    var months = ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];
    var locale = {
        localize: {
            day: function (n) { return days[n]; },
            month: function (n) { return months[n]; }
        },
        formatLong: {
            date: function () { return 'mm/dd/yyyy'; }
        }
    };
    var isWeekday = function (date) {
        var day = date.getDay();
        return day !== 0;
    };
    var CustomInput = forwardRef(function (props, ref) {
        return (React.createElement("div", { className: "dateRangePickerCustomInputWrapper" },
            React.createElement("input", __assign({}, props, { className: "dateRangePickerCustomInput", ref: ref })),
            React.createElement(CalendarRangeIcon, { strokeColor: props.value === '' ? '#6D6D6D' : '#141414', className: "dateRangePickerCalendarIcon" })));
    });
    return (React.createElement(DatePicker, { selectsRange: true, onChange: onDateRangeChange, startDate: dates[0], endDate: dates[1], disabled: disabled, minDate: minDate, 
        // @ts-ignore
        locale: locale, filterDate: filterWeekend ? isWeekday : null, customInput: React.createElement(CustomInput, null), dateFormat: "dd.MM.yyyy", placeholderText: "Wybierz zakres", calendarStartDay: 1 }));
}
export { DateRangePicker };
