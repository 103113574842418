import { AmountInput } from 'components/amountInput/AmountInput.component';
import { TextInput } from 'components/textInput/TextInput.component';
import React, { useState } from 'react';
import { Minus } from './Minus.component';
import { Plus } from './Plus.comonent';
import './accessories.css';
import { List } from 'components/list/List.component';
import { TextAmount } from 'components/textAmount/TextAmount';
import uuid from 'react-uuid';
export function Accessories(_a) {
    var accessories = _a.accessories, onAccessoryAdd = _a.onAccessoryAdd, onAccessoryDelete = _a.onAccessoryDelete, placeholder = _a.placeholder, validationMessage = _a.validationMessage;
    var _b = useState(false), addingAccessory = _b[0], setAddingAccessory = _b[1];
    var _c = useState(true), isValidate = _c[0], setIsValidate = _c[1];
    var _d = useState(''), validateMessage = _d[0], setValidateMessage = _d[1];
    var _e = useState(''), newAccessoryName = _e[0], setNewAccessoryName = _e[1];
    var _f = useState(0), newAccessoryAmount = _f[0], setNewAccessoryAmount = _f[1];
    function checkNameAndAmount() {
        return newAccessoryName !== '' && newAccessoryAmount !== 0;
    }
    function handleClickPlusButton() {
        clearValidate();
        if (addingAccessory) {
            if (checkNameAndAmount()) {
                onAccessoryAdd({ name: newAccessoryName, price: newAccessoryAmount, id: uuid() }, newAccessoryAmount);
                clearNameAndAmount();
                setAddingAccessory(true);
            }
            else {
                setIsValidate(false);
                setValidateMessage(validationMessage);
            }
        }
        else {
            setAddingAccessory(true);
        }
    }
    function handleClickMinusButton(accessoryId) {
        if (addingAccessory) {
            clearNameAndAmount();
            clearValidate();
            setAddingAccessory(false);
        }
        else {
            onAccessoryDelete(accessoryId);
        }
    }
    function clearValidate() {
        setIsValidate(true);
        setValidateMessage('');
    }
    function clearNameAndAmount() {
        setNewAccessoryName('');
        setNewAccessoryAmount(0);
    }
    function onNewAccessoryNameChange(event) {
        setNewAccessoryName(event.target.value);
        clearValidate();
    }
    function onNewAccessoryAmountChange(values) {
        var _a;
        setNewAccessoryAmount((_a = values.floatValue) !== null && _a !== void 0 ? _a : 0);
        clearValidate();
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "accessoriesLabel" }, addingAccessory || accessories.length > 0 ? 'Cena sprzedaży' : ''),
        React.createElement(List.Root, null,
            accessories.length > 0
                ? accessories.map(function (accessory) { return (React.createElement(List.Item, { height: "fixed", key: accessory.id, isSelected: true },
                    React.createElement("div", { className: "accessoryWrapper" },
                        React.createElement("button", { className: "minusButton", onClick: function () { return handleClickMinusButton(accessory.id); }, type: "button", "data-testid": "minus-button" },
                            React.createElement(Minus, { className: "minus" })),
                        React.createElement("div", null, accessory.name),
                        React.createElement("div", null,
                            React.createElement(TextAmount, null, accessory.price))))); })
                : null,
            addingAccessory ? (React.createElement(List.Item, { height: "fixed" },
                React.createElement("div", { className: "newAccessoryWrapper" },
                    React.createElement("button", { className: "minusButton", onClick: function () { return handleClickMinusButton(); }, type: "button", "data-testid": "minus-button" },
                        React.createElement(Minus, { className: "minus" })),
                    React.createElement(TextInput, { name: "accessory_".concat(accessories.length), value: newAccessoryName, onChange: onNewAccessoryNameChange, id: "accessory_".concat(accessories.length), placeholder: placeholder, validate: !isValidate }),
                    React.createElement(AmountInput, { value: newAccessoryAmount, onValueChange: onNewAccessoryAmountChange, validate: !isValidate })))) : null,
            React.createElement(List.Item, { height: "fixed" },
                React.createElement("div", { className: "addAccessoryWrapper" },
                    React.createElement("button", { className: "plusButton", onClick: handleClickPlusButton, type: "button", "data-testid": "plus-button" },
                        React.createElement(Plus, { className: "plus" })),
                    !isValidate ? React.createElement("div", { className: "validateMessage" }, validateMessage) : null)))));
}
