import React from 'react';
export function CalendarRangeIcon(_a) {
    var className = _a.className, _b = _a.strokeColor, strokeColor = _b === void 0 ? '#141414' : _b;
    return React.createElement("svg", { className: className, width: "20", height: "20", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M19.5 3.00012H22.5C23 3.00012 23.5 3.50012 23.5 4.00012V22.0001C23.5 22.5001 23 23.0001 22.5 23.0001H1.5C0.9 23.0001 0.5 22.5001 0.5 22.0001V4.00012C0.5 3.50012 0.9 3.00012 1.5 3.00012H4.5", stroke: strokeColor, strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M6.5 1.00012V5.00012", stroke: strokeColor, strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M17.5 1.00012V5.00012", stroke: strokeColor, strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M11.5 9.00012H12.5", stroke: strokeColor, strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M16.5 9.00012H17.5", stroke: strokeColor, strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M6.5 14.0001H7.5", stroke: strokeColor, strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M11.5 14.0001H12.5", stroke: strokeColor, strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M16.5 14.0001H17.5", stroke: strokeColor, strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M6.5 19.0001H7.5", stroke: strokeColor, strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M11.5 19.0001H12.5", stroke: strokeColor, strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M8.5 3.00012H15.5", stroke: strokeColor, strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }));
}
