import React from 'react';
import './checkbox.css';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { TickIcon } from './TickIcon.component';
import { TextAmount } from 'components/textAmount/TextAmount';
function Root(_a) {
    var defaultChecked = _a.defaultChecked, checked = _a.checked, value = _a.value, onCheckedChange = _a.onCheckedChange, id = _a.id, children = _a.children, disabled = _a.disabled;
    return React.createElement(RadixCheckbox.Root, { className: "checkboxRoot", defaultChecked: defaultChecked, checked: checked, id: id, value: value, onCheckedChange: onCheckedChange, disabled: disabled }, children);
}
function Layout(_a) {
    var children = _a.children;
    return React.createElement("div", { className: "layoutCheckbox" }, children);
}
function LayoutWithAmount(_a) {
    var price = _a.price, children = _a.children;
    return React.createElement("div", { className: 'layoutCheckboxWithAmount' },
        React.createElement(Layout, null, children),
        React.createElement(TextAmount, { bold: true }, price));
}
function Indicator() {
    return React.createElement(RadixCheckbox.Indicator, { className: "checkboxIndicator" },
        React.createElement(TickIcon, null));
}
function Placeholder() {
    return React.createElement("div", { className: 'placeholderCheckbox' });
}
export var Checkbox = {
    Root: Root,
    Layout: Layout,
    LayoutWithAmount: LayoutWithAmount,
    Indicator: Indicator,
    Placeholder: Placeholder
};
