import React, { useEffect, useState } from 'react';
import { StartOfferHeader } from './startOfferHeader.component';
import { Dropdown } from 'components/dropdown/Dropdown.component';
import { Button } from 'components/button/Button.component';
import { TextInput } from 'components/textInput/TextInput.component';
import { useStartOffer } from './useStartOffer';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { useLocation } from 'react-router-dom';
export var StartOfferFromStock = withCancellation(function () {
    var _a = useState([]), availableYears = _a[0], setAvailableYears = _a[1];
    var _b = useState([]), availableModels = _b[0], setAvailableModels = _b[1];
    var _c = useState(''), selectedYear = _c[0], setSelectedYear = _c[1];
    var _d = useState(''), selectedModel = _d[0], setSelectedModel = _d[1];
    var _e = useState(''), configurationId = _e[0], setConfigurationId = _e[1];
    var location = useLocation();
    var StartOffer = useStartOffer();
    function isCreateButtonDisabled() {
        return !selectedModel;
    }
    function isModelDropdownDisabled() {
        if (availableYears.length < 2 && !selectedYear) {
            return true;
        }
        else {
            return !selectedYear;
        }
    }
    function handleChangeYear(value) {
        setSelectedYear(value);
        StartOffer.getModels(value).then(function (models) {
            setAvailableModels(models);
        });
    }
    function handleChangeModel(value) {
        setSelectedModel(value);
    }
    function dropdownYearsOptions() {
        return availableYears.map(function (year) {
            return {
                value: year,
                label: year,
            };
        }).sort(function (a, b) { return b.label.localeCompare(a.label); });
    }
    function dropdownModelsOptions() {
        return availableModels.map(function (model) {
            if ('name' in model) {
                return {
                    value: model.name,
                    label: model.name.toUpperCase(),
                };
            }
            else {
                return {
                    value: model.named,
                    label: model.named.toUpperCase(),
                };
            }
        }).sort(function (a, b) { return a.label.localeCompare(b.label); });
    }
    function handleCreate(event) {
        event.preventDefault();
        var formData = new FormData(event.currentTarget);
        var modelYear = formData.get('modelYear');
        var model = formData.get('model');
        var searchParams = new URLSearchParams();
        searchParams.set('year', modelYear ? modelYear : selectedYear);
        searchParams.set('model', model);
        searchParams.set('context', location.state.context);
        searchParams.set('clientId', location.state.clientId);
        if (location.state.partyId) {
            searchParams.set('partyId', location.state.partyId);
        }
        window.location.hash = '#/creator?' + searchParams.toString();
    }
    function handleChangeConfigurationId(event) {
        setConfigurationId(event.target.value);
    }
    function handleSearchConfiguration(event) {
        event.preventDefault();
        var formData = new FormData(event.currentTarget);
        var configurationId = formData.get('configurationId');
        var searchParams = new URLSearchParams();
        searchParams.set('configurationId', configurationId);
        searchParams.set('context', location.state.context);
        searchParams.set('clientId', location.state.clientId);
        if (location.state.partyId) {
            searchParams.set('partyId', location.state.partyId);
        }
        window.location.hash = '#/creator?' + searchParams.toString();
    }
    useEffect(function () {
        StartOffer.getYears().then(function (years) {
            if (years.length === 1) {
                setSelectedYear(years[0]);
                StartOffer.getModels(years[0]).then(function (models) {
                    setAvailableModels(models);
                });
            }
            else {
                setAvailableYears(years);
            }
        });
    }, []);
    return (React.createElement("div", { className: "startOfferWrapper" },
        React.createElement(StartOfferHeader, { text: "nowa oferta" }),
        React.createElement("div", { className: "startOfferContentWrapper" },
            React.createElement("form", { className: "startOfferSelects", onSubmit: handleCreate, "data-testid": 'start-offer-selects-form' },
                availableYears.length < 2 ? null : (React.createElement("div", { className: "startOfferDropdown", "data-testid": 'years-dropdown' },
                    React.createElement(Dropdown, { options: dropdownYearsOptions(), onChange: handleChangeYear, placeholder: "Wybierz rok modelowy", name: "modelYear" }))),
                React.createElement("div", { className: "startOfferDropdown", "data-testid": 'models-dropdown' },
                    React.createElement(Dropdown, { options: dropdownModelsOptions(), onChange: handleChangeModel, placeholder: "Wybierz model", disabled: isModelDropdownDisabled(), name: "model" })),
                React.createElement("div", { className: "statrOfferButton" },
                    React.createElement(Button, { size: 32, disabled: isCreateButtonDisabled(), type: "submit" }, "utw\u00F3rz"))),
            React.createElement("div", { className: "startOfferText" }, "lub"),
            React.createElement("form", { className: "startOfferConfigurationWrapper", onSubmit: handleSearchConfiguration },
                React.createElement("div", { className: "startOfferConfigurationId" },
                    React.createElement(TextInput, { name: "configurationId", value: configurationId, id: "configurationId", onChange: handleChangeConfigurationId, placeholder: "Podaj numer konfiguracji" })),
                React.createElement("div", { className: "statrOfferButton" },
                    React.createElement(Button, { size: 32, disabled: !configurationId, type: "submit" }, "wyszukaj")))),
        React.createElement("img", { src: "/assets/images/new-dol/background_start_offer.png", alt: "", className: "startOfferBackground" })));
});
