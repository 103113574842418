import React from 'react';
import './carDamageOCIssues.css';
export function HeadRow() {
    return (React.createElement("div", { className: "grid headRow" },
        React.createElement("div", { "data-testid": "head-row-client-column", className: "clientColumn" }, "Klient"),
        React.createElement("div", { "data-testid": "head-row-date-column", className: "issueColumn" }, "Zg\u0142oszenie"),
        React.createElement("div", { "data-testid": "head-row-car-column", className: "carColumn" }, "Samoch\u00F3d"),
        React.createElement("div", { "data-testid": "head-row-attachment-column", className: "attachmentColumn" }, "Plik"),
        React.createElement("div", { "data-testid": "head-row-request-column", className: "requestColumn" }, "Tre\u015B\u0107 zg\u0142oszenia"),
        React.createElement("div", { "data-testid": "head-row-pickup-column", className: "statusColumn" }, "Status")));
}
