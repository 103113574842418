import { withCancellation } from 'common/requestCancellation/requestCancellation';
import React from 'react';
import { formatDate } from 'utils/formatDate';
import { DatePickerInputWithIcon } from 'components/datepicker/DatePickerInputWithIcon';
import './applicantData.css';
export var EditApplicantData = withCancellation(function (_a) {
    var applicantSection = _a.applicantSection, applicationId = _a.applicationId, editApplicantSection = _a.editApplicantSection;
    var applicantData = applicantSection.applicantData;
    var newValidTo = editApplicantSection.newValidTo, handleChangeValidToDate = editApplicantSection.handleChangeValidToDate;
    return (applicantData ?
        React.createElement("div", { className: "applicantDataWrapper" },
            React.createElement("div", { className: "applicantDataSection" },
                React.createElement("div", { className: "applicantDataSectionLabel" }, "Data wniosku:"),
                React.createElement("div", { className: "editApplicantDataSectionValue", "data-testid": "applicant-data-form-date" }, applicantData ? formatDate(new Date(applicantData.applicationDate)) : null)),
            React.createElement("div", { className: "applicantDataSection" },
                React.createElement("div", { className: "applicantDataSectionLabel" }, "Data wa\u017Cno\u015Bci:"),
                React.createElement("div", { className: 'editApplicantDataSectionValue' },
                    React.createElement("div", { className: "clientSectionRequestDatePicker", "data-testid": "applicant-section-validation-date" },
                        React.createElement(DatePickerInputWithIcon, { date: newValidTo, dateOnChangeHandler: handleChangeValidToDate, filterWeekend: false, minDate: new Date(applicantData.validTo) })))),
            React.createElement("div", { className: "applicantDataSection" },
                React.createElement("div", { className: "applicantDataSectionLabel" }, "Nazwa dealera:"),
                React.createElement("div", { className: "editApplicantDataSectionValue", "data-testid": "applicant-data-dealer" }, applicantData ? applicantData.organizationName : null)),
            React.createElement("div", { className: "applicantDataSection" },
                React.createElement("div", { className: "applicantDataSectionLabel" }, "Opiekun Strony:"),
                React.createElement("div", { className: "editApplicantDataSectionValue", "data-testid": "applicant-data-assistant" }, applicantData ? applicantData.applicantName : null)),
            applicationId && (React.createElement("div", { className: "applicantDataSection" },
                React.createElement("div", { className: "applicantDataSectionLabel" }, "Numer wniosku:"),
                React.createElement("div", { className: "editApplicantDataSectionValue", "data-testid": "applicant-data-special-offer-number" }, applicationId))))
        : null);
});
