// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .rangeOfModelsList {
    width: 100%;
}

#new-dol .modelElement {
    display: grid;
    grid-template-columns: 200px 400px;
    gap: 300px;
    align-items: center;
}

#new-dol .modelElementCheckbox {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

#new-dol .modelElementDate {
    display: flex;
    gap: 8px;
    align-items: center;
}

#new-dol .modelElementDateFrom {
    display: flex;
    gap: 48px;
}

#new-dol .modelElementDatePicker {
    width: 128px;
}

#new-dol .rangeOfModelsActionsWrapper {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    padding-top: 24px;
}

#new-dol .rangeOfModelsButtonWrapper {
    width: 160px;
}`, "",{"version":3,"sources":["webpack://./src/pages/priceListImport/priceListImportContainer/rangeOfModels/rangeOfModels.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,kCAAkC;IAClC,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".rangeOfModelsList {\n    width: 100%;\n}\n\n.modelElement {\n    display: grid;\n    grid-template-columns: 200px 400px;\n    gap: 300px;\n    align-items: center;\n}\n\n.modelElementCheckbox {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    cursor: pointer;\n}\n\n.modelElementDate {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n}\n\n.modelElementDateFrom {\n    display: flex;\n    gap: 48px;\n}\n\n.modelElementDatePicker {\n    width: 128px;\n}\n\n.rangeOfModelsActionsWrapper {\n    display: flex;\n    gap: 8px;\n    justify-content: flex-end;\n    padding-top: 24px;\n}\n\n.rangeOfModelsButtonWrapper {\n    width: 160px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
