import React from 'react';
import { Dialog } from 'components/dialog/Dialog.component';
import { Button } from 'components/button/Button.component';
import './infoDialog.css';
export function InfoDialog(_a) {
    var message = _a.message, open = _a.open, onConfirm = _a.onConfirm;
    return (React.createElement(Dialog.RootOpen, { open: open },
        React.createElement(Dialog.Content, null,
            React.createElement("div", { className: 'infoDialogWrapper' },
                React.createElement("div", { className: 'infoDialogMessage' }, message),
                React.createElement("div", { className: "dialogImportActionButtonsWrapper" },
                    React.createElement("div", { className: "dialogImportActionButton" },
                        React.createElement(Button, { type: "button", onClick: onConfirm }, "ok")))))));
}
