var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Dialog } from '../../../components/dialog/Dialog.component';
import { Button } from '../../../components/button/Button.component';
import React, { useEffect, useState } from 'react';
import './diff.css';
export function Diff(_a) {
    var _b, _c, _d, _e;
    var offer = _a.offer, canDiffShow = _a.canDiffShow, handleChangeOffer = _a.handleChangeOffer;
    var _f = useState(false), diff = _f[0], setDiff = _f[1];
    function clearDifferent() {
        handleChangeOffer(__assign(__assign({}, offer), { diff: {
                addedElements: [''],
                subtractedElements: [''],
                differentVersion: false
            } }));
    }
    function handleClose() {
        setDiff(false);
        clearDifferent();
    }
    useEffect(function () {
        var _a, _b;
        if ((((_a = offer === null || offer === void 0 ? void 0 : offer.diff) === null || _a === void 0 ? void 0 : _a.differentVersion) && canDiffShow)) {
            setDiff((_b = offer === null || offer === void 0 ? void 0 : offer.diff) === null || _b === void 0 ? void 0 : _b.differentVersion);
        }
        else {
            setDiff(false);
        }
    }, [offer, canDiffShow]);
    return (React.createElement("div", { style: { position: 'absolute', top: '30px', right: '-43px' } },
        React.createElement(Dialog.RootOpen, { open: diff },
            React.createElement(Dialog.Content, null,
                React.createElement(Dialog.CarPreviewContentLayout, { isDiffDialog: true },
                    React.createElement("div", { className: "diff_header" },
                        React.createElement("div", null, "NAST\u0104PI\u0141Y DODATKOWE ZMIANY W KONFIGURACJI"),
                        React.createElement("div", { className: 'diff_header_subtitels' },
                            React.createElement("div", { className: 'diff_header_subtracted' }, "ODJ\u0118TO:"),
                            React.createElement("div", { style: { width: '1px' } }),
                            React.createElement("div", { className: 'diff_header_added' }, "DODANO:"))),
                    React.createElement("div", { className: "diff_main__wrapper" },
                        React.createElement("div", { className: "diff_column-div__wrapper" }, (_c = (_b = offer === null || offer === void 0 ? void 0 : offer.diff) === null || _b === void 0 ? void 0 : _b.subtractedElements) === null || _c === void 0 ? void 0 : _c.map(function (el, index) {
                            return (React.createElement("div", { className: "diff_element_subtracted", key: index }, el));
                        })),
                        React.createElement("div", { className: "diff_column-div__wrapper_line" }),
                        React.createElement("div", { className: "diff_column-div__wrapper", style: { paddingRight: '12px' } }, (_e = (_d = offer === null || offer === void 0 ? void 0 : offer.diff) === null || _d === void 0 ? void 0 : _d.addedElements) === null || _e === void 0 ? void 0 : _e.map(function (el, index) {
                            return (React.createElement("div", { className: "diff_element_added", key: index }, el));
                        }))),
                    React.createElement("hr", { className: "horizontal" }),
                    React.createElement("div", { className: "div_row_button" },
                        React.createElement(Button, { size: 40, onClick: function () { return handleClose(); } }, "OK")))))));
}
;
