// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .sectionHeader {
    width: 100%;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding: 20px 16px;
    background-color: var(--light-grey);
}

#new-dol .nameUppercase {
    text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/components/sectionHeader/sectionHeader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,+BAA+B;IAC/B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,mCAAmC;AACvC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".sectionHeader {\n    width: 100%;\n    font-family: var(--font-family);\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 24px;\n    padding: 20px 16px;\n    background-color: var(--light-grey);\n}\n\n.nameUppercase {\n    text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
