var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { useStatus } from 'common/statusContext';
import { Chip } from 'components/chip/Chip.component';
import { ChipPlaceholder } from 'components/chip/ChipPlaceholder.component';
import { Spinner } from 'components/spinner/Spinner';
import { sameMembers } from 'pages/priceListImport/priceListImportContainer/rangeOfModels/RangeOfModels.component';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory, useParams } from 'react-router-dom';
import { formatDate } from 'utils/formatDate';
import { immutableSort } from 'utils/immutableSort';
import { FinancialProductConfigurationTab } from '../configurationTabContent/FInancialProductConfigurationTab.component';
import { FinancialProductExclusionsTab } from '../exclusionsTabContent/FinancialProductExclusionsTab.component';
import '../financialProducts.css';
import { useFinancialProductsApi } from '../useFinancialProductsApi';
import SessionStorage from 'common/sessionStorage';
import { FinancialProductsTabs } from '../financialProductsTabs/FinancialProductsTabs.component';
import { FinancialProductDisclaimerTab } from '../disclaimerTabContent/FinancialProductDisclaimerTab.component';
import { ConfirmDialog } from 'pages/priceListImport/confirmDialog/ConfirmDialog.component';
import { useDisclaimerTab } from '../disclaimerTabContent/useDisclaimerTab';
import { useConfigurationTab } from '../configurationTabContent/useConfigurationTab';
import { useExclusionsTab } from '../exclusionsTabContent/useExclusionsTab';
var cancelPopupMessage = 'Czy na pewno chcesz opuścić ekran nie zapisując zmian?';
export var FinancialProductPreview = withCancellation(function () {
    var _a = useState(undefined), productDetails = _a[0], setProductDetails = _a[1];
    var _b = useState(false), openConfirmDialog = _b[0], setOpenConfirmDialog = _b[1];
    var history = useHistory();
    var financialProductsApi = useFinancialProductsApi();
    var productId = useParams().productId;
    var _c = useStatus(), status = _c.status, setStatus = _c.setStatus;
    var _d = useState(''), tabDefaultValue = _d[0], setTabDefaultValue = _d[1];
    function handleChangeProductDetails(newProductDetails) {
        setProductDetails(newProductDetails);
    }
    var _e = useDisclaimerTab({ productDetails: productDetails, productId: productId, handleChangeProductDetails: handleChangeProductDetails }), defaultDisclaimer = _e.defaultDisclaimer, dedicatedDisclaimer = _e.dedicatedDisclaimer, disclaimersHaveAnyChange = _e.disclaimersHaveAnyChange, onDefaultDisclaimerChanges = _e.onDefaultDisclaimerChanges, onDedicatedDisclaimerChanges = _e.onDedicatedDisclaimerChanges, onSaveDisclaimerClick = _e.onSaveDisclaimerClick, handleSetDefaultDisclaimer = _e.handleSetDefaultDisclaimer, handleSetDedicatedDisclaimer = _e.handleSetDedicatedDisclaimer;
    var _f = useConfigurationTab({ productDetails: productDetails, productId: productId, handleChangeProductDetails: handleChangeProductDetails }), cfmProducts = _f.cfmProducts, selectedCfmProduct = _f.selectedCfmProduct, handleChangeSelectedCfmProduct = _f.handleChangeSelectedCfmProduct, handleSetCfmProducts = _f.handleSetCfmProducts, handleSetSelectedCfmProduct = _f.handleSetSelectedCfmProduct, isTotalFeesVisible = _f.isTotalFeesVisible, handleChangeTotalFeesVisible = _f.handleChangeTotalFeesVisible, handleSetTotalFeesVisible = _f.handleSetTotalFeesVisible;
    var _g = useExclusionsTab({ productDetails: productDetails, productId: productId, handleChangeProductDetails: handleChangeProductDetails }), models = _g.models, powerTrains = _g.powerTrains, selectedAllModels = _g.selectedAllModels, selectedAllPowerTrains = _g.selectedAllPowerTrains, exclusionsHasAnyChange = _g.exclusionsHasAnyChange, selectedModels = _g.selectedModels, selectedPowerTrains = _g.selectedPowerTrains, pnoCodes = _g.pnoCodes, handleSetModels = _g.handleSetModels, handleSetPowerTrains = _g.handleSetPowerTrains, handleSetSelectedModels = _g.handleSetSelectedModels, handleSetSelectedPowerTrains = _g.handleSetSelectedPowerTrains, handleSetSelectedAllModels = _g.handleSetSelectedAllModels, handleSetSelectedAllPowerTrains = _g.handleSetSelectedAllPowerTrains, handleSetPnoCodes = _g.handleSetPnoCodes, onAllItemsChecked = _g.onAllItemsChecked, onModelCheckedChange = _g.onModelCheckedChange, onPowerTrainCheckedChange = _g.onPowerTrainCheckedChange, onPnoCodesChanges = _g.onPnoCodesChanges, onSaveExclusionsClick = _g.onSaveExclusionsClick;
    var tabs = [
        {
            value: 'exclusions',
            name: 'Wykluczenia',
            content: (React.createElement(FinancialProductExclusionsTab, { selectedAllModels: selectedAllModels, onAllItemsChecked: onAllItemsChecked, models: models, selectedModels: selectedModels, onModelCheckedChange: onModelCheckedChange, selectedAllPowerTrains: selectedAllPowerTrains, powerTrains: powerTrains, selectedPowerTrains: selectedPowerTrains, onPowerTrainCheckedChange: onPowerTrainCheckedChange, onPnoCodesChanges: onPnoCodesChanges, pnoCodes: pnoCodes, onCancelClick: onCancelClick, hasAnyChange: exclusionsHasAnyChange, onSaveClick: onSaveExclusionsClick })),
            isVisible: true,
        },
        {
            value: 'configuration',
            name: 'Konfiguracja',
            content: (React.createElement(FinancialProductConfigurationTab, { cfmProducts: cfmProducts, selectedCfmProduct: selectedCfmProduct, onCfpProductChange: handleChangeSelectedCfmProduct, isTotalFeesVisible: isTotalFeesVisible, handleChangeTotalFeesVisible: handleChangeTotalFeesVisible, isCfm: productDetails ? productDetails.isCfm : undefined })),
            isVisible: shouldConfigurationTabShow(),
        },
        {
            value: 'disclaimer',
            name: 'Disclaimer',
            content: (React.createElement(FinancialProductDisclaimerTab, { defaultDisclaimer: defaultDisclaimer, onDefaultDisclaimerChanges: onDefaultDisclaimerChanges, dedicatedDisclaimer: dedicatedDisclaimer, onDedicatedDisclaimerChanges: onDedicatedDisclaimerChanges, hasAnyChange: disclaimersHaveAnyChange, onCancelClick: onCancelClick, onSaveClick: onSaveDisclaimerClick })),
            isVisible: true,
        },
    ];
    function getTabDefaultValue() {
        if (SessionStorage.get('currentFinancialProductPreviewTab')) {
            setTabDefaultValue(SessionStorage.get('currentFinancialProductPreviewTab'));
        }
        else {
            setTabDefaultValue(tabs[0].value);
            SessionStorage.set('currentFinancialProductPreviewTab', tabs[0].value);
        }
    }
    function handleTabChange(value) {
        SessionStorage.set('currentFinancialProductPreviewTab', value);
    }
    useEffect(function () {
        getTabDefaultValue();
        function getAllDetails() {
            return __awaiter(this, void 0, void 0, function () {
                var modelsResponse, sortedModels, powerTrainsResponse, sortedPowerTrains, productDetailsResponse, cfmProductsResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, financialProductsApi.getModels()];
                        case 1:
                            modelsResponse = _a.sent();
                            sortedModels = immutableSort(modelsResponse.cars, function (modelA, modelB) {
                                return modelA.model.localeCompare(modelB.model);
                            });
                            return [4 /*yield*/, financialProductsApi.getPowerTrains()];
                        case 2:
                            powerTrainsResponse = _a.sent();
                            sortedPowerTrains = immutableSort(powerTrainsResponse.powerTrains, function (powwerTrainA, powerTrainB) { return powwerTrainA.localeCompare(powerTrainB); });
                            return [4 /*yield*/, financialProductsApi.getProductDetails(productId)];
                        case 3:
                            productDetailsResponse = _a.sent();
                            return [4 /*yield*/, financialProductsApi.getCfmProducts(productDetailsResponse.id)];
                        case 4:
                            cfmProductsResponse = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                handleSetModels(sortedModels);
                                handleSetPowerTrains(sortedPowerTrains);
                                setProductDetails(productDetailsResponse);
                                if (productDetailsResponse.excludeModelCars.values) {
                                    handleSetSelectedModels(productDetailsResponse.excludeModelCars.values);
                                    if (productDetailsResponse.excludeModelCars.values.length === modelsResponse.cars.length) {
                                        handleSetSelectedAllModels(sameMembers(productDetailsResponse.excludeModelCars.values, modelsResponse.cars.map(function (model) { return model.pno; })));
                                    }
                                }
                                if (productDetailsResponse.excludePowerTrains.values) {
                                    handleSetSelectedPowerTrains(productDetailsResponse.excludePowerTrains.values);
                                    if (productDetailsResponse.excludePowerTrains.values.length ===
                                        powerTrainsResponse.powerTrains.length) {
                                        handleSetSelectedAllPowerTrains(sameMembers(productDetailsResponse.excludePowerTrains.values, powerTrainsResponse.powerTrains));
                                    }
                                }
                                if (productDetailsResponse.excludePno12.values) {
                                    var pnoCodesToBeDisplayed = productDetailsResponse.excludePno12.values.join(', ');
                                    handleSetPnoCodes(pnoCodesToBeDisplayed);
                                }
                                handleSetCfmProducts(cfmProductsResponse);
                                handleSetSelectedCfmProduct(productDetailsResponse.cfmServicePackageId);
                                handleSetTotalFeesVisible(productDetailsResponse.isTotalFeesVisible);
                                if (productDetailsResponse.defaultDisclaimer) {
                                    handleSetDefaultDisclaimer(productDetailsResponse.defaultDisclaimer);
                                }
                                if (productDetailsResponse.requestedDisclaimer) {
                                    handleSetDedicatedDisclaimer(productDetailsResponse.requestedDisclaimer);
                                }
                                setStatus('success');
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        getAllDetails();
    }, []);
    function onCancelClick() {
        if (exclusionsHasAnyChange || disclaimersHaveAnyChange) {
            setOpenConfirmDialog(true);
        }
        else {
            history.push('/financial-products');
        }
    }
    function handleOnConfirmDialog() {
        history.push('/financial-products');
    }
    function handleOnCancelDialog() {
        setOpenConfirmDialog(false);
    }
    useEffect(function () {
        return function () {
            SessionStorage.remove('currentFinancialProductPreviewTab');
        };
    }, []);
    function shouldConfigurationTabShow() {
        return (cfmProducts && cfmProducts.length > 0) || (productDetails && !productDetails.isCfm);
    }
    return productDetails ? (React.createElement("div", { className: "financialProductContainer" },
        React.createElement("div", { className: "financialProductHeaderWrapepr" },
            React.createElement("h2", { className: "financialProductsHeader" }, "Produkty finansowe")),
        React.createElement("div", { className: "financialProductDetails" },
            React.createElement("div", { className: "financialProductName" }, productDetails.name),
            React.createElement("div", null, productDetails.isRevert ? React.createElement(Chip, { color: "#BF2012" }, "wycofany") : React.createElement(ChipPlaceholder, null)),
            productDetails.lastConfigModifiedAt ? (React.createElement("div", null,
                "Zmodyfikowano: ",
                formatDate(new Date(productDetails.lastConfigModifiedAt)))) : (React.createElement("div", null))),
        React.createElement("div", { className: "priceListImportWrapper" }, tabDefaultValue ? (React.createElement(FinancialProductsTabs.Root, { defaultValue: tabDefaultValue, onValueChange: handleTabChange },
            React.createElement(FinancialProductsTabs.List, null, tabs.map(function (tab, index) {
                return tab.isVisible ? (React.createElement(FinancialProductsTabs.Trigger, { value: tab.value, key: index }, tab.name)) : null;
            })),
            tabs.map(function (tab, index) { return (React.createElement(FinancialProductsTabs.Content, { value: tab.value, key: index }, tab.content)); }))) : null),
        React.createElement(ConfirmDialog, { open: openConfirmDialog, message: cancelPopupMessage, onCancel: handleOnCancelDialog, onConfirm: handleOnConfirmDialog }))) : status === 'pending' ? (React.createElement(Spinner, null)) : null;
});
