var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useRef, useState } from 'react';
import { CreatorHeader } from '../../components/creatorHeader/CreatorHeader.component';
import { Tabs } from 'components/tabs/Tabs.component';
import { Label } from 'components/label/Label.component';
import { OptionStandardChip } from 'components/chip/OptionStandardChip.component';
import { OptionPackageChip } from 'components/chip/OptionPakietChip.component';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
import { LayoutWithColor } from './components/layoutRadioButtonsWithCarColor/LayoutRadioButtonsWithCarColor.component';
import { LayoutWithWheels } from './components/layoutRadioButtonsWithWheels/LayoutRadioButtonsWithWheels.component';
import { Checkbox } from 'components/checkbox/Checkbox.component';
import { List } from 'components/list/List.component';
import { VisaPriceChip } from 'components/chip/VisaPriceChip.component';
import { LackOfPriceChip } from 'components/chip/LackOfPriceChip.component';
import { HoverCard } from 'components/hoverCard/HoverCard.component';
import { HoverCardPackageContent } from './components/hoverCardPackageContent/HoverCardPackageContent.component';
import { PackageContent } from './components/packageContent/PackageContent.component';
import { HoverCardTriggerWrapper } from './components/hoverCardTriggerWrapper/HoverCardTriggerWrapper.component';
import { TextAmount } from 'components/textAmount/TextAmount';
import { ListItemElement } from 'components/listItemElement/ListItemElement.component';
import { ChipPlaceholder } from 'components/chip/ChipPlaceholder.component';
import { AmountInput } from 'components/amountInput/AmountInput.component';
import { Accordion } from 'components/accordion/Accordion.component';
import { PercentInput } from 'components/percentInput/PercentInput.component';
import { TextInput } from 'components/textInput/TextInput.component';
import { LayoutCommercialOffers } from './components/layoutCommercialOffers/LayoutCommercialOffers.component';
import { LabelPlaceholder } from 'components/label/LabelPlaceholder.component';
import { Button } from 'components/button/Button.component';
import { Dropdown } from 'components/dropdown/Dropdown.component';
import { ConfigurationCode } from 'components/configurationCode/ConfigurationCode.component';
import { TermValueTable } from 'components/termValueTable/TermValueTable.component';
import { Summary } from 'components/summary/Summary.component';
import { NetAmount } from './components/netAmount/NetAmount.component';
import { CarPreviewButton } from 'components/carPreviewButton/CarPreviewButton.component';
import { Dialog } from 'components/dialog/Dialog.component';
import { Swiper } from 'components/swiper/Swiper.component';
import { CarPreviewTabs } from 'components/carPreviewTabs/carPreviewTabs.component';
import { CarPreviewImage } from 'components/carPreviewImage/CarPreviewImage.component';
import { Link } from 'react-router-dom';
import { TextAmountPlaceholder } from 'components/textAmount/TextAmountPlaceholder';
import { Wizard } from 'components/wizard/Wizard.component';
import { Switch } from 'components/switch/Switch';
import { immutableSort } from 'utils/immutableSort';
function Tab0ContentMock() {
    return React.createElement("p", null, "Tab 0 Content");
}
function Tab1ContentMock() {
    return React.createElement("p", null, "Tab 1 Content");
}
;
function Tab2ContentMock() {
    return React.createElement("p", null, "Tab 2 Content");
}
;
function Tab3ContentMock() {
    return React.createElement("p", null, "Tab 3 Content");
}
;
function Tab4ContentMock() {
    return React.createElement("p", null, "Tab 4 Content");
}
;
function Tab5ContentMock() {
    return React.createElement("p", null, "Tab 5 Content");
}
;
function Tab6ContentMock() {
    return React.createElement("p", null, "Tab 6 Content");
}
;
function Tab7ContentMock() {
    return React.createElement("p", null, "Tab 7 Content");
}
;
function Tab8ContentMock() {
    return React.createElement("p", null, "Tab 8 Content");
}
export function Demo() {
    var headerMock = {
        model: 'xc60',
        modelYear: '2023',
        priceList: 'MY2423W17',
        grossPrice: 211900,
        netPrice: 172276.42,
        tokenConfiguration: '782e5f40-71a5-4f1f-bead-ae522551cf06'
    };
    var tabsMock = [
        {
            value: 'drive',
            name: 'Napęd',
            content: React.createElement(Tab1ContentMock, null)
        },
        {
            value: 'version',
            name: 'Wersja',
            content: React.createElement(Tab2ContentMock, null)
        },
        {
            value: 'options',
            name: 'Opcje',
            content: React.createElement(Tab3ContentMock, null)
        },
        {
            value: 'exterior',
            name: 'Nadwozie',
            content: React.createElement(Tab4ContentMock, null)
        },
        {
            value: 'interior',
            name: 'Wnętrze',
            content: React.createElement(Tab5ContentMock, null)
        },
        {
            value: 'accessories',
            name: 'Akcesoria',
            content: React.createElement(Tab6ContentMock, null)
        },
        {
            value: 'summary',
            name: 'Podsumowanie',
            content: React.createElement(Tab7ContentMock, null)
        },
    ];
    var radioButtonMock = [
        {
            id: 'L4_D',
            selected: true,
            name: 'T2 (129 hp), 8-stopniowa automatyczna skrzynia biegów, Benzyna',
            price: 162400
        },
        {
            id: 'K7_F',
            selected: false,
            name: 'B3 (163 hp), 7-stopniowa DCT automatyczna, Benzyna',
            price: 7240
        },
        {
            id: 'K8_F',
            selected: false,
            name: 'B4 (197 hp), 7-stopniowa DCT automatyczna, Benzyna',
            price: 96400
        },
        {
            id: 'BW_F',
            selected: false,
            name: 'T4 (211 hp), 7-stopniowa DCT automatyczna, Elektryczny/benzynowy',
            price: 213400
        },
        {
            id: 'BB_F',
            selected: false,
            name: 'T5 (262 hp), 7-speed Dual Clutch transmission, Elektryczny/benzynowy',
            price: 218400
        },
        {
            id: 'EP_L',
            selected: false,
            name: 'Recharge (238 hp), automatyczna, Elektryczny',
            price: 0
        },
        {
            id: 'EH_L',
            selected: false,
            name: 'Recharge (252 hp), automatyczna, Elektryczny',
            price: 230700
        },
        {
            id: 'ER_E',
            selected: false,
            name: 'Recharge Twin (408 hp), automatyczna, Elektryczny',
            price: 250400
        }
    ];
    var defaultValueOfDrive = radioButtonMock.find(function (drive) { return drive.selected; });
    var _a = useState(defaultValueOfDrive.id), selectedDrive = _a[0], setSelectedDrive = _a[1];
    function onDriveChange(value) {
        setSelectedDrive(value);
    }
    ;
    var radioButtonWithColorMock = [
        {
            id: '73600',
            selected: true,
            name: 'Bright Dusk',
            hexColor: '#E1DCD8',
            imageSrc: 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/T2/73600/R7D000/R151/TC04/_/_/TP02/_/_/GR03/T101/TJ01/NP02/TM02/_/CB03/EV02/JB0A/T212/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=4&w=480&bg=descriptive-studio',
            price: 0,
            standard: true,
            visaPrice: true
        },
        {
            id: '72300',
            selected: false,
            name: 'Denim Blue',
            hexColor: '#4F5A78',
            imageSrc: 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/T2/72300/R7D000/R151/TC04/_/_/TP02/_/_/GR03/T101/TJ01/NP02/TM02/_/CB03/EV02/JB0A/T212/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=4&w=480&bg=descriptive-studio',
            price: 3400,
            standard: false,
            visaPrice: false
        },
        {
            id: '72500',
            selected: false,
            name: 'Fusion Red',
            hexColor: '#BC3D3A',
            imageSrc: 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/T2/72500/R7D000/R151/TC04/_/_/TP02/_/_/GR03/T101/TJ01/NP02/TM02/_/CB03/EV02/JB0A/T212/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=4&w=480&bg=descriptive-studio',
            price: 3400,
            standard: false,
            visaPrice: true
        },
        {
            id: '71700',
            selected: false,
            name: 'Onyx Black',
            hexColor: '#2D2926',
            imageSrc: 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/T2/71700/R7D000/R151/TC04/_/_/TP02/_/_/GR03/T101/TJ01/NP02/TM02/_/CB03/EV02/JB0A/T212/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=4&w=480&bg=descriptive-studio',
            price: 3400,
            standard: false,
            visaPrice: false
        },
        {
            id: '73100',
            selected: false,
            name: 'Platinum Grey',
            hexColor: '#707A86',
            imageSrc: 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/T2/73100/R7D000/R151/TC04/_/_/TP02/_/_/GR03/T101/TJ01/NP02/TM02/_/CB03/EV02/JB0A/T212/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=4&w=480&bg=descriptive-studio',
            price: 13400,
            standard: false,
            visaPrice: false
        },
        {
            id: '73500',
            selected: false,
            name: 'Silver Dawn',
            hexColor: '#D1D1D2',
            imageSrc: 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/T2/73500/R7D000/R151/TC04/_/_/TP02/_/_/GR03/T101/TJ01/NP02/TM02/_/CB03/EV02/JB0A/T212/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=4&w=480&bg=descriptive-studio',
            price: 13400,
            standard: false,
            visaPrice: false
        },
        {
            id: '72800',
            selected: false,
            name: 'Thunder Grey',
            hexColor: '#899098',
            imageSrc: 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/T2/72800/R7D000/R151/TC04/_/_/TP02/_/_/GR03/T101/TJ01/NP02/TM02/_/CB03/EV02/JB0A/T212/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=4&w=480&bg=descriptive-studio',
            price: 113400,
            standard: false,
            visaPrice: true
        },
    ];
    var defaultValueOfColor = radioButtonWithColorMock.find(function (color) { return color.selected; });
    var _b = useState(defaultValueOfColor.id), selectedColor = _b[0], setSelectedColor = _b[1];
    function onColorChange(value) {
        setSelectedColor(value);
    }
    ;
    var radioButtonWithWheelsMock = [
        {
            id: 'R13E',
            selected: true,
            name: '18″ 5-ramienne Silver',
            imageSrc: 'https://cas.volvocars.com/image/v2/icons/MY24_2317/246/102/R13E.png',
            standard: true,
            visaPrice: false,
            price: 8900
        },
        {
            id: 'R18B',
            selected: false,
            name: '19″ 5-ramienne, V Spoke Black Diamond Cut',
            imageSrc: 'https://cas.volvocars.com/image/v2/icons/MY24_2317/246/102/R18B.png',
            standard: false,
            visaPrice: true,
            price: 16000
        }
    ];
    var defaultValueOfWheels = radioButtonWithWheelsMock.find(function (wheel) { return wheel.selected; });
    var _c = useState(defaultValueOfWheels.id), selectedWheels = _c[0], setSelectedWheels = _c[1];
    function onWheelsChange(value) {
        setSelectedWheels(value);
    }
    ;
    var checkboxOptionsMock = [
        {
            id: '2B02',
            selected: false,
            name: 'Ogrzewanie Postojowe',
            standard: true
        },
        {
            id: '0083',
            selected: true,
            name: 'Czujnik deszczu',
            standard: false
        },
        {
            id: 'PN03',
            selected: false,
            name: 'Automatycznie przyciemniane lusterka wsteczne',
            standard: false
        },
        {
            id: 'EE04',
            selected: true,
            name: 'Ogrzewanie i wietrzenie kabiny ciepłem szczątkowym po wyłączeniu silnika',
            standard: false
        },
    ];
    var _d = useState(checkboxOptionsMock), options = _d[0], setOptions = _d[1];
    function onOptionsCheckedChange(opitions, checked) {
        setOptions(function (currentOpitions) {
            return currentOpitions.map(function (currentOpition) {
                if (currentOpition.id === opitions.id) {
                    return __assign(__assign({}, currentOpition), { selected: checked });
                }
                else {
                    return currentOpition;
                }
            });
        });
    }
    ;
    function selectedOptions() {
        var selectedItems = options.filter(function (option) { return option.selected; });
        return selectedItems.map(function (selectedItem) {
            return {
                id: selectedItem.id, name: selectedItem.name
            };
        });
    }
    ;
    var ListMock = ['list item 1', 'list item 2', 'list item 3', 'list item 4', 'list item 5', 'list item 6'];
    var checkboxOptionsMock2 = [
        {
            id: '000273',
            selected: true,
            name: 'Ogrzewanie Postojowe',
            standard: true,
            price: 3700,
            visaPrice: true
        },
        {
            id: '0083',
            selected: true,
            name: 'Czujnik deszczu',
            standard: false,
            price: null,
            package: true
        },
        {
            id: 'PN013',
            selected: false,
            name: 'Automatycznie przyciemniane lusterka wsteczne',
            standard: false,
            price: 3000,
        },
        {
            id: 'EE04',
            selected: false,
            name: 'Ogrzewanie i wietrzenie kabiny ciepłem szczątkowym po wyłączeniu silnika',
            standard: false,
            price: 13700,
        },
    ];
    var _e = useState(checkboxOptionsMock2), options2 = _e[0], setOptions2 = _e[1];
    function onOptionsCheckedChange2(options, checked) {
        setOptions2(function (currentOptions) {
            return currentOptions.map(function (currentOption) {
                if (currentOption.id === options.id) {
                    return __assign(__assign({}, currentOption), { selected: checked });
                }
                else {
                    return currentOption;
                }
            });
        });
    }
    ;
    var listCheckboxPackagesMock = [
        {
            id: 'P0008',
            name: 'Climate',
            price: 1900,
            content: [
                {
                    id: '000869',
                    name: 'Podgrzewana kierownica'
                },
                {
                    id: '000011',
                    name: 'Podgrzewane siedzenia przednie'
                }
            ],
            selected: false,
            visaPrice: false,
        },
        {
            id: 'P0005',
            name: 'Driver Assist',
            price: 6600,
            content: [
                {
                    id: '000132',
                    name: 'Driver Assistance - Pilot Assist wraz z Adaptacyjnym tempomatem'
                },
                {
                    id: '000169',
                    name: 'Automatycznie przyciemniane lusterka wsteczne'
                },
                {
                    id: '000603',
                    name: 'Driver Awareness wraz z BLIS i Cross Traffic Alert'
                }
            ],
            selected: false,
            visaPrice: true,
        },
        {
            id: 'P0006',
            name: 'Navigation Tech',
            price: 6200,
            content: [
                {
                    id: '000255',
                    name: 'Nawigacja'
                },
                {
                    id: '000882',
                    name: 'Integracja smartfona'
                },
                {
                    id: '000918',
                    name: 'Bezprzewodowe ładowanie telefonu'
                }
            ],
            selected: false,
            visaPrice: false,
        },
        {
            id: 'P0010',
            name: 'Park Assist',
            price: null,
            content: [
                {
                    id: '000790',
                    name: 'Park Assist Camera, tył'
                },
                {
                    id: '000691',
                    name: 'Tylny i przedni układ wspomagania parkowania'
                }
            ],
            selected: false,
            visaPrice: false,
        }
    ];
    var _f = useState(listCheckboxPackagesMock), packages = _f[0], setPackages = _f[1];
    function onPackagesCheckedChange(carPackage, checked) {
        setPackages(function (currentPackages) {
            return currentPackages.map(function (currentPackage) {
                if (currentPackage.id === carPackage.id) {
                    return __assign(__assign({}, currentPackage), { selected: checked });
                }
                else {
                    return currentPackage;
                }
            });
        });
    }
    ;
    var listWithRadioButtonsMock = __spreadArray([], radioButtonMock, true);
    var defaultSelectedDrive = listWithRadioButtonsMock.find(function (drive) { return drive.selected; });
    var _g = useState(defaultSelectedDrive.id), selectedDriveCar = _g[0], setSelectedDriveCar = _g[1];
    function onDriveCarChange(value) {
        setSelectedDriveCar(value);
    }
    ;
    var listWithRadioButtonsWithCarColorMock = __spreadArray([], radioButtonWithColorMock, true);
    var deafultSelectedCarColor = listWithRadioButtonsWithCarColorMock.find(function (color) { return color.selected; });
    var _h = useState(deafultSelectedCarColor.id), selectedCarColor = _h[0], setSelectedCarColor = _h[1];
    function onCarColorChange(value) {
        setSelectedCarColor(value);
    }
    ;
    var listWithRadioButtonsWithWheelsMock = __spreadArray([], radioButtonWithWheelsMock, true);
    var defaultSelectedCarWheels = listWithRadioButtonsWithWheelsMock.find(function (wheels) { return wheels.selected; });
    var _j = useState(defaultSelectedCarWheels.id), selectedCarWheels = _j[0], setSelectedCarWheels = _j[1];
    function onCarWheelsChange(value) {
        setSelectedCarWheels(value);
    }
    ;
    var accordionMock = [
        {
            value: 'baseColor',
            name: 'Kolor bazowy',
            content: 'tutaj będą kolory bazowe'
        },
        {
            value: 'metallicColor',
            name: 'Kolor metaliczny',
            content: 'tutaj będą kolory metaliczne'
        },
        {
            value: 'pearlColor',
            name: 'Kolor perłowy',
            content: 'tutaj będą kolory perłowe'
        },
        {
            value: 'wheels',
            name: 'Koła',
            content: 'tutaj będą koła'
        }
    ];
    var accordionContentBaseColorsMock = [
        {
            id: '01900',
            selected: true,
            name: 'Black Stone',
            hexColor: '#101820',
            imageSrc: 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/T2/01900/R7D000/R151/TC04/_/_/TP02/_/_/GR03/T101/TJ01/NP02/TM02/_/CB03/EV02/JB0A/T212/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=4&w=480&bg=descriptive-studio',
            price: 0,
            standard: true,
            visaPrice: true
        }
    ];
    var accordionContentMetallicColorsMock = [
        {
            id: '71700',
            selected: false,
            name: 'Onyx Black',
            hexColor: '#2D2926',
            imageSrc: 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/T2/71700/R7D000/R151/TC04/_/_/TP02/_/_/GR03/T101/TJ01/NP02/TM02/_/CB03/EV02/JB0A/T212/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=4&w=480&bg=descriptive-studio',
            price: 3400,
            standard: false,
            visaPrice: true
        },
        {
            id: '72500',
            selected: false,
            name: 'Fusion Red',
            hexColor: '#BC3D3A',
            imageSrc: 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/T2/72500/R7D000/R151/TC04/_/_/TP02/_/_/GR03/T101/TJ01/NP02/TM02/_/CB03/EV02/JB0A/T212/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=4&w=480&bg=descriptive-studio',
            price: 3400,
            standard: false,
            visaPrice: false
        },
        {
            id: '72300',
            selected: false,
            name: 'Denim Blue',
            hexColor: '#4F5A78',
            imageSrc: 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/T2/72300/R7D000/R151/TC04/_/_/TP02/_/_/GR03/T101/TJ01/NP02/TM02/_/CB03/EV02/JB0A/T212/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=4&w=480&bg=descriptive-studio',
            price: 3400,
            standard: false,
            visaPrice: false
        },
        {
            id: '72800',
            selected: false,
            name: 'Thunder Grey',
            hexColor: '#899098',
            imageSrc: 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/T2/72800/R7D000/R151/TC04/_/_/TP02/_/_/GR03/T101/TJ01/NP02/TM02/_/CB03/EV02/JB0A/T212/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=4&w=480&bg=descriptive-studio',
            price: 3400,
            standard: false,
            visaPrice: false
        },
        {
            id: '73500',
            selected: false,
            name: 'Silver Dawn',
            hexColor: '#D1D1D2',
            imageSrc: 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/T2/73500/R7D000/R151/TC04/_/_/TP02/_/_/GR03/T101/TJ01/NP02/TM02/_/CB03/EV02/JB0A/T212/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=4&w=480&bg=descriptive-studio',
            price: 13400,
            standard: false,
            visaPrice: false
        }
    ];
    var accordionContentPearlColorsMock = [
        {
            id: '70700',
            selected: false,
            name: 'Crystal White',
            hexColor: '#F2F2F2',
            imageSrc: 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/T2/70700/R7D000/R151/TC04/_/_/TP02/_/_/GR03/T101/TJ01/NP02/TM02/_/CB03/EV02/JB0A/T212/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=4&w=480&bg=descriptive-studio',
            price: 113000,
            standard: false,
            visaPrice: true
        }
    ];
    var accordionContentMock = [
        {
            category: 'baseColors',
            name: 'Kolor bazowy',
            content: accordionContentBaseColorsMock
        },
        {
            category: 'metallicColors',
            name: 'Kolor metaliczny',
            content: accordionContentMetallicColorsMock
        },
        {
            category: 'pearlColors',
            name: 'Kolor perłowy',
            content: accordionContentPearlColorsMock
        }
    ];
    var allColors = __spreadArray(__spreadArray(__spreadArray([], accordionContentBaseColorsMock, true), accordionContentMetallicColorsMock, true), accordionContentPearlColorsMock, true);
    var accordionDefaultColor = allColors.find(function (color) { return color.selected; });
    var _k = useState(accordionDefaultColor.id), accordionSelectedColor = _k[0], setAccordionSelectedColor = _k[1];
    function onAccordionColorChange(value) {
        setAccordionSelectedColor(value);
    }
    ;
    var _l = useState(0), normalAmountInput = _l[0], setNormalAmountInput = _l[1];
    function onNormalAmountInputChange(values) {
        var _a;
        setNormalAmountInput((_a = values.floatValue) !== null && _a !== void 0 ? _a : 0);
    }
    ;
    var _m = useState(0), longAmountInput = _m[0], setLongAmountInput = _m[1];
    function onLongAmountInputChange(values) {
        var _a;
        setLongAmountInput((_a = values.floatValue) !== null && _a !== void 0 ? _a : 0);
    }
    ;
    var _o = useState(0), normalPercentInput = _o[0], setNormalPercentInput = _o[1];
    function onNormalPercentInputChange(values) {
        var _a;
        setNormalPercentInput((_a = values.floatValue) !== null && _a !== void 0 ? _a : 0);
    }
    ;
    var _p = useState(0), longPercentInput = _p[0], setLongPercentInput = _p[1];
    function onLongPercentInputChange(values) {
        var _a;
        setLongPercentInput((_a = values.floatValue) !== null && _a !== void 0 ? _a : 0);
    }
    ;
    var listWithAmountInputMock = [
        {
            id: '001028',
            selected: false,
            name: 'Hak holowniczy, składany',
            price: 3700
        },
        {
            id: 'A00330',
            selected: false,
            name: 'Osłona silnika',
            price: 10567.88
        },
        {
            id: 'A00424',
            selected: false,
            name: 'Osłona zderzaka',
            price: 4560
        },
        {
            id: 'A00164',
            selected: false,
            name: 'Osłony przeciwbłotne (chlapacze) przednie',
            price: 6500
        },
        {
            id: 'A00165',
            selected: false,
            name: 'Osłony przeciwbłotne (chlapacze) tylne',
            price: 6500
        },
        {
            id: 'A00458',
            selected: false,
            name: 'Zintegrowane listwy ochronne progów',
            price: 600
        },
    ];
    var _q = useState(listWithAmountInputMock), accessories = _q[0], setAccessories = _q[1];
    function onAccessoriesCheckedChange(accessory, checked) {
        setAccessories(function (currentAccessories) {
            return currentAccessories.map(function (currentAccessory) {
                if (currentAccessory.id === accessory.id) {
                    return __assign(__assign({}, currentAccessory), { selected: checked });
                }
                else {
                    return currentAccessory;
                }
            });
        });
    }
    ;
    function onAccessoriesPriceChange(values, accessory) {
        setAccessories(function (currentAccessories) {
            return currentAccessories.map(function (currentAccessory) {
                if (currentAccessory.id === accessory.id) {
                    return __assign(__assign({}, currentAccessory), { price: values.floatValue });
                }
                else {
                    return currentAccessory;
                }
            });
        });
    }
    ;
    var _r = useState(''), textInput = _r[0], setTextInput = _r[1];
    function onTextInputChange(event) {
        setTextInput(event.target.value);
    }
    ;
    var carPrice = 250000;
    var _s = useState(''), textInputOffer = _s[0], setTextInputOffer = _s[1];
    function onTextInputOfferChange(event) {
        setTextInputOffer(event.target.value);
    }
    ;
    var _t = useState({ value: 0, stringValue: '0.00' }), discountValue = _t[0], setDiscountValue = _t[1];
    function getStringValue(value) {
        return value === 0 ? '0.00' : value.toString();
    }
    function handleDiscountValueChange(values) {
        var newValue = values.floatValue === undefined ? 0 : values.floatValue;
        setDiscountValue({
            value: newValue,
            stringValue: values.value
        });
        var newDiscountPercentValue = newValue / carPrice * 100;
        setDiscountPercentValue({
            value: newDiscountPercentValue,
            stringValue: getStringValue(newDiscountPercentValue)
        });
    }
    var _u = useState({ value: 0, stringValue: '0.00' }), discountPercentValue = _u[0], setDiscountPercentValue = _u[1];
    function handlePercentValueChange(values) {
        var newValue = values.floatValue === undefined ? 0 : values.floatValue;
        setDiscountPercentValue({
            value: newValue,
            stringValue: values.value
        });
        var newDiscountValue = newValue * carPrice / 100;
        setDiscountValue({
            value: newDiscountValue,
            stringValue: getStringValue(newDiscountValue)
        });
    }
    var modelsDictionary = {
        '224': { name: 'S60' },
        '225': { name: 'V60' },
        '227': { name: 'V60 Cross Country' },
        '234': { name: 'S90' },
        '235': { name: 'V90' },
        '236': { name: 'V90 Cross Country' },
        '246': { name: 'XC60' },
    };
    var dropdownMock = [
        {
            id: '224_2023',
            presale: false
        },
        {
            id: '225_2023',
            presale: true
        },
        {
            id: '227_2023',
            presale: true
        },
        {
            id: '234_2023',
            presale: false
        },
        {
            id: '235_2023',
            presale: false
        },
        {
            id: '236_2023',
            presale: false
        },
        {
            id: '246_2023',
            presale: false
        }
    ];
    function getModelName(id) {
        var modelCode = id.split('_')[0];
        return modelsDictionary[modelCode].name;
    }
    var _v = useState(), selectedPriceList = _v[0], setSelectedPriceList = _v[1];
    function onPriceListChange(value) {
        setSelectedPriceList(value);
    }
    function dropdownOptions() {
        return dropdownMock.map(function (element) {
            return {
                value: element.id,
                label: getModelName(element.id) + (element.presale ? ' (Przedsprzedaż)' : '')
            };
        });
    }
    var _w = useState([]), additionalAccessories = _w[0], setAdditionalAccessories = _w[1];
    var configurationCodeMock = '782e5f40-71a5-4f1f-bead-ae522551cf06';
    var TermValueTableLeftColumnMock = [
        {
            term: 'Silnik:',
            value: 'B4 (197 + 14 KM)'
        },
        {
            term: 'Rodzaj paliwa:',
            value: 'Benzyna'
        },
        {
            term: 'Moc maksymalna (KM):',
            value: '250+14 KM'
        },
        {
            term: 'Czas przyspieszenia:',
            value: '8.3 s'
        },
        {
            term: 'Śr. spalanie:',
            value: '6.9 - 7.8 l/100km'
        },
        {
            term: 'Prędkość maksymalna:',
            value: '180 km/h'
        }
    ];
    var TermValueTableRightColumnMock = [
        {
            term: 'Miejsc:',
            value: '5'
        },
        {
            term: 'Wysokość:',
            value: '165,2 cm'
        },
        {
            term: 'Długość:',
            value: '442,5 cm'
        },
        {
            term: 'Szerokość z lusterkami:',
            value: '186,3 cm'
        },
        {
            term: 'Prześwit:',
            value: '23,3 cm'
        },
        {
            term: 'Pojemność bagaznika:',
            value: '583 l'
        }
    ];
    var summaryMock = {
        header: 'razem',
        subtotalGross: 0.00,
        subtotalNet: 0.00,
        summaryGross: 211900.00,
        summaryNet: 172276.42
    };
    var TabsForCarPreviewMock = [
        {
            value: 'priceList',
            name: 'Cennik',
            content: React.createElement(Tab0ContentMock, null)
        },
        {
            value: 'drive',
            name: 'Napęd',
            content: React.createElement(Tab1ContentMock, null)
        },
        {
            value: 'version',
            name: 'Wersja',
            content: React.createElement(Tab2ContentMock, null)
        },
        {
            value: 'options',
            name: 'Opcje',
            content: React.createElement(Tab3ContentMock, null)
        },
        {
            value: 'exterior',
            name: 'Nadwozie',
            content: React.createElement(Tab4ContentMock, null)
        },
        {
            value: 'interior',
            name: 'Wnętrze',
            content: React.createElement(Tab5ContentMock, null)
        },
        {
            value: 'accessories',
            name: 'Akcesoria',
            content: React.createElement(Tab6ContentMock, null)
        },
        {
            value: 'financing',
            name: 'Finansowanie',
            content: React.createElement(Tab8ContentMock, null)
        },
        {
            value: 'summary',
            name: 'Podsumowanie',
            content: React.createElement(Tab7ContentMock, null)
        },
    ];
    var standardData = [
        {
            category: 'drive',
            name: 'napęd',
            value: 'B4 (197 + 14 KM), 8-stopniowa automatyczna skrzynia biegów, Benzyna',
            code: 'XC60K9',
            price: 0
        },
        {
            category: 'version',
            name: 'wersja',
            value: 'Core, Motyw: Core',
            code: '01900',
            price: 2400
        },
        {
            category: 'color',
            name: 'kolor',
            value: 'Black Stone',
            code: '01900',
            price: 3400
        },
        {
            category: 'wheels',
            name: 'koła',
            value: '18″ 5-ramienne Silver',
            code: '1070',
            price: 0
        },
        {
            category: 'upholstery',
            name: 'tapicerka',
            value: 'Tapicerka skórzana w tonacji czerni (Charcoal), wnętrze w tonacji czerni (Charcoal)',
            code: 'WC0000',
            price: 14500
        },
        {
            category: 'interior',
            name: 'wykończenie wnętrza',
            value: 'Aluminiowe elementy ozdobne Metal Mesh',
            code: 'NC04',
            price: 0
        },
        {
            category: 'steering wheel',
            name: 'kierownica',
            value: 'Obszyta kierownica, tonacja czerni (Charcoal)',
            code: 'RU02',
            price: 0
        },
        {
            category: 'gear stick',
            name: 'dźwignia zmiany biegów',
            value: 'Obszyta dźwignia zmiany biegów',
            code: 'DI09',
            price: 0
        }, {
            category: 'roof lining',
            name: 'podsufitka',
            value: 'Podsufitka w tonacji czerni (Charcoal)',
            code: 'FH01',
            price: 0
        }
    ];
    var swiperExteriorMock = [
        'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/12/74000/R7D000/R13E/TC04/_/_/TP04/_/_/GR01/T101/TJ03/NP02/TM03/_/_/_/JB0A/T21A/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=3&w=1920&bg=descriptive-studio',
        'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/12/74000/R7D000/R13E/TC04/_/_/TP04/_/_/GR01/T101/TJ03/NP02/TM03/_/_/_/JB0A/T21A/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=6&w=1920&bg=descriptive-studio',
        'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/12/74000/R7D000/R13E/TC04/_/_/TP04/_/_/GR01/T101/TJ03/NP02/TM03/_/_/_/JB0A/T21A/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=5&w=1920&bg=descriptive-studio',
        'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/12/74000/R7D000/R13E/TC04/_/_/TP04/_/_/GR01/T101/TJ03/NP02/TM03/_/_/_/JB0A/T21A/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=1&w=1920&bg=descriptive-studio',
        'https://acas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v1/12/74000/R7D000/R13E/TC04/_/_/TP04/_/_/GR01/T101/TJ03/NP02/TM03/_/_/_/JB0A/T21A/LF01/_/VP08/_/FH02/T006/_/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=4&w=1920&bg=descriptive-studio'
    ];
    var swiperInteriorMock = [
        'https://cas.volvocars.com/image/dynamic/MY24_2317/246/drivers-view-v2/T2/1/74000/R7D000/NC09/DI0B/RU0C/_/NP02/CB03/EV02/K502/_/LF01/_/FH02/PN03/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=0&h=700',
        'https://cas.volvocars.com/image/dynamic/MY24_2317/246/seat-view-v1/T2/1/74000/R7D000/NP02/CB03/EV02/_/LF01/FH02/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=0&h=700',
        'https://cas.volvocars.com/image/dynamic/MY24_2317/246/deco-view-v1/T2/1/74000/R7D000/NC09/_/NP02/CB03/EV02/K502/_/LF01/_/FH02/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=0&h=700',
        'https://cas.volvocars.com/image/dynamic/MY24_2317/246/rear-view-v1/T2/1/74000/R7D000/NP02/CB03/EV02/_/LF01/FH02/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=0&h=700',
        'https://cas.volvocars.com/image/dynamic/MY24_2317/246/interior-v2/T2/1/74000/R7D000/NC09/DI0B/RU0C/NV03/_/NJ01/NP02/CB03/EV02/K502/_/LF01/_/FH02/PN03/_/default.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&angle=0&h=700',
        'https://cas.volvocars.com/image/dynamic/MY24_2317/246/drivers-view-v2/T2/1/74000/R7D000/NC09/DI0B/RU0C/_/NP02/CB03/EV02/K502/_/LF01/_/FH02/PN03/_/GearShift.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&h=700',
        'https://cas.volvocars.com/image/dynamic/MY24_2317/246/drivers-view-v2/T2/1/74000/R7D000/NC09/DI0B/RU0C/_/NP02/CB03/EV02/K502/_/LF01/_/FH02/PN03/_/SteeringWheel.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&h=700',
        'https://cas.volvocars.com/image/dynamic/MY24_2317/246/seat-view-v1/T2/1/74000/R7D000/NP02/CB03/EV02/_/LF01/FH02/Fabric.jpg?market=pl&client=gox-graph%7Cdotcom-configurator-pl&h=700'
    ];
    var swiperTabs = [
        {
            value: 'exterior',
            name: 'Nadwozie',
            photos: swiperExteriorMock
        },
        {
            value: 'interior',
            name: 'Wnętrze',
            photos: swiperInteriorMock
        }
    ];
    var winterWheelsMock = {
        category: 'KOŁA ZIMOWE',
        accessories: [
            {
                id: '$FR136',
                name: '17-calowa felga w kolorze srebrnym (Silver) z 5 ramionami',
                optionNo: '$FR136',
                price: 2000,
                standard: false,
                token: '13704751216115946511',
                versionToken: '9037111378385922175',
                image: 'https://esd.volvocars.com/imageweb/92d64cb5-ee99-48fe-a289-1131dfb5e3a6.jpg',
                content: [],
            },
            {
                id: '001076',
                name: '18-calowa felga w kolorze czarnym (Black), szlifowana z 5 ramionami (1076)',
                optionNo: '001076',
                price: 3200,
                standard: false,
                token: '6174425929252715105',
                versionToken: '7515127111845883219',
                image: 'https://esd.volvocars.com/imageweb/207f9c1d-7413-4ad6-ac69-fd4fbd003649.jpg',
                content: [],
            },
            {
                id: '001244',
                name: '18-calowa felga w kolorze srebrnym (Silver) z 5 ramionami (1244)',
                optionNo: '001244',
                price: 3700,
                standard: false,
                token: '1355323563840468466',
                versionToken: '7515127111845883333',
                image: 'https://esd.volvocars.com/imageweb/8055cb0d-1a69-452b-9adf-3f0d14c66d87.jpg',
                content: [],
            },
        ],
    };
    var _x = useState('any'), selectedWinterWheels = _x[0], setSelectedWinterWheels = _x[1];
    function handleChangeSelectedWinterWheels(id) {
        setSelectedWinterWheels(id);
    }
    var _y = useState({}), winterWheelsCustomPrice = _y[0], setWinterWheelsCustomPrice = _y[1];
    function handleChangeWinterWheelsCustomPrice(values, id) {
        var _a;
        var newWinterWheelsPrice = (_a = values.floatValue) !== null && _a !== void 0 ? _a : 0;
        setWinterWheelsCustomPrice(function (current) {
            var _a;
            return __assign(__assign({}, current), (_a = {}, _a[id] = newWinterWheelsPrice, _a));
        });
        // tutaj dać debounca jak w przypadku akcesoriów
    }
    var wizardStepsMock = [
        {
            value: 'generate-or-edit',
            label: 'Generowanie/Edycja',
            content: (React.createElement("div", null,
                React.createElement("div", null, "zawarto\u015B\u0107 generowanie/edycja cennika"),
                React.createElement("div", { style: { width: '160px' } },
                    React.createElement(Button, { type: 'button', onClick: handleGoToNextWizardStep }, "Dalej")))),
            type: 'PRIMARY',
        },
        {
            value: 'first-secondary',
            content: (React.createElement("div", null,
                React.createElement("div", null, "zawarto\u015B\u0107 import po generowaniu cennika"),
                React.createElement("div", { style: { width: '160px' } },
                    React.createElement(Button, { type: 'button', onClick: handleGoToNextWizardStep }, "Dalej")))),
            type: 'SECONDARY',
        },
        {
            value: 'range of models',
            label: 'Gama modeli',
            content: (React.createElement("div", null,
                React.createElement("div", null, "zawarto\u015B\u0107 gama modeli"),
                React.createElement("div", { style: { display: 'flex', gap: '8px' } },
                    React.createElement("div", { style: { width: '160px' } },
                        React.createElement(Button, { type: 'button', variant: 'secondary', onClick: function () { return handleGoToStepByValue('price-override'); } }, "nadpisywanie")),
                    React.createElement("div", { style: { width: '160px' } },
                        React.createElement(Button, { type: 'button', onClick: handleGoToNextWizardStep }, "Dalej"))))),
            type: 'PRIMARY',
        },
        {
            value: 'second-secondary',
            content: (React.createElement("div", null,
                React.createElement("div", null, "zawrto\u015B\u0107 import po gamie modelowej"),
                React.createElement("div", { style: { width: '160px' } },
                    React.createElement(Button, { type: 'button', onClick: handleGoToNextWizardStep }, "Dalej")))),
            type: 'SECONDARY',
        },
        {
            value: 'price-override',
            label: 'Nadpisywanie cen',
            content: (React.createElement("div", null,
                React.createElement("div", null, "zawarto\u015B\u0107 nadpisywanie cen"),
                React.createElement("div", { style: { width: '160px' } },
                    React.createElement(Button, { type: 'button', onClick: handleGoToNextWizardStep }, "Dalej")))),
            type: 'PRIMARY',
        },
        {
            value: 'third-secondary',
            content: (React.createElement("div", null,
                React.createElement("div", null, "zawarto\u015B\u0107 import po nadpisywaniu cen"),
                React.createElement("div", { style: { width: '160px' } },
                    React.createElement(Button, { type: 'button', onClick: handleGoToNextWizardStep }, "Dalej")))),
            type: 'SECONDARY',
        },
        {
            value: 'summary',
            label: 'Podsumowanie',
            content: (React.createElement("div", null,
                React.createElement("div", null, "zawarto\u015B\u0107 podsumowanie"),
                React.createElement(Button, { type: 'button', onClick: function () { handleGoToStepByValue('generate-or-edit'); stepIndex.current = 0; } }, "Reset"))),
            type: 'PRIMARY',
        },
    ];
    var _z = useState(0), currentWizardStepIndex = _z[0], setCurrentWizardStepIndex = _z[1];
    var stepIndex = useRef(0);
    function handleGoToNextWizardStep() {
        // zabezpieczenie na wypadek braku kontentu w kolejnym kroku
        if (!wizardStepsMock[stepIndex.current + 1].content) {
            setCurrentWizardStepIndex(function (currentStep) { return currentStep + 1; });
            stepIndex.current = stepIndex.current + 1;
            return handleGoToNextWizardStep();
        }
        else {
            setCurrentWizardStepIndex(function (currentStep) { return currentStep + 1; });
            stepIndex.current = stepIndex.current + 1;
        }
    }
    function handleGoToStepByValue(value) {
        var desiredStep = wizardStepsMock.find(function (step) { return step.value === value; });
        var indexOfDesiredStep = wizardStepsMock.indexOf(desiredStep);
        setCurrentWizardStepIndex(indexOfDesiredStep);
        stepIndex.current = indexOfDesiredStep;
    }
    var _0 = useState(false), switchChecked = _0[0], setSwitchChecked = _0[1];
    function handleSwitchChange(checked) {
        setSwitchChecked(checked);
    }
    var packagesNew = {
        category: 'PAKIETY',
        availableOptions: [
            {
                id: 'P0008',
                name: 'Climate',
                optionNo: 'P0008',
                priceType: 'VISA2',
                price: 3500,
                token: '22222',
                content: [
                    {
                        id: '000752',
                        name: 'Podgrzewane siedzenia tylne',
                        optionNo: '000752',
                        token: '22222',
                    },
                    {
                        id: '000869',
                        name: 'Podgrzewana kierownica',
                        optionNo: '000869',
                    },
                    {
                        id: '000011',
                        name: 'Podgrzewane siedzenia przednie',
                        optionNo: '000011',
                    },
                    {
                        id: '000322',
                        name: 'Integralne podwyższenie',
                        optionNo: '000322',
                        token: '33333',
                    },
                ]
            },
            {
                id: 'P0010',
                name: 'Park Assist',
                optionNo: 'P0010',
                priceType: 'VISA2',
                price: 3000,
                token: '11111',
                content: [
                    {
                        id: '000790',
                        name: 'Park Assist Camera, tył',
                        optionNo: '000790'
                    },
                    {
                        id: '000691',
                        name: 'Tylny i przedni układ wspomagania parkowania',
                        optionNo: '000691'
                    }
                ]
            }
        ]
    };
    var packagesWithExchangableOption = {
        category: 'PAKIETY',
        availableOptions: [
            {
                id: 'P0008',
                name: 'Climate',
                optionNo: 'P0008',
                priceType: 'VISA2',
                price: 3500,
                token: '22222',
                content: [
                    {
                        id: '000752',
                        name: 'Podgrzewane siedzenia tylne',
                        optionNo: '000752',
                        standard: false
                    },
                    {
                        id: '000869',
                        name: 'Podgrzewana kierownica',
                        optionNo: '000869',
                        standard: true
                    },
                    {
                        id: '000011',
                        name: 'Podgrzewane siedzenia przednie',
                        optionNo: '000011',
                        standard: true
                    }
                ]
            },
            {
                id: 'P0008_2',
                name: 'Climate',
                optionNo: 'P0008',
                priceType: 'VISA2',
                price: 3500,
                token: '33333',
                content: [
                    {
                        id: '000322',
                        name: 'Integralne podwyższenie',
                        optionNo: '000322',
                        standard: false
                    },
                    {
                        id: '000869',
                        name: 'Podgrzewana kierownica',
                        optionNo: '000869',
                        standard: true
                    },
                    {
                        id: '000011',
                        name: 'Podgrzewane siedzenia przednie',
                        optionNo: '000011',
                        standard: true
                    }
                ]
            },
            {
                id: 'P0010',
                name: 'Park Assist',
                optionNo: 'P0010',
                priceType: 'VISA2',
                price: 3000,
                token: '11111',
                content: [
                    {
                        id: '000790',
                        name: 'Park Assist Camera, tył',
                        optionNo: '000790'
                    },
                    {
                        id: '000691',
                        name: 'Tylny i przedni układ wspomagania parkowania',
                        optionNo: '000691'
                    }
                ]
            }
        ]
    };
    var deduplicateAvailableOptionIdsSet = new Set(packagesNew.availableOptions.map(function (option) { return option.id; }));
    var deduplicateAvailableOptionIdsArray = Array.from(deduplicateAvailableOptionIdsSet);
    var _1 = useState([]), selectedPackages = _1[0], setSelectedPackages = _1[1];
    var _2 = useState('22222'), selectedToken = _2[0], setSelectedToken = _2[1];
    function onPackagesWithExchangableOptionCheckedChange(checked, id, token) {
        if (checked) {
            setSelectedPackages(function (currentSelectedPackages) {
                return __spreadArray(__spreadArray([], currentSelectedPackages, true), [id], false);
            });
        }
        else {
            setSelectedPackages(function (currentSelectedPackages) {
                return __spreadArray([], currentSelectedPackages.filter(function (packages) { return packages !== id; }), true);
            });
        }
    }
    ;
    function checkIfMultiplePackage(availableOptions, packageId) {
        var packageToCheck = availableOptions.find(function (packageOp) { return packageOp.id === packageId; });
        var isMultiple = packageToCheck.content.some(function (option) { return option.token; });
        return isMultiple;
    }
    function getAvailableOptionById(availableOptions, id) {
        return availableOptions.find(function (availableOption) { return availableOption.id === id; });
    }
    function getContents(availableOptions, id) {
        var packageAvailableOption = availableOptions.find(function (availableOption) { return availableOption.id === id; });
        // const optionsContent = packageAvailableOptions.map(option => option.content).flat();
        var optionContent = packageAvailableOption.content;
        // const uniqueOptionsContent = Array.from(new Set(optionsContent.map(option => JSON.stringify(option)))).map((option: any) => JSON.parse(option))
        // @ts-ignore
        var sortedUniqueOptionsContent = immutableSort(optionContent, function (a, b) { return a.name.localeCompare(b.name); });
        return sortedUniqueOptionsContent;
    }
    function getPackageTokenByOptionId(availableOptions, optionId) {
        var element = availableOptions.find(function (option) { return option.content.some(function (element) { return element.id === optionId; }); });
        return element.token;
    }
    function handleChangeSelectedOptionToken(value) {
        setSelectedToken(value);
    }
    function isFromVCP() {
        return false;
    }
    return (React.createElement("div", { style: { marginTop: '30px', marginBottom: '30px', background: '#FFFFFF', color: 'var(--main-text-color)' } },
        React.createElement("header", null,
            React.createElement("h1", { style: { 'textAlign': 'center' } }, "Komponenty")),
        React.createElement("section", null,
            React.createElement("h4", { style: { textAlign: 'center', marginTop: '30px' } },
                "Tutaj jest ",
                React.createElement(Link, { to: '/start-offer' },
                    React.createElement("span", { style: { color: 'var(--blue)', textDecoration: 'underline' } }, "ROZCHODNIAK"))),
            React.createElement("h4", { style: { textAlign: 'center', marginTop: '30px' } },
                "Tutaj ",
                React.createElement(Link, { to: '/price-lists' },
                    React.createElement("span", { style: { color: 'var(--blue)', textDecoration: 'underline' } }, "CENNIKI")))),
        React.createElement("section", null,
            React.createElement("h4", { style: { textAlign: 'center', marginTop: '30px' } },
                "Tutaj jest ",
                React.createElement(Link, { to: '/price-lists' },
                    React.createElement("span", { style: { color: 'var(--blue)', textDecoration: 'underline' } }, "Zarz\u0105dzanie cennikami")))),
        React.createElement("section", null,
            React.createElement("h3", null, "Header"),
            React.createElement(CreatorHeader, { model: headerMock.model, modelYear: headerMock.modelYear, priceList: headerMock.priceList, grossPrice: headerMock.grossPrice, netPrice: headerMock.netPrice, isTokenConfigurationVisible: true, tokenConfiguration: headerMock.tokenConfiguration })),
        React.createElement("div", { style: { paddingLeft: '43px', paddingRight: '43px' } },
            React.createElement("section", null,
                React.createElement("h3", null, "Tabs"),
                React.createElement(Tabs.Root, { defaultValue: tabsMock[0].value },
                    React.createElement(Tabs.List, null, tabsMock.map(function (tab, index) { return (React.createElement(Tabs.Trigger, { value: tab.value, key: index }, tab.name)); })),
                    tabsMock.map(function (tab, index) { return (React.createElement(Tabs.Content, { value: tab.value, key: index }, tab.content)); }))),
            React.createElement("section", null,
                React.createElement("h3", null, "Labels"),
                React.createElement("h4", null, "Normal"),
                React.createElement(Label, null, "Normal label"),
                React.createElement("h4", null, "Uppercase"),
                React.createElement(Label, { uppercase: true }, "Uppercase label"),
                React.createElement("h4", null, "Bold"),
                React.createElement(Label, { bold: true }, "Bold label"),
                React.createElement("h4", null, "Bolded uppercase"),
                React.createElement(Label, { uppercase: true, bold: true }, "Bolded uppercase label")),
            React.createElement("section", null,
                React.createElement("h3", null, "Chips"),
                React.createElement("h4", null, "Chips for all"),
                React.createElement(OptionStandardChip, null),
                React.createElement("br", null),
                React.createElement(OptionPackageChip, null),
                React.createElement("br", null),
                React.createElement("h4", null, "Chips only for VCP"),
                React.createElement(VisaPriceChip, null),
                React.createElement("br", null),
                React.createElement(LackOfPriceChip, null)),
            React.createElement("section", null,
                React.createElement("h3", null, "Radio group"),
                React.createElement("div", null,
                    "React state: id: ",
                    selectedDrive,
                    " name: ",
                    radioButtonMock.find(function (drive) { return drive.id === selectedDrive; }).name),
                React.createElement("form", null,
                    React.createElement(RadioGroup.Root, { defaultValue: defaultValueOfDrive.id, onValueChange: onDriveChange }, radioButtonMock.map(function (drive, index) { return (React.createElement(RadioGroup.Layout, { key: index },
                        React.createElement(RadioGroup.Item, { value: drive.id, id: drive.id },
                            React.createElement(RadioGroup.Indicator, null)),
                        React.createElement(Label, { htmlFor: drive.id }, drive.name))); })))),
            React.createElement("section", null,
                React.createElement("h3", null, "Radio group with colors"),
                React.createElement("div", null,
                    "React state: id: ",
                    selectedColor,
                    " name: ",
                    radioButtonWithColorMock.find(function (color) { return color.id === selectedColor; }).name),
                React.createElement("form", null,
                    React.createElement(RadioGroup.Root, { defaultValue: defaultValueOfColor.id, onValueChange: onColorChange }, radioButtonWithColorMock.map(function (color, index) { return (React.createElement(LayoutWithColor, { key: index, thumbnailImageSrc: color.imageSrc, imagePreviewSrc: color.imageSrc },
                        React.createElement(RadioGroup.Item, { value: color.id, id: color.id },
                            React.createElement(RadioGroup.Indicator, null)),
                        React.createElement(Label, { htmlFor: color.id }, color.name))); })))),
            React.createElement("section", null,
                React.createElement("h3", null, "Radio group with wheels"),
                React.createElement("div", null,
                    "React state: id: ",
                    selectedWheels,
                    " name: ",
                    radioButtonWithWheelsMock.find(function (wheels) { return wheels.id === selectedWheels; }).name),
                React.createElement("form", null,
                    React.createElement(RadioGroup.Root, { defaultValue: defaultValueOfWheels.id, onValueChange: onWheelsChange }, radioButtonWithWheelsMock.map(function (wheels, index) { return (React.createElement(LayoutWithWheels, { key: index, imageSrc: wheels.imageSrc },
                        React.createElement(RadioGroup.Item, { value: wheels.id, id: wheels.id },
                            React.createElement(RadioGroup.Indicator, null)),
                        React.createElement(Label, { htmlFor: wheels.id }, wheels.name))); })))),
            React.createElement("section", null,
                React.createElement("h3", null, "Checkbox"),
                React.createElement("div", null,
                    "Checked: ",
                    selectedOptions().map(function (selectedPackage) { return React.createElement("div", { key: selectedPackage.id },
                        "id: ",
                        selectedPackage.id,
                        ", name: ",
                        selectedPackage.name); })),
                React.createElement("form", null, options.map(function (carPackage, index) { return (React.createElement(Checkbox.Layout, { key: index },
                    carPackage.standard ?
                        React.createElement(Checkbox.Placeholder, null) :
                        React.createElement(Checkbox.Root, { defaultChecked: carPackage.selected, id: carPackage.id, value: carPackage.id, onCheckedChange: function (checked) { return onOptionsCheckedChange(carPackage, checked); } },
                            React.createElement(Checkbox.Indicator, null)),
                    React.createElement(Label, { htmlFor: carPackage.id }, carPackage.name))); }))),
            React.createElement("section", null,
                React.createElement("h3", null, "List (not selected items)"),
                React.createElement(List.Root, null, ListMock.map(function (listItem, index) { return (React.createElement(List.Item, { key: index }, listItem)); }))),
            React.createElement("section", null,
                React.createElement("h3", null, "List (selected items)"),
                React.createElement(List.Root, null, ListMock.map(function (listItem, index) { return (React.createElement(List.Item, { key: index, isSelected: true }, listItem)); }))),
            React.createElement("section", null,
                React.createElement("h3", null, "List with checkboxes"),
                React.createElement(List.Root, null, options2.map(function (option) { return (React.createElement(List.Item, { key: option.id, isSelected: option.selected, isPrice: option.price !== null, onClick: !option.standard ? function () { return onOptionsCheckedChange2(option, !option.selected); } : undefined },
                    React.createElement(List.LayoutWithCodeAndChips, null,
                        React.createElement(Checkbox.Layout, null,
                            option.standard ?
                                React.createElement(Checkbox.Placeholder, null) :
                                React.createElement(Checkbox.Root, { checked: option.selected, id: 'lwcb' + option.id, value: option.id, onCheckedChange: function (checked) { return onOptionsCheckedChange2(option, checked); } },
                                    React.createElement(Checkbox.Indicator, null)),
                            React.createElement(Label, { htmlFor: 'lwcb' + option.id }, option.name)),
                        React.createElement(ListItemElement.OptionCode, null, option.id),
                        React.createElement(ListItemElement.Chips, null,
                            option.standard ? React.createElement(OptionStandardChip, null) : (option.package ? React.createElement(OptionPackageChip, null) : React.createElement(ChipPlaceholder, null)),
                            option.visaPrice ? React.createElement(VisaPriceChip, null) : React.createElement(ChipPlaceholder, null)),
                        React.createElement(ListItemElement.Price, null, option.price === null ? React.createElement(LackOfPriceChip, null) : React.createElement(TextAmount, { bold: true }, option.price))))); }))),
            React.createElement("section", null,
                React.createElement("h3", null, "List checkbox for packages"),
                React.createElement(List.Root, null, packages.map(function (carPackage) { return (carPackage.selected ?
                    React.createElement(List.Item, { key: carPackage.id, isSelected: carPackage.selected, isPrice: carPackage.price !== null, onClick: function () { return onPackagesCheckedChange(carPackage, !carPackage.selected); } },
                        React.createElement(List.LayoutWithCodeAndChips, null,
                            React.createElement(Checkbox.Layout, null,
                                React.createElement(Checkbox.Root, { checked: carPackage.selected, id: carPackage.id, value: carPackage.id, onCheckedChange: function (checked) { return onPackagesCheckedChange(carPackage, checked); } },
                                    React.createElement(Checkbox.Indicator, null)),
                                React.createElement(Label, { htmlFor: carPackage.id }, carPackage.name)),
                            React.createElement(ListItemElement.OptionCode, null, carPackage.id),
                            React.createElement(ListItemElement.Chips, null,
                                React.createElement(ChipPlaceholder, null),
                                carPackage.visaPrice ? React.createElement(VisaPriceChip, null) : React.createElement(ChipPlaceholder, null)),
                            React.createElement(ListItemElement.Price, null, carPackage.price === null ? React.createElement(LackOfPriceChip, null) : React.createElement(TextAmount, { bold: true }, carPackage.price))),
                        React.createElement(PackageContent.Root, null, carPackage.content.map(function (element) { return (React.createElement(PackageContent.Element, { key: element.id, name: element.name, id: element.id })); }))) :
                    React.createElement(HoverCard.Root, { key: carPackage.id },
                        React.createElement(List.Item, { isSelected: carPackage.selected, isPrice: carPackage.price !== null, onClick: function () { return onPackagesCheckedChange(carPackage, !carPackage.selected); } },
                            React.createElement(HoverCard.Trigger, { asChild: true },
                                React.createElement(HoverCardTriggerWrapper, null,
                                    React.createElement(List.LayoutWithCodeAndChips, null,
                                        React.createElement(Checkbox.Layout, null,
                                            React.createElement(Checkbox.Root, { defaultChecked: carPackage.selected, id: carPackage.id, value: carPackage.id, onCheckedChange: function (checked) { return onPackagesCheckedChange(carPackage, checked); } },
                                                React.createElement(Checkbox.Indicator, null)),
                                            React.createElement(Label, { htmlFor: carPackage.id }, carPackage.name)),
                                        React.createElement(ListItemElement.OptionCode, null, carPackage.id),
                                        React.createElement(ListItemElement.Chips, null,
                                            React.createElement(ChipPlaceholder, null),
                                            carPackage.visaPrice ? React.createElement(VisaPriceChip, null) : React.createElement(ChipPlaceholder, null)),
                                        React.createElement(ListItemElement.Price, null, carPackage.price === null ? React.createElement(LackOfPriceChip, null) : React.createElement(TextAmount, { bold: true }, carPackage.price))))),
                            React.createElement(HoverCard.Content, { align: 'start', alignOffset: 190, sideOffset: -10 },
                                React.createElement(HoverCardPackageContent.Root, null, carPackage.content.map(function (option) { return (React.createElement(HoverCardPackageContent.Element, { key: option.id, name: option.name, id: option.id })); })))))); }))),
            React.createElement("section", null,
                React.createElement("h3", null, "List with radio buttons"),
                React.createElement(RadioGroup.Root, { value: selectedDriveCar, onValueChange: onDriveCarChange },
                    React.createElement(List.Root, null, listWithRadioButtonsMock.map(function (drive) { return (React.createElement(List.Item, { isSelected: selectedDriveCar === drive.id, key: drive.id, onClick: function () { return onDriveCarChange(drive.id); } },
                        React.createElement(RadioGroup.LayoutWithAmount, { price: drive.price },
                            React.createElement(RadioGroup.Item, { value: drive.id, id: 'lwrb' + drive.id },
                                React.createElement(RadioGroup.Indicator, null)),
                            React.createElement(Label, { htmlFor: 'lwrb' + drive.id }, drive.name)))); })))),
            React.createElement("section", null,
                React.createElement("h3", null, "List with radio buttons with car color"),
                React.createElement(RadioGroup.Root, { value: selectedCarColor, onValueChange: onCarColorChange },
                    React.createElement(List.Root, null, listWithRadioButtonsWithCarColorMock.map(function (carColor) { return (React.createElement(List.Item, { isSelected: selectedCarColor === carColor.id, key: carColor.id, onClick: function () { return onCarColorChange(carColor.id); }, height: 'fixed' },
                        React.createElement(List.LayoutWithCodeAndChips, null,
                            React.createElement(LayoutWithColor, { imagePreviewSrc: carColor.imageSrc, thumbnailImageSrc: carColor.imageSrc },
                                React.createElement(RadioGroup.Item, { value: carColor.id, id: 'lwrbc' + carColor.id },
                                    React.createElement(RadioGroup.Indicator, null)),
                                React.createElement(Label, { htmlFor: 'lwrbc' + carColor.id }, carColor.name)),
                            React.createElement(ListItemElement.OptionCode, null, carColor.id),
                            React.createElement(ListItemElement.Chips, null,
                                carColor.standard ? React.createElement(OptionStandardChip, null) : React.createElement(ChipPlaceholder, null),
                                carColor.visaPrice ? React.createElement(VisaPriceChip, null) : React.createElement(ChipPlaceholder, null)),
                            React.createElement(ListItemElement.Price, null, carColor.price === null ? React.createElement(LackOfPriceChip, null) : React.createElement(TextAmount, { bold: true }, carColor.price))))); })))),
            React.createElement("section", null,
                React.createElement("h3", null, "List with radio buttons with car wheels"),
                React.createElement(RadioGroup.Root, { value: selectedCarWheels, onValueChange: onCarWheelsChange },
                    React.createElement(List.Root, null, listWithRadioButtonsWithWheelsMock.map(function (carWheels) { return (React.createElement(List.Item, { isSelected: selectedCarWheels === carWheels.id, key: carWheels.id, onClick: function () { return onCarWheelsChange(carWheels.id); }, height: 'fixed' },
                        React.createElement(List.LayoutWithCodeAndChips, null,
                            React.createElement(LayoutWithWheels, { imageSrc: carWheels.imageSrc },
                                React.createElement(RadioGroup.Item, { value: carWheels.id, id: 'lwrbw' + carWheels.id },
                                    React.createElement(RadioGroup.Indicator, null)),
                                React.createElement(Label, { htmlFor: 'lwrbw' + carWheels.id }, carWheels.name)),
                            React.createElement(ListItemElement.OptionCode, null, carWheels.id),
                            React.createElement(ListItemElement.Chips, null,
                                carWheels.standard ? React.createElement(OptionStandardChip, null) : React.createElement(ChipPlaceholder, null),
                                carWheels.visaPrice ? React.createElement(VisaPriceChip, null) : React.createElement(ChipPlaceholder, null)),
                            React.createElement(ListItemElement.Price, null, carWheels.price === null ? React.createElement(LackOfPriceChip, null) : React.createElement(TextAmount, { bold: true }, carWheels.price))))); })))),
            React.createElement("section", null,
                React.createElement("h3", null, "Accordion"),
                React.createElement(Accordion.Root, null, accordionMock.map(function (element) { return (React.createElement(Accordion.Item, { value: element.value, key: element.value },
                    React.createElement(Accordion.Trigger, null, element.name),
                    React.createElement(Accordion.Content, null, element.content))); }))),
            React.createElement("section", null,
                React.createElement("h3", null, "Accordion with content"),
                React.createElement("div", null,
                    "React state: id: ",
                    accordionSelectedColor,
                    " name: ",
                    allColors.find(function (color) { return color.id === accordionSelectedColor; }).name),
                React.createElement(Accordion.Root, null,
                    React.createElement(RadioGroup.Root, { value: accordionSelectedColor }, accordionContentMock.map(function (element) { return (React.createElement(Accordion.Item, { value: element.category, key: element.category },
                        React.createElement(Accordion.Trigger, null, element.name),
                        React.createElement(Accordion.Content, null,
                            React.createElement(List.Root, null, element.content.map(function (color) { return (React.createElement(List.Item, { isSelected: accordionSelectedColor === color.id, key: color.id, onClick: function () { return onAccordionColorChange(color.id); }, height: 'fixed' },
                                React.createElement(List.LayoutWithCodeAndChips, null,
                                    React.createElement(LayoutWithColor, { imagePreviewSrc: color.imageSrc, thumbnailImageSrc: color.imageSrc },
                                        React.createElement(RadioGroup.Item, { value: color.id, id: 'acc' + color.id },
                                            React.createElement(RadioGroup.Indicator, null)),
                                        React.createElement(Label, { htmlFor: 'acc' + color.id }, color.name)),
                                    React.createElement(ListItemElement.OptionCode, null, color.id),
                                    React.createElement(ListItemElement.Chips, null,
                                        color.standard ? React.createElement(OptionStandardChip, null) : React.createElement(ChipPlaceholder, null),
                                        color.visaPrice ? React.createElement(VisaPriceChip, null) : React.createElement(ChipPlaceholder, null)),
                                    React.createElement(ListItemElement.Price, null, color.price === null ? React.createElement(LackOfPriceChip, null) : React.createElement(TextAmount, { bold: true }, color.price))))); }))))); })))),
            React.createElement("section", null,
                React.createElement("h3", null, "Amount Input"),
                React.createElement("h4", null, "Normal for commercial offers"),
                "React state: ",
                normalAmountInput,
                React.createElement("div", { style: { width: '160px' } },
                    React.createElement(Label, { uppercase: true }, "kwota upustu"),
                    React.createElement(AmountInput, { name: 'test', value: normalAmountInput, onValueChange: onNormalAmountInputChange })),
                React.createElement("h4", null, "Long for other"),
                "React state: ",
                longAmountInput,
                React.createElement("div", { style: { width: '260px' } },
                    React.createElement(Label, { uppercase: true }, "rabat dodatkowy"),
                    React.createElement(AmountInput, { name: 'test', value: longAmountInput, onValueChange: onLongAmountInputChange }))),
            React.createElement("section", null,
                React.createElement("h3", null, "Percent Input"),
                React.createElement("h4", null, "Normal for commercial offers"),
                "React state: ",
                normalPercentInput,
                React.createElement("div", { style: { width: '166px' } },
                    React.createElement(Label, { uppercase: true }, "procent upustu"),
                    React.createElement(PercentInput, { name: 'test', value: normalPercentInput, onValueChange: onNormalPercentInputChange })),
                React.createElement("h4", null, "Long for other"),
                "React state: ",
                longPercentInput,
                React.createElement("div", { style: { width: '260px' } },
                    React.createElement(Label, { uppercase: true }, "oferta specjalna / grupa rabatowa vcp (procent)"),
                    React.createElement(PercentInput, { name: 'test', value: longPercentInput, onValueChange: onLongPercentInputChange }))),
            React.createElement("section", null,
                React.createElement("h3", null, "List with Short Amount Input (accessories)"),
                React.createElement(List.Root, null,
                    React.createElement(List.AccessoriesListHeading, null),
                    accessories.map(function (accessory) { return (React.createElement(List.Item, { key: accessory.id, isSelected: accessory.selected, height: 'fixed', onClick: function () { return onAccessoriesCheckedChange(accessory, !accessory.selected); } },
                        React.createElement(List.LayoutWithAmountInput, null,
                            React.createElement(Checkbox.Layout, null,
                                React.createElement(Checkbox.Root, { checked: accessory.selected, id: accessory.id, value: accessory.id, onCheckedChange: function (checked) { return onAccessoriesCheckedChange(accessory, checked); } },
                                    React.createElement(Checkbox.Indicator, null)),
                                React.createElement(Label, { htmlFor: accessory.id }, accessory.name)),
                            React.createElement(ListItemElement.OptionCode, null, accessory.id),
                            React.createElement(ListItemElement.CatalogPrice, null,
                                React.createElement(TextAmount, { bold: true }, listWithAmountInputMock.find(function (carAccessory) { return carAccessory.id === accessory.id; }).price)),
                            React.createElement(AmountInput, { max: listWithAmountInputMock.find(function (carAccessory) { return carAccessory.id === accessory.id; }).price, name: accessory.name, value: accessory.price, onValueChange: function (values) { return onAccessoriesPriceChange(values, accessory); } })))); }))),
            React.createElement("section", null,
                React.createElement("h3", null, "Text Input"),
                "React state: ",
                textInput,
                React.createElement(TextInput, { name: 'test', id: 'id', value: textInput, onChange: onTextInputChange, placeholder: 'Oferta handlowa...' })),
            React.createElement("section", null,
                React.createElement("h3", null, "Inputs in commercial offers"),
                React.createElement("p", null,
                    "Dla ceny katalogowej auta ",
                    carPrice),
                React.createElement(LayoutCommercialOffers, null,
                    React.createElement("div", null,
                        React.createElement(LabelPlaceholder, null),
                        React.createElement(TextInput, { name: 'test', id: 'id', value: textInputOffer, onChange: onTextInputOfferChange, placeholder: 'Oferta handlowa...' })),
                    React.createElement("div", null,
                        React.createElement(Label, { uppercase: true, htmlFor: 'upustk' }, "kwota upustu"),
                        React.createElement(AmountInput, { id: 'upustk', name: 'upustk', value: discountValue.stringValue, onValueChange: handleDiscountValueChange }),
                        React.createElement("div", { style: {
                                textAlign: 'end',
                                paddingRight: '16px',
                                marginTop: '2px'
                            } },
                            React.createElement(NetAmount, { value: discountValue.value / 1.23 }))),
                    React.createElement("div", null,
                        React.createElement(Label, { uppercase: true, htmlFor: 'upustp' }, "procent upustu"),
                        React.createElement(PercentInput, { id: 'upustp', name: 'upustp', value: discountPercentValue.stringValue, onValueChange: handlePercentValueChange })),
                    React.createElement("div", { style: { textAlign: 'end' } },
                        React.createElement(Label, { uppercase: true }, "suma po upu\u015Bcie"),
                        React.createElement("div", { style: { height: '32px', display: 'flex', alignItems: 'center' } },
                            React.createElement(TextAmount, { bold: true }, carPrice - discountValue.value))))),
            React.createElement("section", null,
                React.createElement("h3", null, "Buttons"),
                React.createElement("h4", null, "Primary button"),
                React.createElement("div", { style: { display: 'grid', gap: '30px', alignItems: 'center', gridTemplateColumns: '100px 150px 230px' } },
                    React.createElement("span", null, "Default: "),
                    React.createElement("div", { style: { width: '120px' } },
                        React.createElement(Button, { onClick: function () { }, size: 32 }, "utw\u00F3rz")),
                    React.createElement("div", { style: { width: '200px' } },
                        React.createElement(Button, { onClick: function () { } }, "zarejestruj ofert\u0119"))),
                React.createElement("br", null),
                React.createElement("div", { style: { display: 'grid', gap: '30px', alignItems: 'center', gridTemplateColumns: '100px 150px 230px' } },
                    React.createElement("span", null, "Dissabled: "),
                    React.createElement("div", { style: { width: '120px' } },
                        React.createElement(Button, { onClick: function () { }, size: 32, disabled: true }, "utw\u00F3rz")),
                    React.createElement("div", { style: { width: '200px' } },
                        React.createElement(Button, { onClick: function () { }, disabled: true }, "zarejestruj ofert\u0119"))),
                React.createElement("br", null),
                React.createElement("div", { style: { display: 'grid', gap: '30px', alignItems: 'center', gridTemplateColumns: '100px 150px 230px' } },
                    React.createElement("span", null, "With spinner: "),
                    React.createElement("div", { style: { width: '132px' } },
                        React.createElement(Button, { onClick: function () { }, size: 32, isLoading: true }, "utw\u00F3rz")),
                    React.createElement("div", { style: { width: '230px' } },
                        React.createElement(Button, { onClick: function () { }, isLoading: true }, "zarejestruj ofert\u0119"))),
                React.createElement("h4", null, "Secondary button"),
                React.createElement("div", { style: { display: 'grid', gap: '30px', alignItems: 'center', gridTemplateColumns: '100px 150px 230px' } },
                    React.createElement("span", null, "Default: "),
                    React.createElement("div", { style: { width: '120px' } },
                        React.createElement(Button, { onClick: function () { }, size: 32, variant: 'secondary' }, "utw\u00F3rz")),
                    React.createElement("div", { style: { width: '200px' } },
                        React.createElement(Button, { onClick: function () { }, variant: 'secondary' }, "pobierz arkusz"))),
                React.createElement("br", null),
                React.createElement("div", { style: { display: 'grid', gap: '30px', alignItems: 'center', gridTemplateColumns: '100px 150px 230px' } },
                    React.createElement("span", null, "Dissabled: "),
                    React.createElement("div", { style: { width: '120px' } },
                        React.createElement(Button, { onClick: function () { }, size: 32, variant: 'secondary', disabled: true }, "utw\u00F3rz")),
                    React.createElement("div", { style: { width: '200px' } },
                        React.createElement(Button, { onClick: function () { }, variant: 'secondary', disabled: true }, "pobierz arkusz")))),
            React.createElement("section", null,
                React.createElement("h3", null, "Dropdown"),
                "React state: ",
                selectedPriceList,
                React.createElement("div", { style: { width: '312px' } },
                    React.createElement(Dropdown, { options: dropdownOptions(), onChange: onPriceListChange, placeholder: 'Wybierz model', name: 'model' }))),
            React.createElement("section", null,
                React.createElement("h3", null, "Plus / minus"),
                "React state:",
                additionalAccessories.map(function (accessory) { return React.createElement("p", { key: accessory.id },
                    React.createElement("span", null, accessory.name),
                    "  ",
                    React.createElement("span", null, accessory.price)); })),
            React.createElement("section", null,
                React.createElement("h3", null, "Configuration code"),
                React.createElement(ConfigurationCode, { configurationCode: configurationCodeMock })),
            React.createElement("section", null,
                React.createElement("h3", null, "Key Value Table"),
                React.createElement("div", null,
                    React.createElement(TermValueTable.Header, null, "podstawowe dane techniczne"),
                    React.createElement(TermValueTable.TwoColumnSummaryLayout, null,
                        React.createElement(TermValueTable.Column, null, TermValueTableLeftColumnMock.map(function (termValuePair, index) { return (React.createElement(TermValueTable.Row, { key: index, term: termValuePair.term, value: termValuePair.value })); })),
                        React.createElement(TermValueTable.Column, null, TermValueTableRightColumnMock.map(function (termValuePair, index) { return (React.createElement(TermValueTable.Row, { key: index, term: termValuePair.term, value: termValuePair.value })); }))))),
            React.createElement("section", null,
                React.createElement("h3", null, "Summary"),
                React.createElement(Accordion.Root, null,
                    React.createElement(Accordion.Item, { value: 'ofertyHandlowe' },
                        React.createElement(Accordion.Trigger, null, "oferty handlowe"),
                        React.createElement(Accordion.Content, null,
                            React.createElement(Summary.Row, null,
                                React.createElement("div", { style: { display: 'grid', gap: '240px', gridTemplateColumns: '520px 120px 128px', alignItems: 'center' } },
                                    React.createElement(Summary.Header, null, summaryMock.header),
                                    React.createElement(Summary.Subtotal, { gross: summaryMock.subtotalGross, net: summaryMock.subtotalNet, textLabel: '' }),
                                    React.createElement(Summary.Amount, { gross: summaryMock.summaryGross, net: summaryMock.summaryNet, textLabel: '' })))))),
                React.createElement("br", null),
                React.createElement("br", null),
                React.createElement(Accordion.Root, null,
                    React.createElement(Accordion.Item, { value: 'other' },
                        React.createElement(Accordion.Trigger, null, "inne"),
                        React.createElement(Accordion.Content, null,
                            React.createElement(Summary.Row, null,
                                React.createElement("div", { style: { display: 'grid', gap: '64px', gridTemplateColumns: '872px 120px 128px', alignItems: 'center' } },
                                    React.createElement(Summary.Header, null, "razem po rabatowaniu"),
                                    React.createElement(Summary.Subtotal, { gross: summaryMock.subtotalGross, net: summaryMock.subtotalNet, textLabel: 'suma rabat\u00F3w' }),
                                    React.createElement(Summary.Amount, { gross: summaryMock.summaryGross, net: summaryMock.summaryNet, textLabel: 'suma po rabatowaniu' }))))))),
            React.createElement("section", { style: { position: 'relative' } },
                React.createElement("h3", null, "Car preview"),
                React.createElement(Tabs.Root, { defaultValue: TabsForCarPreviewMock[0].value },
                    React.createElement(Tabs.List, null, TabsForCarPreviewMock.map(function (tab, index) { return (React.createElement(Tabs.Trigger, { value: tab.value, key: index }, tab.name)); })),
                    TabsForCarPreviewMock.map(function (tab, index) { return (React.createElement(Tabs.Content, { value: tab.value, key: index }, tab.content)); })),
                React.createElement("div", { style: { position: 'absolute', top: '30px', right: '-43px' } },
                    React.createElement(Dialog.Root, null,
                        React.createElement(Dialog.Trigger, null,
                            React.createElement(CarPreviewButton, null)),
                        React.createElement(Dialog.Content, null,
                            React.createElement(Dialog.CloseButton, null),
                            React.createElement(Dialog.CarPreviewContentLayout, null, !!swiperTabs ?
                                React.createElement(CarPreviewTabs.Root, { defaultValue: swiperTabs[0].value },
                                    React.createElement(CarPreviewTabs.List, null, swiperTabs.map(function (tab, index) { return (React.createElement(CarPreviewTabs.Trigger, { value: tab.value, key: index }, tab.name)); })),
                                    swiperTabs.map(function (tab, index) { return (React.createElement(CarPreviewTabs.Content, { value: tab.value, key: index },
                                        React.createElement(Swiper, { items: tab.photos.map(function (item) { return React.createElement(CarPreviewImage, { src: item }); }) }))); }))
                                : null))))),
            React.createElement("section", null,
                React.createElement("h3", null, "Standard equipment"),
                React.createElement(Accordion.Root, null,
                    React.createElement(Accordion.Item, { value: 'other' },
                        React.createElement(Accordion.Trigger, null, "prezentacja g\u0142\u00F3wnego wyposa\u017Cenia standardowego"),
                        React.createElement(Accordion.Content, null,
                            React.createElement(List.Root, null, standardData.map(function (equipment) { return (React.createElement(List.Item, { key: equipment.category, cursor: 'auto' },
                                React.createElement(List.LayoutStandardEquipment, null,
                                    React.createElement(Label, { uppercase: true, bold: true }, equipment.name),
                                    React.createElement(Label, null, equipment.value),
                                    React.createElement(ListItemElement.OptionCode, { gridColumnStart: 3 }, equipment.code),
                                    equipment.price === 0 ? React.createElement(TextAmountPlaceholder, null) : React.createElement(TextAmount, { bold: true }, equipment.price)))); })))))),
            React.createElement("section", null,
                React.createElement("h3", null, "Winter wheels"),
                React.createElement("div", null,
                    React.createElement("div", null,
                        "wybrane ko\u0142a: ",
                        React.createElement("span", null, selectedWinterWheels))),
                React.createElement(Accordion.Root, null,
                    React.createElement(RadioGroup.Root, { value: selectedWinterWheels, onValueChange: handleChangeSelectedWinterWheels },
                        React.createElement(Accordion.Item, { value: 'winterWheels' },
                            React.createElement(Accordion.Trigger, null, winterWheelsMock.category),
                            React.createElement(Accordion.Content, null,
                                React.createElement(List.Root, null,
                                    React.createElement(List.AccessoriesListHeading, null),
                                    React.createElement(List.Item, { isSelected: selectedWinterWheels === 'any', onClick: function (event) {
                                            if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                                                return;
                                            }
                                            handleChangeSelectedWinterWheels('any');
                                        }, height: "fixed", key: 'any' },
                                        React.createElement("div", { style: { display: 'flex', alignItems: 'center', gap: '10px' } },
                                            React.createElement(RadioGroup.Item, { value: 'any', id: 'any' },
                                                React.createElement(RadioGroup.Indicator, null)),
                                            React.createElement(Label, { htmlFor: 'any' }, "Brak"))),
                                    winterWheelsMock.accessories.map(function (accessory) {
                                        var _a;
                                        return (React.createElement(List.Item, { key: accessory.id, isPrice: accessory.price !== null, isSelected: selectedWinterWheels === accessory.id, height: "fixed", onClick: function (event) {
                                                if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                                                    return;
                                                }
                                                handleChangeSelectedWinterWheels(accessory.id);
                                            } },
                                            React.createElement(List.LayoutWithAmountInput, null,
                                                React.createElement(LayoutWithWheels, { imageSrc: accessory.image },
                                                    React.createElement(RadioGroup.Item, { value: accessory.id, id: accessory.id },
                                                        React.createElement(RadioGroup.Indicator, null)),
                                                    React.createElement(Label, { htmlFor: accessory.id }, accessory.name)),
                                                React.createElement(ListItemElement.OptionCode, null, accessory.optionNo),
                                                React.createElement(ListItemElement.CatalogPrice, null,
                                                    React.createElement(TextAmount, { bold: true }, accessory.price)),
                                                React.createElement(AmountInput, { max: accessory.price, name: accessory.name, defaultValue: (_a = winterWheelsCustomPrice[accessory.id]) !== null && _a !== void 0 ? _a : accessory.price, onValueChange: function (values) { return handleChangeWinterWheelsCustomPrice(values, accessory.id); }, disabled: selectedWinterWheels !== accessory.id }))));
                                    }))))))),
            React.createElement("section", null,
                React.createElement("h3", null, "Wizard"),
                React.createElement(Wizard, { steps: wizardStepsMock, stepIndex: currentWizardStepIndex, header: 'MY2023WE46' })),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("section", null,
                React.createElement("h3", null, "Switch"),
                React.createElement("div", null,
                    React.createElement(Switch, { checked: switchChecked, onCheckedChange: handleSwitchChange }))),
            React.createElement("section", null,
                React.createElement("h3", null, "List checkbox for packages with exchangable options"),
                React.createElement(Accordion.Root, { defaultValue: ['PAKIETY'] },
                    React.createElement(Accordion.Item, { value: packagesNew.category, key: packagesNew.category },
                        React.createElement(Accordion.Trigger, null, packagesNew.category),
                        React.createElement(Accordion.Content, null,
                            React.createElement(List.Root, null, (deduplicateAvailableOptionIdsArray).map(function (id) {
                                return selectedPackages.indexOf(id) < 0 ? (React.createElement(HoverCard.Root, { key: id },
                                    React.createElement(List.Item, { isPrice: getAvailableOptionById(packagesNew.availableOptions, id).price !== null, cursor: getAvailableOptionById(packagesNew.availableOptions, id).standard ? 'default' : 'pointer', onClick: function (event) {
                                            if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                                                return;
                                            }
                                            if (!getAvailableOptionById(packagesNew.availableOptions, id).standard) {
                                                onPackagesWithExchangableOptionCheckedChange(!selectedPackages.includes(getAvailableOptionById(packagesNew.availableOptions, id).id), getAvailableOptionById(packagesNew.availableOptions, id).id, getAvailableOptionById(packagesWithExchangableOption.availableOptions, id).token);
                                            }
                                            else {
                                                return undefined;
                                            }
                                        } },
                                        React.createElement(HoverCard.Trigger, { asChild: true },
                                            React.createElement(HoverCardTriggerWrapper, null,
                                                React.createElement(List.LayoutWithCodeAndChips, null,
                                                    React.createElement(Checkbox.Layout, null,
                                                        getAvailableOptionById(packagesNew.availableOptions, id).standard ? (React.createElement(Checkbox.Placeholder, null)) :
                                                            React.createElement(Checkbox.Root, { checked: selectedPackages.includes(getAvailableOptionById(packagesNew.availableOptions, id).id), value: getAvailableOptionById(packagesNew.availableOptions, id).id, id: getAvailableOptionById(packagesNew.availableOptions, id).id, onCheckedChange: function (checked) {
                                                                    return onPackagesWithExchangableOptionCheckedChange(checked, getAvailableOptionById(packagesNew.availableOptions, id).id, getAvailableOptionById(packagesWithExchangableOption.availableOptions, id).token);
                                                                } },
                                                                React.createElement(Checkbox.Indicator, null)),
                                                        React.createElement(Label, { htmlFor: getAvailableOptionById(packagesNew.availableOptions, id).id }, getAvailableOptionById(packagesNew.availableOptions, id).name)),
                                                    React.createElement(ListItemElement.OptionCode, null, getAvailableOptionById(packagesNew.availableOptions, id).optionNo),
                                                    React.createElement(ListItemElement.Chips, null,
                                                        getAvailableOptionById(packagesNew.availableOptions, id).standard ? (React.createElement(OptionStandardChip, null)) : (React.createElement(ChipPlaceholder, null)),
                                                        React.createElement(ChipPlaceholder, null)),
                                                    React.createElement(ListItemElement.Price, null, getAvailableOptionById(packagesNew.availableOptions, id).price === null ? (React.createElement(LackOfPriceChip, null)) : isFromVCP() ? (React.createElement(TextAmount, { bold: true }, getAvailableOptionById(packagesNew.availableOptions, id).price)) : getAvailableOptionById(packagesNew.availableOptions, id).price === 0 ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, getAvailableOptionById(packagesNew.availableOptions, id).price)))))),
                                        React.createElement(HoverCard.Content, { align: "start", alignOffset: 190, sideOffset: -10 },
                                            React.createElement(HoverCardPackageContent.Root, null, getAvailableOptionById(packagesNew.availableOptions, id).content.map(function (option) { return (React.createElement(HoverCardPackageContent.Element, { key: option.id, name: option.name, id: option.optionNo })); })))))) : (React.createElement(List.Item, { isPrice: getAvailableOptionById(packagesNew.availableOptions, id).price !== null, key: getAvailableOptionById(packagesNew.availableOptions, id).id, isSelected: true, cursor: getAvailableOptionById(packagesNew.availableOptions, id).standard ? 'default' : 'pointer', onClick: function (event) {
                                        if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                                            return;
                                        }
                                        if (!getAvailableOptionById(packagesNew.availableOptions, id).standard) {
                                            onPackagesWithExchangableOptionCheckedChange(!selectedPackages.includes(getAvailableOptionById(packagesNew.availableOptions, id).id), getAvailableOptionById(packagesNew.availableOptions, id).id, getAvailableOptionById(packagesNew.availableOptions, id).token);
                                        }
                                        else {
                                            return undefined;
                                        }
                                    } },
                                    React.createElement(List.LayoutWithCodeAndChips, null,
                                        React.createElement(Checkbox.Layout, null,
                                            getAvailableOptionById(packagesNew.availableOptions, id).standard ? (React.createElement(Checkbox.Placeholder, null)) :
                                                React.createElement(Checkbox.Root, { checked: selectedPackages.includes(getAvailableOptionById(packagesNew.availableOptions, id).id), value: getAvailableOptionById(packagesNew.availableOptions, id).id, id: getAvailableOptionById(packagesNew.availableOptions, id).id, onCheckedChange: function (checked) {
                                                        return onPackagesWithExchangableOptionCheckedChange(checked, getAvailableOptionById(packagesNew.availableOptions, id).id, getAvailableOptionById(packagesWithExchangableOption.availableOptions, id).token);
                                                    } },
                                                    React.createElement(Checkbox.Indicator, null)),
                                            React.createElement(Label, { htmlFor: getAvailableOptionById(packagesNew.availableOptions, id).id }, getAvailableOptionById(packagesNew.availableOptions, id).name)),
                                        React.createElement(ListItemElement.OptionCode, null, getAvailableOptionById(packagesNew.availableOptions, id).optionNo),
                                        React.createElement(ListItemElement.Chips, null,
                                            getAvailableOptionById(packagesNew.availableOptions, id).standard ? (React.createElement(OptionStandardChip, null)) : (React.createElement(ChipPlaceholder, null)),
                                            React.createElement(ChipPlaceholder, null)),
                                        React.createElement(ListItemElement.Price, null, getAvailableOptionById(packagesNew.availableOptions, id).price === null ? (React.createElement(LackOfPriceChip, null)) : isFromVCP() ? (React.createElement(TextAmount, { bold: true }, getAvailableOptionById(packagesNew.availableOptions, id).price)) : getAvailableOptionById(packagesNew.availableOptions, id).price === 0 ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, getAvailableOptionById(packagesNew.availableOptions, id).price)))),
                                    checkIfMultiplePackage(packagesNew.availableOptions, id) ?
                                        React.createElement(PackageContent.Root, null,
                                            React.createElement(RadioGroup.Root, { value: getAvailableOptionById(packagesNew.availableOptions, id).token, onValueChange: handleChangeSelectedOptionToken }, getContents(packagesNew.availableOptions, id).map(function (element) { return (React.createElement(RadioGroup.LayoutForExchengableOptions
                                            // @ts-ignore
                                            , { 
                                                // @ts-ignore
                                                optionNo: element.optionNo, 
                                                // @ts-ignore
                                                key: element.id },
                                                element.token ? (
                                                // @ts-ignore
                                                React.createElement(RadioGroup.Item, { id: element.id, value: element.token },
                                                    React.createElement(RadioGroup.Indicator, null))) : (React.createElement(RadioGroup.Placeholder, null)),
                                                React.createElement(Label, { htmlFor: element.id }, element.name))); })))
                                        :
                                            React.createElement(PackageContent.Root, null, getAvailableOptionById(packagesNew.availableOptions, id).content.map(function (element) { return (React.createElement(PackageContent.Element, { key: element.id, name: element.name, id: element.optionNo })); }))));
                            })))))),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("br", null))));
}
