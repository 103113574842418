import React from 'react';
import { Link } from 'react-router-dom';
import { discountStatusName } from '../discountStatusName';
import './otherRequests.css';
export function OtherRequests(_a) {
    var otherRequests = _a.otherRequests;
    function isSpecialOffer(number) {
        return number.split('/')[0] === 'OS';
    }
    function showValidTo(number, status) {
        if (isSpecialOffer(number)) {
            return status === 'APPROVED';
        }
        else {
            return true;
        }
    }
    return (React.createElement("div", { className: "otherRequestsWrapper", "data-testid": 'other-requests-wrapper' },
        React.createElement("table", { className: "otherRequestsTable" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: "otherRequestsHeadCell otherRequestsNumberCell" }, "Numer"),
                    React.createElement("th", { className: "otherRequestsHeadCell otherRequestsDealerCell" }, "Dealer"),
                    React.createElement("th", { className: "otherRequestsHeadCell otherRequestsStatusCell" }, "Status"),
                    React.createElement("th", { className: "otherRequestsHeadCell otherRequestsValidFromCell" }, "Data wys\u0142ania"),
                    React.createElement("th", { className: "otherRequestsHeadCell otherRequestsValidToCell" }, "Rabat wa\u017Cny do"))),
            React.createElement("tbody", null, otherRequests
                ? otherRequests.map(function (otherRequest) { return (React.createElement("tr", { key: otherRequest.id, className: "otherRequestsTableRow", "data-testid": "other-requests-table-row" },
                    isSpecialOffer(otherRequest.number) ? (React.createElement("td", { className: "otherRequestBodyCell otherRequestsNumberCell", "data-testid": "other-requests-number" },
                        React.createElement(Link, { to: "/special-offer/preview?specialOfferNumber=".concat(encodeURIComponent(otherRequest.number)), className: "activeVcpDiscountsLink", onClick: function () {
                                window.scrollTo({ top: 0 });
                            } }, otherRequest.number))) : (React.createElement("td", { className: "otherRequestBodyCell otherRequestsNumberCell", "data-testid": "other-requests-number" },
                        React.createElement("a", { href: "/#/concessions/preview/".concat(otherRequest.id), className: "activeVcpDiscountsLink" }, otherRequest.number))),
                    React.createElement("td", { className: "otherRequestBodyCell otherRequestsDealerCell", "data-testid": "other-requests-dealer" }, otherRequest.dealerName),
                    React.createElement("td", { className: "otherRequestBodyCell otherRequestsStatusCell", "data-testid": "other-requests-status" }, discountStatusName(otherRequest.status)),
                    React.createElement("td", { className: "otherRequestBodyCell otherRequestsValidFromCell", "data-testid": "other-requests-valid-from" }, otherRequest.submissionTimestamp),
                    React.createElement("td", { className: "otherRequestBodyCell otherRequestsValidToCell", "data-testid": "other-requests-valid-to" }, showValidTo(otherRequest.number, otherRequest.status) ? otherRequest.validTo : ''))); })
                : null))));
}
