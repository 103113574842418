import { NetAmount } from 'pages/demo/components/netAmount/NetAmount.component';
import React from 'react';
import { useFormattedCurrency } from 'utils/formatCurrency';
import './summary.css';
import { Label } from 'components/label/Label.component';
function Row(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'summaryRow' }, children);
}
function Header(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'summaryHeading' }, children);
}
function Subtotal(_a) {
    var gross = _a.gross, net = _a.net, textLabel = _a.textLabel;
    return React.createElement("div", { className: 'summarySubtotalWrapper' },
        React.createElement(Label, { uppercase: true }, textLabel),
        React.createElement("div", { className: 'subtotalGross', "data-testid": 'subtotal-gross' },
            React.createElement("span", null, useFormattedCurrency(gross))),
        React.createElement("div", { className: 'subtotalNet', "data-testid": 'subtotal-net' },
            React.createElement(NetAmount, { value: net })));
}
function Amount(_a) {
    var gross = _a.gross, net = _a.net, textLabel = _a.textLabel;
    return React.createElement("div", { className: 'summaryAmountWrapper' },
        React.createElement(Label, { uppercase: true }, textLabel),
        React.createElement("div", { className: 'summaryGross', "data-testid": 'summary-gross' }, useFormattedCurrency(gross)),
        React.createElement("div", { className: 'summaryNet', "data-testid": 'summary-net' },
            React.createElement("span", null,
                useFormattedCurrency(net),
                " netto")));
}
export var Summary = {
    Row: Row,
    Header: Header,
    Subtotal: Subtotal,
    Amount: Amount
};
