// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .amountInputContainer input:disabled {
    color: var(--text-disabled-input);
    background-color: inherit;
}

#new-dol input:disabled + .amountCurrencySymbol{
    color: var(--text-disabled-input); 
}`, "",{"version":3,"sources":["webpack://./src/pages/creator/accessories/accessoriesTab.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,yBAAyB;AAC7B;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".amountInputContainer input:disabled {\n    color: var(--text-disabled-input);\n    background-color: inherit;\n}\n\ninput:disabled + .amountCurrencySymbol{\n    color: var(--text-disabled-input); \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
