// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .carIcon {
    background-color: var(--white);
    width: 38px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px 0% 0% 16px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}`, "",{"version":3,"sources":["webpack://./src/components/carPreviewButton/carPreviewButton.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,8BAA8B;IAC9B,2CAA2C;AAC/C","sourcesContent":[".carIcon {\n    background-color: var(--white);\n    width: 38px;\n    height: 32px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 16px 0% 0% 16px;\n    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
