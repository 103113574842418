var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import UserContext from '../../../common/userContext';
import { useEffect, useState } from 'react';
import { useCarPreviewApi } from './useCarPreviewApi';
function isFromVCP() {
    return UserContext.isFromVCP() && !UserContext.hasAnyRole('VCP_EMPLOYEE_LEASING');
}
export function useCarPreview(token, selectedPno34) {
    var useCarPreview = useCarPreviewApi();
    var _a = useState(undefined), swiperTabs = _a[0], setSwiperTabs = _a[1];
    useEffect(function () {
        if (selectedPno34) {
            var getCarPreviewByPno34Request = isFromVCP() ? useCarPreview.getColorByPno34Vcp : useCarPreview.getColorByPno34;
            getCarPreviewByPno34Request(selectedPno34).then(function (imageResponse) {
                var interiorLinks = imageResponse.find(function (image) { return image.type.toUpperCase() === 'INTERIOR'; });
                var interiorLinksUrlsSet = new Set(interiorLinks === null || interiorLinks === void 0 ? void 0 : interiorLinks.urls);
                var deduplicateInteriorLinks = __assign(__assign({}, interiorLinks), { urls: Array.from(interiorLinksUrlsSet) });
                var exteriorLinks = imageResponse.find(function (image) { return image.type.toUpperCase() === 'EXTERIOR'; });
                var exteriorLinksUrlsSet = new Set(exteriorLinks === null || exteriorLinks === void 0 ? void 0 : exteriorLinks.urls);
                var deduplicateExteriorLinks = __assign(__assign({}, exteriorLinks), { urls: Array.from(exteriorLinksUrlsSet) });
                setSwiperTabs([
                    {
                        value: 'exterior',
                        name: 'Nadwozie',
                        photos: deduplicateExteriorLinks.urls,
                    },
                    {
                        value: 'interior',
                        name: 'Wnętrze',
                        photos: deduplicateInteriorLinks.urls,
                    },
                ]);
            });
        }
        else if (!selectedPno34 && token) {
            var getCarPreviewRequest = isFromVCP() ? useCarPreview.getColorVcp : useCarPreview.getColor;
            getCarPreviewRequest(token).then(function (imageResponse) {
                var interiorLinks = imageResponse.find(function (image) { return image.type.toUpperCase() === 'INTERIOR'; });
                var interiorLinksUrlsSet = new Set(interiorLinks === null || interiorLinks === void 0 ? void 0 : interiorLinks.urls);
                var deduplicateInteriorLinks = __assign(__assign({}, interiorLinks), { urls: Array.from(interiorLinksUrlsSet) });
                var exteriorLinks = imageResponse.find(function (image) { return image.type.toUpperCase() === 'EXTERIOR'; });
                var exteriorLinksUrlsSet = new Set(exteriorLinks === null || exteriorLinks === void 0 ? void 0 : exteriorLinks.urls);
                var deduplicateExteriorLinks = __assign(__assign({}, exteriorLinks), { urls: Array.from(exteriorLinksUrlsSet) });
                setSwiperTabs([
                    {
                        value: 'exterior',
                        name: 'Nadwozie',
                        photos: deduplicateExteriorLinks.urls,
                    },
                    {
                        value: 'interior',
                        name: 'Wnętrze',
                        photos: deduplicateInteriorLinks.urls,
                    },
                ]);
            });
        }
    }, [token, selectedPno34]);
    return swiperTabs;
}
