var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useAppError } from 'common/appErrorContext';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { useStatus } from 'common/statusContext';
import { PriceListsHeader } from 'components/priceListsHeader/PriceListsHeader.component';
import { Spinner } from 'components/spinner/Spinner';
import { Wizard } from 'components/wizard/Wizard.component';
import React, { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { usePriceListApi } from '../usePriceListApi';
import { DataImport } from './dataImport/DataImport.component';
import { GenerateOrEditPriceList } from './generateOrEditPriceList/GenerateOrEditPriceList.component';
import { RangeOfModels } from './rangeOfModels/RangeOfModels.component';
import { PriceListImportSummary } from './summary/PriceListImportSummary.component';
import SessionStorage from 'common/sessionStorage';
export var PriceListImportContainer = withCancellation(function () {
    var status = useStatus().status;
    var _a = useState(''), currentPriceListName = _a[0], setCurrentPriceListName = _a[1];
    var _b = useState(0), currentWizardStepIndex = _b[0], setCurrentWizardStepIndex = _b[1];
    var stepIndex = useRef(0);
    var _c = useAppError(), appError = _c.appError, setAppError = _c.setAppError;
    var isEmployeeLeasing = SessionStorage.get('employeeLeasingContext');
    var priceListsApi = isEmployeeLeasing ? usePriceListApi({ context: 'employeeLeasing' }) : usePriceListApi();
    function handleGoToNextWizardStep() {
        // zabezpieczenie na wypadek braku kontentu w kolejnym kroku
        if (!wizardStepsMock[stepIndex.current + 1].content) {
            setCurrentWizardStepIndex(function (currentStep) { return currentStep + 1; });
            stepIndex.current = stepIndex.current + 1;
            return handleGoToNextWizardStep();
        }
        else {
            setCurrentWizardStepIndex(function (currentStep) { return currentStep + 1; });
            stepIndex.current = stepIndex.current + 1;
        }
    }
    function handleGoToStepByValue(value) {
        var desiredStep = wizardStepsMock.find(function (step) { return step.value === value; });
        var indexOfDesiredStep = wizardStepsMock.indexOf(desiredStep);
        setCurrentWizardStepIndex(indexOfDesiredStep);
        stepIndex.current = indexOfDesiredStep;
    }
    function handleCurrentPriceListNameChange(value) {
        setCurrentPriceListName(value);
    }
    useEffect(function () {
        return function () {
            setAppError(undefined);
        };
    }, []);
    function isFatalError() {
        return (appError === null || appError === void 0 ? void 0 : appError.redirect) === 'errorPage';
    }
    function cancelImportWhenError() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, priceListsApi.cancelImport(currentPriceListName)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    if (isFatalError()) {
        cancelImportWhenError();
        SessionStorage.remove('employeeLeasingContext');
        return (React.createElement(Redirect, { to: {
                pathname: '/error',
                state: {
                    errorId: appError.errorId,
                    errorDesc: appError.errorDesc,
                },
            } }));
    }
    var wizardStepsMock = [
        {
            value: 'generate-or-edit',
            label: 'Generowanie/Edycja',
            content: (React.createElement(GenerateOrEditPriceList, { handleGoToNextWizardStep: handleGoToNextWizardStep, handleCurrentPriceListNameChange: handleCurrentPriceListNameChange, handleGoToStepByValue: handleGoToStepByValue })),
            type: 'PRIMARY',
        },
        {
            value: 'import-car-models',
            content: (React.createElement(DataImport, { handleGoToNextWizardStep: handleGoToNextWizardStep, title: "Import danych", cancelPopupMessage: "Czy chcesz anulowa\u0107 import danych?", currentPriceListName: currentPriceListName })),
            type: 'SECONDARY',
        },
        {
            value: 'range-of-models',
            label: 'Gama modeli',
            content: (React.createElement(RangeOfModels, { cancelPopupMessage: "Czy chcesz anulowa\u0107 import modeli?", currentPriceListName: currentPriceListName, handleGoToStepByValue: handleGoToStepByValue })),
            type: 'PRIMARY',
        },
        {
            value: 'import-pricelist-data',
            content: (React.createElement(DataImport, { handleGoToNextWizardStep: handleGoToNextWizardStep, title: "Import modeli", cancelPopupMessage: "Czy chcesz anulowa\u0107 import modeli?", currentPriceListName: currentPriceListName })),
            type: 'SECONDARY',
        },
        {
            value: 'summary',
            label: 'Podsumowanie',
            content: (React.createElement(PriceListImportSummary, { priceListName: currentPriceListName, cancelPopupMessage: "Czy chcesz anulowa\u0107 import cennika?" })),
            type: 'PRIMARY',
        },
    ];
    return (React.createElement("div", null,
        React.createElement(PriceListsHeader, null),
        React.createElement("div", { className: "createOrEditPriceListWrapper" },
            React.createElement(Wizard, { steps: wizardStepsMock, stepIndex: currentWizardStepIndex, header: currentPriceListName })),
        status === 'pending' ? React.createElement(Spinner, null) : null));
});
