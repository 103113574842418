import React, { useState } from 'react';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
import { HoverCard } from 'components/hoverCard/HoverCard.component';
import './layoutRadioButtonsWithWheels.css';
export function LayoutWithWheels(_a) {
    var imageSrc = _a.imageSrc, children = _a.children;
    var _b = useState(false), isImageReady = _b[0], setIsImageReady = _b[1];
    var _c = useState(false), isThumbnailImageReady = _c[0], setIsThumbnailImageReady = _c[1];
    return React.createElement("div", { className: 'radioWithWheels' },
        React.createElement(RadioGroup.Layout, null, children),
        React.createElement(HoverCard.Root, null,
            React.createElement(HoverCard.Trigger, null,
                React.createElement("div", { className: 'wheelsIcon', style: { display: isThumbnailImageReady ? 'flex' : 'none' } },
                    React.createElement("img", { className: 'wheelsIconImage', src: imageSrc, alt: '', onLoad: function () { return setIsThumbnailImageReady(true); } }))),
            React.createElement(HoverCard.Content, { side: 'right', align: 'end' },
                React.createElement("div", { className: 'wheelsPreview', style: { display: isImageReady ? 'flex' : 'none' } },
                    React.createElement("img", { className: 'wheelsPreviewImage', src: imageSrc, alt: '', onLoad: function () { return setIsImageReady(true); } })))));
}
