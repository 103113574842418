// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .packageContentRoot {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    width: 856px;
    margin-left: 60px;
    color: inherit;
    margin-top: 6px;
}

#new-dol .packageContentRoot.isSummary {
    width: 920px;
}

#new-dol .packageContentElement {
    display: flex;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/pages/demo/components/packageContent/packageContent.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,iBAAiB;IACjB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC","sourcesContent":[".packageContentRoot {\n    font-family: var(--font-family);\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 32px;\n    width: 856px;\n    margin-left: 60px;\n    color: inherit;\n    margin-top: 6px;\n}\n\n.packageContentRoot.isSummary {\n    width: 920px;\n}\n\n.packageContentElement {\n    display: flex;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
