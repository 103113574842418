import React, { forwardRef } from 'react';
import * as RadixHoverCard from '@radix-ui/react-hover-card';
import './hoverCard.css';
function Root(_a) {
    var children = _a.children;
    return React.createElement(RadixHoverCard.Root, { openDelay: 700 }, children);
}
function Trigger(_a) {
    var _b = _a.asChild, asChild = _b === void 0 ? false : _b, children = _a.children;
    return React.createElement(RadixHoverCard.Trigger, { asChild: asChild }, children);
}
var Content = forwardRef(function (_a, ref) {
    var _b = _a.side, side = _b === void 0 ? 'bottom' : _b, _c = _a.align, align = _c === void 0 ? 'center' : _c, sideOffset = _a.sideOffset, alignOffset = _a.alignOffset, children = _a.children, collisionElement = _a.collisionElement;
    return (React.createElement(RadixHoverCard.Portal, { container: document.getElementById('new-dol') },
        React.createElement(RadixHoverCard.Content, { ref: ref, side: side, align: align, sideOffset: sideOffset, alignOffset: alignOffset, arrowPadding: 5, collisionBoundary: collisionElement }, children)));
});
function Arrow() {
    return React.createElement(RadixHoverCard.Arrow, { width: 20, height: 10, className: "arrow" });
}
export var HoverCard = {
    Root: Root,
    Trigger: Trigger,
    Content: Content,
    Arrow: Arrow,
};
