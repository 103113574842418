import React, { useEffect } from 'react';
import './attractiveness.css';
import { AccordionHeaderPlaceholder } from 'components/accordion/AccordionHeaderPlaceholder';
import { Label } from 'components/label/Label.component';
import { Dropdown } from 'components/dropdown/Dropdown.component';
import { TextAmountWithNetAndLabel } from 'components/textAmount/TextAmountWithNetAndLabel';
import { TextInput } from 'components/textInput/TextInput.component';
import { AmountInput } from 'components/amountInput/AmountInput.component';
import { LayoutCommercialOffers } from 'pages/demo/components/layoutCommercialOffers/LayoutCommercialOffers.component';
import { LabelPlaceholder } from 'components/label/LabelPlaceholder.component';
import { NetAmount } from 'pages/demo/components/netAmount/NetAmount.component';
import { PercentInput } from 'components/percentInput/PercentInput.component';
import { TextAmount } from 'components/textAmount/TextAmount';
import { List } from 'components/list/List.component';
import { Summary } from 'components/summary/Summary.component';
import { PercentInputPlaceholder } from 'components/percentInput/PercentInputPlaceholder.component';
import { Checkbox } from 'components/checkbox/Checkbox.component';
export function Attractiveness(_a) {
    var offer = _a.offer, parties = _a.parties, selectedParty = _a.selectedParty, businessOffer = _a.businessOffer, onBusinessOfferDiscountNameChange = _a.onBusinessOfferDiscountNameChange, onBusinessOfferDiscountAmountChange = _a.onBusinessOfferDiscountAmountChange, onBusinessOfferDiscountPercentChange = _a.onBusinessOfferDiscountPercentChange, specialOfferOrDiscountGroup = _a.specialOfferOrDiscountGroup, onSpecialOfferOrGroupChange = _a.onSpecialOfferOrGroupChange, percentageAdditionalDiscount = _a.percentageAdditionalDiscount, onPercentageAdditionalDiscountChange = _a.onPercentageAdditionalDiscountChange, amountAdditionalDiscount = _a.amountAdditionalDiscount, onAmountAdditionalDiscountChange = _a.onAmountAdditionalDiscountChange, percentageValues = _a.percentageValues, envImpactSection = _a.envImpactSection, leaseTotalCost = _a.leaseTotalCost, onPrintTypeChange = _a.onPrintTypeChange, debouncedChangeBusinessOfferName = _a.debouncedChangeBusinessOfferName, debouncedChangeBusinessOfferDiscount = _a.debouncedChangeBusinessOfferDiscount, debouncedChangeDiscount = _a.debouncedChangeDiscount, onSelectedPartyChange = _a.onSelectedPartyChange;
    function getAmount(discountValue, amountType, alternativeAmount) {
        if (offer.discounts.find(function (discount) { return discount.discountValue === discountValue; })) {
            return offer.discounts.find(function (discount) { return discount.discountValue === discountValue; })[amountType];
        }
        else {
            return alternativeAmount;
        }
    }
    var businessOfferNameDefaultValue = offer && offer.discounts.find(function (discount) { return discount.discountValue === 'BUSINESS_OFFER'; }) && offer.discounts.find(function (discount) { return discount.discountValue === 'BUSINESS_OFFER'; }).name !== 'Oferta handlowa' ? offer.discounts.find(function (discount) { return discount.discountValue === 'BUSINESS_OFFER'; }).name : '';
    useEffect(function () {
        return function () {
            debouncedChangeBusinessOfferName.cancel();
            debouncedChangeBusinessOfferDiscount.cancel();
            debouncedChangeDiscount.cancel();
        };
    }, []);
    return (offer && offer.discounts ?
        (React.createElement("div", { className: "attractivenessWrapper" },
            React.createElement("div", { className: "partyWrapper" },
                React.createElement("div", { className: "party" },
                    React.createElement(Label, { nowrap: true }, "Strona klienta"),
                    React.createElement("div", { className: "partyDropdownWrapper", "data-testid": 'party-dropdown' },
                        React.createElement(Dropdown, { options: parties, onChange: onSelectedPartyChange, name: "party", placeholder: "Wybierz stron\u0119", disabled: !parties || parties.length === 0, defaultValue: parties.find(function (party) { return party.value === decodeURIComponent(selectedParty); }) }))),
                React.createElement("div", { className: "basePriceWrapper" },
                    React.createElement(TextAmountWithNetAndLabel, { grossPrice: offer.priceVariant.totalPriceGrossWithoutAccessories, netPrice: offer.priceVariant.totalPriceNetWithoutAccessories, labelText: "cena katalogowa" }))),
            React.createElement("div", { className: "commercialOfferAccordionHeadePlaceholderWraper" },
                React.createElement(AccordionHeaderPlaceholder, null, "oferta handlowa")),
            React.createElement(LayoutCommercialOffers, null,
                React.createElement("div", null,
                    React.createElement(LabelPlaceholder, null),
                    React.createElement(TextInput, { name: "commercialOffer", defaultValue: businessOfferNameDefaultValue, id: "commercialOffer", onChange: onBusinessOfferDiscountNameChange, placeholder: "Oferta handlowa" })),
                React.createElement("div", null,
                    React.createElement("div", { className: "labelWrapper withPaddingRight" },
                        React.createElement(Label, { uppercase: true, htmlFor: "discountAmount" }, "kwota upustu")),
                    React.createElement(AmountInput, { id: "discountAmount", onValueChange: onBusinessOfferDiscountAmountChange, value: businessOffer.discountAmount, name: "discountAmount" }),
                    React.createElement("div", { className: "netAmountWrapper withPaddingRight" },
                        React.createElement(NetAmount, { value: getAmount('BUSINESS_OFFER', 'amountDiscountNet', 0) }))),
                React.createElement("div", null,
                    React.createElement("div", { className: "labelWrapper withPaddingRight" },
                        React.createElement(Label, { uppercase: true, htmlFor: "discountPercentage" }, "procent upustu")),
                    React.createElement(PercentInput, { id: "discountPercentage", name: "discountPercentage", value: businessOffer.discountPercent, onValueChange: onBusinessOfferDiscountPercentChange })),
                React.createElement("div", { className: 'summaryWithBusinessOfferWrapper' },
                    React.createElement(Label, { uppercase: true }, "suma po upu\u015Bcie"),
                    React.createElement("div", { className: 'summaryWithBusinessOfferAmount' },
                        React.createElement(TextAmount, { bold: true }, getAmount('BUSINESS_OFFER', 'computePriceGross', offer.priceVariant.totalPriceGrossWithoutAccessories))))),
            React.createElement("div", { className: "restDiscountOfferAccordionHeaderPlaceholderWrapper" },
                React.createElement(AccordionHeaderPlaceholder, null, "pozosta\u0142e")),
            React.createElement(List.Root, null,
                React.createElement(List.DiscountItem, null,
                    React.createElement(List.LayoutDiscounts, null,
                        React.createElement(Label, null, "Oferta specjalna / Grupa rabatowa VCP"),
                        React.createElement("div", null,
                            React.createElement("div", { className: "labelWrapper withPaddingRight" },
                                React.createElement(Label, { uppercase: true }, "kwota rabatu")),
                            React.createElement("div", { className: "withPaddingRight" },
                                React.createElement(TextAmount, null, getAmount('SPECIAL_OFFER_OR_DISCOUNT_GROUP_VCP', 'amountDiscountGross', 0))),
                            React.createElement("div", { className: "netAmountWrapper withPaddingRight" },
                                React.createElement(NetAmount, { value: getAmount('SPECIAL_OFFER_OR_DISCOUNT_GROUP_VCP', 'amountDiscountNet', 0) }))),
                        React.createElement(PercentInput, { id: "discountSpecialOfferPercentage", name: "discountSpecialOfferPercentage", value: specialOfferOrDiscountGroup, onValueChange: onSpecialOfferOrGroupChange, disabled: !selectedParty }),
                        React.createElement("div", null,
                            React.createElement("div", { className: "labelWrapper" },
                                React.createElement(Label, { uppercase: true }, "suma po rabacie")),
                            React.createElement(TextAmount, { bold: true }, getAmount('SPECIAL_OFFER_OR_DISCOUNT_GROUP_VCP', 'computePriceGross', 0)),
                            React.createElement("div", { className: "netAmountWrapper" },
                                React.createElement(NetAmount, { value: getAmount('SPECIAL_OFFER_OR_DISCOUNT_GROUP_VCP', 'computePriceNet', 0) }))))),
                React.createElement(List.DiscountItem, null,
                    React.createElement(List.LayoutDiscounts, null,
                        React.createElement(Label, null, "Procentowy rabat dodatkowy"),
                        React.createElement("div", null,
                            React.createElement("div", { className: "labelWrapper withPaddingRight" },
                                React.createElement(Label, { uppercase: true }, "kwota rabatu")),
                            React.createElement("div", { className: "withPaddingRight" },
                                React.createElement(TextAmount, null, getAmount('ADDITIONAL_PERCENTAGE_DISCOUNT', 'amountDiscountGross', 0))),
                            React.createElement("div", { className: "netAmountWrapper withPaddingRight" },
                                React.createElement(NetAmount, { value: getAmount('ADDITIONAL_PERCENTAGE_DISCOUNT', 'amountDiscountNet', 0) }))),
                        React.createElement(PercentInput, { id: "discountSpecialOfferPercentage", name: "discountSpecialOfferPercentage", value: percentageAdditionalDiscount, onValueChange: onPercentageAdditionalDiscountChange }),
                        React.createElement("div", null,
                            React.createElement("div", { className: "labelWrapper" },
                                React.createElement(Label, { uppercase: true }, "suma po rabacie")),
                            React.createElement(TextAmount, { bold: true }, getAmount('ADDITIONAL_PERCENTAGE_DISCOUNT', 'computePriceGross', 0)),
                            React.createElement("div", { className: "netAmountWrapper" },
                                React.createElement(NetAmount, { value: getAmount('ADDITIONAL_PERCENTAGE_DISCOUNT', 'computePriceNet', 0) }))))),
                React.createElement(List.DiscountItem, null,
                    React.createElement(List.LayoutDiscounts, null,
                        React.createElement(Label, null, "Kwotowy rabat dodatkowy"),
                        React.createElement("div", { "data-testid": 'additional-amount-discount' },
                            React.createElement("div", { className: "labelWrapper withPaddingRight" },
                                React.createElement(Label, { uppercase: true }, "kwota rabatu")),
                            React.createElement(AmountInput, { id: "discountAmount", onValueChange: onAmountAdditionalDiscountChange, value: amountAdditionalDiscount, name: "discountAmount" }),
                            React.createElement("div", { className: "netAmountWrapper withPaddingRight" },
                                React.createElement(NetAmount, { value: getAmount('QUANTITATIVE_ADDITIONAL_DISCOUNT', 'amountDiscountNet', 0) }))),
                        React.createElement(PercentInputPlaceholder, null),
                        React.createElement("div", null,
                            React.createElement("div", { className: "labelWrapper" },
                                React.createElement(Label, { uppercase: true }, "suma po rabacie")),
                            React.createElement(TextAmount, { bold: true }, getAmount('QUANTITATIVE_ADDITIONAL_DISCOUNT', 'computePriceGross', 0)),
                            React.createElement("div", { className: "netAmountWrapper" },
                                React.createElement(NetAmount, { value: getAmount('QUANTITATIVE_ADDITIONAL_DISCOUNT', 'computePriceNet', 0) }))))),
                React.createElement(Summary.Row, null,
                    React.createElement("div", { className: "discountsSummaryWrapper", "data-testid": "discounts-summary-wrapper" },
                        React.createElement(Summary.Header, null, "razem po rabatowaniu"),
                        React.createElement(Summary.Subtotal, { gross: offer.priceVariant.discountSummaryGrossAmount, net: offer.priceVariant.discountSummaryNetAmount, textLabel: "suma rabat\u00F3w" }),
                        React.createElement(PercentInputPlaceholder, null),
                        React.createElement(Summary.Amount, { gross: offer.priceVariant.minimumPriceGrossAfterDiscount, net: offer.priceVariant.minimumPriceNetAfterDiscount, textLabel: "suma po rabatach" })))),
            React.createElement("div", { className: "printVariantAccordionHeaderPlaceholderWrapper" },
                React.createElement(AccordionHeaderPlaceholder, null, "wariant wydruku")),
            React.createElement("div", { className: "printVariantsWrapper" },
                React.createElement("div", { className: "printVariantLayout" },
                    React.createElement(Checkbox.Root, { id: "percentageValues", value: "OFFERS_AND_DISCOUNTS_EXPRESSED_IN_PERCENTAGES", checked: percentageValues, onCheckedChange: function (checked) { return onPrintTypeChange(checked, 'OFFERS_AND_DISCOUNTS_EXPRESSED_IN_PERCENTAGES'); } },
                        React.createElement(Checkbox.Indicator, null)),
                    React.createElement(Label, { htmlFor: "percentageValues" }, "Drukuj oferty i rabaty wyra\u017Cone w procentach")),
                React.createElement("div", { className: "printVariantLayout" },
                    React.createElement(Checkbox.Root, { id: "envImpactSection", value: "ENVIRONMENTAL_IMPACT", checked: envImpactSection, onCheckedChange: function (checked) { return onPrintTypeChange(checked, 'ENVIRONMENTAL_IMPACT'); } },
                        React.createElement(Checkbox.Indicator, null)),
                    React.createElement(Label, { htmlFor: "envImpactSection" }, "Drukuj sekcj\u0119 \u201CWp\u0142yw na \u015Brodowisko\u201D")),
                React.createElement("div", { className: "printVariantLayout" },
                    React.createElement(Checkbox.Root, { id: "leaseTotalCost", value: "LEASE_TOTAL_COST", checked: leaseTotalCost, onCheckedChange: function (checked) { return onPrintTypeChange(checked, 'LEASE_TOTAL_COST'); } },
                        React.createElement(Checkbox.Indicator, null)),
                    React.createElement(Label, { htmlFor: "leaseTotalCost" }, "Drukuj sum\u0119 op\u0142at")))))
        : null);
}
