import React from 'react';
export function CalendarIcon(_a) {
    var className = _a.className;
    return React.createElement("svg", { className: className, width: "18", height: "16", viewBox: "0 0 18 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M14.0007 2H16.0007C16.334 2 16.6673 2.33333 16.6673 2.66667V14.6667C16.6673 15 16.334 15.3333 16.0007 15.3333H2.00065C1.60065 15.3333 1.33398 15 1.33398 14.6667V2.66667C1.33398 2.33333 1.60065 2 2.00065 2H4.00065", stroke: "#141414", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M5.33398 0.666672V3.33334", stroke: "#141414", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M12.668 0.666672V3.33334", stroke: "#141414", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M8.66797 6H9.33464", stroke: "#141414", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M12 6H12.6667", stroke: "#141414", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M5.33398 9.33334H6.00065", stroke: "#141414", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M8.66797 9.33334H9.33464", stroke: "#141414", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M12 9.33334H12.6667", stroke: "#141414", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M5.33398 12.6667H6.00065", stroke: "#141414", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M8.66797 12.6667H9.33464", stroke: "#141414", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M6.66797 2H11.3346", stroke: "#141414", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }));
}
