// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .priceListCheckboxesDialogWrapper {
    position: absolute; 
    top: 30px;
    right: -43px;
}

#new-dol .priceListCheckboxesContentWrapper {
    width: 216px;
    min-height: 200px;
    max-height: 618px;
    display: flex;
    flex-direction: column;
    padding: 2px;
    overflow-y: auto;
}

#new-dol .priceListsCheckboxes {
    flex-grow: 1;
    padding: 6px 14px;
}

#new-dol .priceListCheckboxesActionButton {
    width: 100%;
}

#new-dol .priceListCheckbox {
    display: flex;
    gap: 16px;
    height: 40px;
    align-items: center;
    cursor: pointer;
}

#new-dol .priceListCheckboxDisabled {
    cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/pages/priceListImport/ModelsListTab/priceListCheckboxesDialog.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,SAAS;IACT,YAAY;IACZ,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".priceListCheckboxesDialogWrapper {\n    position: absolute; \n    top: 30px;\n    right: -43px;\n}\n\n.priceListCheckboxesContentWrapper {\n    width: 216px;\n    min-height: 200px;\n    max-height: 618px;\n    display: flex;\n    flex-direction: column;\n    padding: 2px;\n    overflow-y: auto;\n}\n\n.priceListsCheckboxes {\n    flex-grow: 1;\n    padding: 6px 14px;\n}\n\n.priceListCheckboxesActionButton {\n    width: 100%;\n}\n\n.priceListCheckbox {\n    display: flex;\n    gap: 16px;\n    height: 40px;\n    align-items: center;\n    cursor: pointer;\n}\n\n.priceListCheckboxDisabled {\n    cursor: default;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
