import React from 'react';
import './termValueTable.css';
function Header(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'termValueTableHeader' }, children.toUpperCase());
}
function Column(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'termValueTableColumn' }, children);
}
function Row(_a) {
    var term = _a.term, value = _a.value;
    return React.createElement("div", { className: 'termValueTableRow' },
        React.createElement("div", { className: 'term' }, term),
        React.createElement("div", { className: 'value' }, value));
}
function TwoColumnSummaryLayout(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'termValueTableTwoColumnLayout' }, children);
}
export var TermValueTable = {
    Header: Header,
    Column: Column,
    Row: Row,
    TwoColumnSummaryLayout: TwoColumnSummaryLayout
};
