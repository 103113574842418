var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useEffect, useState } from 'react';
import { useDriveApi } from './useDriveApi';
import ReactDOM from 'react-dom';
import { useCreatorApi } from '../useCreatorApi';
import { useStatus } from 'common/statusContext';
import { immutableSort } from 'utils/immutableSort';
import SessionStorage from 'common/sessionStorage';
import UserContext from 'common/userContext';
function isFromVCP() {
    return UserContext.isFromVCP() && !UserContext.hasAnyRole('VCP_EMPLOYEE_LEASING');
}
export function useDriveTab(_a) {
    var carModelId = _a.carModelId, canBeSynced = _a.canBeSynced, onSynced = _a.onSynced, onDriveChange = _a.onDriveChange, handleOnTokenChange = _a.handleOnTokenChange, offerId = _a.offerId, handleChangeOffer = _a.handleChangeOffer, configurationId = _a.configurationId, offer = _a.offer, handleChangeClientId = _a.handleChangeClientId, offerIdToClone = _a.offerIdToClone, configurationIdWithContext = _a.configurationIdWithContext, offerIdFromStock = _a.offerIdFromStock, isSaveInSessionStorage = _a.isSaveInSessionStorage, employeeLeasingMode = _a.employeeLeasingMode;
    var _b = useState(undefined), drives = _b[0], setDrives = _b[1];
    var _c = useState(''), selectedDrive = _c[0], setSelectedDrive = _c[1];
    var driveApi = useDriveApi();
    var creatorApi = useCreatorApi(employeeLeasingMode ? { context: 'employeeLeasing' } : undefined);
    var setStatus = useStatus().setStatus;
    var selectCarVersionRequest = creatorApi.selectCarVersion;
    function handleChangeSelectedDrive(drive, token) {
        return __awaiter(this, void 0, void 0, function () {
            var getOfferResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, selectCarVersionRequest(offerId, carModelId, token, 'POWER_TRAIN')];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, creatorApi.getOffer(offerId)];
                    case 2:
                        getOfferResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setStatus('success');
                            handleChangeOffer(getOfferResponse);
                            setSelectedDrive(getOfferResponse.vehicle.selectedToken === token ? drive : '');
                            onDriveChange();
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        function selectDefaultDrive() {
            return __awaiter(this, void 0, void 0, function () {
                var getChangingPriceList, getDrivesRequest, getDrivesResponse, sortedDrivesData_1, getOfferResponse_1, token_1, getOfferResponse_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            getChangingPriceList = SessionStorage.get('changingPriceList');
                            if (!(canBeSynced && carModelId && offerId)) return [3 /*break*/, 7];
                            getDrivesRequest = isFromVCP() ? driveApi.getDrivesVcp : driveApi.getDrives;
                            return [4 /*yield*/, getDrivesRequest(carModelId)];
                        case 1:
                            getDrivesResponse = _a.sent();
                            sortedDrivesData_1 = immutableSort(getDrivesResponse, function (driveA, driveB) { return driveA.powerTrainPrice - driveB.powerTrainPrice; });
                            if (!(configurationId || offerIdToClone || configurationIdWithContext)) return [3 /*break*/, 3];
                            return [4 /*yield*/, creatorApi.getOffer(offerId)];
                        case 2:
                            getOfferResponse_1 = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                setDrives(sortedDrivesData_1);
                                setSelectedDrive(getOfferResponse_1.vehicle.powerTrainId);
                                onSynced();
                            });
                            return [3 /*break*/, 7];
                        case 3:
                            if (!(offerIdFromStock || (isSaveInSessionStorage && !getChangingPriceList))) return [3 /*break*/, 4];
                            ReactDOM.unstable_batchedUpdates(function () {
                                setDrives(sortedDrivesData_1);
                                setSelectedDrive(offer.vehicle.powerTrainId);
                                onSynced();
                            });
                            return [3 /*break*/, 7];
                        case 4:
                            token_1 = sortedDrivesData_1[0].token;
                            return [4 /*yield*/, selectCarVersionRequest(offerId, carModelId, token_1, 'POWER_TRAIN')];
                        case 5:
                            _a.sent();
                            return [4 /*yield*/, creatorApi.getOffer(offerId)];
                        case 6:
                            getOfferResponse_2 = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                setDrives(sortedDrivesData_1);
                                // najtańszy napęd jest ustawiany na starcie
                                setSelectedDrive((getOfferResponse_2 === null || getOfferResponse_2 === void 0 ? void 0 : getOfferResponse_2.vehicle.selectedToken) === token_1 ? sortedDrivesData_1[0].powerTrainId : '');
                                handleOnTokenChange(token_1);
                                handleChangeOffer(getOfferResponse_2);
                                if (getOfferResponse_2.client.clientId) {
                                    handleChangeClientId(getOfferResponse_2.client.clientId);
                                }
                                SessionStorage.set('changingPriceList', false);
                                onSynced();
                            });
                            _a.label = 7;
                        case 7: return [2 /*return*/];
                    }
                });
            });
        }
        selectDefaultDrive();
    }, [canBeSynced, carModelId, offerId]);
    return {
        drives: drives,
        selectedDrive: selectedDrive,
        onSelectedDriveChange: handleChangeSelectedDrive,
    };
}
