// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .carPreviewImage {
	display: block;
	max-width: 100%;
	user-select: none;
}

#new-dol .visible {
	animation: fadeIn 450ms ease-in;
}

#new-dol .hidden {
	animation: fadeOut 450ms ease-out;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

#new-dol .carPreviewImageLack {
	height: 100%;
	color: var(--main-text-color);
	display: flex;
	justify-content: center;
	align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/carPreviewImage/carPreviewImage.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,eAAe;CACf,iBAAiB;AAClB;;AAEA;CACC,+BAA+B;AAChC;;AAEA;CACC,iCAAiC;AAClC;;AAEA;CACC;EACC,UAAU;CACX;CACA;EACC,UAAU;CACX;AACD;;AAEA;CACC;EACC,UAAU;CACX;CACA;EACC,UAAU;CACX;AACD;;AAEA;CACC,YAAY;CACZ,6BAA6B;CAC7B,aAAa;CACb,uBAAuB;CACvB,mBAAmB;AACpB","sourcesContent":[".carPreviewImage {\n\tdisplay: block;\n\tmax-width: 100%;\n\tuser-select: none;\n}\n\n.visible {\n\tanimation: fadeIn 450ms ease-in;\n}\n\n.hidden {\n\tanimation: fadeOut 450ms ease-out;\n}\n\n@keyframes fadeIn {\n\tfrom {\n\t\topacity: 0;\n\t}\n\tto {\n\t\topacity: 1;\n\t}\n}\n\n@keyframes fadeOut {\n\tfrom {\n\t\topacity: 1;\n\t}\n\tto {\n\t\topacity: 0;\n\t}\n}\n\n.carPreviewImageLack {\n\theight: 100%;\n\tcolor: var(--main-text-color);\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
