import { List } from 'components/list/List.component';
import React from 'react';
import ReactPaginate from 'react-paginate';
import './paginatedPriceLists.css';
import { LinkButton } from 'components/linkButton/LinkButton.component';
import { ITEMS_PER_PAGINATION_PAGE } from '../PriceListTab/PriceLists.component';
import { useHistory } from 'react-router-dom';
import SessionStorage from 'common/sessionStorage';
export function PaginatedPriceLists(_a) {
    var items = _a.items, totalPages = _a.totalPages, changePageHandler = _a.changePageHandler, currentPage = _a.currentPage, isImportInProgress = _a.isImportInProgress, employeeLeasing = _a.employeeLeasing;
    var history = useHistory();
    function onPageChange(event) {
        var selectedPage = event.selected;
        changePageHandler(selectedPage);
    }
    function onModifyClick(priceListName) {
        if (employeeLeasing) {
            SessionStorage.set('employeeLeasingContext', true);
        }
        history.push("/price-list/".concat(priceListName, "/modify"));
    }
    return (React.createElement("div", { className: "paginatedPriceListsWrapper" },
        React.createElement("div", { className: "paginatedPriceLists", style: { minHeight: "calc(".concat(ITEMS_PER_PAGINATION_PAGE, " * 48px + 48px)") } },
            React.createElement(List.Root, null, items
                ? items.map(function (priceList, index) { return (React.createElement(List.Item, { key: index, cursor: "default" },
                    React.createElement("div", { className: "priceListItemWrapper", "data-testid": "price-list-item" },
                        React.createElement("div", { className: "priceListItemName" }, priceList.priceList),
                        React.createElement("div", { className: "priceListItemActions" },
                            React.createElement("div", { className: "priceListItemRedirectPlaceholder" }),
                            React.createElement(LinkButton, { onClick: function () { return onModifyClick(priceList.priceList); }, disabled: isImportInProgress }, "modyfikuj"))))); })
                : null)),
        totalPages > 1 ? (React.createElement("div", { className: "pagination", "data-testid": "pagination" },
            React.createElement(ReactPaginate, { breakLabel: "...", nextLabel: "Nast\u0119pna strona", onPageChange: onPageChange, pageRangeDisplayed: 5, pageCount: totalPages, previousLabel: "Poprzednia strona", renderOnZeroPageCount: function () { return null; }, pageLinkClassName: "page-link", previousClassName: "page-item", previousLinkClassName: "page-link-prev", nextClassName: "page-item", nextLinkClassName: "page-link-next", breakClassName: "page-item", breakLinkClassName: "page-link", containerClassName: "pagination", activeClassName: "active", activeLinkClassName: "active-link", forcePage: currentPage }))) : null));
}
