// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .netPrice {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: var(--text-grey);
    text-align: end;
}

#new-dol .netPriceWrapper {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: var(--text-grey);
    display: flex;
    justify-content: flex-end;
    gap: 4px;
}

#new-dol .netTextAmountSummary {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-grey);
}`, "",{"version":3,"sources":["webpack://./src/components/textAmount/netPrice.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,+BAA+B;IAC/B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,aAAa;IACb,yBAAyB;IACzB,QAAQ;AACZ;;AAEA;IACI,+BAA+B;IAC/B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,uBAAuB;AAC3B","sourcesContent":[".netPrice {\n    font-family: var(--font-family);\n    font-weight: 500;\n    font-size: 10px;\n    line-height: 20px;\n    color: var(--text-grey);\n    text-align: end;\n}\n\n.netPriceWrapper {\n    font-family: var(--font-family);\n    font-weight: 500;\n    font-size: 10px;\n    line-height: 20px;\n    color: var(--text-grey);\n    display: flex;\n    justify-content: flex-end;\n    gap: 4px;\n}\n\n.netTextAmountSummary {\n    font-family: var(--font-family);\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    color: var(--text-grey);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
