// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .termValueTableHeader {
    font-family: 'Volvo Novum';
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding-left: 16px;
    margin-bottom: 4px;
}

#new-dol .termValueTableColumn {
    width: 100%;
}

#new-dol .termValueTableRow {
    font-family: 'Volvo Novum';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 0px;
    border-bottom: 1px solid var(--grey); 
    display: flex;
    justify-content: space-between;
}

#new-dol .termValueTableRow .term {
    color: var(--text-grey);
}

#new-dol .termValueTableTwoColumnLayout {
    display: grid;
    grid-template-columns: repeat(2, 571px);
    margin-left: 16px;
    margin-right: 16px;
    gap: 106px;
}`, "",{"version":3,"sources":["webpack://./src/components/termValueTable/termValueTable.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,oCAAoC;IACpC,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,uCAAuC;IACvC,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;AACd","sourcesContent":[".termValueTableHeader {\n    font-family: 'Volvo Novum';\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 24px;\n    padding-left: 16px;\n    margin-bottom: 4px;\n}\n\n.termValueTableColumn {\n    width: 100%;\n}\n\n.termValueTableRow {\n    font-family: 'Volvo Novum';\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    padding: 12px 0px;\n    border-bottom: 1px solid var(--grey); \n    display: flex;\n    justify-content: space-between;\n}\n\n.termValueTableRow .term {\n    color: var(--text-grey);\n}\n\n.termValueTableTwoColumnLayout {\n    display: grid;\n    grid-template-columns: repeat(2, 571px);\n    margin-left: 16px;\n    margin-right: 16px;\n    gap: 106px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
