// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .priceListImportTabsRoot {
	font-family: 'Volvo Novum';
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: var(--text-light-grey);
}

#new-dol .priceListImportTabsList {
	display: flex;
	gap: 24px;
	align-items: center;
	height: 60px;
    padding: 16px 59px;
    background-color: var(--background-very-light-grey);
}

#new-dol .priceListImportTabsTrigger {
	background: none;
	border: none;
	padding: 0;
    font-size: 20px;
    font-weight: 500;
    font-family: var(--font-family);
}

#new-dol .priceListImportTabsTrigger[data-state='active'] {
	color: var(--main-text-color);
}

#new-dol .priceListImportTabsTrigger[data-state='inactive'] {
	color: var(---line-grey-darker);
}

#new-dol .priceListImportTabsContent {
	min-height: calc(70vw / (16 / 9));
}`, "",{"version":3,"sources":["webpack://./src/pages/priceListImport/priceListsImportTabs.css"],"names":[],"mappings":"AAAA;CACC,0BAA0B;CAC1B,gBAAgB;CAChB,eAAe;CACf,iBAAiB;CACjB,6BAA6B;AAC9B;;AAEA;CACC,aAAa;CACb,SAAS;CACT,mBAAmB;CACnB,YAAY;IACT,kBAAkB;IAClB,mDAAmD;AACvD;;AAEA;CACC,gBAAgB;CAChB,YAAY;CACZ,UAAU;IACP,eAAe;IACf,gBAAgB;IAChB,+BAA+B;AACnC;;AAEA;CACC,6BAA6B;AAC9B;;AAEA;CACC,+BAA+B;AAChC;;AAEA;CACC,iCAAiC;AAClC","sourcesContent":[".priceListImportTabsRoot {\n\tfont-family: 'Volvo Novum';\n\tfont-weight: 400;\n\tfont-size: 14px;\n\tline-height: 20px;\n\tcolor: var(--text-light-grey);\n}\n\n.priceListImportTabsList {\n\tdisplay: flex;\n\tgap: 24px;\n\talign-items: center;\n\theight: 60px;\n    padding: 16px 59px;\n    background-color: var(--background-very-light-grey);\n}\n\n.priceListImportTabsTrigger {\n\tbackground: none;\n\tborder: none;\n\tpadding: 0;\n    font-size: 20px;\n    font-weight: 500;\n    font-family: var(--font-family);\n}\n\n.priceListImportTabsTrigger[data-state='active'] {\n\tcolor: var(--main-text-color);\n}\n\n.priceListImportTabsTrigger[data-state='inactive'] {\n\tcolor: var(---line-grey-darker);\n}\n\n.priceListImportTabsContent {\n\tmin-height: calc(70vw / (16 / 9));\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
