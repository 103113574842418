// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .interiorContentRoot {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 872px;
    margin-left: 46px;
    color: inherit;
    margin-top: 12px;
}

#new-dol .interiorContentElement {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
}

#new-dol .interiorContentElementId {
    width: 80px;
    height: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

`, "",{"version":3,"sources":["webpack://./src/pages/demo/components/interiorContent/interiorContent.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,iBAAiB;IACjB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB","sourcesContent":[".interiorContentRoot {\n    font-family: var(--font-family);\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 20px;\n    width: 872px;\n    margin-left: 46px;\n    color: inherit;\n    margin-top: 12px;\n}\n\n.interiorContentElement {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    height: 32px;\n}\n\n.interiorContentElementId {\n    width: 80px;\n    height: 20px;\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
