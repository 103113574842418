// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .switchRoot {
    all: unset;
    background-color: var(--middle-grey);
    border-radius: 9999px;
    position: relative;
}

#new-dol .normalSize {
    width: 48px;
    height: 24px;
}

#new-dol .smallSize {
    width: 28px;
    height: 16px;
}

#new-dol .switchRoot[data-state='checked'] {
    background-color: var(--blue);
}

#new-dol .switchRoot[data-disabled] {
    cursor: not-allowed;
    background-color: var(--line-light-grey);
}

#new-dol .switchThumb {
    display: block;
    background-color: white;
    border-radius: 9999px;
    transition: transform 160ms linear;
    will-change: transform;
}

#new-dol .normalThumb {
    width: 20px;
    height: 20px;
    transform: translateX(2px);
}

#new-dol .smallThumb {
    width: 14px;
    height: 14px;
    transform: translateX(1px);
}

#new-dol .switchThumb[data-disabled] {
    background-color: var(--white);
}

#new-dol .normalThumb[data-state='checked'] {
    transform: translateX(26px);
}

#new-dol .smallThumb[data-state='checked'] {
    transform: translateX(13px);
}`, "",{"version":3,"sources":["webpack://./src/components/switch/switch.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,oCAAoC;IACpC,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;IACnB,wCAAwC;AAC5C;;AAEA;IACI,cAAc;IACd,uBAAuB;IACvB,qBAAqB;IACrB,kCAAkC;IAClC,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,0BAA0B;AAC9B;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".switchRoot {\n    all: unset;\n    background-color: var(--middle-grey);\n    border-radius: 9999px;\n    position: relative;\n}\n\n.normalSize {\n    width: 48px;\n    height: 24px;\n}\n\n.smallSize {\n    width: 28px;\n    height: 16px;\n}\n\n.switchRoot[data-state='checked'] {\n    background-color: var(--blue);\n}\n\n.switchRoot[data-disabled] {\n    cursor: not-allowed;\n    background-color: var(--line-light-grey);\n}\n\n.switchThumb {\n    display: block;\n    background-color: white;\n    border-radius: 9999px;\n    transition: transform 160ms linear;\n    will-change: transform;\n}\n\n.normalThumb {\n    width: 20px;\n    height: 20px;\n    transform: translateX(2px);\n}\n\n.smallThumb {\n    width: 14px;\n    height: 14px;\n    transform: translateX(1px);\n}\n\n.switchThumb[data-disabled] {\n    background-color: var(--white);\n}\n\n.normalThumb[data-state='checked'] {\n    transform: translateX(26px);\n}\n\n.smallThumb[data-state='checked'] {\n    transform: translateX(13px);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
