import React from 'react';
var carsListHeaderList = [
    [],
    ['Stock', 'Model', 'Cena Katalogowa'],
    ['Typ', 'Dealer Order', 'VIN'],
    ['Data Dostawy'],
    ['Data zamrożenia specyfikacji'],
    ['Konfiguracja']
];
export function StockCarsListHeadRow() {
    return (React.createElement("div", { className: "stockCarsListHeaderRow" }, carsListHeaderList.map(function (columnHeader, index) {
        return (React.createElement("div", { className: 'stockCarListColumn', key: index }, columnHeader.map(function (headerElement) {
            return (React.createElement("p", { className: 'stockCarListHeaderText', key: headerElement }, headerElement));
        })));
    })));
}
