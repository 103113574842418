// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .carPreviewTabsRoot {
	font-family: 'Volvo Novum';
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: var(--text-light-grey);
}

#new-dol .carPreviewTabsList {
	display: flex;
	gap: 24px;
	align-items: center;
	height: 32px;
}

#new-dol .carPreviewTabsTrigger {
	background: none;
	border: none;
	padding: 0;
}

#new-dol .carPreviewTabsTrigger[data-state='active'] {
	color: var(--main-text-color);
}

#new-dol .carPreviewTabsContent {
	min-height: calc(70vw / (16 / 9));
}
`, "",{"version":3,"sources":["webpack://./src/components/carPreviewTabs/carPreviewTabs.css"],"names":[],"mappings":"AAAA;CACC,0BAA0B;CAC1B,gBAAgB;CAChB,eAAe;CACf,iBAAiB;CACjB,6BAA6B;AAC9B;;AAEA;CACC,aAAa;CACb,SAAS;CACT,mBAAmB;CACnB,YAAY;AACb;;AAEA;CACC,gBAAgB;CAChB,YAAY;CACZ,UAAU;AACX;;AAEA;CACC,6BAA6B;AAC9B;;AAEA;CACC,iCAAiC;AAClC","sourcesContent":[".carPreviewTabsRoot {\n\tfont-family: 'Volvo Novum';\n\tfont-weight: 400;\n\tfont-size: 14px;\n\tline-height: 20px;\n\tcolor: var(--text-light-grey);\n}\n\n.carPreviewTabsList {\n\tdisplay: flex;\n\tgap: 24px;\n\talign-items: center;\n\theight: 32px;\n}\n\n.carPreviewTabsTrigger {\n\tbackground: none;\n\tborder: none;\n\tpadding: 0;\n}\n\n.carPreviewTabsTrigger[data-state='active'] {\n\tcolor: var(--main-text-color);\n}\n\n.carPreviewTabsContent {\n\tmin-height: calc(70vw / (16 / 9));\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
