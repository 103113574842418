var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { formatShortDate } from 'utils/formatShortDate';
import { formatTime } from 'utils/formatTime';
import './carDamageOCIssues.css';
import { MoreLessButton } from './MoreLessButton.component';
import { PdfIcon } from './pdfIcon.component';
import AuthToken from '../../common/authToken';
import { useCarDamageOCIssues } from './useCarDamageOCIssues';
import { ActionButton } from './ActionButton.component';
import { StatusChip } from './StatusChip.component';
import { ConfirmDialog } from 'pages/priceListImport/confirmDialog/ConfirmDialog.component';
import { useStatus } from 'common/statusContext';
import UserContext from 'common/userContext';
var cancelPopupMessage = 'Czy na pewno chcesz zamknąć zgłoszenie?';
export function BodyRow(_a) {
    var issue = _a.issue, onChangeIssueState = _a.onChangeIssueState;
    var requestCellRef = useRef();
    var clientCellRef = useRef();
    var carDamageOCIssues = useCarDamageOCIssues();
    var _b = useState(true), isCollapsed = _b[0], setIsCollapsed = _b[1];
    var _c = useState(false), isHigherThanClientCellHight = _c[0], setIsHigherThanClientCellHeight = _c[1];
    var _d = useState(0), clientCellHeight = _d[0], setClientCellHeight = _d[1];
    var _e = useState(false), openConfirmDialog = _e[0], setOpenConfirmDialog = _e[1];
    var _f = useState(), issueToClose = _f[0], setIssueToClose = _f[1];
    var _g = useStatus(), status = _g.status, setStatus = _g.setStatus;
    var isFromVcp = UserContext.isFromVCP();
    function countHeight() {
        if (requestCellRef.current && clientCellRef.current) {
            var clientCellRect = clientCellRef.current.getBoundingClientRect();
            var requestCellRect = requestCellRef.current.getBoundingClientRect();
            setClientCellHeight(clientCellRect.height);
            if (requestCellRect.height > clientCellRect.height && isCollapsed) {
                setIsHigherThanClientCellHeight(true);
            }
        }
    }
    useLayoutEffect(function () {
        countHeight();
    }, []);
    useEffect(function () {
        function isReady() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: 
                        //@ts-ignore
                        return [4 /*yield*/, document.fonts.ready];
                        case 1:
                            //@ts-ignore
                            _a.sent();
                            countHeight();
                            return [2 /*return*/];
                    }
                });
            });
        }
        isReady();
    }, []);
    function handleMoreClick() {
        setIsCollapsed(false);
    }
    function handleLessClick() {
        setIsCollapsed(true);
    }
    function handleOnPickupClick(issueId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, carDamageOCIssues.pickupIssue(issueId)];
                    case 1:
                        _a.sent();
                        setStatus('success');
                        onChangeIssueState();
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleOnCloseClick(issueId) {
        setIssueToClose(issueId);
        setOpenConfirmDialog(true);
    }
    function handleOnConfirmDialog() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, carDamageOCIssues.closeIssue(issueToClose)];
                    case 1:
                        _a.sent();
                        setStatus('success');
                        setOpenConfirmDialog(false);
                        setIssueToClose('');
                        onChangeIssueState();
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleOnCancelDialog() {
        setOpenConfirmDialog(false);
        setIssueToClose('');
    }
    var requestCellStyles = isCollapsed && clientCellHeight !== 0 ? { maxHeight: clientCellHeight, overflow: 'hidden' } : undefined;
    function getStatusCellData(issue) {
        if (!issue.pickupTimestamp) {
            return React.createElement(React.Fragment, null, isFromVcp ?
                React.createElement(StatusChip, { title: 'do podj\u0119cia', secondary: true })
                :
                    React.createElement(ActionButton, { title: 'podejmij', onClick: function () { return handleOnPickupClick(issue.id); } }));
        }
        if (issue.pickupTimestamp && !issue.closingTimestamp) {
            return React.createElement(React.Fragment, null,
                isFromVcp ?
                    React.createElement(StatusChip, { title: 'podj\u0119to', secondary: true })
                    :
                        React.createElement(ActionButton, { title: 'zamknij', onClick: function () { return handleOnCloseClick(issue.id); } }),
                issue.pickupAssistantId && issue.pickupAssistantId.value && React.createElement("div", { className: 'issueAssistantWrapper' }, issue.pickupAssistantId.value),
                React.createElement("div", { className: "pickupDateWrapper" },
                    React.createElement("div", { className: "pickupDate" }, formatShortDate(issue.pickupTimestamp)),
                    React.createElement("div", null, formatTime(issue.pickupTimestamp))));
        }
        if (issue.closingTimestamp) {
            return React.createElement(React.Fragment, null,
                React.createElement(StatusChip, { title: 'zamkni\u0119to' }),
                issue.pickupAssistantId && issue.pickupAssistantId.value && React.createElement("div", { className: 'issueAssistantWrapper' }, issue.closingAssistantId.value),
                React.createElement("div", { className: "pickupDateWrapper" },
                    React.createElement("div", { className: "pickupDate" }, formatShortDate(issue.closingTimestamp)),
                    React.createElement("div", null, formatTime(issue.closingTimestamp))));
        }
        return null;
    }
    return (React.createElement("div", { className: "grid bodyRow", "data-testid": 'body-row' },
        React.createElement("div", { className: "clientColumn", "data-testid": 'body-row-client-column' },
            React.createElement("div", { ref: clientCellRef },
                React.createElement("div", { className: "clientName" },
                    issue.name,
                    " ",
                    issue.surname),
                React.createElement("div", { className: "clientPhoneNumber" }, issue.phoneNumber),
                React.createElement("div", { className: "clientEMail" }, issue.email ? issue.email : React.createElement("span", null, "\u2000")))),
        React.createElement("div", { className: "issueColumn", "data-testid": 'body-row-date-column' },
            React.createElement("div", null, formatShortDate(issue.registrationTimestamp)),
            React.createElement("div", null, issue.caseNumber)),
        React.createElement("div", { className: "carColumn", "data-testid": 'body-row-car-column' },
            React.createElement("div", { className: "carModelName" }, issue.modelName),
            React.createElement("div", { className: "carVin" }, issue.vin),
            React.createElement("div", { className: 'carRegistrationNumber' }, issue.carRegistrationNumber)),
        React.createElement("div", { className: "attachmentColumn attachmentsWrapper attachmentBodyColumnCell", "data-testid": 'body-row-attachment-column' },
            React.createElement("a", { key: issue.fileLocationConsentLocation, target: "_blank", href: "/api".concat(issue.fileLocationConsentLocation).concat(issue.fileLocationConsentLocation.indexOf('?') === -1 ? '?' : '&', "authToken=").concat(AuthToken.get()), download: true },
                React.createElement(PdfIcon, null))),
        React.createElement("div", { className: "requestColumn", "data-testid": 'body-row-request-column' },
            React.createElement("div", { style: requestCellStyles, ref: requestCellRef }, issue.request),
            isHigherThanClientCellHight ? (isCollapsed ? (React.createElement(MoreLessButton, { onClick: handleMoreClick }, "Wi\u0119cej")) : (React.createElement(MoreLessButton, { onClick: handleLessClick }, "Mniej"))) : null),
        React.createElement("div", { className: "statusColumn", "data-testid": 'body-row-pickup-column' }, getStatusCellData(issue)),
        React.createElement(ConfirmDialog, { open: openConfirmDialog, message: cancelPopupMessage, onCancel: handleOnCancelDialog, onConfirm: handleOnConfirmDialog })));
}
