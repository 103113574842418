// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .chip {
    width: 68px;
    border-radius: 10px;
    color: #FFFFFF;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    padding: 2px 4px;
    display: flex;
    justify-content: center;
    height: 20px;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/chip/chip.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,cAAc;IACd,+BAA+B;IAC/B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".chip {\n    width: 68px;\n    border-radius: 10px;\n    color: #FFFFFF;\n    font-family: var(--font-family);\n    font-weight: 500;\n    font-size: 12px;\n    line-height: 18px;\n    padding: 2px 4px;\n    display: flex;\n    justify-content: center;\n    height: 20px;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
