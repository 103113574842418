var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { Label } from '../../../components/label/Label.component';
import { RadioGroup } from '../../../components/radioGroup/RadioGroup.component';
import { List } from '../../../components/list/List.component';
import { ListItemElement } from '../../../components/listItemElement/ListItemElement.component';
import { OptionStandardChip } from '../../../components/chip/OptionStandardChip.component';
import { TextAmount } from '../../../components/textAmount/TextAmount';
import { InteriorContent } from '../../demo/components/interiorContent/InteriorContent.component';
import { immutableSort } from 'utils/immutableSort';
export function Interior(_a) {
    var interiorElements = _a.interiorElements, selectedInterior = _a.selectedInterior, onSelectedInteriorChange = _a.onSelectedInteriorChange, isReadOnly = _a.isReadOnly;
    var onInteriorChange = function (value) {
        onSelectedInteriorChange(value);
    };
    var sortedInterior = interiorElements
        ? immutableSort(interiorElements, function (interiorA, interiorB) {
            if (interiorA.versionPrice - interiorB.versionPrice !== 0) {
                return interiorA.versionPrice - interiorB.versionPrice;
            }
            else {
                return interiorA.versionName.localeCompare(interiorB.versionName);
            }
        })
        : [];
    var sortedInteriorWithSortedContent = sortedInterior.map(function (interior) {
        return __assign(__assign({}, interior), { requiredOptions: immutableSort(interior.requiredOptions, function (requiredOptionA, requiredOptionB) {
                return requiredOptionA.name.localeCompare(requiredOptionB.name);
            }) });
    });
    function getOrderedInteriorsByStandardProperty(interiors) {
        var standardInteriors = interiors.filter(function (interior) { return interior.versionStandard; });
        var nonStandardInteriors = interiors.filter(function (interior) { return !interior.versionStandard; });
        return __spreadArray(__spreadArray([], standardInteriors, true), nonStandardInteriors, true);
    }
    var selectedInteriorElement = interiorElements.find(function (element) { return element.versionId === selectedInterior; });
    return (React.createElement("div", { className: "interior__wrapper" }, interiorElements ? (isReadOnly ?
        React.createElement("div", null,
            React.createElement(List.Root, null,
                React.createElement(List.Item, { isPrice: selectedInteriorElement.versionPrice !== null, isSelected: true, cursor: 'default' },
                    React.createElement(List.LayoutWithCodeAndChips, null,
                        React.createElement(RadioGroup.Layout, null,
                            React.createElement(RadioGroup.Placeholder, null),
                            React.createElement(Label, { htmlFor: selectedInteriorElement.versionId }, selectedInteriorElement.versionName)),
                        React.createElement(ListItemElement.OptionCode, null, selectedInteriorElement.versionId),
                        React.createElement(ListItemElement.Chips, null, selectedInteriorElement.versionStandard && React.createElement(OptionStandardChip, null)),
                        React.createElement(ListItemElement.Price, null,
                            React.createElement(TextAmount, { bold: true }, selectedInteriorElement.versionPrice))),
                    selectedInteriorElement.requiredOptions.length > 0 && (React.createElement(InteriorContent.Root, null, selectedInteriorElement.requiredOptions.map(function (ro) { return (React.createElement(InteriorContent.Element, { key: ro.id, name: ro.name, id: ro.id })); }))))))
        :
            React.createElement(RadioGroup.Root, { value: selectedInterior, onValueChange: onInteriorChange },
                React.createElement(List.Root, null, getOrderedInteriorsByStandardProperty(sortedInteriorWithSortedContent).map(function (ie, index) { return (React.createElement(List.Item, { key: ie.versionId, isPrice: ie.versionPrice !== null, isSelected: selectedInterior === ie.versionId, onClick: function (event) {
                        if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                            return;
                        }
                        onInteriorChange(ie.versionId);
                    } },
                    React.createElement(List.LayoutWithCodeAndChips, null,
                        React.createElement(RadioGroup.Layout, { key: index },
                            React.createElement(RadioGroup.Item, { value: ie.versionId, id: ie.versionId },
                                React.createElement(RadioGroup.Indicator, null)),
                            React.createElement(Label, { htmlFor: ie.versionId }, ie.versionName)),
                        React.createElement(ListItemElement.OptionCode, null, ie.versionId),
                        React.createElement(ListItemElement.Chips, null, ie.versionStandard && React.createElement(OptionStandardChip, null)),
                        React.createElement(ListItemElement.Price, null,
                            React.createElement(TextAmount, { bold: true }, ie.versionPrice))),
                    ie.requiredOptions.length > 0 && (React.createElement(InteriorContent.Root, null, ie.requiredOptions.map(function (ro) { return (React.createElement(InteriorContent.Element, { key: ro.id, name: ro.name, id: ro.id })); }))))); })))) : null));
}
