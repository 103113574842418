// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .linkButton {
    text-transform: uppercase;
    border: 1px solid var(--button-primary-default-bg);
    border-radius: 4px;
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    height: 20px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: inherit;
    border: 1px solid var(--button-primary-default-bg);
    color: var(--blue-text-color);
}

#new-dol .linkButton:hover {
    border: 1px solid var(--button-secondary-hover-border);
}

#new-dol .linkButton:active {
    border: 1px solid var(--button-secondary-default-bg);
}

#new-dol .linkButton:disabled {
    border: 1px solid var(--button-secondary-dissabled-border);
    color: var(--blue-text-color-disabled);
    cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/components/linkButton/linkButton.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kDAAkD;IAClD,kBAAkB;IAClB,+BAA+B;IAC/B,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,yBAAyB;IACzB,kDAAkD;IAClD,6BAA6B;AACjC;;AAEA;IACI,sDAAsD;AAC1D;;AAEA;IACI,oDAAoD;AACxD;;AAEA;IACI,0DAA0D;IAC1D,sCAAsC;IACtC,eAAe;AACnB","sourcesContent":[".linkButton {\n    text-transform: uppercase;\n    border: 1px solid var(--button-primary-default-bg);\n    border-radius: 4px;\n    font-family: var(--font-family);\n    font-size: 14px;\n    line-height: 20px;\n    font-weight: 500;\n    height: 20px;\n    width: 100px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    background-color: inherit;\n    border: 1px solid var(--button-primary-default-bg);\n    color: var(--blue-text-color);\n}\n\n.linkButton:hover {\n    border: 1px solid var(--button-secondary-hover-border);\n}\n\n.linkButton:active {\n    border: 1px solid var(--button-secondary-default-bg);\n}\n\n.linkButton:disabled {\n    border: 1px solid var(--button-secondary-dissabled-border);\n    color: var(--blue-text-color-disabled);\n    cursor: default;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
