import { useHttp } from 'http/httpService';
import SessionStorage from 'common/sessionStorage';
import { creatorContextPath } from '../CreatorContextPath';
export function useVersionApi() {
    var http = useHttp();
    var context = SessionStorage.get('employeeLeasingMode') ? 'employeeLeasing' : 'creator';
    return {
        getVersions: function (carModelId, powerTrainId) {
            return http
                .get("/api/".concat(creatorContextPath(context), "/car-version/").concat(carModelId, "?powerTrainId=").concat(powerTrainId))
                .then(function (response) { return response.data; });
        },
        getVersionsVcp: function (carModelId, powerTrainId) {
            return http
                .get("/api/".concat(creatorContextPath(context), "/car-version/vcp/").concat(carModelId, "?powerTrainId=").concat(powerTrainId))
                .then(function (response) {
                return response.data;
            });
        },
    };
}
