// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .plusButton, #new-dol .minusButton {
    all: unset;
    width: 20px;
    height: 20px;
}

#new-dol .newAccessoryWrapper {
    display: grid;
    grid-template-columns: 20px 1036px 160px;
    gap: 16px;
    align-items: center;
}

#new-dol .addAccessoryWrapper {
    display: flex;
    gap: 18px;
}

#new-dol .validateMessage {
    font-family: 'Volvo Novum';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--validate-color);
}

#new-dol .accessoryWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 20px 1024px 123px;
    align-items: center;
    gap: 32px;
    padding-right: 17px;
}

#new-dol .accessoriesLabel {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    width: 100%;
    text-align: right;
    padding-right: 32px;
    margin-bottom: 8px;
    height: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/accessories/accessories.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,wCAAwC;IACxC,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,4BAA4B;AAChC;;AAEA;IACI,WAAW;IACX,aAAa;IACb,wCAAwC;IACxC,mBAAmB;IACnB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,+BAA+B;IAC/B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".plusButton, .minusButton {\n    all: unset;\n    width: 20px;\n    height: 20px;\n}\n\n.newAccessoryWrapper {\n    display: grid;\n    grid-template-columns: 20px 1036px 160px;\n    gap: 16px;\n    align-items: center;\n}\n\n.addAccessoryWrapper {\n    display: flex;\n    gap: 18px;\n}\n\n.validateMessage {\n    font-family: 'Volvo Novum';\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    color: var(--validate-color);\n}\n\n.accessoryWrapper {\n    width: 100%;\n    display: grid;\n    grid-template-columns: 20px 1024px 123px;\n    align-items: center;\n    gap: 32px;\n    padding-right: 17px;\n}\n\n.accessoriesLabel {\n    font-family: var(--font-family);\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 18px;\n    width: 100%;\n    text-align: right;\n    padding-right: 32px;\n    margin-bottom: 8px;\n    height: 18px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
