// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .errorWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#new-dol .row {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

#new-dol .errorHeader {
    font-family: Volvo Novum;
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: center;
    color: #B0B0B0;
}

#new-dol .errorIdPlaceholder {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: #141414;
}

#new-dol .dailyPlan {
    font-family: var(--font-family);
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: center;
    color: #333333;
}

#new-dol .transparentBtn {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

`, "",{"version":3,"sources":["webpack://./src/pages/errorPage/errorPage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,cAAc;IACd,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,wBAAwB;IACxB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,+BAA+B;IAC/B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,+BAA+B;IAC/B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,6BAA6B;IAC7B,4BAA4B;IAC5B,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,aAAa;AACjB","sourcesContent":[".errorWrapper {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.row {\n    width: 100%;\n    display: flex;\n    flex-flow: row;\n    justify-content: center;\n    align-items: center;\n    gap: 30px;\n}\n\n.errorHeader {\n    font-family: Volvo Novum;\n    font-size: 32px;\n    font-weight: 500;\n    line-height: 36px;\n    letter-spacing: 0px;\n    text-align: center;\n    color: #B0B0B0;\n}\n\n.errorIdPlaceholder {\n    font-family: var(--font-family);\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 20px;\n    letter-spacing: 0px;\n    text-align: center;\n    color: #141414;\n}\n\n.dailyPlan {\n    font-family: var(--font-family);\n    font-size: 32px;\n    font-weight: 500;\n    line-height: 36px;\n    letter-spacing: 0px;\n    text-align: center;\n    color: #333333;\n}\n\n.transparentBtn {\n    background-color: transparent;\n    background-repeat: no-repeat;\n    border: none;\n    cursor: pointer;\n    overflow: hidden;\n    outline: none;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
