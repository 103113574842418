import React from 'react';
import { useActiveVcpDiscounts } from './useActiveVcpDiscounts';
import './activeVcpDiscounts.css';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
export var ActiveVcpDiscounts = withCancellation(function (_a) {
    var identificationNumber = _a.identificationNumber;
    var activeVcpDiscounts = useActiveVcpDiscounts(identificationNumber).activeVcpDiscounts;
    function getDiscountGroupValue(vcpDiscount) {
        switch (vcpDiscount.discountGroup.id) {
            case 'SECOND_GMA':
                return vcpDiscount.secondDiscountGroupGlobalMajorAccounts.name;
            case 'PROFESSION_GROUP':
                return vcpDiscount.professionGroups.name;
            case 'DETAILED_SPECIAL_OFFER_GMA':
                return vcpDiscount.specialOfferGlobalMajorAccounts.name;
            case 'DETAILED_SPECIAL_OFFER':
                return vcpDiscount.specialOfferTypes.name;
            default:
                return false;
        }
    }
    return (React.createElement("div", { className: "activeVcpDiscountsWrapper", "data-testid": 'active-vcp-discount-wrapper' },
        React.createElement("table", { className: "activeVcpDiscountsTable" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: "activeVcpDiscountsHeadCell activeVcpDiscountsNumberCell" }, "Numer"),
                    React.createElement("th", { className: "activeVcpDiscountsHeadCell activeVcpDiscountsDealerCell" }, "Dealer"),
                    React.createElement("th", { className: "activeVcpDiscountsHeadCell activeVcpDiscountsGroupCell" }, "Oferta specjalna/Grupa rabatowa"),
                    React.createElement("th", { className: "activeVcpDiscountsHeadCell activeVcpDiscountsValidFromCell" }, "Rabat wa\u017Cny do"))),
            React.createElement("tbody", null, activeVcpDiscounts
                ? activeVcpDiscounts.map(function (vcpDiscount) { return (React.createElement("tr", { className: "activeVcpDiscountsTableRow", key: vcpDiscount.id, "data-testid": "active-vcp-discounts-table-row" },
                    React.createElement("td", { className: "activeVcpDiscountsBodyCell activeVcpDiscountsNumberCell", "data-testid": "active-vcp-dicounts-number" },
                        React.createElement("a", { href: "/#/concessions/preview/".concat(vcpDiscount.id), className: "activeVcpDiscountsLink" }, vcpDiscount.number)),
                    React.createElement("td", { className: "activeVcpDiscountsBodyCell activeVcpDiscountsDealerCell", "data-testid": "active-vcp-dicounts-dealer" }, vcpDiscount.dealerName),
                    React.createElement("td", { className: "activeVcpDiscountsBodyCell activeVcpDiscountsGroupCell", "data-testid": "active-vcp-dicounts-group" },
                        vcpDiscount.discountGroup.name,
                        " ",
                        getDiscountGroupValue(vcpDiscount) ? "- ".concat(getDiscountGroupValue(vcpDiscount)) : ''),
                    React.createElement("td", { className: "activeVcpDiscountsBodyCell activeVcpDiscountsValidToCell", "data-testid": "active-vcp-dicounts-valid-to" }, vcpDiscount.validTo))); })
                : null))));
});
