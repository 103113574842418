import React from 'react';
import './interiorContent.css';
function Root(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'interiorContentRoot' }, children);
}
function Element(_a) {
    var name = _a.name, id = _a.id;
    return React.createElement("div", { className: 'interiorContentElement' },
        React.createElement("span", { className: 'interiorContentElementName' }, name),
        React.createElement("span", { className: 'interiorContentElementId' }, id));
}
export var InteriorContent = {
    Root: Root,
    Element: Element
};
