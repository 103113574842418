// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .generateOrEditWrapper {
    width: 688px;
    display: flex;
    flex-direction: column;
    padding: 0 22px;
    color: var(--main-text-color);
    margin: 0 auto;
}

#new-dol .generateOrEditRadioGroup {
    padding: 25px 56px;
}

#new-dol .radioGroupElements {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

#new-dol .generateOrEditRadio {
    display: flex;
    gap: 8px;
    height: 32px;
    align-items: center;
}

#new-dol .generateOrEditActionsWrapper {
    display: flex;
    gap: 8px;
    align-self: flex-end;
    padding: 100px 12px 0px 12px;
}

#new-dol .editPriceListDropdown {
    width: 180px;
}

#new-dol .createNewPriceListInputWrapper {
    width: 180px;
    height: 32px;
}

#new-dol .createNewPriceListNameInput {
    border: 1px solid var(--line-light-grey);
    border-radius: 4px;
    width: 100%;
    height: 100%;
    padding: 6px 14px;
    color: var(--main-text-color);
}

#new-dol .createNewPriceListNameInput:focus {
    outline: none;
}

#new-dol .generateOrEditButtonWrapper {
    width: 160px;
}

#new-dol .createNewPriceListNameInput::placeholder {
    color: var(--text-grey);
}`, "",{"version":3,"sources":["webpack://./src/pages/priceListImport/priceListImportContainer/generateOrEditPriceList/generateOrEditPriceList.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,6BAA6B;IAC7B,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,oBAAoB;IACpB,4BAA4B;AAChC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,wCAAwC;IACxC,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,6BAA6B;AACjC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".generateOrEditWrapper {\n    width: 688px;\n    display: flex;\n    flex-direction: column;\n    padding: 0 22px;\n    color: var(--main-text-color);\n    margin: 0 auto;\n}\n\n.generateOrEditRadioGroup {\n    padding: 25px 56px;\n}\n\n.radioGroupElements {\n    display: flex;\n    flex-direction: column;\n    gap: 30px;\n}\n\n.generateOrEditRadio {\n    display: flex;\n    gap: 8px;\n    height: 32px;\n    align-items: center;\n}\n\n.generateOrEditActionsWrapper {\n    display: flex;\n    gap: 8px;\n    align-self: flex-end;\n    padding: 100px 12px 0px 12px;\n}\n\n.editPriceListDropdown {\n    width: 180px;\n}\n\n.createNewPriceListInputWrapper {\n    width: 180px;\n    height: 32px;\n}\n\n.createNewPriceListNameInput {\n    border: 1px solid var(--line-light-grey);\n    border-radius: 4px;\n    width: 100%;\n    height: 100%;\n    padding: 6px 14px;\n    color: var(--main-text-color);\n}\n\n.createNewPriceListNameInput:focus {\n    outline: none;\n}\n\n.generateOrEditButtonWrapper {\n    width: 160px;\n}\n\n.createNewPriceListNameInput::placeholder {\n    color: var(--text-grey);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
