export var Errors = [
    {
        code: 'api-error',
        description: 'Wystąpił niespodziewany błąd.',
        redirect: 'errorPage'
    },
    {
        code: 'internal-error',
        description: 'Wystąpił niespodziewany błąd.',
        redirect: 'errorPage'
    },
    {
        code: 'car-option-already-exist',
        description: 'Opcja, którą wybrałeś jest już dodana.',
        redirect: 'errorPopup'
    },
    {
        code: 'car-option-not-found',
        description: 'Nie można zaznaczyć tej opcji. Spróbuj za chwilę.',
        redirect: 'errorPopup'
    },
    {
        code: 'car-version-not-found',
        description: 'Wystąpił niespodziewany błąd.',
        redirect: 'errorPage'
    },
    {
        code: 'color-not-found',
        description: 'Wystąpił niespodziewany błąd.',
        redirect: 'errorPage'
    },
    {
        code: 'eurocode-not-exist',
        description: 'Dla wybranej konfiguracji kalkulację finansowania przygotuj w ZPS.',
        redirect: 'ignore'
    },
    {
        code: 'installment-not-illegal',
        description: 'Wystąpił niespodziewany błąd.',
        redirect: 'errorPage'
    },
    {
        code: 'json-mapper-exception',
        description: 'Wystąpił niespodziewany błąd.',
        redirect: 'errorPage'
    },
    {
        code: 'offer-not-found',
        description: 'Wystąpił niespodziewany błąd.',
        redirect: 'errorPage'
    },
    {
        code: 'token-mLeasing',
        description: 'Dla wybranej konfiguracji kalkulację finansowania przygotuj w ZPS.',
        redirect: 'ignore'
    },
    {
        code: 'api-error-zps',
        description: 'Dla wybranej konfiguracji kalkulację finansowania przygotuj w ZPS.',
        redirect: 'ignore'
    },
    {
        code: 'import-in-progress-exists',
        description: 'Istnieje już import w toku',
        redirect: 'errorPage'
    },
    {
        code: 'krs-does-not-exist-in-database',
        description: 'KRS nie istnieje w bazie danych',
        redirect: 'ignore'
    },
    {
        code: 'nip-does-not-exist-in-database',
        description: 'NIP nie istnieje w bazie danych',
        redirect: 'ignore'
    }
];
var MLEASING_ERRORS = ['ERR_10_007', 'ERR_13_003'];
export function errorDictionary(error) {
    var errorDict = Errors.filter(function (e) { return e.code === error.errorCode; });
    if (error.errorDesc !== undefined && MLEASING_ERRORS.some(function (e) { return error.errorDesc.includes(e); })) {
        return {
            code: 'mLeasing-error',
            description: 'Dla wybranej konfiguracji kalkulację finansowania przygotuj w ZPS.',
            redirect: 'ignore'
        };
    }
    else if (errorDict.length > 0) {
        return errorDict[0];
    }
    else {
        return {
            code: '',
            description: 'Wystąpił niespodziewany błąd.',
            redirect: 'errorPage'
        };
    }
}
