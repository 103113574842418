import { useState, useEffect } from 'react';
import { usePricesListApi } from './usePricesListApi';
import UserContext from 'common/userContext';
import ReactDOM from 'react-dom';
import { immutableSort } from 'utils/immutableSort';
import SessionStorage from 'common/sessionStorage';
function isFromVCP() {
    return UserContext.isFromVCP() && !UserContext.hasAnyRole('VCP_EMPLOYEE_LEASING');
}
export function usePriceListTab(_a) {
    var year = _a.year, model = _a.model, handleOnCarModelIdChange = _a.handleOnCarModelIdChange, configurationId = _a.configurationId, isSaveInSessionStorage = _a.isSaveInSessionStorage, offerIdToClone = _a.offerIdToClone, configurationIdWithContext = _a.configurationIdWithContext, offerIdFromStock = _a.offerIdFromStock, employeeLeasingMode = _a.employeeLeasingMode, handleSetModelYear = _a.handleSetModelYear;
    var _b = useState(undefined), priceLists = _b[0], setPriceLists = _b[1];
    var _c = useState(''), selectedPriceList = _c[0], setSelectedPriceList = _c[1];
    var priceListApi = usePricesListApi();
    function handleChangeSelectedPriceList(priceListName, priceListsToUse) {
        ReactDOM.unstable_batchedUpdates(function () {
            setSelectedPriceList(function (currentPriceListName) {
                if (currentPriceListName && currentPriceListName !== priceListName) {
                    SessionStorage.set('changingPriceList', true);
                }
                return priceListName;
            });
            var changingPriceListFromSessionStorage = SessionStorage.get('changingPriceList');
            if (isSaveInSessionStorage && !changingPriceListFromSessionStorage) {
                return;
            }
            if (!configurationId && !offerIdToClone && !configurationIdWithContext && !offerIdFromStock) {
                if (priceLists) {
                    var carModelId = priceLists.find(function (priceList) { return priceList.priceListName === priceListName; }).carModelId;
                    handleOnCarModelIdChange(carModelId);
                }
                else {
                    var carModelId = priceListsToUse.find(function (priceList) { return priceList.priceListName === priceListName; }).carModelId;
                    handleOnCarModelIdChange(carModelId);
                }
            }
        });
    }
    function handleChangePriceLists(priceLists) {
        setPriceLists(priceLists);
    }
    useEffect(function () {
        if (model && employeeLeasingMode && !isSaveInSessionStorage) {
            var priceListForELRequest = priceListApi.getPricesListForEL;
            priceListForELRequest(model).then(function (data) {
                SessionStorage.set('priceLists', data);
                setSelectedPriceList(data[0].priceListName);
                handleSetModelYear(data[0].year);
                handleOnCarModelIdChange(data[0].carModelId);
            });
        }
        var priceListRequest = isFromVCP() ? priceListApi.getPricesListVcp : priceListApi.getPricesList;
        if (year && model && !configurationId && !isSaveInSessionStorage && !offerIdToClone && !configurationIdWithContext && !offerIdFromStock && !employeeLeasingMode) {
            priceListRequest(year, model).then(function (data) {
                var sortedPriceLists = immutableSort(data, function (a, b) { return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(); });
                SessionStorage.set('priceLists', sortedPriceLists);
                ReactDOM.unstable_batchedUpdates(function () {
                    setPriceLists(sortedPriceLists);
                    // wybieramy pierwszy najnowszy cennik
                    setSelectedPriceList(sortedPriceLists[0].priceListName);
                    handleOnCarModelIdChange(sortedPriceLists[0].carModelId);
                });
            });
        }
    }, [year, model]);
    return {
        priceLists: priceLists,
        selectedPriceList: selectedPriceList,
        onSelectedPriceListChange: handleChangeSelectedPriceList,
        onPriceListsChange: handleChangePriceLists
    };
}
