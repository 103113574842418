var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ReactSelect from 'react-select';
import { ChevronDown } from './ChevronDown.component';
import './dropdown.css';
export function Dropdown(_a) {
    var options = _a.options, onChange = _a.onChange, name = _a.name, _b = _a.keyValue, keyValue = _b === void 0 ? '' : _b, _c = _a.defaultValue, defaultValue = _c === void 0 ? null : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, _e = _a.placeholder, placeholder = _e === void 0 ? 'Wybierz' : _e, value = _a.value, customOptionComponent = _a.CustomOptionComponent, customSingleValue = _a.CustomSingleValueComponent, noOptionsMessage = _a.noOptionsMessage;
    function handleOnChange(option) {
        onChange(option.value, option);
    }
    var customStyles = {
        container: function (provided) { return (__assign(__assign({}, provided), { fontFamily: 'var(--font-family)', color: 'var(--black-text-color)', fontSize: 14, lineHeight: '20px' })); },
        valueContainer: function (provided) { return (__assign(__assign({}, provided), { paddingLeft: 0, paddingRight: 0 })); },
        control: function (provided, status) { return (__assign(__assign({}, provided), { '&:hover': {
                border: '1px solid var(--dropdown-border-color)',
            }, border: '1px solid var(--dropdown-border-color)', boxShadow: 'none', outline: 'none', paddingLeft: 16, paddingRight: 12, gap: 8, minHeight: 32, backgroundColor: status.isDisabled ? 'var(--dropdown-disabled-bg)' : 'var(--dropdown-bg)' })); },
        menu: function (provided) { return (__assign(__assign({}, provided), { boxShadow: 'none', backgroundColor: 'none' })); },
        menuList: function (provided) { return (__assign(__assign({}, provided), { padding: 0, boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.3)', border: '1px solid #D5D5D5', borderRadius: 4, top: '-9px', background: 'var(--white)' })); },
        option: function (provided, state) { return (__assign(__assign({}, provided), { height: 40, display: 'flex', alignItems: 'center', padding: '12px 16px', fontSize: 14, color: '#141414', fontWeight: 400, backgroundColor: state.isFocused ? 'var(--light-grey)' : state.isSelected ? 'var(--light-grey)' : '', '&:active': {
                backgroundColor: 'var(--light-grey)'
            }, fontFamily: 'var(--font-family)' })); },
        placeholder: function (provided, state) { return (__assign(__assign({}, provided), { color: state.isDisabled ? 'var(--text-disabled-input)' : 'var(--text-grey)', marginLeft: 0, marginRight: 0 })); },
        singleValue: function (provided, state) { return (__assign(__assign({}, provided), { color: state.isDisabled ? 'var(--text-disabled-input)' : 'var(--font-family)' })); }
    };
    var components = {
        IndicatorSeparator: function () { return null; },
        IndicatorsContainer: function () { return React.createElement(ChevronDown, { className: "chevronDropdown ".concat(disabled ? 'chevronDisabled' : '') }); },
    };
    if (customOptionComponent) {
        components.Option = customOptionComponent;
    }
    if (customSingleValue) {
        components.SingleValue = customSingleValue;
    }
    return React.createElement(ReactSelect, { options: options, key: keyValue, defaultValue: defaultValue, onChange: handleOnChange, placeholder: placeholder, styles: customStyles, components: components, isSearchable: false, isDisabled: disabled, name: name, value: value, noOptionsMessage: function () { return noOptionsMessage; } });
}
