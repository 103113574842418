// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .amountInputContainer{
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    padding: 6px 16px;
    background-color: #FFFFFF;
}

#new-dol .amountInputContainer input::placeholder {
    color: var(--main-text-color);
}

#new-dol .amountInputContainer input {
    border: none;
    text-align: end;
    box-sizing: border-box;
    width: 100%;
}

#new-dol .amountInputContainer input:focus {
    outline: none;
}

#new-dol .amountCurrencySymbol {
    margin-left: 2px;
}

#new-dol .validate {
    border: 1px solid var(--validate-color);
}`, "",{"version":3,"sources":["webpack://./src/components/amountInput/amountInput.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,+BAA+B;IAC/B,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,uCAAuC;AAC3C","sourcesContent":[".amountInputContainer{\n    width: 100%;\n    height: 32px;\n    display: flex;\n    align-items: center;\n    font-family: var(--font-family);\n    font-size: 14px;\n    line-height: 20px;\n    font-weight: 400;\n    border: 1px solid #D5D5D5;\n    border-radius: 4px;\n    padding: 6px 16px;\n    background-color: #FFFFFF;\n}\n\n.amountInputContainer input::placeholder {\n    color: var(--main-text-color);\n}\n\n.amountInputContainer input {\n    border: none;\n    text-align: end;\n    box-sizing: border-box;\n    width: 100%;\n}\n\n.amountInputContainer input:focus {\n    outline: none;\n}\n\n.amountCurrencySymbol {\n    margin-left: 2px;\n}\n\n.validate {\n    border: 1px solid var(--validate-color);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
