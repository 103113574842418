import React from 'react';
import ReactPaginate from 'react-paginate';
import { BodyRow } from './BodyRow.component';
export function PaginatedIssues(_a) {
    var issues = _a.issues, totalPages = _a.totalPages, onChangeIssueState = _a.onChangeIssueState, changePageHandler = _a.changePageHandler, currentPage = _a.currentPage;
    function onPageChange(event) {
        var selectedPage = event.selected;
        changePageHandler(selectedPage);
    }
    return (React.createElement(React.Fragment, null,
        issues
            ? issues.map(function (issue) { return React.createElement(BodyRow, { key: issue.id, issue: issue, onChangeIssueState: onChangeIssueState }); })
            : null,
        totalPages > 1 ? (React.createElement("div", { className: "carDamageOCIssuesPagination", "data-testid": "pagination" },
            React.createElement(ReactPaginate, { breakLabel: "...", nextLabel: "Nast\u0119pna strona", onPageChange: onPageChange, pageRangeDisplayed: 5, pageCount: totalPages, previousLabel: "Poprzednia strona", renderOnZeroPageCount: null, pageLinkClassName: "page-link", previousClassName: "page-item", previousLinkClassName: "page-link-prev", nextClassName: "page-item", nextLinkClassName: "page-link-next", breakClassName: "page-item", breakLinkClassName: "page-link", containerClassName: "pagination", activeClassName: "active", activeLinkClassName: "active-link", forcePage: currentPage }))) : null));
}
