import AuthToken from './authToken';
import SessionStorage from './sessionStorage';
var UserContext = /** @class */ (function () {
    function UserContext() {
    }
    UserContext.prototype.create = function (authenticationData) {
        this.store(authenticationData, false);
    };
    UserContext.prototype.grantedRoles = function () {
        return SessionStorage.get('roles') || [];
    };
    UserContext.prototype.isLoggedIn = function () {
        return AuthToken.exists();
    };
    UserContext.prototype.isNotLoggedIn = function () {
        return !this.isLoggedIn();
    };
    UserContext.prototype.isImpersonated = function () {
        return SessionStorage.get('impersonated');
    };
    UserContext.prototype.login = function () {
        return SessionStorage.get('login');
    };
    UserContext.prototype.organizationId = function () {
        return SessionStorage.get('organizationId');
    };
    UserContext.prototype.organizationName = function () {
        return SessionStorage.get('organizationName');
    };
    UserContext.prototype.availableOrganizations = function () {
        return SessionStorage.get('availableOrganizations');
    };
    UserContext.prototype.isFromVCP = function () {
        return this.organizationId() === 'PL100';
    };
    UserContext.prototype.isNotFromVCP = function () {
        return !this.isFromVCP();
    };
    UserContext.prototype.isFleetSales = function () {
        return SessionStorage.get('fleetSales');
    };
    UserContext.prototype.hasAnyRole = function () {
        var roles = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            roles[_i] = arguments[_i];
        }
        var grantedRoles = this.grantedRoles();
        return roles.some(function (role) {
            return grantedRoles.includes(role);
        });
    };
    UserContext.prototype.hasOnlyRole = function (role) {
        var grantedRoles = this.grantedRoles();
        return grantedRoles.length === 1 && grantedRoles.includes(role);
    };
    UserContext.prototype.hasOnlyRoles = function () {
        var roles = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            roles[_i] = arguments[_i];
        }
        var grantedRoles = this.grantedRoles();
        return grantedRoles.length === roles.length && roles.every(function (role) {
            return grantedRoles.includes(role);
        });
    };
    UserContext.prototype.doesNotHaveRole = function (role) {
        var grantedRoles = this.grantedRoles();
        return !grantedRoles.includes(role);
    };
    UserContext.prototype.store = function (authenticationData, impersonated) {
        AuthToken.set(authenticationData.authToken);
        SessionStorage.set('login', AuthToken.decodeLogin());
        SessionStorage.set('organizationId', AuthToken.decodeOrganizationId());
        SessionStorage.set('roles', AuthToken.decodeRoles());
        SessionStorage.set('organizationName', authenticationData.organizationName);
        SessionStorage.set('availableOrganizations', authenticationData.availableOrganizations);
        SessionStorage.set('fleetSales', authenticationData.fleetSales);
        SessionStorage.set('impersonated', impersonated);
    };
    return UserContext;
}());
export default new UserContext();
