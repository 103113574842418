// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .newSpecialOfferWrapper {
    width: 1280px;
    padding: 32px 0;
    margin: 0 auto;
}

#new-dol .specialOfferSubmitButtonWrapper {
    padding: 8px 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

#new-dol .specialOfferSubmitButton {
    width: 208px;
}

#new-dol .newSpecialOfferComment {
    padding: 32px 0;
}

#new-dol .specialOfferPlusButton {
    all: unset;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 11px;
    left: 260px;
    cursor: pointer;
}

#new-dol .specialOfferPlusButton.disabled {
    cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/pages/specialOffer/newSpecialOfferForm/newSpecialOfferForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".newSpecialOfferWrapper {\n    width: 1280px;\n    padding: 32px 0;\n    margin: 0 auto;\n}\n\n.specialOfferSubmitButtonWrapper {\n    padding: 8px 0;\n    display: flex;\n    justify-content: flex-end;\n    align-items: flex-end;\n}\n\n.specialOfferSubmitButton {\n    width: 208px;\n}\n\n.newSpecialOfferComment {\n    padding: 32px 0;\n}\n\n.specialOfferPlusButton {\n    all: unset;\n    width: 20px;\n    height: 20px;\n    position: absolute;\n    top: 11px;\n    left: 260px;\n    cursor: pointer;\n}\n\n.specialOfferPlusButton.disabled {\n    cursor: not-allowed;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
