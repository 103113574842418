import React, { useEffect, useState } from 'react';
import { StartOfferHeader } from './startOfferHeader.component';
import { Dropdown } from 'components/dropdown/Dropdown.component';
import { Button } from 'components/button/Button.component';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { useHistory } from 'react-router-dom';
import { useStartOffer } from './useStartOffer';
export var EmployeeLeasingStartOffer = withCancellation(function () {
    var _a = useState(), availableCases = _a[0], setAvailableCases = _a[1];
    var _b = useState(), selectedCaseOption = _b[0], setSelectedCaseOption = _b[1];
    var history = useHistory();
    var StartOffer = useStartOffer();
    function isCreateButtonDisabled() {
        return !selectedCaseOption;
    }
    function handleChangeCaseNumber(_, option) {
        setSelectedCaseOption(option);
    }
    var dropdownCaseNumberOptions = availableCases && availableCases.map(function (lpCase) {
        return {
            value: lpCase.id,
            label: lpCase.id + ' - ' + lpCase.model.toUpperCase()
        };
    });
    function handleCreate(event) {
        event.preventDefault();
        var selectedCase = availableCases.find(function (lpCase) { return lpCase.id === selectedCaseOption.value; });
        history.push({
            pathname: '/employee-leasing-creator',
            state: { selectedCase: selectedCase }
        });
    }
    useEffect(function () {
        StartOffer.employeeLeasingCases().then(function (cases) {
            setAvailableCases(cases);
            if (cases.length === 1) {
                setSelectedCaseOption({
                    value: cases[0].id,
                    label: cases[0].id + ' - ' + cases[0].model.toUpperCase()
                });
            }
        });
    }, []);
    return (React.createElement("div", { className: "startOfferWrapper" },
        React.createElement(StartOfferHeader, { text: "nowa oferta" }),
        React.createElement("div", { className: "startOfferContentWrapper" },
            React.createElement("form", { className: "startOfferSelects", onSubmit: handleCreate },
                React.createElement("div", { className: "startOfferDropdown", "data-testid": 'cases-dropdown' },
                    React.createElement(Dropdown, { options: dropdownCaseNumberOptions, onChange: handleChangeCaseNumber, placeholder: "Wybierz spraw\u0119", name: "caseNumber", value: selectedCaseOption, noOptionsMessage: "Brak aktywnych spraw" })),
                React.createElement("div", { className: "statrOfferButton" },
                    React.createElement(Button, { size: 32, disabled: isCreateButtonDisabled(), type: "submit" }, "utw\u00F3rz")))),
        React.createElement("img", { src: "/assets/images/new-dol/background_start_offer.png", alt: "", className: "startOfferBackground" })));
});
