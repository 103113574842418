// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .SliderRoot {
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    touch-action: none;
    width: 100%;
}

#new-dol .SliderRoot[data-orientation='horizontal'] {
    height: 24px;
    background: var(--background-very-light-grey);
    border: 1px solid var(--line-light-grey);
    border-radius: 27px;
}

#new-dol .SliderRoot[data-orientation='vertical'] {
    flex-direction: column;
    width: 20px;
    height: 100px;
}

#new-dol .SliderTrack {
    position: relative;
    flex-grow: 1;
    border-radius: 9999px;
}
#new-dol .SliderTrack[data-orientation='horizontal'] {
    height: 3px;
}
#new-dol .SliderTrack[data-orientation='vertical'] {
    width: 3px;
}

#new-dol .SliderRange {
    position: absolute;
    border-radius: 9999px;
    height: 100%;
}

#new-dol .SliderThumb {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 78px;
    height: 24px;
    background-color: var(--blue);
    border-radius: 27px;
}

#new-dol span.slider-printed-value {
    font-size: 14px;
    line-height: 24px;
    color: white;
    font-weight: bold;
}

#new-dol .SliderThumb:hover {
    background-color: var(--blue);
}
#new-dol .SliderThumb:focus {
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/slider/slider.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,6CAA6C;IAC7C,wCAAwC;IACxC,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,qBAAqB;AACzB;AACA;IACI,WAAW;AACf;AACA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;AACjC;AACA;IACI,aAAa;AACjB","sourcesContent":[".SliderRoot {\n    position: relative;\n    display: flex;\n    align-items: center;\n    user-select: none;\n    touch-action: none;\n    width: 100%;\n}\n\n.SliderRoot[data-orientation='horizontal'] {\n    height: 24px;\n    background: var(--background-very-light-grey);\n    border: 1px solid var(--line-light-grey);\n    border-radius: 27px;\n}\n\n.SliderRoot[data-orientation='vertical'] {\n    flex-direction: column;\n    width: 20px;\n    height: 100px;\n}\n\n.SliderTrack {\n    position: relative;\n    flex-grow: 1;\n    border-radius: 9999px;\n}\n.SliderTrack[data-orientation='horizontal'] {\n    height: 3px;\n}\n.SliderTrack[data-orientation='vertical'] {\n    width: 3px;\n}\n\n.SliderRange {\n    position: absolute;\n    border-radius: 9999px;\n    height: 100%;\n}\n\n.SliderThumb {\n    display: flex;\n    justify-content: center;\n    align-content: center;\n    width: 78px;\n    height: 24px;\n    background-color: var(--blue);\n    border-radius: 27px;\n}\n\nspan.slider-printed-value {\n    font-size: 14px;\n    line-height: 24px;\n    color: white;\n    font-weight: bold;\n}\n\n.SliderThumb:hover {\n    background-color: var(--blue);\n}\n.SliderThumb:focus {\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
