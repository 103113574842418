import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarIcon } from './CalendarIcon';
import './datepicker.css';
export function DatePickerInputWithIcon(_a) {
    var date = _a.date, minDate = _a.minDate, dateOnChangeHandler = _a.dateOnChangeHandler, maxDate = _a.maxDate, _b = _a.filterWeekend, filterWeekend = _b === void 0 ? true : _b;
    var days = ['NDZ.', 'PON.', 'WT.', 'ŚR.', 'CZW.', 'PT.', 'SB.'];
    var months = [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień',
    ];
    var locale = {
        localize: {
            day: function (n) { return days[n]; },
            month: function (n) { return months[n]; },
        },
        formatLong: {
            date: function () { return 'mm/dd/yyyy'; },
        },
    };
    var isWeekday = function (date) {
        var day = date.getDay();
        return day !== 0 && day !== 6;
    };
    var CustomInput = forwardRef(function (_a, ref) {
        var value = _a.value, onClick = _a.onClick, onChange = _a.onChange;
        return (React.createElement("div", { className: "datePickerCustomInputWrapper" },
            React.createElement(CalendarIcon, { className: "datePickerCalendarIcon" }),
            React.createElement("input", { value: value, className: "datePickerCustomInput", onClick: onClick, onChange: onChange, ref: ref, style: { cursor: 'pointer' } })));
    });
    return (React.createElement(DatePicker, { selected: date, 
        // @ts-ignore
        locale: locale, minDate: minDate, maxDate: maxDate, dateFormat: "dd.MM.yyyy", showPopperArrow: false, disabledKeyboardNavigation: true, onChange: dateOnChangeHandler, calendarStartDay: 1, filterDate: filterWeekend ? isWeekday : null, customInput: React.createElement(CustomInput, null) }));
}
