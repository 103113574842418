var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { stockCategoryDictionary } from 'common/stockCategoryDictionary';
import { Button } from 'components/button/Button.component';
import { InfoIcon } from 'components/infoIcon/InfoIcon.component';
import { Label } from 'components/label/Label.component';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
import { HorizontalLine } from 'pages/specialOffer/horizontalLine/HorizontalLine';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import ModelDictionary from 'common/modelDictionary';
import { useNewOfferFromStockApi } from 'pages/newOfferFromStock/useNewOfferFromStockApi';
import { Dropdown } from 'components/dropdown/Dropdown.component';
import { RemoveIcon } from 'components/dropdown/RemoveIcon.component';
import { MultiDropdownWithoutChips } from 'components/dropdown/MultiDropdownWithoutChips';
import { DateRangePicker } from 'components/datepicker/DateRangePicker';
import { format } from 'date-fns';
var generateStockCarOptionLabel = function (e) {
    return (stockCategoryDictionary(e.stockCategory) +
        ', ' +
        ModelDictionary.nameFor(e.modelId.split('_')[0]) +
        ' ' +
        "(".concat(e.modelId.split('_')[1], ")") +
        ', ' +
        "".concat(e.dealerOrder ? 'DO: ' + e.dealerOrder + ',' : '') +
        ' ' +
        "".concat(e.commonOrderNumber ? 'CON: ' + e.commonOrderNumber + ',' : '') +
        ' ' +
        e.vin);
};
var dateRangeFilterKeys = ['lastDateForOrderChange', 'deliveryDate'];
var sortingAvailableOptions = [
    {
        label: 'najwcześniejsza data dostawy',
        value: 'earliestDeliverytDate'
    },
    {
        label: 'najpóźniejsza data dostawy',
        value: 'latestDeliveryDate'
    },
    {
        label: 'najwcześniejsza data zamrożenia specyfikacji',
        value: 'earliestLastDateForOrderChange'
    },
    {
        label: 'najpóźniejsza data zamrożenia specyfikacji',
        value: 'latestLastDateForOrderChange'
    },
    {
        label: 'najwyższa cena katalogowa',
        value: 'highestCatalogPrice'
    },
    {
        label: 'najniższa cena katalogowa',
        value: 'lowestCatalogPrice'
    }
];
export function SearchCarSection(_a) {
    var onShowSelectedCar = _a.onShowSelectedCar, onStockCarSearchClick = _a.onStockCarSearchClick, clearStockCars = _a.clearStockCars, sortingAvailable = _a.sortingAvailable;
    var _b = useState('stock-cars'), searchingType = _b[0], setSearchingType = _b[1];
    var _c = useState(undefined), filtersOptions = _c[0], setFiltersOptions = _c[1];
    var _d = useState(undefined), selectedFilters = _d[0], setSelectedFilters = _d[1];
    var _e = useState(sortingAvailableOptions[0]), seletedSorting = _e[0], setSelectedSorting = _e[1];
    function handleSetFiltersOptions(filtersOptions) {
        setFiltersOptions(function (currentFiltersOptions) {
            return __assign(__assign({}, currentFiltersOptions), filtersOptions);
        });
    }
    function handleSetSelectedFilter(filterType, option) {
        setSelectedFilters(function (currentSelectedFilters) {
            var _a;
            return __assign(__assign({}, currentSelectedFilters), (_a = {}, _a[filterType] = option, _a));
        });
    }
    function handleDeleteSelectedFilter(filterType, value) {
        setSelectedFilters(function (currentSelectedFilters) {
            var _a;
            if (Array.isArray(currentSelectedFilters[filterType])) {
                var editedSelectedFilter = currentSelectedFilters[filterType].filter(function (v) { return v.value !== value; });
                if (editedSelectedFilter.length === 0) {
                    var newSelectedFilters = __assign({}, currentSelectedFilters);
                    delete newSelectedFilters[filterType];
                    return newSelectedFilters;
                }
                return __assign(__assign({}, currentSelectedFilters), (_a = {}, _a[filterType] = editedSelectedFilter, _a));
            }
            else {
                var newSelectedFilters = __assign({}, currentSelectedFilters);
                delete newSelectedFilters[filterType];
                return newSelectedFilters;
            }
        });
    }
    function clearSelectedFilters() {
        setSelectedFilters(undefined);
    }
    var newOfferFromStockApi = useNewOfferFromStockApi();
    // const { setStatus } = useStatus();
    function handleChangeSearchingType(value) {
        setSearchingType(value);
        clearStockCars();
        clearSelectedFilters();
    }
    function loadOptions(inputValue) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (inputValue.length < 3) {
                    return [2 /*return*/];
                }
                // strzał na phrase GET /stock-cars?phrase={phrase} lub GET /sold-cars?phrase={phrase} zależnie od kontekstu searchingType
                return [2 /*return*/, newOfferFromStockApi.searchCars(searchingType, inputValue)];
            });
        });
    }
    function stockCarSearchChangeHandler(option) {
        return __awaiter(this, void 0, void 0, function () {
            var selectedCar;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, newOfferFromStockApi.getCar(searchingType, option.commonOrderNumber)];
                    case 1:
                        selectedCar = _a.sent();
                        onShowSelectedCar(selectedCar);
                        clearSelectedFilters();
                        return [2 /*return*/];
                }
            });
        });
    }
    ;
    function onModelChange(_, option) {
        return __awaiter(this, void 0, void 0, function () {
            var modelYears, driveTypes, engines, orderTypes, carLocations, versions, colors, upholsteryColors, priceRanges, stockOptions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setSelectedFilters(function () {
                            return {
                                'model': option
                            };
                        });
                        return [4 /*yield*/, newOfferFromStockApi.getAvailableOptions('modelYears', option.value)];
                    case 1:
                        modelYears = _a.sent();
                        return [4 /*yield*/, newOfferFromStockApi.getAvailableOptions('driveTypes', option.value)];
                    case 2:
                        driveTypes = _a.sent();
                        return [4 /*yield*/, newOfferFromStockApi.getAvailableOptions('engines', option.value)];
                    case 3:
                        engines = _a.sent();
                        return [4 /*yield*/, newOfferFromStockApi.getAvailableOptions('orderTypes', option.value)];
                    case 4:
                        orderTypes = _a.sent();
                        return [4 /*yield*/, newOfferFromStockApi.getAvailableOptions('carLocations', option.value)];
                    case 5:
                        carLocations = _a.sent();
                        return [4 /*yield*/, newOfferFromStockApi.getAvailableOptions('versions', option.value)];
                    case 6:
                        versions = _a.sent();
                        return [4 /*yield*/, newOfferFromStockApi.getAvailableOptions('colors', option.value)];
                    case 7:
                        colors = _a.sent();
                        return [4 /*yield*/, newOfferFromStockApi.getAvailableOptions('upholsteryColors', option.value)];
                    case 8:
                        upholsteryColors = _a.sent();
                        return [4 /*yield*/, newOfferFromStockApi.getAvailableOptions('priceRanges', option.value)];
                    case 9:
                        priceRanges = _a.sent();
                        return [4 /*yield*/, newOfferFromStockApi.getAvailableOptions('stock', option.value)];
                    case 10:
                        stockOptions = _a.sent();
                        handleSetFiltersOptions({
                            'modelYears': modelYears,
                            'driveTypes': driveTypes,
                            'engines': engines,
                            'orderTypes': orderTypes,
                            'carLocations': carLocations,
                            'versions': versions,
                            'colors': colors,
                            'upholsteryColors': upholsteryColors,
                            'priceRanges': priceRanges,
                            'stock': stockOptions,
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function onModelYearChange(values) {
        handleSetSelectedFilter('modelYears', values);
        // TODO: zapytanie o resztę filtrów VOLVODOL-4664
    }
    function onDriveTypeChange(values) {
        handleSetSelectedFilter('driveTypes', values);
        // TODO: zapytanie o resztę filtrów VOLVODOL-4664
    }
    function onEnginesChange(values) {
        handleSetSelectedFilter('engines', values);
        // TODO: zapytanie o resztę filtrów VOLVODOL-4664
    }
    function onOrderTypeChange(values) {
        handleSetSelectedFilter('orderTypes', values);
        // TODO: zapytanie o resztę filtrów VOLVODOL-4664
    }
    function onCarLocationChange(values) {
        handleSetSelectedFilter('carLocations', values);
        // TODO: zapytanie o resztę filtrów VOLVODOL-4664
    }
    function onVersionChange(values) {
        handleSetSelectedFilter('versions', values);
        // TODO: zapytanie o resztę filtrów VOLVODOL-4664
    }
    function onColorChange(values) {
        handleSetSelectedFilter('colors', values);
        // TODO: zapytanie o resztę filtrów VOLVODOL-4664
    }
    function onUpholteryColorChange(values) {
        handleSetSelectedFilter('upholsteryColors', values);
        // TODO: zapytanie o resztę filtrów VOLVODOL-4664
    }
    function onPriceRangeChange(values) {
        handleSetSelectedFilter('priceRanges', values);
        // TODO: zapytanie o resztę filtrów VOLVODOL-4664
    }
    function onStockChange(_, value) {
        handleSetSelectedFilter('stock', value);
        // TODO: zapytanie o resztę filtrów VOLVODOL-4664
    }
    function onLastDateForOrderChange(dates) {
        handleSetSelectedFilter('lastDateForOrderChange', dates);
        // TODO: zapytanie o resztę filtrów - tutaj też? VOLVODOL-4664
    }
    function onDeliveryDateChange(dates) {
        handleSetSelectedFilter('deliveryDate', dates);
        // TODO: zapytanie o resztę filtrów - tutaj też? VOLVODOL-4664
    }
    useEffect(function () {
        function getModels() {
            return __awaiter(this, void 0, void 0, function () {
                var availableModels, availableModelsOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, newOfferFromStockApi.getAvailableModels()];
                        case 1:
                            availableModels = _a.sent();
                            availableModelsOptions = availableModels.map(function (availableModel) {
                                return {
                                    value: availableModel,
                                    label: ModelDictionary.nameFor(availableModel)
                                };
                            });
                            handleSetFiltersOptions({ 'model': availableModelsOptions });
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (filtersOptions === undefined) {
            //strzał po możliwe modele
            getModels();
        }
    }, []);
    var isModelSelected = !!(selectedFilters && selectedFilters.model);
    var selectedOptions = selectedFilters ? Object.entries(selectedFilters) : [];
    function onDeleteFilter(filterType, filterValue) {
        if (filterType === 'model') {
            setSelectedFilters(undefined);
        }
        else if (dateRangeFilterKeys.includes(filterType)) {
            handleSetSelectedFilter(filterType, [null, null]);
        }
        else {
            handleDeleteSelectedFilter(filterType, filterValue);
        }
    }
    function onSortingChange(_, option) {
        setSelectedSorting(option);
        if (sortingAvailable) {
            //TODO: strzał do BE z nową wartością sorting jeśli już mamy coś wyszukane VOLVODOL-4664
        }
    }
    function onSearchClick() {
        // TODO zbudować body dla BE i przekazać i strzał VOLVODOL-4664
        var filtersForBody = __assign({}, selectedFilters);
        var body = __assign(__assign({}, filtersForBody), { sorting: seletedSorting });
        onStockCarSearchClick(body);
    }
    return (React.createElement("div", { className: "searchCarSection" },
        React.createElement("div", { className: "searchCarHeader" }, "Wyszukaj samoch\u00F3d:"),
        React.createElement(HorizontalLine, { color: "#D5D5D5" }),
        React.createElement("div", { className: "stockCarSearchWrapper" },
            React.createElement("div", { className: "stockCarsSearchHeading" },
                React.createElement(RadioGroup.Root, { value: searchingType, onValueChange: handleChangeSearchingType },
                    React.createElement("div", { className: "stockCarTypeSearch" },
                        React.createElement("div", { className: "stockCarTypeItem" },
                            React.createElement(RadioGroup.Item, { value: "stock-cars", id: "stock-cars" },
                                React.createElement(RadioGroup.Indicator, null)),
                            React.createElement(Label, { htmlFor: "stock-cars" }, "Stock")),
                        React.createElement("div", { className: "stockCarTypeItem" },
                            React.createElement(RadioGroup.Item, { value: "sold-cars", id: "sold-cars" },
                                React.createElement(RadioGroup.Indicator, null)),
                            React.createElement(Label, { htmlFor: "sold-cars" }, "Sprzedane"))))),
            React.createElement("div", { className: "stockCarSearch" },
                React.createElement(AsyncSelect, { value: null, getOptionLabel: function (e) { return generateStockCarOptionLabel(e); }, loadOptions: loadOptions, onChange: stockCarSearchChangeHandler, autoFocus: false, placeholder: 'DO, CON, VIN, Numer rejestracyjny', noOptionsMessage: function () { return null; } }))),
        searchingType === 'stock-cars' && !isModelSelected ? (React.createElement("div", { className: "stockCarInfoWrapper" },
            React.createElement(InfoIcon, null),
            React.createElement("p", { className: "stockCarInfo" }, "Wybierz model pojazdu, aby aktywowa\u0107 pozosta\u0142e filtry."))) : null,
        searchingType === 'stock-cars' ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "stockCarFiltersWrapper" },
                React.createElement("div", { className: "stockCarFilter" },
                    React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Model:"),
                    React.createElement(Dropdown, { options: filtersOptions ? filtersOptions['model'] : [], onChange: onModelChange, 
                        // @ts-ignore
                        keyValue: selectedFilters ? selectedFilters['model'].value : undefined, name: "model", placeholder: "Wybierz", value: selectedFilters && selectedFilters['model'] ? selectedFilters['model'] : undefined })),
                React.createElement("div", { className: "stockCarFilter" },
                    React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Rok modelowy:"),
                    React.createElement(MultiDropdownWithoutChips, { disabled: !isModelSelected, options: filtersOptions ? filtersOptions['modelYears'] : [], name: "modelYears", onChange: onModelYearChange, placeholder: "Wybierz", value: selectedFilters && selectedFilters['modelYears'] ? selectedFilters['modelYears'] : [] })),
                React.createElement("div", { className: "stockCarFilter" },
                    React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Typ nap\u0119du:"),
                    React.createElement(MultiDropdownWithoutChips, { disabled: !isModelSelected, options: filtersOptions ? filtersOptions['driveTypes'] : [], name: "driveTypes", onChange: onDriveTypeChange, placeholder: "Wybierz", value: selectedFilters && selectedFilters['driveTypes'] ? selectedFilters['driveTypes'] : [] })),
                React.createElement("div", { className: "stockCarFilter" },
                    React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Silnik:"),
                    React.createElement(MultiDropdownWithoutChips, { disabled: !isModelSelected, options: filtersOptions ? filtersOptions['engines'] : [], name: "engines", onChange: onEnginesChange, placeholder: "Wybierz", value: selectedFilters && selectedFilters['engines'] ? selectedFilters['engines'] : [] })),
                React.createElement("div", { className: "stockCarFilter" },
                    React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Typ zam\u00F3wienia:"),
                    React.createElement(MultiDropdownWithoutChips, { disabled: !isModelSelected, options: filtersOptions ? filtersOptions['orderTypes'] : [], name: "orderTypes", onChange: onOrderTypeChange, placeholder: "Wybierz", value: selectedFilters && selectedFilters['orderTypes'] ? selectedFilters['orderTypes'] : [] })),
                React.createElement("div", { className: "stockCarFilter" },
                    React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Miejsce przebywania auta:"),
                    React.createElement(MultiDropdownWithoutChips, { disabled: !isModelSelected, options: filtersOptions ? filtersOptions['carLocations'] : [], name: "carLocations", onChange: onCarLocationChange, placeholder: "Wybierz", value: selectedFilters && selectedFilters['carLocations'] ? selectedFilters['carLocations'] : [] })),
                React.createElement("div", { className: "stockCarFilter" },
                    React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Wersja:"),
                    React.createElement(MultiDropdownWithoutChips, { disabled: !isModelSelected, options: filtersOptions ? filtersOptions['versions'] : [], name: "versions", onChange: onVersionChange, placeholder: "Wybierz", value: selectedFilters && selectedFilters['versions'] ? selectedFilters['versions'] : [] })),
                React.createElement("div", { className: "stockCarFilter" },
                    React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Kolor:"),
                    React.createElement(MultiDropdownWithoutChips, { disabled: !isModelSelected, options: filtersOptions ? filtersOptions['colors'] : [], name: "colors", onChange: onColorChange, placeholder: "Wybierz", value: selectedFilters && selectedFilters['colors'] ? selectedFilters['colors'] : [] })),
                React.createElement("div", { className: "stockCarFilter" },
                    React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Kolor tapicerki:"),
                    React.createElement(MultiDropdownWithoutChips, { disabled: !isModelSelected, options: filtersOptions ? filtersOptions['upholsteryColors'] : [], name: "upholsteryColors", onChange: onUpholteryColorChange, placeholder: "Wybierz", value: selectedFilters && selectedFilters['upholsteryColors'] ? selectedFilters['upholsteryColors'] : [] })),
                React.createElement("div", { className: "stockCarFilter" },
                    React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Cena:"),
                    React.createElement(MultiDropdownWithoutChips, { disabled: !isModelSelected, options: filtersOptions ? filtersOptions['priceRanges'] : [], name: "priceRanges", onChange: onPriceRangeChange, placeholder: "Wybierz", value: selectedFilters && selectedFilters['priceRanges'] ? selectedFilters['priceRanges'] : [] })),
                React.createElement("div", { className: "stockCarFilter" },
                    React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Data zamro\u017Cenia specyfikacji:"),
                    React.createElement(DateRangePicker, { dates: selectedFilters && selectedFilters.lastDateForOrderChange ? selectedFilters.lastDateForOrderChange : [null, null], onDateRangeChange: onLastDateForOrderChange, disabled: !isModelSelected })),
                React.createElement("div", { className: "stockCarFilter" },
                    React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Data dostawy:"),
                    React.createElement(DateRangePicker, { dates: selectedFilters && selectedFilters.deliveryDate ? selectedFilters.deliveryDate : [null, null], onDateRangeChange: onDeliveryDateChange, disabled: !isModelSelected })),
                React.createElement("div", { className: "stockCarFilter" },
                    React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Stock:"),
                    React.createElement(Dropdown, { disabled: !isModelSelected, options: filtersOptions ? filtersOptions['stock'] : [], onChange: onStockChange, 
                        // @ts-ignore
                        keyValue: selectedFilters && selectedFilters['stock'] ? selectedFilters['stock'].value : undefined, name: "stock", placeholder: "Wybierz", value: selectedFilters ? selectedFilters['stock'] : undefined }))),
            React.createElement(HorizontalLine, { color: "#D5D5D5" }),
            React.createElement("div", { className: "filteringActionsWrapper" },
                React.createElement("div", { className: "filtersSelectedWrapper" },
                    React.createElement("div", { className: "stockCarLabel" }, "Wybrane opcje:"),
                    React.createElement("div", { className: "filtersChips" }, selectedOptions
                        ? selectedOptions.map(function (filterOptions) {
                            var filterOptionKey = filterOptions[0];
                            var filterOptionValue = filterOptions[1];
                            // @ts-ignore
                            var isMultiSelectOption = Array.isArray(filterOptionValue) && !dateRangeFilterKeys.includes(filterOptionKey);
                            if (isMultiSelectOption) {
                                return filterOptionValue.map(function (filterOption) {
                                    return (React.createElement("div", { className: "filterChips", key: filterOption.value },
                                        filterOption.label,
                                        React.createElement("button", { type: "button", className: "removeFilterButton", onClick: function () { return onDeleteFilter(filterOptionKey, filterOption.value); }, onMouseDown: function (e) { return e.stopPropagation(); } },
                                            React.createElement(RemoveIcon, { size: 10 }))));
                                });
                            }
                            // @ts-ignore
                            if (dateRangeFilterKeys.includes(filterOptionKey)) {
                                var isDateRangeValue = filterOptionValue[0] instanceof Date && filterOptionValue[1] instanceof Date;
                                if (!isDateRangeValue) {
                                    return null;
                                }
                                return (React.createElement("div", { className: "filterChips", key: filterOptionKey },
                                    format(filterOptionValue[0], 'dd.MM.yyyy'),
                                    " - ",
                                    format(filterOptionValue[1], 'dd.MM.yyyy'),
                                    React.createElement("button", { type: "button", className: "removeFilterButton", 
                                        // @ts-ignore
                                        onClick: function () { return onDeleteFilter(filterOptionKey, [null, null]); }, onMouseDown: function (e) { return e.stopPropagation(); } },
                                        React.createElement(RemoveIcon, { size: 10 }))));
                            }
                            return (React.createElement("div", { className: "filterChips", key: filterOptionValue.value },
                                filterOptionValue.label,
                                React.createElement("button", { type: "button", className: "removeFilterButton", onClick: function () { return onDeleteFilter(filterOptionKey, filterOptionValue.value); }, onMouseDown: function (e) { return e.stopPropagation(); } },
                                    React.createElement(RemoveIcon, { size: 10 }))));
                        })
                        : null)),
                React.createElement("div", { className: "searchStockCarButtonsWrapper" },
                    React.createElement("div", { className: "searchStockCarButtons" },
                        React.createElement("div", { className: "clearFilters" },
                            React.createElement("button", { className: 'clearFiltersButton', type: 'button', onClick: clearSelectedFilters }, "Wyczy\u015B\u0107 filtry")),
                        React.createElement("div", { className: "searchStockCarButtonWrapper" },
                            React.createElement(Button, { disabled: !isModelSelected, onClick: onSearchClick }, "wyszukaj"))))),
            React.createElement("div", { className: "carsListHeader" },
                React.createElement("div", { className: "carsAmount" }, "Liczba samochod\u00F3w (120)"),
                React.createElement("div", { className: "carsSortingWrapper" },
                    React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Sortowanie:"),
                    React.createElement(Dropdown, { options: sortingAvailableOptions, onChange: onSortingChange, name: "sorting", value: seletedSorting }))))) : null));
}
