var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ReactSelect, { components as defaultComponents, } from 'react-select';
import { ChevronDown } from './ChevronDown.component';
import { RemoveIcon } from './RemoveIcon.component';
export function MultiDropdown(_a) {
    var defaultValue = _a.defaultValue, name = _a.name, options = _a.options, placeholder = _a.placeholder, disabled = _a.disabled, value = _a.value, onChange = _a.onChange;
    var customStyles = {
        container: function (provided) { return (__assign(__assign({}, provided), { fontFamily: 'var(--font-family)', color: 'var(--black-text-color)', fontSize: 14, lineHeight: '20px' })); },
        valueContainer: function (provided) { return (__assign(__assign({}, provided), { padding: 0, gap: 4, '&>input': {
                position: 'absolute'
            } })); },
        control: function (provided, status) { return (__assign(__assign({}, provided), { '&:hover': {
                border: '1px solid var(--dropdown-border-color)',
            }, border: '1px solid var(--dropdown-border-color)', boxShadow: 'none', outline: 'none', paddingLeft: status.hasValue ? 4 : 16, paddingRight: 12, paddingTop: 4, paddingBottom: 4, display: 'flex', flexDirection: 'row', gap: 8, minHeight: 32, backgroundColor: status.isDisabled ? 'var(--dropdown-disabled-bg)' : 'var(--dropdown-bg)' })); },
        menu: function (provided) { return (__assign(__assign({}, provided), { boxShadow: 'none', backgroundColor: 'none' })); },
        menuList: function (provided) { return (__assign(__assign({}, provided), { padding: 0, boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.3)', border: '1px solid #D5D5D5', borderRadius: 4, top: '-9px', background: 'var(--white)' })); },
        option: function (provided, state) { return (__assign(__assign({}, provided), { height: 40, display: 'flex', alignItems: 'center', padding: '12px 16px', fontSize: 14, color: '#141414', fontWeight: 400, backgroundColor: state.isFocused ? 'var(--light-grey)' : state.isSelected ? 'var(--light-grey)' : '', '&:active': {
                backgroundColor: 'var(--light-grey)',
            }, fontFamily: 'var(--font-family)' })); },
        placeholder: function (provided, state) { return (__assign(__assign({}, provided), { color: state.isDisabled ? 'var(--text-disabled-input)' : 'var(--black-text-color)', marginLeft: 0, marginRight: 0 })); },
        singleValue: function (provided, state) { return (__assign(__assign({}, provided), { color: state.isDisabled ? 'var(--text-disabled-input)' : 'var(--font-family)' })); },
        multiValue: function (base) { return (__assign(__assign({}, base), { height: '24px', padding: '0 12px 0 12px', borderRadius: '12px', background: 'var(--paginate-items-bg)', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0, gap: '6px' })); },
        multiValueLabel: function () { return ({
            color: 'var(--black-text-color)',
            fontSize: '14px',
            fontFamily: 'var(--font-family)',
            fontWeight: '400',
        }); },
        multiValueRemove: function (base) { return (__assign(__assign({}, base), { color: '#6D6D6D', ':hover': {
                backgroundColor: 'transparent',
                color: '#6D6D6D',
            }, paddingRight: 0 })); },
        indicatorSeparator: function (base) { return (__assign(__assign({}, base), { width: '1px', borderLeft: '1px solid black' })); }
    };
    var MultiValueRemove = function (props) {
        return (React.createElement(defaultComponents.MultiValueRemove, __assign({}, props),
            React.createElement(RemoveIcon, null)));
    };
    var IndicatorsContainer = function (props) {
        return (React.createElement("div", { className: "indicatorsContainerWrapper ".concat(props.hasValue ? 'indicatorContainerBaseline' : 'indicatorContainerCenter') },
            props.hasValue ? (React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement("button", { type: "button", className: "multiDropdownValueContainerRemoveButton", onClick: props.clearValue, onMouseDown: function (e) { return e.stopPropagation(); } },
                        React.createElement(RemoveIcon, { size: 10 }))),
                React.createElement("span", { className: "multiDropdownSeparator" }))) : null,
            React.createElement(ChevronDown, { className: "chevronMultiDropdown ".concat(disabled ? 'chevronDisabled' : '') })));
    };
    var components = {
        IndicatorsContainer: IndicatorsContainer,
        MultiValueRemove: MultiValueRemove,
    };
    return (React.createElement(ReactSelect, { isMulti: true, defaultValue: defaultValue, name: name, options: options, placeholder: placeholder, isDisabled: disabled, onChange: onChange, styles: customStyles, components: components, isSearchable: false, value: value, noOptionsMessage: function () { return 'Brak więcej opcji'; } }));
}
