export function stockTypeDictionary(stockType) {
    switch (stockType) {
        case 'OWN':
            return 'PRO';
        case 'TRADEABLE':
            return 'LST';
        case 'OTHER':
            return 'INNY';
        default:
            return stockType;
    }
}
