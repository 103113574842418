import React from 'react';
import * as RadixTabs from '@radix-ui/react-tabs';
import './priceListsImportTabs.css';
function Root(_a) {
    var defaultValue = _a.defaultValue, children = _a.children, onValueChange = _a.onValueChange;
    return (React.createElement(RadixTabs.Root, { defaultValue: defaultValue, className: "priceListImportTabsRoot", onValueChange: onValueChange }, children));
}
function List(_a) {
    var children = _a.children;
    return React.createElement(RadixTabs.List, { className: "priceListImportTabsList" }, children);
}
function Trigger(_a) {
    var value = _a.value, children = _a.children;
    return (React.createElement(RadixTabs.Trigger, { value: value, className: "priceListImportTabsTrigger" }, children));
}
function Content(_a) {
    var value = _a.value, children = _a.children;
    return (React.createElement(RadixTabs.Content, { value: value, className: "priceListImportTabsContent" }, children));
}
export var PriceListImportTabs = {
    Root: Root,
    List: List,
    Trigger: Trigger,
    Content: Content,
};
