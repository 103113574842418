import { useHttp } from 'http/httpService';
export function useSpecialOfferApi() {
    var http = useHttp();
    return {
        getApplicantData: function () {
            return http
                .get('/api/users/me')
                .then(function (response) { return response.data; });
        },
        getPartiesWithIdentyficationNumber: function (clientId) {
            return http
                .get("/api/clients/".concat(clientId, "/parties?withIdentificationNumber"))
                .then(function (response) { return response.data; });
        },
        getParty: function (partyId) {
            return http
                .get("/api/parties/".concat(partyId))
                .then(function (response) { return response.data; });
        },
        carsByParty: function (partyId, from, to) {
            return http
                .get("/api/cars?byPartyIdentificationNumber=".concat(partyId, "&from=").concat(from, "&to=").concat(to))
                .then(function (response) { return response.data; });
        },
        addFile: function (file) {
            var formData = new FormData();
            formData.append('file', file);
            return http
                .post('/api/files', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(function (response) {
                return {
                    fileUrl: response.headers.location
                };
            });
        },
        deleteFile: function (fileLocation) {
            return http.delete("/api".concat(fileLocation));
        },
        loadLocation: function (location) {
            return http
                .get('/api' + location, { responseType: 'blob' });
        },
        listActiveVcpDiscountsForIdentificationNumber: function (identificationNumber) {
            return http.get("/api/concessions?active&forIdentificationNumber=".concat(identificationNumber))
                .then(function (response) { return response.data; });
        },
        fundingForms: function () {
            return http.get('/api/discount/funding/dictionary').then(function (response) { return response.data; });
        },
        getAvailablePercentages: function () {
            return http.get('/api/discount/configuration/special-offer').then(function (response) { return response.data; });
        },
        saveNewSpecialOffer: function (specialOfferData) {
            return http.post('/api/discount/specialoffer', specialOfferData).then(function (response) {
                return response;
            });
        },
        getAvailableOffersByPartyIdentificationNumber: function (identiciationNumber) {
            return http.get("/api/creator/offers/viaSpecialOfferByIdentificationNumber/".concat(identiciationNumber)).then(function (response) { return response.data; });
        },
        getPercentagesValues: function (dealerShareMarginPercent, vcpLevelPercent, offeredPriceNetto, offeredPriceGross) {
            var params = new URLSearchParams();
            params.set('dealerShareMarginPercent', (dealerShareMarginPercent / 100).toString());
            params.set('vcpLevelPercent', (vcpLevelPercent / 100).toString());
            params.set('offeredPriceNetto', offeredPriceNetto);
            params.set('offeredPriceGross', offeredPriceGross);
            return http.get("/api/discount/percentage/special-offer?".concat(params)).then(function (response) { return response.data; });
        },
        getSpecialOffer: function (specialOfferNumber) {
            return http.get("/api/discount/specialoffer/".concat(specialOfferNumber)).then(function (response) { return response; });
        },
        supplementSpecialOffer: function (specialOfferNumber, querySubject) {
            return http.put("/api/discount/specialoffer/".concat(specialOfferNumber), querySubject).then(function (response) {
                return response.status === 200;
            });
        },
        specialOffersForParty: function (identificationNumber) {
            return http.get("/api/discount/specialoffer/preview?forIdentificationNumbers=".concat(identificationNumber)).then(function (response) { return response.data; });
        },
        getBusinessEntityData: function (identificationNumberType, identificationNumber) {
            return http.get("/api/business-entity-data/".concat(identificationNumber, "/").concat(identificationNumberType)).then(function (response) { return response; });
        },
        otherRequestsForIdentificationNumber: function (identificationNumber, capitalGroup) {
            if (capitalGroup === void 0) { capitalGroup = false; }
            return http.get("/api/discount/specialoffer/listForIdentificationNumber?forIdentificationNumber=".concat(identificationNumber, "&capitalGroup=").concat(capitalGroup)).then(function (response) { return response.data; });
        },
        requestsForDealer: function (dealerId) {
            return http.get("/api/discount/specialoffer/listForDealer?forDealer=".concat(dealerId)).then(function (response) { return response.data; });
        },
        vcpRequestsForIdentificationNumber: function (identificationNumber) {
            return http.get("/api/concessions?forIdentificationNumber=".concat(identificationNumber)).then(function (response) { return response.data; });
        },
        historicalVcpRequestsForIdentificationNumber: function (identificationNumber) {
            return http.get("/api/concessions?historical&forIdentificationNumber=".concat(identificationNumber)).then(function (response) { return response.data; });
        },
        getSpecialOfferTypes: function () {
            return http.get('/api/discount/special-offer-types').then(function (response) { return response.data; });
        },
        search: function (phrase) {
            return http.get("/api/discount/specialoffer/search?phrase=".concat(phrase)).then(function (response) { return response.data; });
        },
        getSpecialOfferForSettlement: function (specialOfferNumber, specialOfferId) {
            return http.get("/api/discount/specialoffer/".concat(specialOfferNumber, "/").concat(specialOfferId)).then(function (response) { return response; });
        },
        getOrder: function (orderId) {
            return http.get("/api/orders/".concat(orderId)).then(function (response) { return response; });
        },
        sendSettlement: function (specialOfferNumber, specialOfferId, settlementData) {
            return http.post("/api/discount/specialoffer/".concat(specialOfferNumber, "/").concat(specialOfferId, "/reckoning"), settlementData).then(function (response) { return response; });
        },
        getSettlementStatus: function (id) {
            return http.get("/api/orders/".concat(id, "/concession-settlement")).then(function (response) { return response.data; });
        },
        getSettlementsForSpecialOffer: function (specialOfferNumber) {
            return http.get("/api/discount/specialoffer/".concat(specialOfferNumber, "/reckonings")).then(function (response) { return response.data; });
        },
        saveEditSpecialOffer: function (specialOfferNumber, extendSpecialOfferJson) {
            return http.put("/api/discount/specialoffer/".concat(specialOfferNumber, "/extends"), extendSpecialOfferJson).then(function (response) { return response; });
        },
        saveNewDateTo: function (specialOfferNumber, changeValidToJson) {
            return http.put("/api/discount/specialoffer/".concat(specialOfferNumber, "/validTo"), changeValidToJson).then(function (response) { return response; });
        },
        getAuditDocuments: function (orderId) {
            return http.get("/api/concession-settlement-documentations/".concat(orderId)).then(function (response) { return response; });
        },
        returnAuditDocuments: function (settlementId) {
            return http.post("/api/concession-settlement-documentations/".concat(settlementId, "/return")).then(function (response) { return response; });
        }
    };
}
