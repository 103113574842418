import React, { useState } from 'react';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
import { HoverCard } from 'components/hoverCard/HoverCard.component';
import './layoutRadioButtonsWithCarColor.css';
export function LayoutWithColor(_a) {
    var thumbnailImageSrc = _a.thumbnailImageSrc, imagePreviewSrc = _a.imagePreviewSrc, children = _a.children;
    var _b = useState(false), isImageReady = _b[0], setIsImageReady = _b[1];
    var _c = useState(false), isThumbnailImageReady = _c[0], setIsThumbnailImageReady = _c[1];
    return React.createElement("div", { className: 'radioWithColors' },
        React.createElement(RadioGroup.Layout, null, children),
        React.createElement(HoverCard.Root, null,
            React.createElement(HoverCard.Trigger, null,
                React.createElement("div", { className: 'colorIcon', style: { display: isThumbnailImageReady ? 'flex' : 'none' } },
                    React.createElement("img", { className: 'colorIconImage', src: thumbnailImageSrc, alt: '', onLoad: function () { return setIsThumbnailImageReady(true); } }))),
            React.createElement(HoverCard.Content, { side: 'right', align: 'end', collisionElement: document.getElementById('tabContentWrapper') },
                React.createElement("div", { className: 'carPreview', style: { display: isImageReady ? 'block' : 'none' } },
                    React.createElement("img", { className: 'carPreviewImage', src: imagePreviewSrc, alt: '', onLoad: function () { return setIsImageReady(true); } })))));
}
