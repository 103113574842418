import { useHttp } from 'http/httpService';
export function useCarDamageOCIssues() {
    var http = useHttp();
    return {
        getIssues: function (vcp, options) {
            if (options) {
                var params = new URLSearchParams();
                if (options.phrase) {
                    params.set('phrase', options.phrase);
                }
                if (options.status) {
                    params.set('status', options.status.join(','));
                }
                if (options.assistant) {
                    params.set('assistant', options.assistant.join(','));
                }
                if (options.page !== undefined) {
                    params.set('page', options.page.toString());
                }
                if (options.size) {
                    params.set('size', options.size.toString());
                }
                if (options.organizationId) {
                    params.set('organizationId', options.organizationId);
                }
                return http
                    .get("/api/oc-leads/".concat(vcp ? 'vcp/' : '', "filter?").concat(params))
                    .then(function (response) { return response.data; });
            }
            return http
                .get("/api/oc-leads/".concat(vcp ? 'vcp/' : '', "filter"))
                .then(function (response) { return response.data; });
        },
        pickupIssue: function (id) {
            return http
                .post("/api/oc-leads/".concat(id, "/pick"))
                .then(function (response) { return response; });
        },
        closeIssue: function (id) {
            return http
                .post("/api/oc-leads/".concat(id, "/close"))
                .then(function (response) { return response; });
        },
        getAssistants: function (vcp) {
            return http
                .get("/api/oc-leads/".concat(vcp ? 'vcp/' : '', "assistants"))
                .then(function (response) { return response.data; });
        },
        getAvailableDealers: function () {
            return http
                .get('/api/oc-leads/vcp/organizations')
                .then(function (response) { return response.data; });
        },
        getDealersNames: function () {
            return http
                .get('/api/organizations?dealers')
                .then(function (response) { return response.data; });
        }
    };
}
