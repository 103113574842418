import Axios from 'axios';
import AuthToken from 'common/authToken';
// import { MessageBox } from 'common/message-box/messageBox';
import { useCancelTokenSourceContext } from 'common/requestCancellation/requestCancellation';
import { useMemo } from 'react';
import '../components/dialog/dialog.css';
import { useHistory } from 'react-router-dom';
import { errorDictionary } from './errorDictionary';
import { useAppError } from '../common/appErrorContext';
import { useStatus } from 'common/statusContext';
var ERROR_CODES_TO_IGNORE = ['eurocode-not-exist', 'token-mLeasing', 'mLeasing-error', 'api-error-zps'];
export function createAxiosInstance(setAppError, history, setStatus) {
    var UNAUTHORIZED = 401;
    var axiosInstance = Axios.create({});
    var authorizationInterceptor = function (request) {
        var authToken = AuthToken.get();
        if (authToken) {
            request.headers['AuthToken'] = authToken;
        }
        return request;
    };
    var errorInterceptor = function (error) {
        if (!error.response) {
            return Promise.reject(error);
        }
        var errorData = error.response.data;
        var errorStatus = error.response.status;
        var errorDict = errorDictionary(errorData);
        if (errorStatus === UNAUTHORIZED) {
            if (errorData.errorCode === 'password-expired') {
                history.push({ pathname: '/user-password-change' });
            }
            else {
                history.push({ pathname: '/login' });
            }
        }
        else if (error.config.url === '/api/creator/offer/stock') {
            return Promise.reject(error);
        }
        else if (errorDict.redirect !== 'ignore') {
            setAppError({
                errorId: errorData.errorId,
                errorDesc: errorDict.description,
                redirect: errorDict.redirect
            });
            return Promise.resolve();
        }
        else if (errorData.errorCode === 'eurocode-not-exist' && error.response.request.responseURL.includes('/creator/offer')) {
            setStatus('error');
            setAppError({
                errorId: errorData.errorId,
                errorDesc: 'Wystąpił niespodziewany błąd.',
                redirect: 'errorPage'
            });
            return Promise.resolve();
        }
        else if (errorDict.redirect === 'ignore' && (ERROR_CODES_TO_IGNORE.some(function (e) { return e.includes(errorDict.code); }))) {
            setStatus('error');
            setAppError({
                errorId: errorDict.code,
                errorDesc: errorDict.description,
                redirect: undefined
            });
            return Promise.resolve();
        }
        else if (errorData.errorCode === 'krs-does-not-exist-in-database' || errorData.errorCode === 'nip-does-not-exist-in-database') {
            return Promise.resolve(error.response);
        }
        else {
            return Promise.reject(error);
        }
    };
    var applicationNeedsUpdateInterceptor = function (response) {
        var oldApplicationFingerprint = AuthToken.decodeApplicationFingerprint();
        var authToken = response.headers.authtoken;
        if (authToken) {
            AuthToken.set(authToken);
        }
        notifyThatApplicationNeedsUpdateIfChanged(oldApplicationFingerprint);
        return response;
    };
    axiosInstance.interceptors.request.use(authorizationInterceptor);
    axiosInstance.interceptors.response.use(applicationNeedsUpdateInterceptor, errorInterceptor);
    function notifyThatApplicationNeedsUpdateIfChanged(oldApplicationFingerprint) {
        var newApplicationFingerprint = AuthToken.decodeApplicationFingerprint();
        if (oldApplicationFingerprint && newApplicationFingerprint && oldApplicationFingerprint !== newApplicationFingerprint) {
            showApplicationNeedsUpdateModal();
        }
    }
    function showApplicationNeedsUpdateModal() {
        //TODO do napisania własny MessageBox - do omówienia
        // MessageBox.confirmation('Dostępna jest nowa wersja DOL.<br/>Aktualizacja zajmie kilka sekund. Nie ma wpływu na inne aplikacje.', (confirm, cancel) => (
        //     <div>
        //         <div className="pull-left">
        //             <button className="btn btn-app btn-default" onClick={() => cancel()}>Przypomnij mi za chwilę
        //             </button>
        //         </div>
        //         <div className="pull-right">
        //             <button className="btn btn-app btn-confirm" onClick={() => confirm()}>Zaktualizuj</button>
        //         </div>
        //     </div>
        // )).then(
        //     () => window.location.reload(),
        //     () => {
        //         setTimeout(() => showApplicationNeedsUpdateModal(), 5 * 60 * 1000)
        //     });
        return;
    }
    return axiosInstance;
}
export function useHttp() {
    var history = useHistory();
    var setAppError = useAppError().setAppError;
    var setStatus = useStatus().setStatus;
    var http = useMemo(function () {
        var http = createAxiosInstance(setAppError, history, setStatus);
        return http;
    }, []);
    var cancelTokenSource = useCancelTokenSourceContext();
    http.defaults.cancelToken = cancelTokenSource.token;
    return http;
}
