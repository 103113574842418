// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .tabsRoot {
    font-family: 'Volvo Novum';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-text-color);
}

#new-dol .tabsList {
    display: flex;
    border-bottom: 1px solid #EBEBEB;
    gap: 29px;
    padding-top: 12px;
}

#new-dol .tabsList.flexWrap {
    flex-wrap: wrap;
}

#new-dol .tabsList.noGap {
    gap: 0;
}

#new-dol .tabsTrigger {
    flex-grow: 1;
    flex-basis: 0;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    padding-bottom: 16px;
    user-select: none;
}

#new-dol .tabsTrigger.borderBottom {
    border-bottom: 1px solid #EBEBEB;
}

#new-dol .tabsTrigger[data-state="active"] {
    color: var(--blue);
    border-bottom: 2px solid var(--blue);
    padding-bottom: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/tabs/tabs.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,gCAAgC;IAChC,SAAS;IACT,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,MAAM;AACV;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,cAAc;IACd,YAAY;IACZ,UAAU;IACV,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,oCAAoC;IACpC,oBAAoB;AACxB","sourcesContent":[".tabsRoot {\n    font-family: 'Volvo Novum';\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    color: var(--main-text-color);\n}\n\n.tabsList {\n    display: flex;\n    border-bottom: 1px solid #EBEBEB;\n    gap: 29px;\n    padding-top: 12px;\n}\n\n.tabsList.flexWrap {\n    flex-wrap: wrap;\n}\n\n.tabsList.noGap {\n    gap: 0;\n}\n\n.tabsTrigger {\n    flex-grow: 1;\n    flex-basis: 0;\n    background: none;\n    color: inherit;\n    border: none;\n    padding: 0;\n    padding-bottom: 16px;\n    user-select: none;\n}\n\n.tabsTrigger.borderBottom {\n    border-bottom: 1px solid #EBEBEB;\n}\n\n.tabsTrigger[data-state=\"active\"] {\n    color: var(--blue);\n    border-bottom: 2px solid var(--blue);\n    padding-bottom: 14px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
