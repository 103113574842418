// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .textInputWrapper {
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    padding: 6px 16px;
    height: 32px;
    display: flex;
    align-items: center;
    font-family: var(--font-family);
}

#new-dol .textInput {
    border: none;
    width: 100%;
    font-size: 14px;
}

#new-dol .textInput:focus {
    outline: none;
}

#new-dol .textInput::placeholder {
    color: var(--text-grey);
    font-family: var(--font-family);
}

#new-dol .validate {
    border: 1px solid var(--validate-color);
}

#new-dol .disabled {
    background-color: var(--dropdown-disabled-bg);
    color: var(--text-disabled-input);
}

#new-dol .disabled > input::placeholder {
    color: var(--text-disabled-input);
}

#new-dol input:-webkit-autofill, #new-dol input:-webkit-autofill:hover, #new-dol input:-webkit-autofill:focus, #new-dol input:-webkit-autofill:active, #new-dol input:-webkit-autofill::first-line {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    font-family: var(--font-family);
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/textInput/textInput.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,+BAA+B;AACnC;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,+BAA+B;AACnC;;AAEA;IACI,uCAAuC;AAC3C;;AAEA;IACI,6CAA6C;IAC7C,iCAAiC;AACrC;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IAKI,iDAAiD;IACjD,6CAA6C;IAC7C,+BAA+B;IAC/B,eAAe;AACnB","sourcesContent":[".textInputWrapper {\n    background: #FFFFFF;\n    border: 1px solid #D5D5D5;\n    border-radius: 4px;\n    padding: 6px 16px;\n    height: 32px;\n    display: flex;\n    align-items: center;\n    font-family: var(--font-family);\n}\n\n.textInput {\n    border: none;\n    width: 100%;\n    font-size: 14px;\n}\n\n.textInput:focus {\n    outline: none;\n}\n\n.textInput::placeholder {\n    color: var(--text-grey);\n    font-family: var(--font-family);\n}\n\n.validate {\n    border: 1px solid var(--validate-color);\n}\n\n.disabled {\n    background-color: var(--dropdown-disabled-bg);\n    color: var(--text-disabled-input);\n}\n\n.disabled > input::placeholder {\n    color: var(--text-disabled-input);\n}\n\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ninput:-webkit-autofill:active,\ninput:-webkit-autofill::first-line {\n    transition: background-color 5000s ease-in-out 0s;\n    -webkit-box-shadow: 0 0 0px 1000px #fff inset;\n    font-family: var(--font-family);\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
