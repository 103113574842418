import React, { createContext, useContext, useState } from 'react';
var StatusContext = createContext({
    status: 'idle',
    setStatus: null
});
export function StatusProvider(_a) {
    var children = _a.children;
    var _b = useState('idle'), status = _b[0], setStatus = _b[1];
    var value = { status: status, setStatus: setStatus };
    return React.createElement(StatusContext.Provider, { value: value }, children);
}
export function useStatus() {
    var context = useContext(StatusContext);
    if (!context) {
        throw new Error('useStatus must be used within StatusProvider');
    }
    return context;
}
