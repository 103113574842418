import { Dialog } from '../../../components/dialog/Dialog.component';
import { Button } from '../../../components/button/Button.component';
import React, { useState } from 'react';
import { AttentionIcon } from '../../../components/dialog/AttentionIcon.component';
import { useHistory } from 'react-router-dom';
import { useAppError } from '../../../common/appErrorContext';
export function ConfigErrorPopup(_a) {
    var _b = _a.navigateTo, navigateTo = _b === void 0 ? 'start-offer' : _b;
    var _c = useState(true), open = _c[0], setOpen = _c[1];
    var history = useHistory();
    var setAppError = useAppError().setAppError;
    var routeChange = function () {
        history.push(navigateTo);
        setAppError(null);
    };
    var handleClose = function () {
        setOpen(false);
        setAppError(null);
    };
    return (React.createElement(Dialog.RootOpen, { open: open },
        React.createElement(Dialog.PopupContent, null,
            React.createElement(Dialog.PopupContentLayout, null,
                React.createElement(Dialog.PopupTextWrapper, null,
                    React.createElement(AttentionIcon, null),
                    " ",
                    React.createElement("span", { style: { marginLeft: '10px' } }, "Odznaczenie tego komponentu nie jest mo\u017Cliwe.")),
                React.createElement(Dialog.PopupButtonWrapper, { variant: 'multiple' },
                    React.createElement(Button, { variant: 'secondary', onClick: function () { return routeChange(); } }, "Rozpocznij now\u0105 konfiguracj\u0119"),
                    React.createElement("span", { style: { margin: '0px 40px' } }, "lub"),
                    React.createElement(Button, { onClick: function () { return handleClose(); } }, "Kontynuuj z obecnymi ustawieniami"))))));
}
;
