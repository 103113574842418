import React, { useEffect, useState } from 'react';
import { ModelsList } from './ModelsListTab/ModelsList.component';
import { PriceListImportTabs } from './PriceListImportTabs.component';
import { PriceLists } from './PriceListTab/PriceLists.component';
import SessionStorage from '../../common/sessionStorage';
import userContext from 'common/userContext';
export function PriceListImportWrapper() {
    var _a = useState(''), tabDefaultValue = _a[0], setTabDefaultValue = _a[1];
    var isEmployeeLeasingPriceListAvailable = userContext.hasAnyRole('VCP_EMPLOYEE_LEASING_ADMIN');
    var tabs = [
        {
            value: 'priceList',
            name: 'Lista cenników',
            content: React.createElement(PriceLists, { employeeLeasing: false }),
            isVisible: true
        },
        {
            value: 'modelsList',
            name: 'Lista modeli',
            content: React.createElement(ModelsList, null),
            isVisible: true
        },
        {
            value: 'employeeLeasingPriceList',
            name: 'Program pracowniczy',
            content: React.createElement(PriceLists, { employeeLeasing: true }),
            isVisible: isEmployeeLeasingPriceListAvailable
        }
    ];
    function getTabDefaultValue() {
        if (SessionStorage.get('currentPriceListImportTab')) {
            setTabDefaultValue(SessionStorage.get('currentPriceListImportTab'));
        }
        else {
            var visibleTabs = tabs.filter(function (tab) { return tab.isVisible; });
            setTabDefaultValue(visibleTabs[0].value);
            SessionStorage.set('currentPriceListImportTab', visibleTabs[0].value);
        }
    }
    function handleTabChange(value) {
        SessionStorage.set('currentPriceListImportTab', value);
    }
    useEffect(function () {
        getTabDefaultValue();
    }, []);
    useEffect(function () {
        return function () {
            SessionStorage.remove('currentPriceListImportTab');
        };
    }, []);
    return (React.createElement("div", { className: "priceListImportWrapper" }, tabDefaultValue ?
        React.createElement(PriceListImportTabs.Root, { defaultValue: tabDefaultValue, onValueChange: handleTabChange },
            React.createElement(PriceListImportTabs.List, null, tabs.map(function (tab, index) { return (tab.isVisible ?
                React.createElement(PriceListImportTabs.Trigger, { value: tab.value, key: index }, tab.name)
                : null); })),
            tabs.map(function (tab, index) { return (tab.isVisible ?
                React.createElement(PriceListImportTabs.Content, { value: tab.value, key: index }, tab.content)
                : null); }))
        : null));
}
