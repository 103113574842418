import React from 'react';
import { TextAmount } from 'components/textAmount/TextAmount';
import './summary.css';
export function PackagesSummary(_a) {
    var total = _a.total;
    return React.createElement("div", { className: 'packagesSummaryWrapper' },
        React.createElement("div", { className: 'packagesSummaryTitel' }, "pakiety razem"),
        React.createElement("div", null,
            React.createElement(TextAmount, { bold: true }, total)));
}
