import { Dialog } from '../../../components/dialog/Dialog.component';
import { Button } from '../../../components/button/Button.component';
import React, { useState } from 'react';
import { useAppError } from '../../../common/appErrorContext';
export function ErrorPopup(_a) {
    var text = _a.text;
    var _b = useState(true), open = _b[0], setOpen = _b[1];
    var setAppError = useAppError().setAppError;
    function handleClose() {
        setOpen(false);
        setAppError(null);
    }
    return (React.createElement(Dialog.RootOpen, { open: open },
        React.createElement(Dialog.PopupContent, null,
            React.createElement(Dialog.PopupContentLayout, null,
                React.createElement(Dialog.PopupTextWrapper, null, text),
                React.createElement(Dialog.PopupButtonWrapper, null,
                    React.createElement(Button, { onClick: function () { return handleClose(); } }, "OK"))))));
}
;
