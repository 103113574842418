// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .spinnerWrapper {
    position: fixed;
    inset: 0;
    display: grid;
    place-items: center;
    background-color: #00000017;
    z-index: 9999;
}

#new-dol .spinner {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 6px solid;
    border-color: #506e96;
    border-right-color: #9cafc9;
    animation: spinner-d3wgkg 1.2s infinite linear;
 }
 
 @keyframes spinner-d3wgkg {
    to {
       transform: rotate(1turn);
    }
 }

`, "",{"version":3,"sources":["webpack://./src/components/spinner/spinner.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,qBAAqB;IACrB,2BAA2B;IAC3B,8CAA8C;CACjD;;CAEA;IACG;OACG,wBAAwB;IAC3B;CACH","sourcesContent":[".spinnerWrapper {\n    position: fixed;\n    inset: 0;\n    display: grid;\n    place-items: center;\n    background-color: #00000017;\n    z-index: 9999;\n}\n\n.spinner {\n    width: 60px;\n    height: 60px;\n    border-radius: 50%;\n    border: 6px solid;\n    border-color: #506e96;\n    border-right-color: #9cafc9;\n    animation: spinner-d3wgkg 1.2s infinite linear;\n }\n \n @keyframes spinner-d3wgkg {\n    to {\n       transform: rotate(1turn);\n    }\n }\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
