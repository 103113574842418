import React from 'react';
export function CarIcon() {
    return React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M8.06667 8.32969C9.73333 8.32969 10.4667 8.32969 10.5333 8.32969H10.6C10.6 8.66302 10.5333 9.12969 10.5333 9.12969C10.5333 9.12969 10.5333 9.32969 10.4667 9.32969C10.3333 9.32969 10.2667 9.32969 10.0667 9.32969C9.53333 9.39635 8.86667 9.46302 8.2 9.46302C8.13333 9.46302 8.06667 9.46302 8 9.46302C7.93333 9.46302 7.86667 9.46302 7.8 9.46302C7.2 9.46302 6.46667 9.39635 5.93333 9.32969C5.8 9.32969 5.66667 9.32969 5.53333 9.32969C5.46667 9.32969 5.46667 9.19635 5.46667 9.12969C5.46667 9.12969 5.4 8.72969 5.4 8.32969H5.46667C5.6 8.32969 6.26667 8.32969 7.93333 8.32969H8.06667ZM8.06667 7.92969H8C7.4 7.92969 5.6 7.92969 5.46667 7.99635C5.26667 8.06302 5 7.99635 5 8.39635C5 8.79635 5.06667 9.32969 5.06667 9.32969C5.06667 9.32969 5.13333 9.86302 5.53333 9.92969C6 9.99635 7 10.063 7.8 10.063C7.86667 10.063 7.93333 10.063 8 10.063C8.06667 10.063 8.13333 10.063 8.2 10.063C9 10.063 10 9.92969 10.4667 9.92969C10.9333 9.86302 10.9333 9.32969 10.9333 9.32969C10.9333 9.32969 11 8.79635 11 8.39635C11 7.99635 10.7333 8.06302 10.5333 7.99635C10.4667 7.92969 8.66667 7.92969 8.06667 7.92969Z", fill: "black" }),
        React.createElement("path", { d: "M10.7333 2.66562C11.3333 2.66562 12.0667 2.93229 12.3333 3.53229L14 6.53229C14.2667 7.06563 14.5333 7.99896 14.5333 8.59896V13.1323C14.5333 13.2656 14.4667 13.3323 14.3333 13.3323H13.3333C13.2667 13.3323 13.2 13.2656 13.1333 13.199L12.8666 12.0656L12.8 11.7323H12.4667H3.59998H3.26665L3.13332 12.0656L2.86665 13.199C2.86665 13.2656 2.79998 13.3323 2.66665 13.3323H1.73332C1.59998 13.3323 1.53332 13.2656 1.53332 13.1323V8.59896C1.53332 7.99896 1.79998 7.06563 2.06665 6.53229L3.73332 3.53229C4.19998 2.79896 4.93332 2.66562 5.33332 2.66562H10.7333ZM10.7333 2.26562H5.33332C4.66665 2.26562 3.79998 2.53229 3.33332 3.33229L1.66665 6.33229C1.33332 6.93229 1.06665 7.93229 1.06665 8.59896V13.1323C1.06665 13.4656 1.33332 13.7323 1.66665 13.7323H2.66665C2.93332 13.7323 3.19998 13.5323 3.26665 13.2656V13.199L3.53332 12.0656H12.4L12.6667 13.199V13.2656C12.7333 13.5323 13 13.7323 13.2667 13.7323H14.2667C14.6 13.7323 14.8666 13.4656 14.8666 13.1323V8.59896C14.8666 7.93229 14.6 6.93229 14.2667 6.33229L12.6 3.33229C12.2667 2.53229 11.4 2.26562 10.7333 2.26562Z", fill: "black" }),
        React.createElement("path", { d: "M14.0667 6.06876L14 5.60209H14.0667V4.93543C14.0667 4.80209 14.1333 4.66876 14.2 4.60209C14.2667 4.53543 14.4 4.46876 14.5333 4.53543L15.6667 4.66876C15.8667 4.66876 16.0667 4.93543 16.0667 5.13543V5.40209C16.0667 5.60209 15.8667 5.86876 15.6667 5.86876L14.0667 6.06876ZM14.4667 4.93543V5.53543L15.5333 5.40209V5.13543L14.4667 4.93543Z", fill: "black" }),
        React.createElement("path", { d: "M2 5.93542L0.466667 5.73542C0.2 5.73542 0 5.53542 0 5.33542V5.06875C0 4.86875 0.2 4.66875 0.4 4.60208L1.53333 4.46875C1.66667 4.46875 1.8 4.46875 1.86667 4.53542C2 4.66875 2 4.80208 2 4.86875V5.46875H2.06667L2 5.93542ZM1.6 4.93542L0.466667 5.06875V5.26875L1.6 5.40208V4.93542Z", fill: "black" }),
        React.createElement("path", { d: "M11.7334 8.66302V8.19635H11.8001C11.9334 8.19635 12.2667 8.19635 12.8001 8.12969C13.2667 8.06302 13.4667 7.99635 13.6667 7.92969L13.8001 8.32969C13.6667 8.39635 13.3334 8.46302 12.8667 8.52969C12.2667 8.66302 11.9334 8.66302 11.7334 8.66302Z", fill: "black" }),
        React.createElement("path", { d: "M13.8874 7.84654L13.4236 7.89844L13.5125 8.69348L13.9763 8.64158L13.8874 7.84654Z", fill: "black" }),
        React.createElement("path", { d: "M4.33327 8.66667C4.13327 8.66667 3.79993 8.66667 3.19993 8.6C2.73327 8.53333 2.39993 8.46667 2.2666 8.4L2.39993 8C2.53327 8.06667 2.79993 8.13333 3.2666 8.2C3.8666 8.26667 4.13327 8.26667 4.2666 8.26667H4.33327V8.66667Z", fill: "black" }),
        React.createElement("path", { d: "M2.13428 7.82223L2.04492 8.61719L2.50865 8.66931L2.59801 7.87435L2.13428 7.82223Z", fill: "black" }));
}
