// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .radioWithColors {
    width: 264px;
    display: flex;
    justify-content: space-between;
}

#new-dol .carPreview {
    width: 582px;
    border: 1px solid var(--light-grey);
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.2);
}

#new-dol .carPreviewImage {
    display: block;
    max-width: 100%;
}

#new-dol .colorIcon {
    width: 40px;
	height: 40px;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
}

#new-dol .colorIconImage {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #707070;
}`, "",{"version":3,"sources":["webpack://./src/pages/demo/components/layoutRadioButtonsWithCarColor/layoutRadioButtonsWithCarColor.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,mCAAmC;IACnC,+CAA+C;AACnD;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,WAAW;CACd,YAAY;CACZ,uBAAuB;CACvB,mBAAmB;CACnB,iBAAiB;AAClB;;AAEA;IACI,cAAc;IACd,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,yBAAyB;AAC7B","sourcesContent":[".radioWithColors {\n    width: 264px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.carPreview {\n    width: 582px;\n    border: 1px solid var(--light-grey);\n    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.2);\n}\n\n.carPreviewImage {\n    display: block;\n    max-width: 100%;\n}\n\n.colorIcon {\n    width: 40px;\n\theight: 40px;\n\tjustify-content: center;\n\talign-items: center;\n\tmargin-left: 10px;\n}\n\n.colorIconImage {\n    display: block;\n    width: 32px;\n    height: 32px;\n    border-radius: 50%;\n    object-fit: cover;\n    border: 1px solid #707070;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
