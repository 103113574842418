export function AuditDocumentationConfig() {
    return [
        {
            key: 'DISCOUNT_GROUP_BELONGING',
            label: 'Dokument potwierdzający przynależność do grupy rabatowej – o ile nie zostało to załączone na etapie składania wniosku jako Załącznik do wniosku'
        },
        {
            key: 'HANDOVER_CONFIRMATION',
            label: 'Potwierdzenie wydania samochodu Klientowi – protokół odbioru samochodu (w przypadku, kiedy właścicielem samochodu jest firma finansująca, a Klient jest docelowym użytkownikiem)'
        },
        {
            key: 'INSURANCE_POLICY',
            label: 'Kopie polis OC aktualnie rozliczanych samochodów'
        },
        {
            key: 'ADDTIONAL_EQUIPMENT',
            label: 'Dokument potwierdzający wyposażenie dodatkowe'
        },
        {
            key: 'RESALE_PROHIBITION',
            label: 'Dokument o zakazie odsprzedaży samochodu/ów przed upływem 6 miesięcy oraz przed osiągnięciem przebiegu 6 000 km (obowiązkowy dla GR2 i OS, dla pozostałych grup rabatowych - na prośbę VCP)'
        },
        {
            key: 'OTHER',
            label: 'Inne'
        },
        {
            key: 'SUPPLEMENTS',
            label: 'Uzupełnienie braków audytowych'
        }
    ];
}
