import React from 'react';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import './radioGroup.css';
import { TextAmount } from 'components/textAmount/TextAmount';
function Root(_a) {
    var defaultValue = _a.defaultValue, value = _a.value, onValueChange = _a.onValueChange, children = _a.children;
    return React.createElement(RadixRadioGroup.Root, { value: value, defaultValue: defaultValue, onValueChange: onValueChange }, children);
}
function FinancingRoot(_a) {
    var defaultValue = _a.defaultValue, value = _a.value, onValueChange = _a.onValueChange, children = _a.children;
    return React.createElement(RadixRadioGroup.Root, { className: "radioFinancingRoot", value: value, defaultValue: defaultValue, onValueChange: onValueChange }, children);
}
function Layout(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'radioLayout' }, children);
}
function LayoutHorizontal(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'radioLayoutHorizontal' }, children);
}
function LayoutWithAmount(_a) {
    var price = _a.price, children = _a.children;
    return React.createElement("div", { className: 'layoutRadioWithAmount' },
        React.createElement(Layout, null, children),
        React.createElement(TextAmount, { bold: true }, price));
}
function LayoutForExchengableOptions(_a) {
    var children = _a.children, optionNo = _a.optionNo;
    return React.createElement("div", { className: 'radioLayoutForExchangableOptions' },
        React.createElement("div", { className: 'radioLayoutOptions' }, children),
        React.createElement("div", null, optionNo));
}
function Item(_a) {
    var value = _a.value, id = _a.id, children = _a.children;
    return React.createElement(RadixRadioGroup.Item, { value: value, id: id, className: 'radioItem' }, children);
}
function Indicator() {
    return React.createElement(RadixRadioGroup.Indicator, { className: 'radioIndicator' });
}
function Placeholder() {
    return React.createElement("div", { className: 'placeholderRadio' });
}
export var RadioGroup = {
    Root: Root,
    FinancingRoot: FinancingRoot,
    Layout: Layout,
    LayoutHorizontal: LayoutHorizontal,
    LayoutWithAmount: LayoutWithAmount,
    LayoutForExchengableOptions: LayoutForExchengableOptions,
    Item: Item,
    Indicator: Indicator,
    Placeholder: Placeholder
};
