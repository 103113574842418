// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .priceListsHeaderWrapper {
    width: 100%;
    background-color: var(--light-grey);
    padding: 16px 59px;
}

#new-dol .priceListHeader {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--main-text-color);
}`, "",{"version":3,"sources":["webpack://./src/components/priceListsHeader/priceListsHeader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mCAAmC;IACnC,kBAAkB;AACtB;;AAEA;IACI,+BAA+B;IAC/B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,6BAA6B;AACjC","sourcesContent":[".priceListsHeaderWrapper {\n    width: 100%;\n    background-color: var(--light-grey);\n    padding: 16px 59px;\n}\n\n.priceListHeader {\n    font-family: var(--font-family);\n    font-weight: 500;\n    font-size: 20px;\n    line-height: 28px;\n    color: var(--main-text-color);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
